import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { CreateUserGroupModal } from './Modals/CreateUserGroupModal';

export const EmptyList = ({ refreshUserGroups, addFlashMessage, handleError }) => {
    const [createModalOpen, setCreateModalOpen] = useState(false);

    const toggleCreateModal = () => setCreateModalOpen(!createModalOpen);

    return (
        <>
            <p>Create your first user group!</p>

            <Button color="primary" onClick={toggleCreateModal}>
                Create User Group
            </Button>

            {createModalOpen && (
                <CreateUserGroupModal
                    isOpen={createModalOpen}
                    toggle={toggleCreateModal}
                    addFlashMessage={addFlashMessage}
                    refreshUserGroups={refreshUserGroups}
                    handleError={handleError}
                />
            )}
        </>
    );
};
