import React, { useState, useRef, useCallback } from 'react';
import ReactCrop from 'react-image-crop';
import { Button, Modal, ModalFooter } from 'reactstrap';
import { ModalBodyWithClose } from 'common/ModalBodyWithClose';
import { SpinnerButton } from 'common/SpinnerButton';
import 'react-image-crop/dist/ReactCrop.css';

export const CropModal = ({ isOpen, toggle, submitting, imagePreviewUrl, onSubmit }) => {
    const imgRef = useRef(null);
    const [crop, setCrop] = useState({ unit: '%', width: 100, height: 80 });
    const [previewUrl, setPreviewUrl] = useState();

    const onLoad = useCallback((img) => {
        imgRef.current = img;
    }, []);

    const makeClientCrop = async (crop) => {
        if (imgRef.current && crop.width && crop.height) {
            await createCropPreview(imgRef.current, crop, 'newFile.jpeg');
        }
    };

    const createCropPreview = async (image, crop) => {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(image, crop.x * scaleX, crop.y * scaleY, crop.width * scaleX, crop.height * scaleY, 0, 0, crop.width, crop.height);

        setPreviewUrl(canvas.toDataURL('image/jpeg'));
    };

    if (!imagePreviewUrl) return null;

    return (
        <Modal isOpen={isOpen} toggle={toggle} className="photo-upload-modal">
            <div>
                <ModalBodyWithClose toggle={toggle}>
                    <h2>Crop your image</h2>

                    <div>
                        <ReactCrop
                            src={imagePreviewUrl}
                            onImageLoaded={onLoad}
                            crop={crop}
                            onChange={(c) => setCrop(c)}
                            onComplete={makeClientCrop}
                        />
                    </div>
                </ModalBodyWithClose>

                <ModalFooter>
                    <Button color="secondary" onClick={toggle}>
                        Cancel
                    </Button>

                    <SpinnerButton type="modal" color="primary" onClick={() => onSubmit(previewUrl, toggle)} submitting={submitting} title="Upload" />
                </ModalFooter>
            </div>
        </Modal>
    );
};
