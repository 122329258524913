import React, { useState, useContext } from 'react';
import { Col, Row, FormGroup, Input } from 'reactstrap';
import { useDevice } from 'hooks/useMediaQuery';
import { AuthContext } from 'contexts/AuthContext';
import { StatisticsTab } from './DashboardTabs/StatisticsTab';
import { ComparativeAnalysis } from './DashboardTabs/ComparativeAnalysis';
import './style.scss';

export const Dashboard = () => {
    const { state, handleError } = useContext(AuthContext);
    const { isMobile } = useDevice();
    const [activeView, setActiveView] = useState('alert-statistics');
    const isHasAccessComparativeSet = state?.profile?.organization?.accesses?.accessComparativeSet;

    const handleTabChange = () => {
        switch (activeView) {
            case 'alert-statistics':
                return <StatisticsTab type="alert" handleError={handleError} />;
            case 'comparative-analysis':
                return <ComparativeAnalysis handleError={handleError} />;
            case 'newsletter-statistics':
                return <StatisticsTab type="newsletter" handleError={handleError} />;
            default:
                return <StatisticsTab type="alert" handleError={handleError} />;
        }
    };

    const alertStatsActive = activeView === 'alert-statistics' ? 'active' : '';
    const compActive = activeView === 'comparative-analysis' ? 'active' : '';
    const newsStatsActive = activeView === 'newsletter-statistics' ? 'active' : '';

    return (
        <div className="dashboard">
            <div className="animated fadeIn mb-4">
                <Row>
                    <Col xs="12" className="col-no-padding-mobile">
                        {!isMobile ? (
                            <Col xs="12" className="m-0 p-0">
                                <div className="dashboard__tab-container">
                                    <div className={'dashboard__tab ' + alertStatsActive} onClick={() => setActiveView('alert-statistics')}>
                                        Content Statistics
                                    </div>

                                    <div className={'dashboard__tab ' + newsStatsActive} onClick={() => setActiveView('newsletter-statistics')}>
                                        Newsletter Statistics
                                    </div>

                                    {!!isHasAccessComparativeSet && (
                                        <div className={'dashboard__tab ' + compActive} onClick={() => setActiveView('comparative-analysis')}>
                                            Comparative Analysis
                                        </div>
                                    )}
                                </div>
                            </Col>
                        ) : (
                            <div
                                style={{
                                    width: '100%',
                                    marginBottom: '20px',
                                }}
                                className="account__menu">
                                <FormGroup>
                                    <Input onChange={(e) => setActiveView(e.target.value)} type="select" name="menu">
                                        <option value="alert-statistics">Alert Statistics</option>
                                        <option value="newsletter-statistics">Newsletter Statistics</option>
                                        {!!isHasAccessComparativeSet && <option value="comparative-analysis">Comparative Analysis</option>}
                                    </Input>
                                </FormGroup>
                            </div>
                        )}
                    </Col>
                    <Col xs="12" className="col-no-padding-mobile">
                        {handleTabChange()}
                    </Col>
                </Row>
            </div>
        </div>
    );
};
