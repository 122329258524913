import React, { useState, useEffect } from 'react';
import { differenceBy } from 'lodash';
import { Button, Modal, ModalFooter } from 'reactstrap';
import { sortObjects, filterByValue } from 'utils/helpers';
import { DualSelectBoxContainer } from 'common/DualSelect/DualSelectBoxContainer';
import { ModalBodyWithClose } from 'common/ModalBodyWithClose';
import { SpinnerButton } from 'common/SpinnerButton';
import './style.scss';

export const DualSelect = ({
    selectedList,
    fullList,
    onSelect,
    contentType,
    hideDoubleArrows,
    hideGroups,
    withConfirm,
    isSourcesOnly,
    isSearch,
    groups: propsGroups,
}) => {
    const [unassignedItems, setUnassignedItems] = useState([]);
    const [assignedItems, setAssignedItems] = useState([]);
    const [selectedUnassignedItems, setSelectedUnassignedItems] = useState([]);
    const [selectedAssignedItems, setSelectedAssignedItems] = useState([]);
    const [filteredUnassignedItems, setFilteredUnassignedItems] = useState([]);
    const [filteredAssignedItems, setFilteredAssignedItems] = useState([]);
    const [assignedSearchValue, setAssignedSearchValue] = useState('');
    const [assignedSortDirection, setAssignedSortDirection] = useState('asc');

    const [unassignedSearchValue, setUnassignedSearchValue] = useState('');
    const [unassignedSortDirection, setUnassignedSortDirection] = useState('asc');
    const [unassignedViewType, setUnassignedViewType] = useState('sources');
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);

    useEffect(() => {
        const groups = propsGroups || [];
        const uniqueFullList = differenceBy(fullList, selectedList, 'id');
        const uniqueListWithGroups = [
            ...uniqueFullList,
            ...groups.map((group) => {
                return {
                    id: group.id,
                    name: group.name,
                    group: true,
                    items: contentType === 'users' ? group.users : group.alerts,
                };
            }),
        ];
        const sortedFullList = sortObjects(uniqueListWithGroups, 'name', 'asc');
        const sortedSelectedList = sortObjects(selectedList, 'name', 'asc');
        const sortedByView = isSearch ? sortedFullList.filter((item) => !item.hasOwnProperty('groups')) : sortedFullList;

        setUnassignedItems(sortedByView);
        setAssignedItems(sortedSelectedList);
    }, [propsGroups, fullList, selectedList]);

    useEffect(() => {
        const newUnassignedItems = [...unassignedItems];
        const filteredBySearch = filterByValue(newUnassignedItems, 'name', unassignedSearchValue);
        const sortedByDirection = sortObjects(filteredBySearch, 'name', unassignedSortDirection);
        const sortedByView = isSearch
            ? sortedByDirection.filter((item) => {
                  if (unassignedViewType === 'sources') {
                      return !item.hasOwnProperty('group');
                  } else {
                      return item.hasOwnProperty('group');
                  }
              })
            : sortedByDirection;

        setFilteredUnassignedItems(sortedByView);
    }, [unassignedSearchValue, unassignedSortDirection, unassignedViewType, unassignedItems]);

    useEffect(() => {
        const newAssignedItems = [...assignedItems];
        const filteredBySearch = filterByValue(newAssignedItems, 'name', assignedSearchValue);
        const sortedByDirection = sortObjects(filteredBySearch, 'name', assignedSortDirection);

        setFilteredAssignedItems(sortedByDirection);
    }, [assignedSearchValue, assignedSortDirection, assignedItems]);

    const toggleConfirmModal = () => setConfirmModalOpen(!confirmModalOpen);

    const handleSearchFilter = (source, searchValue) => {
        if (source === 'unassigned') {
            setUnassignedSearchValue(searchValue);
            const newUnassignedList = unassignedItems.filter((item) => {
                return item.name.toLowerCase().includes(searchValue.toLowerCase());
            });
            const sortedList = sortObjects(newUnassignedList, 'name', unassignedSortDirection);
            setFilteredUnassignedItems(sortedList);
        } else {
            setAssignedSearchValue(searchValue);
            const newAssignedList = assignedItems.filter((item) => {
                return item.name.toLowerCase().includes(searchValue.toLowerCase());
            });
            const sortedList = sortObjects(newAssignedList, 'name', assignedSortDirection);
            setFilteredAssignedItems(sortedList);
        }
    };

    const handleClearSearch = (source) => {
        handleSearchFilter(source, '');
    };

    const moveLeft = () => {
        const newUnassignedItems = [...unassignedItems];
        const newAssignedItems = [...assignedItems];

        if (selectedAssignedItems.length > 0) {
            selectedAssignedItems.map((item) => {
                newUnassignedItems.push(item);
                newAssignedItems.splice(newAssignedItems.indexOf(item), 1);
            });
            setAssignedItems(newAssignedItems);
            setUnassignedItems(newUnassignedItems);
            setFilteredAssignedItems(newAssignedItems);
            setFilteredUnassignedItems(newUnassignedItems);
            setSelectedAssignedItems([]);
            setSelectedUnassignedItems([]);
            onSelect(newAssignedItems);
        }
    };

    const moveRight = () => {
        const newUnassignedItems = [...unassignedItems];
        const newAssignedItems = [...assignedItems];

        if (selectedUnassignedItems.length > 0) {
            selectedUnassignedItems.map((item) => {
                if (item.group) {
                    item.items.map((alertInGroup) => {
                        let foundAlert = newUnassignedItems.find((i) => i.id === alertInGroup.id);
                        if (foundAlert && newAssignedItems.indexOf(foundAlert === -1)) {
                            newAssignedItems.push(foundAlert);
                            newUnassignedItems.splice(newUnassignedItems.indexOf(foundAlert), 1);
                        }
                    });
                } else {
                    let foundItem = newAssignedItems.find((n) => n.id === item.id);
                    if (!foundItem) {
                        newAssignedItems.push(item);
                        newUnassignedItems.splice(newUnassignedItems.indexOf(item), 1);
                    }
                }
            });
            setAssignedItems(newAssignedItems);
            setUnassignedItems(newUnassignedItems);
            setSelectedAssignedItems([]);
            setSelectedUnassignedItems([]);
            onSelect(newAssignedItems);
        }
    };

    const moveAllRight = (showConfirm) => {
        if (showConfirm) {
            toggleConfirmModal();
            return;
        }
        const newFilteredItems = [...filteredUnassignedItems];
        const newUnassignedItems = [...unassignedItems];
        const newAssignedItems = [...assignedItems];

        newFilteredItems.map((item) => {
            if (item.group) {
                item.items.map((alertInGroup) => {
                    let foundAlert = newUnassignedItems.find((i) => {
                        return i.id === alertInGroup.id;
                    });
                    if (foundAlert) {
                        if (newAssignedItems.indexOf(foundAlert === -1)) {
                            newAssignedItems.push(foundAlert);
                            newUnassignedItems.splice(newUnassignedItems.indexOf(foundAlert), 1);
                        }
                    }
                });
            } else {
                let foundItem = newAssignedItems.find((n) => n.id === item.id);
                if (!foundItem) {
                    newAssignedItems.push(item);
                    newUnassignedItems.splice(newUnassignedItems.indexOf(item), 1);
                }
            }
        });
        setAssignedItems(newAssignedItems);
        setUnassignedItems(newUnassignedItems);
        setSelectedAssignedItems([]);
        setSelectedUnassignedItems([]);
        onSelect(newAssignedItems);
    };

    const moveAllLeft = () => {
        const newFilteredItems = [...filteredAssignedItems];
        const newUnassignedItems = [...unassignedItems];
        const newAssignedItems = [...assignedItems];

        newFilteredItems.map((item) => {
            newUnassignedItems.push(item);
            newAssignedItems.splice(newAssignedItems.indexOf(item), 1);
        });

        setAssignedItems(newAssignedItems);
        setUnassignedItems(newUnassignedItems);
        setFilteredAssignedItems(newAssignedItems);
        setFilteredUnassignedItems(newUnassignedItems);
        setSelectedAssignedItems([]);
        setSelectedUnassignedItems([]);
        onSelect(newAssignedItems);
    };

    const handleItemSelect = (item, source) => {
        if (source === 'unassigned') {
            let newSelectedItems = [...selectedUnassignedItems];
            if (newSelectedItems.indexOf(item) > -1) {
                newSelectedItems.splice(newSelectedItems.indexOf(item), 1);
            } else {
                newSelectedItems.push(item);
            }
            setSelectedUnassignedItems(newSelectedItems);
        } else {
            let newSelectedItems = [...selectedAssignedItems];
            if (newSelectedItems.indexOf(item) > -1) {
                newSelectedItems.splice(newSelectedItems.indexOf(item), 1);
            } else {
                newSelectedItems.push(item);
            }
            setSelectedAssignedItems(newSelectedItems);
        }
    };

    const handleModalConfirm = () => {
        moveAllRight();
        toggleConfirmModal();
    };

    return (
        <div className="dual-select">
            <DualSelectBoxContainer
                source="unassigned"
                list={filteredUnassignedItems}
                selected={selectedUnassignedItems}
                handleItemSelect={handleItemSelect}
                handleSearchFilter={handleSearchFilter}
                handleClearSearch={handleClearSearch}
                handleSortDirection={setUnassignedSortDirection}
                sortDirection={unassignedSortDirection}
                searchValue={unassignedSearchValue}
                contentType={contentType}
                handleViewTypeChange={setUnassignedViewType}
                viewType={unassignedViewType}
                hideGroups={hideGroups}
                isSourcesOnly={isSourcesOnly}
                isSearchLeftSide={isSearch}
            />
            <div className="dual-select__controls">
                {!hideDoubleArrows && (
                    <div className="dual-select__control-item" onClick={() => moveAllRight(withConfirm)}>
                        <i className="fa fa-chevron-right" />
                        <i className="fa fa-chevron-right" />
                    </div>
                )}
                <div className="dual-select__control-item" onClick={moveRight}>
                    <i className="fa fa-chevron-right" />
                </div>
                <div className="dual-select__control-item" onClick={moveLeft}>
                    <i className="fa fa-chevron-left" />
                </div>
                {!hideDoubleArrows && (
                    <div className="dual-select__control-item" onClick={moveAllLeft}>
                        <i className="fa fa-chevron-left" />
                        <i className="fa fa-chevron-left" />
                    </div>
                )}
            </div>
            <DualSelectBoxContainer
                source="assigned"
                list={filteredAssignedItems}
                selected={selectedAssignedItems}
                handleItemSelect={handleItemSelect}
                handleSearchFilter={handleSearchFilter}
                handleClearSearch={handleClearSearch}
                handleSortDirection={setAssignedSortDirection}
                sortDirection={assignedSortDirection}
                searchValue={assignedSearchValue}
                contentType={contentType}
                isSourcesOnly={isSourcesOnly}
                isSearchRightSide={isSearch}
            />
            {confirmModalOpen && (
                <Modal isOpen={confirmModalOpen} toggle={toggleConfirmModal}>
                    <div>
                        <ModalBodyWithClose toggle={toggleConfirmModal}>
                            <span>You have selected all existing alerts for assignment. Are you sure you want to assign that many alerts?</span>
                        </ModalBodyWithClose>
                        <ModalFooter>
                            <Button color="secondary" onClick={toggleConfirmModal}>
                                Cancel
                            </Button>
                            <SpinnerButton type="modal" color="primary" onClick={handleModalConfirm} title="Confirm" />
                        </ModalFooter>
                    </div>
                </Modal>
            )}
        </div>
    );
};
