import React from 'react';
import ModalBody from 'reactstrap/esm/ModalBody';
import './style.scss';

export const ModalBodyWithClose = (props) => {
    return (
        <ModalBody {...props} toggle="toggle">
            <div className="modal-close-btn" onClick={props.toggle}>
                <span aria-hidden="true">×</span>
            </div>
            {props.children}
        </ModalBody>
    );
};
