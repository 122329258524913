import React, { useState, useEffect } from 'react';
import { Modal, ModalFooter, Button } from 'reactstrap';
import { addAlertLabel, editAlertLabel } from 'utils/api/alertsAPI';
import { SpinnerButton } from 'common/SpinnerButton';
import { InputWithError } from 'common/InputWithError';
import { ModalBodyWithClose } from 'common/ModalBodyWithClose';

export const CreateEditLabelModal = ({ isOpen, toggle, refreshLabels, handleError, addFlashMessage, item, isEdit = false }) => {
    const [name, setName] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const [errors, setErrors] = useState(false);

    useEffect(() => {
        setName('');
        setErrors(false);
    }, [isOpen]);

    useEffect(() => {
        item && setName(item.name);
    }, [item]);

    const handleSubmit = async () => {
        try {
            setSubmitting(true);
            setErrors(null);

            isEdit && item.id ? await editAlertLabel(item.id, { name }) : await addAlertLabel({ name });
            refreshLabels && (await refreshLabels());

            addFlashMessage('success', `Alert label successfully ${isEdit ? 'edited' : 'created'}`);
            setSubmitting(false);
            toggle();
        } catch (err) {
            setSubmitting(false);
            handleError(err, setErrors);
        }
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle} className="create-label-modal">
            {isEdit && !item ? null : (
                <>
                    <ModalBodyWithClose toggle={toggle}>
                        <h2>{isEdit ? 'Edit' : 'Create'} Label</h2>
                        <InputWithError
                            prependIcon={<i className="fa fa-tag" />}
                            errorObj={errors}
                            name="name"
                            value={name}
                            type="text"
                            placeholder="Label name"
                            onChange={setName}
                        />
                    </ModalBodyWithClose>
                    <ModalFooter>
                        <Button color="secondary" onClick={toggle}>
                            Cancel
                        </Button>
                        <SpinnerButton
                            type="modal"
                            color="primary"
                            onClick={handleSubmit}
                            submitting={submitting}
                            title={isEdit ? 'Update' : 'Create'}
                        />
                    </ModalFooter>
                </>
            )}
        </Modal>
    );
};
