import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { useQuery } from 'react-query';
import { AuthContext } from 'contexts/AuthContext';
import { FilterContext } from 'contexts/FilterContext';
import { FlashMessageContext } from 'contexts/FlashMessageContext';
import { SingleUserBlock } from 'common/SingleUserBlock';
import { getUserLabels, getUsers } from 'utils/api/usersAPI';
import { OrganizationList } from './OrganizationList';
import './style.scss';

export const UsersList = () => {
    const { state, handleError } = useContext(AuthContext);
    const { addFlashMessage } = useContext(FlashMessageContext);
    const { setFiltersObject, userFilters } = useContext(FilterContext);

    const { paginationCurrentPageNumber, activeSortProperty, activeSortDirection, activeFilterLabels, searchValue, searchProperty } = userFilters;

    const [users, setUsers] = useState([]);
    const [fetchingData, setFetchingData] = useState(true);
    const [tempSearchValue, setTempSearchValue] = useState('');
    const [submittingSearch, setSubmittingSearch] = useState(false);
    const [showFirstTimeMessage, setShowFirstTimeMessage] = useState(true);
    const [totalPages, setTotalPages] = useState(1);
    const [initialLoad, setInitialLoad] = useState(true);
    const [fetchingCsv, setFetchingCsv] = useState(false);

    const { data: labelsData } = useQuery('getUserLabels', () => getUserLabels(), {
        onError: () => addFlashMessage('danger', 'Unable to fetch alerts'),
    });

    const fetchUsers = async (csv) => {
        try {
            if (csv) {
                setFetchingCsv(true);

                const title = 'ozmosys_users_' + moment().tz(moment.tz.guess()).format('MMDDYYYY');
                await getUsers(
                    false,
                    paginationCurrentPageNumber,
                    10000,
                    activeSortProperty,
                    activeSortDirection,
                    searchProperty,
                    searchValue,
                    activeFilterLabels,
                    true,
                    title
                );

                setFetchingCsv(false);
            } else {
                setSubmittingSearch(true);

                const refreshCount = initialLoad || paginationCurrentPageNumber === 1 || !users.length;
                const userData = await getUsers(
                    refreshCount,
                    paginationCurrentPageNumber,
                    50,
                    activeSortProperty,
                    activeSortDirection,
                    searchProperty,
                    searchValue,
                    activeFilterLabels
                );

                setUsers(userData.result);

                if (refreshCount) setTotalPages(userData.navigation.total_pages);
                if (tempSearchValue !== searchValue) setTempSearchValue(searchValue);

                setFetchingData(false);
                setSubmittingSearch(false);

                if (userData.result.length > 0) showFirstTimeMessage && setShowFirstTimeMessage(false);
                initialLoad && setInitialLoad(false);
            }
        } catch (err) {
            setFetchingData(false);
            fetchingCsv && setFetchingCsv(false);
            setSubmittingSearch(false);
        }
    };

    const refreshUsers = async (callback) => {
        await fetchUsers();
        callback && callback();
    };

    const handleSearchValueSubmit = () => {
        if (tempSearchValue.length > 2048) {
            addFlashMessage('danger', 'Your search exceeds the maximum number of allowed characters (2048)');
            return;
        }

        const filters = { ...userFilters };
        filters.searchValue = tempSearchValue;
        filters.paginationCurrentPageNumber = 1;
        setFiltersObject('users', filters);
    };

    const handleActiveFilterLabelSelect = (labelName) => {
        const filterArr = [...activeFilterLabels];

        if (filterArr.indexOf(labelName) > -1) {
            filterArr.splice(filterArr.indexOf(labelName), 1);
        } else {
            filterArr.push(labelName);
        }

        const filters = { ...userFilters };
        filters.activeFilterLabels = filterArr;
        filters.paginationCurrentPageNumber = 1;
        setFiltersObject('users', filters);
    };

    const handleClearSearchValue = () => {
        const filters = { ...userFilters };
        filters.searchValue = '';
        filters.paginationCurrentPageNumber = 1;

        setFiltersObject('users', filters);
        setTempSearchValue('');
    };

    useEffect(() => {
        fetchUsers();
    }, [userFilters]);

    return (
        <>
            {state.isSingleUser && <SingleUserBlock itemType="users" wide />}
            <OrganizationList
                users={users}
                isSingleUser={state.isSingleUser}
                fetchingData={fetchingData}
                userFilters={userFilters}
                refreshUsers={refreshUsers}
                tempSearchValue={tempSearchValue}
                setTempSearchValue={setTempSearchValue}
                submittingSearch={submittingSearch}
                handleSearchValueSubmit={handleSearchValueSubmit}
                allLabels={labelsData}
                handleActiveFilterLabelSelect={handleActiveFilterLabelSelect}
                handleClearSearchValue={handleClearSearchValue}
                totalPages={totalPages}
                addFlashMessage={addFlashMessage}
                handleError={handleError}
                setFiltersObject={setFiltersObject}
                downloadFilteredCsv={() => fetchUsers(true)}
                fetchingCsv={fetchingCsv}
            />
        </>
    );
};
