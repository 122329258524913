import React, { useState, useContext, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Row, Col, Button } from 'reactstrap';
import { AuthContext } from 'contexts/AuthContext';
import { signUp } from 'utils/api/authAPI';
import { InputWithError } from 'common/InputWithError';
import { SpinnerButton } from 'common/SpinnerButton';
import Logo from 'images/logo.png';
import './style.scss';

export const SignUp = () => {
    const navigate = useNavigate();
    const { handleError } = useContext(AuthContext);

    const [submitting, setSubmitting] = useState(false);
    const [email, setEmail] = useState('');
    const [emailSent, setEmailSent] = useState(false);
    const [errors, setErrors] = useState(null);

    useEffect(() => {
        localStorage.removeItem('token');
    }, []);

    const redirectToLogin = () => {
        navigate('/signin');
    };

    const onSubmit = async () => {
        try {
            setSubmitting(true);
            setErrors(null);

            await signUp(email, window.location.origin + '/registration/confirm');

            setEmailSent(true);
        } catch (err) {
            setSubmitting(false);
            handleError(err, setErrors);
        }
    };

    return (
        <div className="auth-block">
            <div className="auth-block__inner">
                <div className="animated fadeIn">
                    <Row className="justify-content-center mt-5">
                        <Col md={9}>
                            <div className="auth-block__image-container">
                                <a href="/">
                                    <img src={Logo} alt="Ozmosys" className="auth-block__image img-fluid" />
                                </a>
                            </div>
                            {emailSent ? (
                                <div>
                                    <div className="text-center mb-4">
                                        <h2>Complete Registration</h2>
                                        <p className="mb-2 mt-3 text-muted">We just emailed a confirmation link to {email}.</p>
                                        <p className="mb-4 mt-0 text-muted">Follow the link to complete your account set-up.</p>
                                    </div>
                                    <Button block className="mt-4 " color={'primary'} onClick={redirectToLogin}>
                                        Return to Login
                                    </Button>
                                </div>
                            ) : (
                                <div>
                                    <div className="text-center">
                                        <h2>Sign Up For Free</h2>
                                        <p className="mb-4 mt-3 text-muted">Create an account to search for anything</p>
                                    </div>

                                    <InputWithError
                                        errorObj={errors}
                                        name="email"
                                        placeholder="Email"
                                        onChange={setEmail}
                                        type="email"
                                        value={email}
                                        autoCompleteOn
                                        prependIcon="@"
                                        marginBottomClass="mb-3"
                                    />

                                    <Row>
                                        <Col xs="12" className="text-right mb-2">
                                            <SpinnerButton
                                                color="primary"
                                                block
                                                disabled={email.length < 2}
                                                onClick={onSubmit}
                                                submitting={submitting}
                                                title="Create Account"
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="text-center">
                                            <span className="align-middle text-muted">Already have an account?</span>
                                            <Link to="/signin">
                                                <Button type="button" color="link">
                                                    Sign In
                                                </Button>
                                            </Link>
                                        </Col>
                                    </Row>
                                </div>
                            )}
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
};
