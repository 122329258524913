import React, { Component } from 'react';
import { Page404 } from 'components/Page404';

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {}

    render() {
        if (this.state.hasError) {
            return <Page404 fromErrorBoundary />;
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
