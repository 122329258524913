import React, { useEffect, useState } from 'react';
import { cloneDeep } from 'lodash';
import {
    addAlertToSection,
    clearNewsletterSection,
    moveAlertBetweenNewsletterSections,
    removeAlertFromSection,
    sortNewsletterSections,
} from 'utils/api/newslettersAPI';
import { Sections } from './Sections';
import { Sources } from './Sources';

export const ManageSources = ({ newsletterData, addFlashMessage, refreshNewsletter, handleError }) => {
    const [savingSectionChanges, setSavingSectionChanges] = useState(false);
    const [newsletterForDom, setNewsletterForDom] = useState(null);

    useEffect(() => {
        setNewsletterForDom(newsletterData);
    }, [newsletterData]);

    const assignAlertToSection = async (alertId, newSectionId) => {
        try {
            await addAlertToSection(newsletterData.id, newSectionId, { alerts: [alertId] });
            refreshNewsletter();
        } catch (err) {
            handleError(err);
        }
    };

    const removeAllAlertsFromSection = async (e, sectionId) => {
        try {
            e.stopPropagation();
            await clearNewsletterSection(newsletterData.id, sectionId);
            refreshNewsletter();
        } catch (err) {
            handleError(err);
        }
    };

    const handleSectionSorting = async (sortOrderArr) => {
        setSavingSectionChanges(true);

        try {
            await sortNewsletterSections(newsletterData.id, { section: sortOrderArr });
            refreshNewsletter();
        } catch (err) {
            handleError(err);
        }

        setSavingSectionChanges(false);
    };

    const moveAlertBetweenSections = async (alertId, previousSectionId, nextSectionId, sortOrder, isRemoveAlert) => {
        try {
            const nextColumn = newsletterForDom.sections.find((obj) => obj.id === nextSectionId);
            const prevColumn = newsletterForDom.sections.find((obj) => obj.id === previousSectionId);
            const selectedAlert = prevColumn.alerts.find((alert) => alert.id === alertId);

            if (isRemoveAlert) {
                const newStateSections = cloneDeep(newsletterForDom.sections);
                const prevColumnIndex = newsletterForDom.sections.indexOf(prevColumn);
                const prevColumnAlerts = Array.from(prevColumn.alerts);

                newStateSections[prevColumnIndex] = { ...prevColumn, alerts: prevColumnAlerts };
                newStateSections[prevColumnIndex] = { ...prevColumn, alerts: prevColumnAlerts };

                const newNewsletter = { ...newsletterData, sections: newStateSections };
                prevColumnAlerts.splice(prevColumnAlerts.indexOf(selectedAlert), 1);

                setNewsletterForDom(newNewsletter);

                await removeAlertFromSection(newsletterData.id, previousSectionId, alertId);
                refreshNewsletter();
                return;
            }

            if (!nextColumn || !prevColumn) {
                addFlashMessage('danger', 'Unable to move alert');
                return;
            }

            const nextColumnIndex = newsletterForDom.sections.indexOf(nextColumn);
            const prevColumnIndex = newsletterForDom.sections.indexOf(prevColumn);

            if (!selectedAlert) {
                addFlashMessage('danger', 'Unable to move alert');
                return;
            }

            const nextColumnAlerts = Array.from(nextColumn.alerts);
            const prevColumnAlerts = Array.from(prevColumn.alerts);
            selectedAlert.sortOrder = sortOrder;

            nextColumnAlerts.splice(sortOrder - 1, 0, selectedAlert);

            const newNextColumn = { ...nextColumn, alerts: nextColumnAlerts };
            const newPrevColumn = { ...prevColumn, alerts: prevColumnAlerts };

            prevColumnAlerts.splice(prevColumnAlerts.indexOf(selectedAlert), 1);
            const newStateSections = cloneDeep(newsletterForDom.sections);
            newStateSections[nextColumnIndex] = newNextColumn;
            newStateSections[prevColumnIndex] = newPrevColumn;

            const newNewsletter = { ...newsletterData, sections: newStateSections };

            setNewsletterForDom(newNewsletter);

            await moveAlertBetweenNewsletterSections(newsletterData.id, alertId, previousSectionId, { section: nextSectionId });
        } catch (err) {
            handleError(err);
        }
    };

    return (
        <div className="d-flex flex-1">
            {newsletterForDom && (
                <>
                    <Sections
                        newsletterData={newsletterForDom}
                        addFlashMessage={addFlashMessage}
                        refreshNewsletter={refreshNewsletter}
                        removeAllAlertsFromSection={removeAllAlertsFromSection}
                        handleSectionSorting={handleSectionSorting}
                        savingSectionChanges={savingSectionChanges}
                        moveAlertBetweenSections={moveAlertBetweenSections}
                        handleError={handleError}
                    />

                    <Sources newsletterData={newsletterForDom} assignAlertToSection={assignAlertToSection} />
                </>
            )}
        </div>
    );
};
