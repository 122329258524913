import React, { useContext, useState } from 'react';
import { Table } from 'reactstrap';
import { useQuery } from 'react-query';
import moment from 'moment-timezone';
import { AuthContext } from 'contexts/AuthContext';
import { LoadingSpinner } from 'common/LoadingSpinner';
import { DayPickerInput } from 'common/DayPickerInput';
import { getUserUsageNewslettersPerPeriod } from 'utils/api/statisticsAPI';
import { handleCaret } from 'utils/helpers';
import { CsvButton } from '../CsvButton';

export const UserUsageNewslettersReport = () => {
    const { handleError } = useContext(AuthContext);
    const [isOpenFromDate, setIsOpenFromDate] = useState(false);
    const [isOpenToDate, setIsOpenToDate] = useState(false);
    const [fetchingCsv, setFetchingCsv] = useState(false);
    const [fromDate, setFromDate] = useState(moment().subtract(1, 'month').format('YYYY-MM-DD'));
    const [toDate, setToDate] = useState(moment().format('YYYY-MM-DD'));
    const [activeSortProperty, setActiveSortProperty] = useState('userFullName');
    const [activeSortDirection, setActiveSortDirection] = useState('asc');
    const [isShowBounces, setIsShowBounces] = useState({});

    const { data, isLoading } = useQuery(
        ['getUserUsageNewslettersPerPeriod', fromDate, toDate, activeSortProperty, activeSortDirection],
        () => getUserUsageNewslettersPerPeriod(fromDate, toDate, activeSortProperty, activeSortDirection, false),
        {
            onError: (err) => handleError(err),
        }
    );

    const downloadFilteredCsv = async () => {
        setFetchingCsv(true);

        try {
            await getUserUsageNewslettersPerPeriod(fromDate, toDate, activeSortProperty, activeSortDirection, true);
        } catch (err) {
            handleError(err);
        }

        setFetchingCsv(false);
    };

    const handleSortableHeaderClick = (value) => {
        if (value === activeSortProperty) {
            activeSortDirection === 'asc' ? setActiveSortDirection('desc') : setActiveSortDirection('asc');
        } else {
            setActiveSortProperty(value);
            setActiveSortDirection('asc');
        }
    };

    const toggleShowBounces = (index) => {
        isShowBounces[index] = isShowBounces[index] ? !isShowBounces[index] : true;
        setIsShowBounces(isShowBounces);
    };

    return (
        <div className="list">
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <div>
                    <div className="list__utility-row">
                        <div className="list__search-container">
                            <DayPickerInput
                                name="userUsageNewslettersReport-from"
                                isOpen={isOpenFromDate}
                                setIsOpen={setIsOpenFromDate}
                                specificDate={fromDate}
                                setSpecificDate={setFromDate}
                                disabled={{ before: new Date(moment().subtract(12, 'months').format('YYYY-MM-DD')), after: new Date(toDate) }}
                            />

                            <DayPickerInput
                                name="userUsageNewslettersReport-to"
                                isOpen={isOpenToDate}
                                setIsOpen={setIsOpenToDate}
                                specificDate={toDate}
                                setSpecificDate={setToDate}
                                disabled={{ before: new Date(fromDate), after: new Date() }}
                            />

                            <CsvButton downloadFilteredCsv={downloadFilteredCsv} downloading={fetchingCsv} />
                        </div>
                    </div>

                    {!data?.length ? (
                        <div>No results found</div>
                    ) : (
                        <Table className="responsive-table alert-list-table">
                            <tbody>
                                <tr className="responsive-table header-row">
                                    <th className="responsive-table__sortable-th" onClick={() => handleSortableHeaderClick('userFullName')}>
                                        User {handleCaret(activeSortProperty, activeSortDirection, 'userFullName')}
                                    </th>

                                    <th className="responsive-table__sortable-th" onClick={() => handleSortableHeaderClick('numberOpenedEmails')}>
                                        # Opened Newsletters {handleCaret(activeSortProperty, activeSortDirection, 'numberOpenedEmails')}
                                    </th>

                                    <th className="responsive-table__sortable-th" onClick={() => handleSortableHeaderClick('numberSentEmails')}>
                                        # of Newsletters sent {handleCaret(activeSortProperty, activeSortDirection, 'numberSentEmails')}
                                    </th>

                                    <th className="responsive-table__sortable-th" onClick={() => handleSortableHeaderClick('rateOpenedEmails')}>
                                        Open Rate {handleCaret(activeSortProperty, activeSortDirection, 'rateOpenedEmails')}
                                    </th>

                                    <th className="responsive-table__sortable-th" onClick={() => handleSortableHeaderClick('numberBouncedEmails')}>
                                        Number of Bounced {handleCaret(activeSortProperty, activeSortDirection, 'numberBouncedEmails')}
                                    </th>

                                    <th className="responsive-table__sortable-th" onClick={() => handleSortableHeaderClick('name')}>
                                        Title {handleCaret(activeSortProperty, activeSortDirection, 'name')}
                                    </th>

                                    <th>Labels</th>
                                </tr>

                                {data?.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item?.userFullName || item?.name}</td>
                                        <td>{item.numberOpenedEmails}</td>
                                        <td>{item.numberSentEmails}</td>
                                        <td>{item.rateOpenedEmails}</td>

                                        <td>
                                            {item.numberBouncedEmails > 0 ? (
                                                isShowBounces[index] ? (
                                                    <>
                                                        {item?.bounces?.map((bounce, key) => (
                                                            <div key={key}>
                                                                <span>{bounce.type}</span>: <span>{bounce.count}</span>
                                                            </div>
                                                        ))}

                                                        {item.numberBouncedEmails}

                                                        <button className="btn btn-link" onClick={() => toggleShowBounces(index)}>
                                                            &nbsp;Hide
                                                        </button>
                                                    </>
                                                ) : (
                                                    <>
                                                        {item.numberBouncedEmails}

                                                        <button className="btn btn-link" onClick={() => toggleShowBounces(index)}>
                                                            Show
                                                        </button>
                                                    </>
                                                )
                                            ) : (
                                                0
                                            )}
                                        </td>

                                        <td>{item.name}</td>

                                        <td style={{ whiteSpace: 'pre-line' }}>{item.labels.join('\n')}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    )}
                </div>
            )}
        </div>
    );
};
