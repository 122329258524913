import React, { useState, useEffect } from 'react';
import { Modal, ModalFooter, Button } from 'reactstrap';
import { useQuery } from 'react-query';
import { SpinnerButton } from 'common/SpinnerButton';
import { LoadingSpinner } from 'common/LoadingSpinner';
import { DualSelect } from 'common/DualSelect';
import { InputWithError } from 'common/InputWithError';
import { ModalBodyWithClose } from 'common/ModalBodyWithClose';
import { addUserGroup, getUsersWithoutParams } from 'utils/api/usersAPI';

export const CreateUserGroupModal = ({ isOpen, toggle, refreshUserGroups, addFlashMessage, handleError }) => {
    const [name, setName] = useState('');
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [errors, setErrors] = useState(null);
    const [submitting, setSubmitting] = useState(false);

    const { data, isLoading } = useQuery('getUsersWithoutParams', () => getUsersWithoutParams(false, false, 1, 2000), {
        onError: (err) => handleError(err),
    });

    useEffect(() => {
        setName('');
    }, [isOpen]);

    const handleCreateGroup = async () => {
        try {
            setSubmitting(true);
            setErrors(null);

            await addUserGroup({ name: name || null, entities: selectedUsers.map((item) => item.id) });
            await refreshUserGroups();

            addFlashMessage('success', 'User group successfully created');
            setSubmitting(false);
            toggle();
        } catch (err) {
            setSubmitting(false);
            handleError(err, setErrors);
        }
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle} className="modal-lg">
            {isLoading ? (
                <LoadingSpinner padding text="Fetching user data" />
            ) : (
                <div>
                    <ModalBodyWithClose toggle={toggle}>
                        <h2>Create User Group</h2>
                        <InputWithError
                            prependIcon={<i className="fa fa-tag" />}
                            placeholder="User group name"
                            name="name"
                            value={name}
                            type="text"
                            onChange={setName}
                            errorObj={errors}
                        />
                        <div>
                            <DualSelect
                                selectedList={selectedUsers}
                                fullList={data?.result || []}
                                onSelect={setSelectedUsers}
                                contentType="users"
                                hideGroups
                            />
                        </div>
                    </ModalBodyWithClose>
                    <ModalFooter>
                        <Button color="secondary" onClick={toggle}>
                            Cancel
                        </Button>
                        <SpinnerButton type="modal" color="primary" onClick={handleCreateGroup} submitting={submitting} title="Create" />
                    </ModalFooter>
                </div>
            )}
        </Modal>
    );
};
