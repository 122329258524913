import React from 'react';
import { Input } from 'reactstrap';
import { useDevice } from 'hooks/useMediaQuery';
import { TabItem } from 'common/TabBar/TabItem';
import './style.scss';

export const TabBar = ({ items, activeItem, setActive, errorObj, useMobileSelect, shrink }) => {
    const { isMobile } = useDevice();

    const handleSelect = (itemData) => setActive(itemData);

    return (
        <>
            {useMobileSelect && isMobile ? (
                <div style={{ maxWidth: '391px' }}>
                    <Input onChange={(e) => handleSelect(e.target.value)} type="select" name="menu">
                        {!!items?.length &&
                            items.map((item, i) => (
                                <option key={i} value={item.data}>
                                    {item.name}
                                </option>
                            ))}
                    </Input>
                </div>
            ) : (
                <div className={'tab-bar ' + (shrink ? 'tab-bar--shrink' : '')}>
                    <div className="sub-tab-bar">
                        {!!items?.length &&
                            items.map((item) => (
                                <TabItem
                                    key={item?.type || item?.data}
                                    activeItem={activeItem}
                                    item={item}
                                    handleSelect={handleSelect}
                                    errorObj={errorObj}
                                    shrink={shrink}
                                    smallText={items && items.length > 6}
                                />
                            ))}
                    </div>
                </div>
            )}
        </>
    );
};
