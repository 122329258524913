import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Table } from 'reactstrap';
import { LoadingSpinner } from 'common/LoadingSpinner';
import { SearchInputWithSubmit } from 'common/SearchInput/SearchInputWithSubmit';
import { PaginationWrapper } from 'common/PaginationWrapper';
import { ListLabelDropdown } from 'common/Labels/ListLabelDropdown';
import { useDevice } from 'hooks/useMediaQuery';
import { handleCaret } from 'utils/helpers';
import { DeleteUserModal } from '../Modals/DeleteUserModal';
import { LiveOrLastPreview } from '../Modals/LiveOrLastPreview';
import { UsersListItem } from './UsersListItem';
import { UsersListMobileFilterDropdown } from './UsersMobileFilterDropdown';
import { UserCsvButton } from './UserCsvButton';

export const OrganizationList = ({
    users,
    fetchingData,
    allLabels,
    handleSearchValueSubmit,
    refreshUsers,
    handleClearSearchValue,
    submittingSearch,
    setFiltersObject,
    handleActiveFilterLabelSelect,
    tempSearchValue,
    setTempSearchValue,
    totalPages,
    addFlashMessage,
    handleError,
    userFilters,
    downloadFilteredCsv,
    fetchingCsv,
    isSingleUser,
}) => {
    const navigate = useNavigate();
    const { isMobile, isTablet, isDesktop } = useDevice();

    const { paginationCurrentPageNumber, activeSortProperty, activeSortDirection, activeFilterLabels } = userFilters;

    const [userToDelete, setUserToDelete] = useState(null);
    const [userToPreview, setUserToPreview] = useState(null);
    const [previewType, setPreviewType] = useState(null);
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);
    const [previewModalVisible, setPreviewModalVisible] = useState(false);
    const [activeMobileFilterValue, setActiveMobileFilterValue] = useState('');

    const goToCreateUser = () => navigate(`/account/users/create`);
    const goToEditUser = (id) => navigate(`/account/users/edit/${id}`);
    const togglePreviewModal = () => setPreviewModalVisible(!previewModalVisible);
    const handleDeleteButtonClick = (user) => setUserToDelete(user);
    const toggleDeleteModal = () => setDeleteModalVisible(!deleteModalVisible);

    const handlePageClick = (data) => {
        const filters = { ...userFilters };
        filters.paginationCurrentPageNumber = data.selected + 1;
        filters.useCount = false;
        setFiltersObject('users', filters);
    };

    const handleSortableHeaderClick = (value) => {
        const filters = { ...userFilters };

        if (value === activeSortProperty) {
            activeSortDirection === 'asc' ? (filters.activeSortDirection = 'desc') : (filters.activeSortDirection = 'asc');
        } else {
            filters.activeSortProperty = value;
            filters.activeSortDirection = 'asc';
        }

        setFiltersObject('users', filters);
    };

    const handlePreviewClick = (user, type) => {
        setPreviewType(type);
        setUserToPreview(user);
    };

    useEffect(() => {
        previewType && userToPreview && togglePreviewModal();
    }, [previewType, userToPreview]);

    useEffect(() => {
        if (!previewModalVisible) {
            setPreviewType(null);
            setUserToPreview(null);
        }
    }, [previewModalVisible]);

    useEffect(() => {
        userToDelete && toggleDeleteModal();
    }, [userToDelete]);

    const handleMobileFilterSelect = (val) => {
        const splitValue = val.split('-');
        const filters = { ...userFilters };
        filters.activeSortProperty = splitValue[0];
        filters.activeSortDirection = splitValue[1];
        setFiltersObject('users', filters);
    };

    const determineMobileFiltersValue = () => {
        const filterStr = userFilters.activeSortProperty + '-' + userFilters.activeSortDirection;
        setActiveMobileFilterValue(filterStr);
    };

    useEffect(() => {
        userFilters && determineMobileFiltersValue();
    }, [userFilters]);

    const clearActiveLabels = () => {
        const filters = { ...userFilters };
        filters.activeFilterLabels = [];
        filters.paginationCurrentPageNumber = 1;
        setFiltersObject('users', filters);
    };

    return (
        <div className="list user-list">
            {fetchingData ? (
                <LoadingSpinner />
            ) : (
                <div>
                    {!isSingleUser && (
                        <>
                            {isDesktop && (
                                <div className="list__utility-row list__utility-row--desktop">
                                    <div className="list__search-container">
                                        <SearchInputWithSubmit
                                            value={tempSearchValue}
                                            onChange={setTempSearchValue}
                                            placeholder="Filter by name or email"
                                            onClear={handleClearSearchValue}
                                            onSubmit={handleSearchValueSubmit}
                                            submitting={submittingSearch}
                                            list
                                        />

                                        <ListLabelDropdown
                                            labels={allLabels}
                                            activeFilterLabels={activeFilterLabels}
                                            handleActiveFilterLabelSelect={handleActiveFilterLabelSelect}
                                        />

                                        {activeFilterLabels.length > 0 && (
                                            <div className="ml-3">
                                                {activeFilterLabels.length +
                                                    ' ' +
                                                    ((activeFilterLabels.length === 1 ? 'label' : 'labels') + ' selected')}
                                                <span className="list__utility-row__clear-labels-btn" onClick={clearActiveLabels}>
                                                    ×
                                                </span>
                                            </div>
                                        )}
                                    </div>

                                    <div className="d-flex">
                                        <UserCsvButton downloading={fetchingCsv} downloadFilteredCsv={downloadFilteredCsv} desktopSize />

                                        <Button onClick={goToCreateUser} color="primary">
                                            Create User
                                        </Button>
                                    </div>
                                </div>
                            )}

                            {isTablet && (
                                <div className="list__utility-row list__utility-row--tablet">
                                    <div className="d-flex justify-content-between w-100">
                                        <div className="list__search-container">
                                            <SearchInputWithSubmit
                                                value={tempSearchValue}
                                                onChange={setTempSearchValue}
                                                placeholder="Filter by name or email"
                                                onClear={handleClearSearchValue}
                                                onSubmit={handleSearchValueSubmit}
                                                submitting={submittingSearch}
                                                list
                                            />
                                        </div>
                                    </div>

                                    <div className="d-flex mt-2 w-100 justify-content-end">
                                        <div className="mr-2 d-flex">
                                            <Button onClick={goToCreateUser} color="primary" className="mb-0">
                                                <i className="fa fa-plus" />
                                                &nbsp; Create User
                                            </Button>
                                        </div>

                                        <div className="mr-2">
                                            <ListLabelDropdown
                                                labels={allLabels}
                                                activeFilterLabels={activeFilterLabels}
                                                handleActiveFilterLabelSelect={handleActiveFilterLabelSelect}
                                                useIconOnly
                                            />
                                        </div>

                                        <div>
                                            <UserCsvButton downloading={fetchingCsv} downloadFilteredCsv={downloadFilteredCsv} />
                                        </div>

                                        <UsersListMobileFilterDropdown
                                            handleMobileFilterSelect={handleMobileFilterSelect}
                                            activeMobileFilterValue={activeMobileFilterValue}
                                        />
                                    </div>
                                </div>
                            )}

                            {isMobile && (
                                <div className="list-utility-row list__utility-row--mobile">
                                    <div className="d-flex mb-2">
                                        <UserCsvButton downloading={fetchingCsv} downloadFilteredCsv={downloadFilteredCsv} showText />

                                        <Button onClick={goToCreateUser} color="primary" className="ml-1" style={{ flex: '0 0 50%' }}>
                                            <i className="fa fa-plus" />
                                            &nbsp; Create User
                                        </Button>
                                    </div>

                                    <div className="d-flex mb-2">
                                        <UsersListMobileFilterDropdown
                                            handleMobileFilterSelect={handleMobileFilterSelect}
                                            activeMobileFilterValue={activeMobileFilterValue}
                                        />

                                        <div className="ml-2">
                                            <ListLabelDropdown
                                                labels={allLabels}
                                                activeFilterLabels={activeFilterLabels}
                                                handleActiveFilterLabelSelect={handleActiveFilterLabelSelect}
                                                useIconOnly
                                            />
                                        </div>
                                    </div>

                                    <div className="mb-3">
                                        <SearchInputWithSubmit
                                            value={tempSearchValue}
                                            onChange={setTempSearchValue}
                                            placeholder="Filter by name or email"
                                            onClear={handleClearSearchValue}
                                            onSubmit={handleSearchValueSubmit}
                                            submitting={submittingSearch}
                                            list
                                        />

                                        {activeFilterLabels.length > 0 && (
                                            <div className="list__utility-row__clear-labels-mobile">
                                                <span>
                                                    {activeFilterLabels.length +
                                                        ' ' +
                                                        ((activeFilterLabels.length === 1 ? 'label' : 'labels') + ' selected')}
                                                    <span onClick={clearActiveLabels} className="list__utility-row__clear-labels-btn">
                                                        ×
                                                    </span>
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                        </>
                    )}

                    {!users.length ? (
                        <p>No users found</p>
                    ) : (
                        <div>
                            <Table className="responsive-table">
                                <tbody>
                                    <tr className="responsive-table header-row">
                                        <th
                                            className="responsive-table__sortable-th responsive-table--long-th"
                                            onClick={() => handleSortableHeaderClick('user.name')}>
                                            Name
                                            {handleCaret(activeSortProperty, activeSortDirection, 'user.name')}
                                        </th>
                                        <th
                                            className="responsive-table__sortable-th responsive-table--long-th"
                                            onClick={() => handleSortableHeaderClick('user.email')}>
                                            Email
                                            {handleCaret(activeSortProperty, activeSortDirection, 'user.email')}
                                        </th>
                                        <th>Admin</th>
                                        <th>Confirmed</th>
                                        {!isSingleUser && <th className="responsive-table--label-th">Labels</th>}
                                        <th>Suspended</th>
                                        <th>Dashboard</th>
                                        <th>Prioritize</th>
                                        <th>Last Email</th>
                                        <th>Next Email</th>
                                        <th>{isSingleUser ? 'Edit' : 'Edit/Remove'} </th>
                                    </tr>

                                    {users.map((user) => (
                                        <UsersListItem
                                            key={user.id}
                                            user={user}
                                            goToEditUser={goToEditUser}
                                            handlePreviewClick={handlePreviewClick}
                                            handleDeleteButtonClick={handleDeleteButtonClick}
                                            isSingleUser={isSingleUser}
                                        />
                                    ))}
                                </tbody>
                            </Table>
                            <PaginationWrapper
                                totalPages={totalPages}
                                handlePageClick={handlePageClick}
                                forcePage={paginationCurrentPageNumber - 1}
                            />
                        </div>
                    )}
                </div>
            )}

            {deleteModalVisible && (
                <DeleteUserModal
                    userToDelete={userToDelete}
                    isOpen={deleteModalVisible}
                    toggle={toggleDeleteModal}
                    addFlashMessage={addFlashMessage}
                    handleError={handleError}
                    refreshUsers={refreshUsers}
                />
            )}

            {previewModalVisible && (
                <LiveOrLastPreview
                    user={userToPreview}
                    type={previewType}
                    isOpen={previewModalVisible}
                    toggle={togglePreviewModal}
                    handleError={handleError}
                />
            )}
        </div>
    );
};
