import React, { useState, useEffect } from 'react';
import { Modal, ModalFooter, Button } from 'reactstrap';
import { useQuery } from 'react-query';
import { SpinnerButton } from 'common/SpinnerButton';
import { DualSelect } from 'common/DualSelect';
import { LoadingSpinner } from 'common/LoadingSpinner';
import { InputWithError } from 'common/InputWithError';
import { ModalBodyWithClose } from 'common/ModalBodyWithClose';
import { editUserGroup, getUsersWithoutParams } from 'utils/api/usersAPI';

export const EditUserGroupModal = ({ isOpen, toggle, item, addFlashMessage, refreshUserGroups, handleError }) => {
    const [name, setName] = useState('');
    const [errors, setErrors] = useState([]);
    const [submitting, setSubmitting] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState([]);

    const { data, isLoading } = useQuery('getUsersWithoutParams', () => getUsersWithoutParams(false, false, 1, 2000), {
        onError: (err) => handleError(err),
    });

    useEffect(() => {
        if (item) {
            setName(item.name);
            setSelectedUsers(item.users);
        }
        item && setName(item.name);
    }, [item]);

    const handleEditGroup = async () => {
        try {
            setSubmitting(true);
            setErrors(null);

            await editUserGroup(item.id, { name, entities: selectedUsers.map((item) => item.id) });
            await refreshUserGroups();

            addFlashMessage('success', 'User group successfully updated');
            setSubmitting(false);
            toggle();
        } catch (err) {
            setSubmitting(false);
            handleError(err, setErrors);
        }
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle} className="modal-lg">
            {isLoading ? (
                <LoadingSpinner padding text={'Fetching user data'} />
            ) : (
                <>
                    {!item ? null : (
                        <>
                            <ModalBodyWithClose toggle={toggle}>
                                <h2>Edit User Group</h2>
                                <InputWithError
                                    placeholder="User group name"
                                    name="name"
                                    value={name}
                                    type="text"
                                    autoComplete="off"
                                    onChange={setName}
                                    errorObj={errors}
                                    prependIcon={<i className="fa fa-tag" />}
                                />
                                <div className="mt-4">
                                    <DualSelect
                                        selectedList={selectedUsers}
                                        fullList={data?.result}
                                        onSelect={setSelectedUsers}
                                        contentType="users"
                                        hideGroups
                                    />
                                </div>
                            </ModalBodyWithClose>
                            <ModalFooter>
                                <Button color="secondary" onClick={toggle}>
                                    Cancel
                                </Button>
                                <SpinnerButton type="modal" color="primary" onClick={handleEditGroup} submitting={submitting} title="Update" />
                            </ModalFooter>
                        </>
                    )}
                </>
            )}
        </Modal>
    );
};
