import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { FlashMessageContext } from 'contexts/FlashMessageContext';
import { AuthContext } from 'contexts/AuthContext';
import { addInternalAlert, editInternalAlert, previewInternalAlert, getGeoLocations } from 'utils/api/alertsAPI';
import { getAlertsSort } from 'utils/helpers';
import { LoadingSpinner } from 'common/LoadingSpinner';
import { SearchForm } from './SearchForm';
import { TitleForm } from '../TitleForm';
import { InternalPreview } from './InternalPreview';
import { FiltersBlock } from './FiltersBlock';
import './style.scss';

export const InternalAlerts = ({ isEdit, foundAlert, labels, categories, categoriesLoading }) => {
    const navigate = useNavigate();
    const { addFlashMessage } = useContext(FlashMessageContext);
    const { handleError, state } = useContext(AuthContext);

    const [searchValue, setSearchValue] = useState('');
    const [searchValueError, setSearchValueError] = useState(false);
    const [titleValue, setTitleValue] = useState('');
    const [useGoogleAlerts, setUseGoogleAlerts] = useState(false);
    const [inTitlesOnly, setInTitlesOnly] = useState(false);
    const [selectedAlerts, setSelectedAlerts] = useState([]);
    const [tempSelectedAlerts, setTempSelectedAlerts] = useState([]);
    const [preview, setPreview] = useState(null);
    const [titleFormVisible, setTitleFormVisible] = useState(false);
    const [searchSubmitting, setSearchSubmitting] = useState(!!isEdit);
    const [submitting, setSubmitting] = useState(false);
    const [selectedLabels, setSelectedLabels] = useState([]);
    const [newLabelToAdd, setNewLabelToAdd] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [countries, setCountries] = useState([]);
    const [regions, setRegions] = useState([]);
    const [activePreviewSortValue, setActivePreviewSortValue] = useState(null);
    const [activeCountryFilter, setActiveCountryFilter] = useState(null);
    const [activeRegionFilter, setActiveRegionFilter] = useState(null);
    const [minRelValue, setMinRelValue] = useState(0);
    const [updatingPreview, setUpdatingPreview] = useState(false);
    const [errors, setErrors] = useState(null);
    const [preSubmitError, setPreSubmitError] = useState(null);
    const [loadingSpinnerText, setLoadingSpinnerText] = useState('');
    const [useStopWords, setUseStopWords] = useState(false);
    const [parseByElastic, setParseByElastic] = useState(false);
    const [isHasError, setIsHasError] = useState(false);
    const [isAdvancedSearchShown, setIsAdvancedSearchShown] = useState(false);
    const [previewError, setPreviewError] = useState('');
    const access = state.profile.organization?.accesses;

    useQuery(['getGeoLocations'], () => getGeoLocations(), {
        onSuccess: (res) => setCountries(res),
        onError: (err) => handleError(err),
        staleTime: countries?.length ? Infinity : 0,
    });

    useEffect(() => {
        if (countries?.length && activeCountryFilter) {
            const regions = countries.find((item) => item.countryName === activeCountryFilter)?.regions;
            regions?.length && setRegions(regions);
        }
    }, [activeCountryFilter, countries]);

    useEffect(() => {
        isEdit && getFoundAlertValues();
    }, [foundAlert]);

    useEffect(() => {
        if (!selectedCategory && !searchValue) return;
        if (searchValue === '' && selectedCategory === 'All AI Topics') return resetForm();

        handleCreatePreview();
    }, [
        selectedCategory,
        activePreviewSortValue,
        inTitlesOnly,
        useStopWords,
        activeCountryFilter,
        activeRegionFilter,
        selectedAlerts,
        useGoogleAlerts,
        parseByElastic,
        minRelValue,
    ]);

    useEffect(() => {
        const messageArr = [
            'processing Top sources',
            'processing My sources',
            'processing All sources',
            'removing known marketing sites',
            'removing irrelevant articles',
            'weighting and ranking results',
        ];
        const googleNews = useGoogleAlerts || (null === useGoogleAlerts && foundAlert !== undefined && foundAlert.source.withGoogleNews);
        setLoadingSpinnerText(messageArr[0]);
        let messageIndex = 0;

        const interval = searchSubmitting
            ? setInterval(
                  () => {
                      messageIndex < messageArr.length - 1 && messageIndex++;
                      setLoadingSpinnerText(messageArr[messageIndex]);
                  },
                  googleNews ? 3000 : 1000
              )
            : null;

        return () => clearInterval(interval);
    }, [searchSubmitting]);

    useEffect(() => {
        if (newLabelToAdd) {
            const foundLabel = labels.find((label) => label.id === newLabelToAdd.id);
            foundLabel && addToSelected(foundLabel);
            setNewLabelToAdd(null);
        }
    }, [labels]);

    const clearAllFilters = () => {
        setActiveCountryFilter('');
        setActiveRegionFilter('');
        setRegions([]);
        setActivePreviewSortValue(null);
    };

    const getFoundAlertValues = async () => {
        const source = foundAlert.source;
        const preSelectedAlerts = source.attachedAlertsList
            ? source.attachedAlertsList.map((item) => {
                  if (item.id === foundAlert.id) {
                      return null;
                  } else {
                      return {
                          id: item.id,
                          name: item.name,
                      };
                  }
              })
            : [];
        setSearchValue(source.keyword.slice(0, 8) === 'intitle:' ? source.keyword.slice(8, source.keyword.length) : source.keyword);
        setTitleValue(foundAlert.name);
        setSelectedAlerts(preSelectedAlerts);
        setUseGoogleAlerts(!!source?.withGoogleNews);
        setTitleFormVisible(true);
        setSelectedLabels(foundAlert.labels);
        setActiveCountryFilter(source?.geoLocation?.country || '');
        setActiveRegionFilter(source?.geoLocation?.region || '');
        setInTitlesOnly(source.keyword.slice(0, 8) === 'intitle:');
        setUseStopWords(source.useStopWords);
        setParseByElastic(source.parseByElastic);
        setMinRelValue(source.minRelevanceValue);
        setSelectedCategory(source?.topic?.id || null);
    };

    const handleCreatePreview = async (advancedSearchValue = '') => {
        try {
            if (preSubmitError) return;
            errors && setErrors(null);

            if (searchValue === '' && advancedSearchValue === '' && (useGoogleAlerts || inTitlesOnly)) {
                const errObj = {
                    code: 'VALIDATION_FAILED',
                    errors: {
                        keyword: [
                            {
                                message: "A keyword must be included when 'All Sources' is selected below",
                                code: 'INVALID_CHARACTERS_ERROR',
                                payload: null,
                            },
                        ],
                    },
                };
                setErrors(errObj);
                return;
            }
            setSearchSubmitting(true);

            const { sortValue, sortDirection } = getAlertsSort(activePreviewSortValue);
            const newSearchValue = (inTitlesOnly ? 'intitle:' : '') + (advancedSearchValue || searchValue);
            const params = {
                keyword: newSearchValue,
                alerts: selectedAlerts.map((item) => item.id),
                withGoogleNews: !!(useGoogleAlerts && selectedAlerts.length < 1),
                sortField: sortValue,
                sortDirection,
                useStopWords,
                topic: selectedCategory === 'All AI Topics' ? null : +selectedCategory,
                minRelevanceValue: minRelValue,
                parseByElastic: parseByElastic,
                geoLocation: { country: activeCountryFilter || null, region: activeRegionFilter || null },
            };
            const preview = await previewInternalAlert(params);
            setTitleFormVisible(true);
            setPreview(preview);
            setUpdatingPreview(false);
            setPreviewError('');
        } catch (err) {
            setUpdatingPreview(false);
            setTitleFormVisible(false);
            setSearchValueError(true);
            setPreview(null);
            handleError(err, setErrors);

            const error = err?.message || err;
            error && error !== 'Validation failed' && setPreviewError(error);
        }

        setSearchSubmitting(false);
    };

    const handleSearchValueChange = (text) => {
        setErrors(null);
        setSearchValue(text);
    };

    const resetForm = () => {
        setPreview(null);
        setTitleFormVisible(false);
        setInTitlesOnly(false);
        setUseGoogleAlerts(false);
        setTitleValue('');
        setSearchValue('');
        setSearchValueError(false);
        handleClearAlerts();
        setErrors(null);
        setUseStopWords(false);
    };

    const clearPreviewForm = () => {
        setPreview(null);
        setTitleFormVisible(false);
        setTitleValue('');
        setErrors(null);
    };

    const handleSubmit = async () => {
        if (preSubmitError) return;

        setErrors(null);

        if (searchValue === '' && (useGoogleAlerts || inTitlesOnly)) {
            let errObj = {
                code: 'VALIDATION_FAILED',
                errors: {
                    keyword: [
                        {
                            message: "A keyword must be included when 'All Sources' is selected below",
                            code: 'INVALID_CHARACTERS_ERROR',
                            payload: null,
                        },
                    ],
                },
            };
            setErrors(errObj);
            return;
        }
        const newSearchValue = inTitlesOnly ? 'intitle:' + searchValue : searchValue;

        if (newSearchValue?.length < 3) return;

        const params = {
            name: titleValue,
            keyword: newSearchValue,
            useStopWords,
            alerts: selectedAlerts.map((item) => item.id),
            topic: selectedCategory === 'All AI Topics' ? null : +selectedCategory,
            withGoogleNews: !!(useGoogleAlerts && selectedAlerts.length < 1),
            alertLabels: selectedLabels.map((n) => n.id),
            minRelevanceValue: minRelValue,
            parseByElastic: parseByElastic,
            geoLocation: { country: activeCountryFilter || null, region: activeRegionFilter || null },
        };

        try {
            setSubmitting(true);

            if (isEdit) {
                await editInternalAlert(foundAlert.id, params);
                addFlashMessage('success', 'Alert successfully updated');
                navigate('/account/alerts');
            } else {
                navigate('/account/alerts');
                localStorage.setItem('newAlertsData', JSON.stringify({ name: titleValue, keyword: newSearchValue }));
                await addInternalAlert(params);
                setSubmitting(false);
                addFlashMessage('success', 'Alert successfully created');
                localStorage.setItem('newAlertsData', 'success');
            }
        } catch (err) {
            localStorage.removeItem('newAlertsData');
            setSubmitting(false);

            if (err?.message?.includes('You can add up to')) {
                return addFlashMessage(
                    'danger',
                    "You've reached the limit of allowed number of alerts for your account.\n" + 'Contact Customer Care team for more information.'
                );
            }

            if (err.code === 'VALIDATION_FAILED' && err.message.slice(0, 17) === 'Alert with source') {
                const errObj = {
                    code: 'VALIDATION_FAILED',
                    errors: {
                        keyword: [
                            {
                                message: 'An alert with this keyword already exists.',
                                code: 'IS_BLANK_ERROR',
                                payload: null,
                            },
                        ],
                    },
                };
                handleError(errObj, setErrors);
            } else {
                handleError(err, setErrors);
            }
        }
    };

    const addToSelected = (label) => {
        const newSelectedLabels = [...selectedLabels];

        if (newSelectedLabels.indexOf(label) === -1) {
            newSelectedLabels.push(label);
            setSelectedLabels(newSelectedLabels);
        }
    };

    const handleAlertSelectSubmit = () => setSelectedAlerts([...tempSelectedAlerts]);

    const handleClearAlerts = () => {
        setSelectedAlerts([]);
        setTempSelectedAlerts([]);
    };

    const getFiltersBlock = (isSimple = false) => (
        <FiltersBlock
            isAdvancedSearchShown={isAdvancedSearchShown}
            regions={regions}
            countries={countries}
            setRegions={setRegions}
            activeCountryFilter={activeCountryFilter}
            activeRegionFilter={activeRegionFilter}
            minRelValue={minRelValue}
            setActiveCountryFilter={setActiveCountryFilter}
            setActiveRegionFilter={setActiveRegionFilter}
            setMinRelValue={setMinRelValue}
            isHasAccessElasticSearch={access?.accessElasticSearch}
            clearAllFilters={clearAllFilters}
            isSimple={isSimple}
            isEdit={isEdit}
        />
    );

    return (
        <div className="mb-4">
            <SearchForm
                searchValue={searchValue}
                setSearchValue={handleSearchValueChange}
                handleCreatePreview={handleCreatePreview}
                useGoogleAlerts={useGoogleAlerts}
                setUseGoogleAlerts={setUseGoogleAlerts}
                searchValueError={searchValueError}
                setSearchValueError={setSearchValueError}
                resetForm={resetForm}
                clearPreviewForm={clearPreviewForm}
                setSelectedAlerts={setTempSelectedAlerts}
                handleAlertSelectSubmit={handleAlertSelectSubmit}
                selectedAlerts={selectedAlerts}
                handleClearAlerts={handleClearAlerts}
                inTitlesOnly={inTitlesOnly}
                setInTitlesOnly={setInTitlesOnly}
                errorObj={errors}
                handleError={handleError}
                currentId={foundAlert && foundAlert.id}
                setErrors={setErrors}
                categories={categories}
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
                categoriesLoading={categoriesLoading}
                preSubmitError={preSubmitError}
                setPreSubmitError={setPreSubmitError}
                useStopWords={useStopWords}
                setUseStopWords={setUseStopWords}
                setIsHasError={setIsHasError}
                isHasError={isHasError}
                isEditForm={isEdit}
                setIsAdvancedSearchShown={setIsAdvancedSearchShown}
                titleFormVisible={titleFormVisible}
                previewError={previewError}>
                {getFiltersBlock(true)}
            </SearchForm>

            {titleFormVisible && (
                <TitleForm
                    title={titleValue}
                    setTitleValue={setTitleValue}
                    submitting={submitting}
                    isEditForm={isEdit}
                    handleSubmit={handleSubmit}
                    errors={errors}
                    withoutInfoMessage
                    disabled={isHasError}
                    isAdvancedSearchShown={isAdvancedSearchShown}>
                    {getFiltersBlock()}
                </TitleForm>
            )}

            {searchSubmitting ? (
                <div className="mt-5">
                    <LoadingSpinner text={loadingSpinnerText} />
                </div>
            ) : preview ? (
                <InternalPreview preview={preview} updatingPreview={updatingPreview} />
            ) : null}
        </div>
    );
};
