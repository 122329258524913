import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { FlashMessageContext } from 'contexts/FlashMessageContext';
import { AuthContext } from 'contexts/AuthContext';
import { LoadingSpinner } from 'common/LoadingSpinner';
import {
    addOzmosysCategorizedAlert,
    editOzmosysCategorizedAlert,
    previewOzmosysCategorizedAlert,
    getAiCategories,
    getGeoLocations,
} from 'utils/api/alertsAPI';
import { getAlertsSort } from 'utils/helpers';
import { TitleForm } from '../TitleForm';
import { Preview } from './Preview';
import './style.scss';

export const OzmosysCategorizedAlerts = ({ isEdit, foundAlert }) => {
    const navigate = useNavigate();
    const { addFlashMessage } = useContext(FlashMessageContext);
    const { handleError } = useContext(AuthContext);

    const [titleValue, setTitleValue] = useState('');
    const [additionalTitleValue, setAdditionalTitleValue] = useState('');
    const [isDisableTitle, setIsDisableTitle] = useState(false);
    const [preview, setPreview] = useState(null);
    const [titleFormVisible, setTitleFormVisible] = useState(false);
    const [searchSubmitting, setSearchSubmitting] = useState(!!isEdit);
    const [submitting, setSubmitting] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [activePreviewSortValue, setActivePreviewSortValue] = useState(null);
    const [activeCountryFilter, setActiveCountryFilter] = useState(null);
    const [activeRegionFilter, setActiveRegionFilter] = useState(null);
    const [countries, setCountries] = useState([]);
    const [regions, setRegions] = useState([]);
    const [activeAdditionalCategoryFilter, setActiveAdditionalCategoryFilter] = useState(null);
    const [typeOfSource, setTypeOfSource] = useState('all');
    const [errors, setErrors] = useState(null);
    const [loadingSpinnerText, setLoadingSpinnerText] = useState('');
    const [isInitialTypeLegal, setIsInitialTypeLegal] = useState(false);

    const messageArr = ['Fetching News', 'Applying AI models', 'Applying relevance', 'Sorting & filtering data'];
    const searchTypeId = foundAlert?.source?.newsCategory?.searchType?.id;

    useQuery(['getGeoLocations'], () => getGeoLocations(), {
        onSuccess: (res) => setCountries(res),
        onError: (err) => handleError(err),
        staleTime: countries?.length ? Infinity : 0,
    });

    const { data: additionalCategories } = useQuery(
        ['getAiCategories', foundAlert?.id, searchTypeId],
        () => (searchTypeId ? getAiCategories(searchTypeId) : null),
        { onError: (err) => handleError(err) }
    );

    useEffect(() => {
        isEdit && foundAlert && getFoundAlertValues();
    }, []);

    useEffect(() => {
        if (countries?.length && activeCountryFilter) {
            const regions = countries.find((item) => item.countryName === activeCountryFilter)?.regions;
            regions?.length && setRegions(regions);
        }
    }, [activeCountryFilter, countries]);

    useEffect(() => {
        if (!selectedCategory) return;
        handleCreatePreview();
    }, [selectedCategory, activePreviewSortValue, activeCountryFilter, activeRegionFilter, activeAdditionalCategoryFilter, typeOfSource]);

    useEffect(() => {
        setLoadingSpinnerText(messageArr[0]);
        let messageIndex = 0;

        const interval = searchSubmitting
            ? setInterval(() => {
                  messageIndex < messageArr.length - 1 && messageIndex++;
                  setLoadingSpinnerText(messageArr[messageIndex]);
              }, 6000)
            : null;

        return () => clearInterval(interval);
    }, [searchSubmitting]);

    useEffect(() => {
        const additionalCatName = additionalCategories?.find((item) => item.id?.toString() === activeAdditionalCategoryFilter?.toString())?.name;
        const categoryName = additionalCatName?.replace(' (Topic)', '')?.replace(' (Industry)', '')?.replace(' (Practice)', '');
        const activeCountry = activeCountryFilter ? countries?.filter((country) => country?.countryName === activeCountryFilter) : [];
        const activeRegion = activeRegionFilter ? regions?.filter((region) => region?.name === activeRegionFilter) : [];

        setAdditionalTitleValue(
            foundAlert?.source?.newsCategory?.name +
                (typeOfSource === 'legal' ? ' :: Legal only' : '') +
                (activeCountry?.length && activeCountry[0]?.countryName ? ` :: ${activeCountry[0].countryName}` : '') +
                (activeRegion?.length && activeCountry?.length && activeRegion[0]?.name ? `/${activeRegion[0].name}` : '') +
                (categoryName ? ` :: ${categoryName}` : '')
        );
    }, [
        activeAdditionalCategoryFilter,
        additionalCategories,
        activeCountryFilter,
        activeRegionFilter,
        typeOfSource,
        foundAlert,
        preview,
        countries,
        regions,
    ]);

    const getFoundAlertValues = async () => {
        setTitleValue(foundAlert.name);
        setAdditionalTitleValue(foundAlert.name);
        setIsDisableTitle(!!foundAlert.source?.topic?.id);

        setTitleFormVisible(true);
        setActiveCountryFilter(foundAlert?.source?.geoLocation?.country || '');
        setActiveRegionFilter(foundAlert?.source?.geoLocation?.region || '');
        setSelectedCategory(foundAlert.source?.newsCategory.id);
        setTypeOfSource(foundAlert.source?.legalOnly ? 'legal' : 'all');
        setIsInitialTypeLegal(!!foundAlert.source?.legalOnly);

        if (foundAlert.source?.topic) {
            setActiveAdditionalCategoryFilter(foundAlert.source.topic.id);
        }
    };

    const handleCreatePreview = async () => {
        try {
            errors && setErrors(null);
            setSearchSubmitting(true);

            const { sortValue, sortDirection } = getAlertsSort(activePreviewSortValue);
            const params = {
                sortValue,
                sortDirection,
                name: additionalTitleValue || titleValue,
                newsCategory: selectedCategory,
                topic: activeAdditionalCategoryFilter || null,
                geoLocation: { country: activeCountryFilter || null, region: activeRegionFilter || null },
                useAllSources: typeOfSource === 'all',
                legalOnly: typeOfSource === 'legal',
            };
            const preview = await previewOzmosysCategorizedAlert(params);
            setTitleFormVisible(true);
            setPreview(preview);
            setSearchSubmitting(false);
        } catch (err) {
            setSearchSubmitting(false);
            setTitleFormVisible(false);
            setPreview(null);
            handleError(err, setErrors);
        }
    };

    const handleSubmit = async () => {
        setErrors(null);
        const saveAsNewSource = isEdit && typeOfSource === 'legal' && !isInitialTypeLegal;
        const params = {
            name: additionalTitleValue || titleValue,
            newsCategory: selectedCategory,
            topic: activeAdditionalCategoryFilter || null,
            geoLocation: { country: activeCountryFilter || null, region: activeRegionFilter || null },
            useAllSources: saveAsNewSource ? false : typeOfSource === 'all',
            legalOnly: saveAsNewSource ? true : typeOfSource === 'legal',
        };
        try {
            setSubmitting(true);
            const searchType = { 1: 'practices', 2: 'industries', 3: 'topics' };

            if (isEdit) {
                await editOzmosysCategorizedAlert(foundAlert.id, params);
                addFlashMessage('success', 'Alert successfully updated');
                navigate(`/account/manage-category-based/${searchType[searchTypeId]}`);
            } else {
                await addOzmosysCategorizedAlert(params);
                setSubmitting(false);
                addFlashMessage('success', 'Alert successfully created');
                navigate(`/account/manage-category-based/${searchType[searchTypeId]}`);
            }
        } catch (err) {
            setSubmitting(false);

            if (err?.message?.includes('You can add up to')) {
                return addFlashMessage(
                    'danger',
                    "You've reached the limit of allowed number of alerts for your account.\n" + 'Contact Customer Care team for more information.'
                );
            }
            if (err.code === 'VALIDATION_FAILED' && err.message.slice(0, 17) === 'Alert with source') {
                let errObj = {
                    code: 'VALIDATION_FAILED',
                    errors: {
                        keyword: [
                            {
                                message: 'An alert with this keyword already exists.',
                                code: 'IS_BLANK_ERROR',
                                payload: null,
                            },
                        ],
                    },
                };

                addFlashMessage('danger', 'Alert with these parameters already exists');
                handleError(errObj, setErrors);
            } else {
                const checkExistence = (err) => {
                    for (let key in err) {
                        if (err.hasOwnProperty(key)) {
                            if (typeof err[key] === 'object') {
                                if (checkExistence(err[key], 'already exist')) {
                                    return true;
                                }
                            } else if (err[key].toString().includes('already exist')) {
                                return true;
                            }
                        }
                    }
                    return false;
                };

                if (checkExistence(err)) {
                    addFlashMessage('danger', 'Alert with these parameters already exists');
                } else {
                    if (err?.hasOwnProperty('errors') && err?.errors?.hasOwnProperty('parentId')) {
                        typeof err?.errors?.parentId === 'string' && addFlashMessage('danger', err.errors.parentId);
                    } else {
                        addFlashMessage('danger', 'Unable to complete your request at this time');
                    }
                }

                handleError(err, setErrors);
            }
        }
    };

    return (
        <div className="mb-4">
            {titleFormVisible && (
                <>
                    <TitleForm
                        title={titleValue}
                        setTitleValue={setTitleValue}
                        submitting={submitting}
                        isEditForm={isEdit}
                        handleSubmit={handleSubmit}
                        errors={errors}
                        isDisableTitle={isDisableTitle}
                        isEditCategory
                        additionalTitle={additionalTitleValue}
                        setAdditionalTitle={setAdditionalTitleValue}
                        isCategory
                    />
                </>
            )}
            {searchSubmitting ? (
                <div className="mt-5">
                    <LoadingSpinner text={loadingSpinnerText} />
                </div>
            ) : (
                preview && (
                    <Preview
                        preview={preview}
                        setActivePreviewSortValue={setActivePreviewSortValue}
                        activeCountryFilter={activeCountryFilter}
                        setActiveCountryFilter={setActiveCountryFilter}
                        activeRegionFilter={activeRegionFilter}
                        setActiveRegionFilter={setActiveRegionFilter}
                        activeAdditionalCategoryFilter={activeAdditionalCategoryFilter}
                        setActiveAdditionalCategoryFilter={setActiveAdditionalCategoryFilter}
                        typeOfSource={typeOfSource}
                        setTypeOfSource={setTypeOfSource}
                        additionalCategories={additionalCategories}
                        countries={countries}
                        regions={regions}
                    />
                )
            )}
        </div>
    );
};
