import React, { useState } from 'react';
import { Modal, ModalFooter, Button } from 'reactstrap';
import { SpinnerButton } from 'common/SpinnerButton';
import { ModalBodyWithClose } from 'common/ModalBodyWithClose';
import { deleteUserLabel } from 'utils/api/usersAPI';

export const DeleteLabelModal = ({ isOpen, toggle, item, refreshLabels, addFlashMessage }) => {
    const [submitting, setSubmitting] = useState(false);

    const handleSubmit = async () => {
        try {
            setSubmitting(true);

            await deleteUserLabel(item.id);
            refreshLabels();

            addFlashMessage('success', 'User label successfully deleted');
            setSubmitting(false);
            toggle();
        } catch (err) {
            setSubmitting(false);
            addFlashMessage('danger', 'Unable to delete user label');
        }
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle} className="create-label-modal">
            {!item ? null : (
                <>
                    <ModalBodyWithClose toggle={toggle}>
                        <span>Are you sure you want to delete {item.name}?</span>
                    </ModalBodyWithClose>

                    <ModalFooter>
                        <Button color="secondary" onClick={toggle}>
                            Cancel
                        </Button>

                        <SpinnerButton type="modal" color="danger" onClick={handleSubmit} submitting={submitting} title="Delete" />
                    </ModalFooter>
                </>
            )}
        </Modal>
    );
};
