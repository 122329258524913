import React, { useState } from 'react';
import { Modal, ModalFooter, Button } from 'reactstrap';
import { SpinnerButton } from 'common/SpinnerButton';
import { ModalBodyWithClose } from 'common/ModalBodyWithClose';
import { deleteUserGroup } from 'utils/api/usersAPI';

export const DeleteUserGroupModal = ({ isOpen, toggle, handleError, item, refreshUserGroups, addFlashMessage }) => {
    const [submitting, setSubmitting] = useState(false);

    const handleDeleteGroup = async () => {
        try {
            setSubmitting(true);
            await deleteUserGroup(item.id);
            await refreshUserGroups();
            addFlashMessage('success', 'User group successfully deleted');
            setSubmitting(false);
            toggle();
        } catch (err) {
            setSubmitting(false);
            handleError(err);
        }
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            {!item ? null : (
                <>
                    <ModalBodyWithClose toggle={toggle}>
                        <span>Are you sure you want to delete {item.name}?</span>
                    </ModalBodyWithClose>

                    <ModalFooter>
                        <Button color="secondary" onClick={toggle}>
                            Cancel
                        </Button>
                        <SpinnerButton type="modal" color="danger" onClick={handleDeleteGroup} submitting={submitting} title="Delete" />
                    </ModalFooter>
                </>
            )}
        </Modal>
    );
};
