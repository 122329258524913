import React from 'react';
import { Button } from 'reactstrap';

export const SmallSpinnerButtonSquare = ({ submitting, title, disabled, block, color, onClick, className, defaultSize, style }) => (
    <Button
        color={color}
        style={{ width: defaultSize ? 'auto' : '27px', height: defaultSize ? 'auto' : '22px', ...style }}
        type="button"
        className={className}
        disabled={disabled}
        block={block}
        onClick={onClick}>
        {submitting ? <i className="fa fa-spinner fa-spin" /> : title}
    </Button>
);
