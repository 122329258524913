import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { convertDateString } from 'utils/helpers';

export const Alert = ({ alert, removeAlertFromSection, sectionId, index }) => (
    <Draggable draggableId={String(alert.id)} index={index} type="alert">
        {(provided, snapshot) => (
            <div
                className={'newsletter-section-alert ' + (snapshot.isDragging ? 'newsletter-section-alert--is-dragging' : '')}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                ref={provided.innerRef}>
                <div className="newsletter-section-alert__inner">
                    <span className="newsletter-section-alert__title">{alert.name}</span>

                    <span className="newsletter-section-alert__date">
                        {alert.source.contentUpdatedAt ? convertDateString(alert.source.contentUpdatedAt) : 'Pending Updates'}
                    </span>
                </div>

                <div className="newsletter-section-alert__unassign-btn" onClick={() => removeAlertFromSection(alert.id, sectionId, 0, 0, true)}>
                    <span>×</span>
                </div>
            </div>
        )}
    </Draggable>
);
