import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { AddSectionForm } from './AddSectionForm';
import { Section } from './Section';
import { UnassignedSection } from 'components/Newsletters/ManageContent/Desktop/ManageSources/Sections/UnassignedSection';
import { NotesForm } from 'components/Newsletters/ManageContent/Desktop/ManageSources/Sections/NotesForm';
import './style.scss';

export const Sections = ({
    refreshNewsletter,
    addFlashMessage,
    newsletterData,
    removeAllAlertsFromSection,
    handleSectionSorting,
    savingSectionChanges,
    handleError,
    moveAlertBetweenSections,
}) => {
    const [sections, setSections] = useState([]);
    const [sectionDragInfo, setSectionDragInfo] = useState({ destinationId: '', sourceId: '' });

    useEffect(() => {
        setSections(newsletterData.sections);
    }, [newsletterData.sections]);

    const onDragEnd = (result) => {
        const { destination, source, draggableId, type } = result;

        if (!destination || savingSectionChanges) return;

        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            setSectionDragInfo({ destinationId: '', sourceId: '' });
            return;
        }

        if (type === 'section') {
            const existingSectionOrder = Array.from(newsletterData.sections);
            const sectionToMove = newsletterData.sections.find((obj) => +obj.id === +draggableId);

            existingSectionOrder.splice(existingSectionOrder.indexOf(sectionToMove), 1);
            existingSectionOrder.splice(destination.index, 0, sectionToMove);

            const reversedArr = [...existingSectionOrder].reverse();
            setSections(existingSectionOrder);

            const sectionIdArray = reversedArr.map((item, index) => ({ id: item.id, sortOrder: index })).filter((item) => item.sortOrder !== 0);
            handleSectionSorting(sectionIdArray);
            setSectionDragInfo({ destinationId: '', sourceId: '' });
        } else {
            const nextSectionId = destination.droppableId;
            const prevSectionId = source.droppableId;
            setSectionDragInfo({ destinationId: '', sourceId: '' });

            if (nextSectionId !== prevSectionId) {
                moveAlertBetweenSections(Number(draggableId), Number(prevSectionId), Number(nextSectionId), Number(destination.index) + 1);
            }
        }
    };

    const handleOnDragUpdate = (result) =>
        result.source &&
        result.destination &&
        setSectionDragInfo({ destinationId: result.destination.droppableId, sourceId: result.source.droppableId });

    return (
        <div className="newsletter-content-panel newsletter-content-panel--left">
            <NotesForm newsletterData={newsletterData} refreshNewsletter={refreshNewsletter} addFlashMessage={addFlashMessage} />

            <div className="newsletter-content-panel__header">
                <h4>Sections</h4>
                {savingSectionChanges && <div style={{ color: 'gray' }}>Saving...</div>}
            </div>

            <div className="newsletter-content-panel__body">
                <AddSectionForm refreshNewsletter={refreshNewsletter} addFlashMessage={addFlashMessage} newsletterId={newsletterData.id} />

                <div className="newsletter-content-panel__section-instructions">
                    Drag and drop sections or alerts to change their order. Click on section titles to edit.
                    <br />
                    <strong>*For curated newsletters, you must continue to the next section to add articles</strong>
                </div>

                <DragDropContext onDragUpdate={handleOnDragUpdate} onDragEnd={onDragEnd}>
                    <Droppable droppableId={'section-list'} type="section">
                        {(provided) => (
                            <div ref={provided.innerRef} className="newsletter-content-panel__section-list" {...provided.droppableProps}>
                                {sections &&
                                    sections.map(
                                        (section, index) =>
                                            section.name !== 'Unassigned' && (
                                                <Section
                                                    section={section}
                                                    index={index}
                                                    key={section.id}
                                                    removeAlertFromSection={moveAlertBetweenSections}
                                                    removeAllAlertsFromSection={removeAllAlertsFromSection}
                                                    addFlashMessage={addFlashMessage}
                                                    newsletterId={newsletterData.id}
                                                    refreshNewsletter={refreshNewsletter}
                                                    handleError={handleError}
                                                    sectionDragInfo={sectionDragInfo}
                                                />
                                            )
                                    )}

                                {provided.placeholder}

                                {sections.find((section) => section.name === 'Unassigned') && (
                                    <UnassignedSection
                                        section={sections.find((section) => section.name === 'Unassigned')}
                                        removeAlertFromSection={moveAlertBetweenSections}
                                        removeAllAlertsFromSection={removeAllAlertsFromSection}
                                        sectionDragInfo={sectionDragInfo}
                                    />
                                )}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
        </div>
    );
};
