import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'reactstrap';
import { LabelsBlock } from './LabelsBlock';

export const UsersListItem = ({ user, handleDeleteButtonClick, goToEditUser, handlePreviewClick, isSingleUser }) => {
    const navigate = useNavigate();

    const goToReader = (id) => navigate(`/account/reader/${id}`);
    const goToAlertSort = (id) => navigate(`/account/users/sort/${id}`);

    return (
        <tr className="user-list__item">
            <td data-label="Name">{user.name}</td>
            <td data-label="Email">{user.email}</td>
            <td data-label="Admin">
                {user.roles[0] === 'ROLE_ADMIN' ? (
                    <i className="fa fa-check" />
                ) : (
                    <div style={{ height: '20px', width: '35px', backgroundColor: 'transparent', display: 'inline-block' }} />
                )}
            </td>
            <td data-label="Confirmed">
                {user.confirmed ? (
                    <i className="fa fa-check" />
                ) : (
                    <div style={{ height: '20px', width: '35px', backgroundColor: 'transparent', display: 'inline-block' }} />
                )}
            </td>
            {!isSingleUser && (
                <td data-label="Labels">
                    <LabelsBlock labels={user.labels} />
                </td>
            )}
            <td data-label="Suspended">
                {user.suspendSubscriber ? (
                    <i className="fa fa-check" />
                ) : (
                    <div style={{ height: '20px', width: '35px', backgroundColor: 'transparent', display: 'inline-block' }} />
                )}
            </td>
            <td data-label="Reader">
                <Button color="light" disabled={!user.confirmed} onClick={() => goToReader(user.id)}>
                    <i className="fa fa-book fa-lg" />
                </Button>
            </td>
            <td data-label="Sort Alerts">
                <Button className="edit-delete-button" color="light" disabled={!user.confirmed} onClick={() => goToAlertSort(user.id)}>
                    <i className="fa fa-sort-numeric-asc fa-lg" />
                </Button>
            </td>
            <td data-label="Last Alert Email">
                <Button color="light" disabled={!user.confirmed} onClick={() => handlePreviewClick(user, 'last')}>
                    <i className="fa fa-envelope fa-lg" />
                </Button>
            </td>
            <td data-label="Next Alert Email">
                <Button color="light" disabled={!user.confirmed} onClick={() => handlePreviewClick(user, 'live')}>
                    <i className="fa fa-envelope-o fa-lg" />
                </Button>
            </td>
            <td className="responsive-table__edit-block" data-label={isSingleUser ? 'Edit' : 'Edit/Remove'}>
                <Button color="light" className={isSingleUser ? 'm-0' : ''} disabled={!user.confirmed} onClick={() => goToEditUser(user.id)}>
                    <i className="fa fa-edit fa-lg" />
                </Button>
                {!isSingleUser && (
                    <Button color="light" className="delete-button" onClick={() => handleDeleteButtonClick(user)}>
                        <i className="fa fa-trash fa-lg" />
                    </Button>
                )}
            </td>
        </tr>
    );
};
