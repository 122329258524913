import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from 'contexts/AuthContext';
import { SearchInput } from 'common/SearchInput';
import { filterByValue } from 'utils/helpers';
import { ManageAlertsModal } from 'components/Reader/Modals/ManageAlertsModal';
import { PublicModalSignIn } from './Modals/PublicModalSignIn';
import { AlertListItem } from './AlertListItem';

export const AlertList = ({
    alerts,
    setSelectedAlert,
    fetchingAlertContent,
    tagData,
    selectedAlert,
    handleTagSelect,
    selectedTag,
    userId,
    refreshAlerts,
    allowManageAlertsInReader,
    isPublic,
    publicEmail,
}) => {
    const { state, logout } = useContext(AuthContext);
    const [searchValue, setSearchValue] = useState('');
    const [visibleAlerts, setVisibleAlerts] = useState([]);
    const [manageAlertsModalOpen, setManageAlertsModalOpen] = useState(false);
    const [signInModalOpen, setSignInModalOpen] = useState(false);

    useEffect(() => {
        setVisibleAlerts(alerts);
    }, [alerts]);

    useEffect(() => {
        const newList = filterByValue(alerts, 'name', searchValue);
        setVisibleAlerts(newList);
    }, [searchValue]);

    const toggleManageAlertsModal = () => setManageAlertsModalOpen(!manageAlertsModalOpen);
    const toggleSignInModal = () => setSignInModalOpen(!signInModalOpen);

    const handleAlertModalButton = () => {
        if (state.isAuthenticated || state.isReaderProfileAuthenticated) {
            toggleManageAlertsModal();
        } else {
            toggleSignInModal();
        }
    };

    const handleLogout = () => logout();

    return (
        <div className="reader-column reader-alert-list">
            <div className="reader-column__wrapper">
                <div className="reader-column__header reader-alert-list__header">
                    <span>My Sources</span>
                    {allowManageAlertsInReader && <i className="fa fa-plus" onClick={handleAlertModalButton} />}
                </div>

                <div className="reader-column__searchbar reader-alert-list__searchbar">
                    <SearchInput
                        placeholder="Search by name"
                        value={searchValue}
                        classNames={['reader-column__input reader-alert-list__input']}
                        iconClassNames={['reader-alert-list__searchbar-icon']}
                        closeClassNames={['reader-alert-list__searchbar-icon']}
                        onChange={setSearchValue}
                        onClear={() => setSearchValue('')}
                    />
                </div>

                <div className="reader-column__body reader-alert-list__body">
                    {visibleAlerts.length < 1 ? (
                        <div style={{ padding: '0.375rem 0.75rem' }}>No alerts found</div>
                    ) : (
                        visibleAlerts.map((alert) => (
                            <AlertListItem
                                alert={alert}
                                key={alert.id}
                                tagData={tagData}
                                setSelectedAlert={setSelectedAlert}
                                fetchingAlertContent={fetchingAlertContent}
                                selectedAlert={selectedAlert}
                                handleTagSelect={handleTagSelect}
                                selectedTag={selectedTag}
                            />
                        ))
                    )}
                </div>

                <div className="reader-column__footer reader-alert-list__footer">
                    {visibleAlerts.length} {visibleAlerts.length === 1 ? 'Item' : 'Items'}
                </div>

                <div className="reader-column__footer">
                    <i className={'fa fa-lock'} style={{ cursor: 'pointer' }} onClick={() => handleLogout()} />
                </div>
            </div>

            {manageAlertsModalOpen && (
                <ManageAlertsModal
                    isOpen={manageAlertsModalOpen}
                    toggle={toggleManageAlertsModal}
                    assignedAlerts={alerts}
                    isPublic={isPublic}
                    state={state}
                    refreshAlerts={refreshAlerts}
                    userId={userId}
                />
            )}

            {signInModalOpen && <PublicModalSignIn isOpen={signInModalOpen} toggle={toggleSignInModal} publicEmail={publicEmail} />}
        </div>
    );
};
