import React from 'react';
import { FormGroup, Input } from 'reactstrap';
import { useDevice } from 'hooks/useMediaQuery';

export const GeoDropdownFilter = ({
    activeCountryFilter,
    setActiveCountryFilter,
    setActiveRegionFilter,
    activeRegionFilter,
    countries,
    regions,
    setRegions,
    isCountyFilter,
    style,
}) => {
    const { isMobile } = useDevice();
    const options = isCountyFilter ? countries : regions;
    const activeFilter = isCountyFilter ? activeCountryFilter : activeRegionFilter;

    const handleDropdownSelect = (val) => {
        if (isCountyFilter) {
            setActiveCountryFilter(val);
            setActiveRegionFilter('');

            if (!val) {
                setActiveRegionFilter(null);
                setRegions && setRegions([]);
            }
        } else {
            setActiveRegionFilter(val);
        }
    };

    return (
        <div className="geo-dropdown d-flex align-items-center md-ml-2" style={style}>
            {!isMobile && <span style={{ color: 'black', width: '70px' }}>Filter by</span>}

            <FormGroup className="m-0 mr-2 geo-dropdown-select">
                <Input
                    type="select"
                    name="select"
                    placeholder={isCountyFilter ? 'Countries' : 'Regions'}
                    value={activeFilter || ''}
                    onChange={(e) => handleDropdownSelect(e.target.value)}
                    className="countries-select">
                    <option value="">{isCountyFilter ? 'Country' : 'Region'}</option>
                    {!!options?.length &&
                        options.map((item, i) => (
                            <option key={(item?.countryName || item?.name) + '-' + i} value={item?.countryName || item?.name}>
                                {item?.countryName || item?.name}
                            </option>
                        ))}
                </Input>
            </FormGroup>
        </div>
    );
};
