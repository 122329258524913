import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalFooter } from 'reactstrap';
import { SpinnerButton } from 'common/SpinnerButton';
import { InputWithError } from 'common/InputWithError';
import { ModalBodyWithClose } from 'common/ModalBodyWithClose';
import { editNewsletterSection, addNewsletterSection } from 'utils/api/newslettersAPI';

export const CreateEditSectionModal = ({ isEdit = false, sectionId, newsletterId, sectionName, refreshNewsletter, toggle, isOpen, handleError }) => {
    const [name, setName] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const [errors, setErrors] = useState(null);

    useEffect(() => {
        if (isEdit) setName(sectionName);
    }, []);

    useEffect(() => {
        if (!isOpen) setName('');
    }, [isOpen]);

    const handleSubmit = async () => {
        if (!submitting) {
            setSubmitting(true);
            setErrors(null);

            try {
                if (isEdit) {
                    await editNewsletterSection(newsletterId, sectionId, { name });
                    await refreshNewsletter();
                    toggle();
                } else {
                    await addNewsletterSection(newsletterId, { name });
                    await refreshNewsletter();
                    toggle();
                }
            } catch (err) {
                handleError(err, setErrors);
            }

            setSubmitting(false);
        }
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalBodyWithClose toggle={toggle}>
                <h4>{isEdit ? 'Rename Section' : 'Create Section'}</h4>

                <InputWithError value={name} errorObj={errors} name="name" onChange={setName} marginBottomClass="mb-0" placeholder="Section name" />
            </ModalBodyWithClose>

            <ModalFooter>
                <Button color="secondary" onClick={toggle}>
                    Cancel
                </Button>

                <SpinnerButton
                    submitting={submitting}
                    title={isEdit ? 'Save' : 'Create'}
                    style={{ minWidth: '68px' }}
                    color="primary"
                    onClick={handleSubmit}
                />
            </ModalFooter>
        </Modal>
    );
};
