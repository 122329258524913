import React from 'react';
import { Bar } from 'react-chartjs-2';
import { useDevice } from 'hooks/useMediaQuery';

export const EmptyChart = ({ loading, isAlerts }) => {
    const clickData = { datasets: [] };
    const { isMobile } = useDevice();

    return (
        <div className="comparative-analysis-chart comparative-analysis-chart--empty">
            <div className="comparative-analysis-chart__empty-label">
                {isAlerts ? (
                    <span>No alert content is available yet. Please check again later for updated content.</span>
                ) : loading ? (
                    <span>
                        <i className="fa fa-spinner fa-spin mr-2" /> Fetching comparative set data...
                    </span>
                ) : (
                    <span>Select or create a new set to compare</span>
                )}
            </div>
            <div
                style={{
                    opacity: 0.5,
                    maxHeight: isMobile ? 500 : 300,
                    alignItems: 'center',
                }}>
                <Bar
                    data={clickData}
                    height={isMobile ? 500 : 300}
                    options={{
                        fillOpacity: 0.3,
                        legend: {
                            display: true,
                        },
                        maintainAspectRatio: false,
                        scales: {
                            y: {
                                ticks: {
                                    beginAtZero: true,
                                },
                            },
                        },
                    }}
                />
            </div>
        </div>
    );
};
