import React, { useContext } from 'react';
import { useQuery } from 'react-query';
import { AuthContext } from 'contexts/AuthContext';
import { FlashMessageContext } from 'contexts/FlashMessageContext';
import { FilterContext } from 'contexts/FilterContext';
import { getAlertLabels } from 'utils/api/alertsAPI';
import { SingleUserBlock } from 'common/SingleUserBlock';
import { LoadingSpinner } from 'common/LoadingSpinner';
import { LabelList } from './LabelList';
import { EmptyList } from './EmptyList';

export const Labels = () => {
    const { state, handleError } = useContext(AuthContext);
    const { addFlashMessage } = useContext(FlashMessageContext);
    const { setFiltersObject, alertLabelFilters } = useContext(FilterContext);

    const { data, isLoading, refetch } = useQuery(['getAlertLabels', state.isSingleUser], () => (!state.isSingleUser ? getAlertLabels() : null), {
        onError: () => addFlashMessage('danger', 'Unable to fetch labels'),
    });

    return (
        <div>
            {state.isSingleUser ? (
                <SingleUserBlock itemType="labels" />
            ) : isLoading ? (
                <LoadingSpinner />
            ) : data?.length > 0 ? (
                <LabelList
                    labels={data}
                    fetchingData={isLoading}
                    refreshLabels={refetch}
                    addFlashMessage={addFlashMessage}
                    handleError={handleError}
                    setFiltersObject={setFiltersObject}
                    alertLabelFilters={alertLabelFilters}
                />
            ) : (
                <EmptyList
                    fetchingData={isLoading}
                    refreshLabels={refetch}
                    addFlashMessage={addFlashMessage}
                    handleError={handleError}
                    setFiltersObject={setFiltersObject}
                    alertLabelFilters={alertLabelFilters}
                />
            )}
        </div>
    );
};
