import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Row, InputGroupText, Input, InputGroup, Button } from 'reactstrap';
import { FlashMessageContext } from 'contexts/FlashMessageContext';
import { resetPasswordRequest } from 'utils/api/authAPI';
import { SpinnerButton } from 'common/SpinnerButton';
import Logo from 'images/logo.png';

export const AccountSuspendedForm = () => {
    const navigate = useNavigate();

    const { addFlashMessage } = useContext(FlashMessageContext);
    const [email, setEmail] = useState('');
    const [requestSent, setRequestSent] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    const redirectToLogin = () => {
        navigate('/signin');
    };

    const handleSubmit = async () => {
        try {
            setSubmitting(true);
            const params = {
                email,
                targetUrl: window.location.origin + '/reset-password',
            };
            await resetPasswordRequest(params);

            setSubmitting(false);
            setRequestSent(true);
        } catch (err) {
            setSubmitting(false);
            addFlashMessage('danger', 'Unable to complete your request at this time');
        }
    };

    return (
        <div className="auth-block">
            <div className="auth-block__form">
                <div className="animated fadeIn">
                    <Row className="justify-content-center mt-5" style={{ marginBottom: '.5rem' }}>
                        <Col md={9}>
                            <div className="auth-block__image-container">
                                <a href="/">
                                    <img src={Logo} alt="Ozmosys" className="auth-block__image img-fluid" />
                                </a>
                            </div>
                            {requestSent ? (
                                <div>
                                    <div className="text-center">
                                        <h2>Check Your Email</h2>
                                        <p className="mt-4 mb-4 text-muted">
                                            We've sent an email to the address you provided. Please follow the instructions in the email to reset your
                                            account.
                                        </p>
                                        <Button block className="mt-4 " color={'primary'} onClick={redirectToLogin}>
                                            Return to Login
                                        </Button>
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    <div className="text-center">
                                        <h2>Account Suspended</h2>
                                        <p className="mt-4 mb-4 text-muted">
                                            Your account has been suspended. Enter your email address below to reset your password and reinstate your
                                            account.
                                        </p>
                                        <InputGroup>
                                            <InputGroupText>@</InputGroupText>
                                            <Input placeholder="Email" name="email" onChange={(e) => setEmail(e.target.value)} />
                                        </InputGroup>
                                        <SpinnerButton
                                            color="primary"
                                            block
                                            onClick={handleSubmit}
                                            disabled={email.length < 1}
                                            submitting={submitting}
                                            title={'Submit'}
                                        />
                                    </div>
                                </div>
                            )}
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
};
