import React from 'react';
import { Pie } from 'react-chartjs-2';

export const TopStatsChart = ({ statsData }) => {
    const labels = statsData.slice(0, 5).map((item) => item?.name || item?.domain || item?.title);
    const counts = statsData.slice(0, 5).map((item) => item.cnt);
    const sum = counts.reduce((a, b) => a + b);

    const data = {
        labels: labels,
        datasets: [
            {
                data: counts,
                backgroundColor: ['#1c5181', '#1a94c7', '#03bdea', '#90ccfe', '#c3d2da'],
                hoverBackgroundColor: ['#1c5181', '#1a94c7', '#03bdea', '#90ccfe', '#c3d2da'],
            },
        ],
    };

    return (
        <div style={{ maxHeight: 250 }}>
            <Pie
                data={data}
                animation={{
                    duration: 0,
                }}
                height={250}
                options={{
                    legend: {
                        display: false,
                    },
                    fontSize: 44,
                    maintainAspectRatio: false,
                    plugins: {
                        datalabels: {
                            display: true,
                            color: 'white',
                            font: {
                                size: 18,
                                weight: '500',
                            },
                            formatter: function (value) {
                                if (Math.round((value / sum) * 100) > 9) {
                                    return Math.round((value / sum) * 100) + '%';
                                } else {
                                    return null;
                                }
                            },
                        },
                    },
                }}
            />
        </div>
    );
};
