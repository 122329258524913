import React, { useEffect, useState } from 'react';
import { Modal, ModalFooter, Button } from 'reactstrap';
import { useQuery } from 'react-query';
import { getAlertGroups, getAlertsWithoutParams } from 'utils/api/alertsAPI';
import { SpinnerButton } from 'common/SpinnerButton';
import { DualSelect } from 'common/DualSelect';
import { LoadingSpinner } from 'common/LoadingSpinner';
import { ModalBodyWithClose } from 'common/ModalBodyWithClose';
import './style.scss';

export const SelectAlertsModal = ({
    toggle,
    isOpen,
    submitting,
    currentId,
    setSelectedAlerts,
    handleAlertSelectSubmit,
    selectedList,
    handleError,
}) => {
    const [fullAlertList, setFullAlertList] = useState([]);

    const { data: alertsData, isLoading: isLoadingAlerts } = useQuery(
        ['getAlertsWithoutParams', isOpen],
        () => (isOpen ? getAlertsWithoutParams(false, false, 1, 2000) : null),
        {
            onSuccess: (res) => setFullAlertList(res?.result),
            onError: (err) => handleError(err),
        }
    );
    const { data: alertGroupsData, isLoading: isLoadingAlertGroups } = useQuery(
        ['getAlertGroups', isOpen],
        () => (isOpen ? getAlertGroups() : null),
        { onError: (err) => handleError(err) }
    );

    useEffect(() => {
        if (alertsData && currentId) {
            const newList = [...alertsData.result];
            const foundAlert = newList.find((item) => item.id === currentId);
            newList.splice(alertsData.result.indexOf(foundAlert), 1);
        }
    }, [currentId, alertsData]);

    const handleSubmit = () => {
        handleAlertSelectSubmit();
        toggle();
    };

    return (
        <Modal className="internal-search-select" isOpen={isOpen} toggle={toggle}>
            <ModalBodyWithClose toggle={toggle}>
                <h2>Select Sources</h2>

                {isLoadingAlerts || isLoadingAlertGroups ? (
                    <LoadingSpinner />
                ) : (
                    <DualSelect
                        selectedList={selectedList}
                        fullList={fullAlertList}
                        onSelect={setSelectedAlerts}
                        contentType="alerts"
                        groups={alertGroupsData}
                        isSearch
                    />
                )}
            </ModalBodyWithClose>
            <ModalFooter>
                <Button type="button" onClick={toggle}>
                    Cancel
                </Button>

                <SpinnerButton
                    color="primary"
                    submitting={submitting}
                    onClick={handleSubmit}
                    className="float-right"
                    title="Save Changes"
                    type="modal"
                />
            </ModalFooter>
        </Modal>
    );
};
