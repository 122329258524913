import React, { useState } from 'react';
import { Modal, ModalFooter, Button } from 'reactstrap';
import { SpinnerButton } from 'common/SpinnerButton';
import { ModalBodyWithClose } from 'common/ModalBodyWithClose';
import { deleteUser } from 'utils/api/usersAPI';

export const DeleteUserModal = ({ isOpen, toggle, userToDelete, addFlashMessage, handleError, refreshUsers }) => {
    const [deleting, setDeleting] = useState(false);

    const handleDeleteUser = async () => {
        setDeleting(true);

        try {
            await deleteUser(userToDelete.id);
            await refreshUsers();
            toggle();
            addFlashMessage('success', 'User successfully deleted');
        } catch (err) {
            handleError(err);
        }

        setDeleting(false);
    };

    if (!userToDelete) return null;

    return (
        <Modal isOpen={isOpen} toggle={toggle} className="organization-switch-confirm">
            <ModalBodyWithClose toggle={toggle}>Are you sure you want to delete {userToDelete.name}?</ModalBodyWithClose>
            <ModalFooter>
                <Button color="secondary" onClick={toggle}>
                    Cancel
                </Button>
                <SpinnerButton color="danger" title="Confirm" onClick={handleDeleteUser} submitting={deleting} />
            </ModalFooter>
        </Modal>
    );
};
