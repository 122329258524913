import React from 'react';
import { Button } from 'reactstrap';
import { RadioInput } from 'common/Input';
import { GeoDropdownFilter } from '../Dropdowns/GeoDropdownFilter';

export const FiltersBlock = ({
    isAdvancedSearchShown,
    regions,
    countries,
    setRegions,
    activeCountryFilter,
    activeRegionFilter,
    minRelValue,
    setActiveCountryFilter,
    setActiveRegionFilter,
    setMinRelValue,
    isHasAccessElasticSearch,
    clearAllFilters,
    isSimple,
    isEdit,
}) => {
    const isNeedMargin = isEdit || isAdvancedSearchShown || !isSimple;

    return (
        <>
            {isAdvancedSearchShown || isSimple ? (
                <div className="mt-2 mb-3 flex">
                    <div className="mr-3">
                        <GeoDropdownFilter
                            activeCountryFilter={activeCountryFilter}
                            setActiveCountryFilter={setActiveCountryFilter}
                            setActiveRegionFilter={setActiveRegionFilter}
                            countries={countries || []}
                            setRegions={setRegions}
                            isCountyFilter
                            style={{ marginLeft: isNeedMargin ? '0' : '-1rem' }}
                        />
                    </div>

                    {!!regions?.length && (
                        <GeoDropdownFilter
                            activeRegionFilter={activeRegionFilter}
                            setActiveRegionFilter={setActiveRegionFilter}
                            regions={regions || []}
                            style={{ marginLeft: isNeedMargin ? '0' : '-1rem' }}
                        />
                    )}
                </div>
            ) : (
                <div className="bordered-preview-list__summary">
                    <GeoDropdownFilter
                        activeCountryFilter={activeCountryFilter}
                        setActiveCountryFilter={setActiveCountryFilter}
                        setActiveRegionFilter={setActiveRegionFilter}
                        countries={countries || []}
                        setRegions={setRegions}
                        isCountyFilter
                        style={{ marginLeft: isNeedMargin ? '0' : '-1rem' }}
                    />

                    {!!regions?.length && (
                        <GeoDropdownFilter
                            activeRegionFilter={activeRegionFilter}
                            setActiveRegionFilter={setActiveRegionFilter}
                            regions={regions || []}
                            style={{ marginLeft: isNeedMargin ? '0' : '-1rem' }}
                        />
                    )}

                    {isHasAccessElasticSearch && (
                        <div>
                            <RadioInput
                                style={{ display: 'inline-block', marginRight: '15px' }}
                                onChange={() => setMinRelValue(75)}
                                checked={minRelValue * 1 === 75}
                                name="75"
                                text="Top Results"
                            />

                            <RadioInput
                                style={{ display: 'inline-block' }}
                                onChange={() => setMinRelValue(0)}
                                checked={minRelValue * 1 === 0}
                                name="100"
                                text="All Results"
                            />
                        </div>
                    )}

                    <Button color="link" className="btn-clear-all" style={{ width: '90px' }} onClick={clearAllFilters}>
                        Clear all
                    </Button>
                </div>
            )}
        </>
    );
};
