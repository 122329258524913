import React from 'react';

import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { COMPANY_TYPE, SEARCH_TYPE, SOURCE_TYPE } from 'constants/alerts';

export const EmptyList = ({ type }) => (
    <div className="font-md">
        <p>
            You can get emails when new results for a topic show up in Google Search. For example, you can get info about news, products, or mentions
            of your name. Monitor the web for desired news, create your first alert!
        </p>
        {type === SOURCE_TYPE && (
            <Link to="/account/sources/create?type=rss">
                <Button color="primary">Add RSS</Button>
            </Link>
        )}
        {type === COMPANY_TYPE && (
            <Link to="/account/companies/create">
                <Button color="primary">Add a company</Button>
            </Link>
        )}
        {type === SEARCH_TYPE && (
            <Link to="/account/alerts/create">
                <Button color="primary">Create Alert</Button>
            </Link>
        )}
    </div>
);
