import React, { useState, useEffect } from 'react';
import { Modal, ModalFooter, Button } from 'reactstrap';
import { useQuery } from 'react-query';
import { getUsersWithoutParams } from 'utils/api/usersAPI';
import { SpinnerButton } from 'common/SpinnerButton';
import { LoadingSpinner } from 'common/LoadingSpinner';
import { DualSelect } from 'common/DualSelect';
import { ModalBodyWithClose } from 'common/ModalBodyWithClose';

export const AssignGroupModal = ({ isOpen, toggle, onSubmit, submitting, addFlashMessage, alertGroup }) => {
    const [selectedUsers, setSelectedUsers] = useState([]);

    const { data, isLoading } = useQuery(['getUsersWithoutParams'], () => getUsersWithoutParams(false, false, 1, 2000), {
        onError: () => addFlashMessage('danger', 'Unable to fetch user data'),
    });

    useEffect(() => {
        setSelectedUsers([]);
    }, [isOpen]);

    return (
        <Modal isOpen={isOpen} toggle={toggle} className="create-alert-group-modal modal-xl">
            {isLoading ? (
                <LoadingSpinner padding text="Fetching user data" />
            ) : (
                <>
                    <ModalBodyWithClose toggle={toggle}>
                        <h2>Assign Group</h2>
                        <div className="mt-2" style={{ fontSize: '1rem' }}>
                            Alert Group: <strong>{alertGroup && alertGroup.name}</strong>
                        </div>
                        <div className="mt-3">
                            <h4>Select Users</h4>
                            <DualSelect selectedList={selectedUsers} fullList={data?.result || []} onSelect={setSelectedUsers} />
                        </div>
                    </ModalBodyWithClose>
                    <ModalFooter>
                        <Button color="secondary" onClick={toggle}>
                            Cancel
                        </Button>
                        <SpinnerButton
                            type="modal"
                            color="primary"
                            onClick={() => onSubmit(alertGroup, selectedUsers, toggle)}
                            submitting={submitting}
                            title="Assign"
                        />
                    </ModalFooter>
                </>
            )}
        </Modal>
    );
};
