import React, { useState, useEffect } from 'react';
import { Modal, ModalFooter, Button } from 'reactstrap';
import { SpinnerButton } from 'common/SpinnerButton';
import { InputWithError } from 'common/InputWithError';
import { ModalBodyWithClose } from 'common/ModalBodyWithClose';
import { editUserLabel } from 'utils/api/usersAPI';

export const EditLabelModal = ({ isOpen, toggle, refreshLabels, handleError, item, addFlashMessage }) => {
    const [name, setName] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const [errors, setErrors] = useState(null);

    useEffect(() => {
        item && setName(item.name);
    }, [item]);

    const handleSubmit = async () => {
        try {
            setSubmitting(true);
            setErrors(null);
            let params = {
                name,
            };
            await editUserLabel(item.id, params);
            refreshLabels();
            addFlashMessage('success', 'User label successfully edited');
            setSubmitting(false);
            toggle();
        } catch (err) {
            setSubmitting(false);
            handleError(err, setErrors);
        }
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle} className="create-label-modal">
            {!item ? null : (
                <>
                    <ModalBodyWithClose toggle={toggle} className="pb-0">
                        <h2>Edit Label</h2>

                        <InputWithError
                            placeholder="Label name"
                            name="name"
                            value={name}
                            type="text"
                            onChange={setName}
                            errorObj={errors}
                            prependIcon={<i className="fa fa-tag" />}
                            marginBottonClass="mb-3"
                        />
                    </ModalBodyWithClose>

                    <ModalFooter>
                        <Button color="secondary" onClick={toggle}>
                            Cancel
                        </Button>

                        <SpinnerButton type="modal" color="primary" onClick={handleSubmit} submitting={submitting} title="Update" />
                    </ModalFooter>
                </>
            )}
        </Modal>
    );
};
