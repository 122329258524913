import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from 'contexts/AuthContext';
import { SpinnerButton } from 'common/SpinnerButton';
import { suspendOrganizationAccount } from 'utils/api/profileAPI';
import { ConfirmSuspend } from './Modals/ConfirmSuspend';
import './style.scss';

export const SuspendAccount = ({ isSingleUser }) => {
    const navigate = useNavigate();
    const { handleError } = useContext(AuthContext);

    const redirectToAccountSuspended = () => navigate('/suspended');
    const toggleSuspendModal = () => setSuspendModalOpen(!suspendModalOpen);

    const [submitting, setSubmitting] = useState(false);
    const [suspendModalOpen, setSuspendModalOpen] = useState(false);

    const handleSuspendAccount = async () => {
        setSubmitting(true);

        try {
            await suspendOrganizationAccount();
            redirectToAccountSuspended();
        } catch (err) {
            handleError(err);
        }

        setSubmitting(false);
    };

    return (
        <div className="suspend-account">
            <h4 className="settings__page-header">Suspend Account</h4>

            {isSingleUser ? (
                <div className="suspend-account__info">
                    Clicking this button will log you out, and suspend your account. For reinstatement, you must attempt to sign in and change your
                    password.
                </div>
            ) : (
                <div className="suspend-account__info">
                    Clicking this button will log you out, and suspend the accounts of you and all of your users. For reinstatement, the admin who
                    suspended this account must attempt to sign in and change their password.
                </div>
            )}

            <div className="suspend-account__btn">
                <SpinnerButton color="primary" submitting={submitting} onClick={handleSuspendAccount} title={'Suspend Account'} />
            </div>

            <ConfirmSuspend isOpen={suspendModalOpen} toggle={toggleSuspendModal} submitting={submitting} onSubmit={handleSuspendAccount} />
        </div>
    );
};
