import React, { useEffect, useState } from 'react';
import { Table, Button, UncontrolledTooltip } from 'reactstrap';
import { CSVLink } from 'react-csv';
import moment from 'moment-timezone';
import { SpinnerButtonSquare } from 'common/SpinnerButton/SpinnerButtonSquare';
import { SearchInputWithSubmit } from 'common/SearchInput/SearchInputWithSubmit';
import { filterByValue, sortObjects, sortByValueLength, handleCaret, safeTrim } from 'utils/helpers';
import { useDevice } from 'hooks/useMediaQuery';
import { CreateUserGroupModal } from './Modals/CreateUserGroupModal';
import { EditUserGroupModal } from './Modals/EditUserGroupModal';
import { DeleteUserGroupModal } from './Modals/DeleteUserGroupModal';
import { UserGroupsMobileFilterDropdown } from './UserGroupsMobileFilterDropdown';

export const UserGroupList = ({ userGroups, addFlashMessage, refreshUserGroups, handleError, setFiltersObject, userGroupFilters }) => {
    const { isDesktop, isTablet, isMobile } = useDevice();
    const { searchValue, activeSortProperty, activeSortDirection } = userGroupFilters;

    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [itemToEdit, setItemToEdit] = useState(null);
    const [itemToDelete, setItemToDelete] = useState(null);
    const [tempSearchValue, setTempSearchValue] = useState('');
    const [visibleUserGroups, setVisibleUserGroups] = useState([]);
    const [csvData, setCsvData] = useState([]);
    const [activeMobileFilterValue, setActiveMobileFilterValue] = useState('');

    useEffect(() => {
        tempSearchValue !== searchValue && setTempSearchValue(searchValue);
    }, []);

    useEffect(() => {
        setVisibleUserGroups(userGroups);
    }, [userGroups]);

    useEffect(() => {
        const filteredList = filterByValue(userGroups, 'name', searchValue);
        const sortedList =
            activeSortProperty === 'users'
                ? sortByValueLength(filteredList, activeSortProperty, activeSortDirection)
                : sortObjects(filteredList, activeSortProperty, activeSortDirection);

        setVisibleUserGroups(sortedList);
    }, [userGroupFilters, userGroups]);

    const toggleCreateModal = () => setCreateModalOpen(!createModalOpen);

    const toggleEditModal = () => {
        editModalOpen && setItemToEdit(null);
        setEditModalOpen(!editModalOpen);
    };

    useEffect(() => {
        itemToEdit && toggleEditModal();
    }, [itemToEdit]);

    const toggleDeleteModal = (item) => {
        setDeleteModalOpen(!deleteModalOpen);

        if (!deleteModalOpen) {
            setItemToDelete(item);
        } else {
            setItemToDelete(null);
        }
    };

    const handleSearchValueSubmit = () => {
        if (tempSearchValue.length > 2048) {
            addFlashMessage('danger', 'Your search exceeds the maximum number of allowed characters (2048)');
            return;
        }

        const filters = { ...userGroupFilters };
        filters.searchValue = tempSearchValue;
        filters.paginationCurrentPageNumber = 1;
        setFiltersObject('userGroups', filters);
    };

    const handleClear = () => {
        const filters = { ...userGroupFilters };
        filters.searchValue = '';
        filters.paginationCurrentPageNumber = 1;
        setFiltersObject('userGroups', filters);
        setTempSearchValue('');
    };

    const handleBuildCsvFile = (arr) => {
        const csvData = [['Group Name', 'User Name', 'User Email']];

        if (arr.length) {
            arr.map((item) => {
                item.users.map((user) => {
                    csvData.push([safeTrim(item.name), safeTrim(user.name), user.email]);
                });
            });
        }

        return csvData;
    };

    useEffect(() => {
        visibleUserGroups.length && setCsvData(handleBuildCsvFile(visibleUserGroups));
    }, [visibleUserGroups]);

    const handleSortableHeaderClick = (value, activeSortProperty, activeSortDirection) => {
        const filters = { ...userGroupFilters };

        if (value === activeSortProperty) {
            activeSortDirection === 'asc' ? (filters.activeSortDirection = 'desc') : (filters.activeSortDirection = 'asc');
        } else {
            filters.activeSortProperty = value;
            filters.activeSortDirection = 'asc';
        }

        setFiltersObject('userGroups', filters);
    };

    const handleMobileFilterSelect = (val) => {
        const splitValue = val.split('-');
        const filters = { ...userGroupFilters };

        filters.activeSortProperty = splitValue[0];
        filters.activeSortDirection = splitValue[1];
        setFiltersObject('userGroups', filters);
    };

    const determineMobileFiltersValue = () => {
        const filterStr = userGroupFilters.activeSortProperty + '-' + userGroupFilters.activeSortDirection;
        setActiveMobileFilterValue(filterStr);
    };

    useEffect(() => {
        userGroupFilters && determineMobileFiltersValue();
    }, [userGroupFilters]);

    return (
        <div className="list user-group-list">
            {isDesktop && (
                <div className="list__utility-row list__utility-row--desktop">
                    <div className="list__search-container">
                        <SearchInputWithSubmit
                            value={tempSearchValue}
                            onChange={setTempSearchValue}
                            placeholder={'Filter by name'}
                            onClear={handleClear}
                            extraParams={null}
                            submitting={false}
                            onSubmit={handleSearchValueSubmit}
                            list
                        />
                    </div>
                    <div className="d-flex">
                        <CSVLink data={csvData} filename={'ozmosys_user-groups_' + moment().tz(moment.tz.guess()).format('MMDDYYYY') + '.csv'}>
                            <SpinnerButtonSquare
                                title={<i className="fa fa-download" />}
                                className="mr-2 d-block"
                                id="user-groups-csv-button-tooltip"
                            />
                        </CSVLink>

                        <UncontrolledTooltip placement="bottom" target="user-groups-csv-button-tooltip" delay={{ show: 400, hide: 0 }}>
                            Download as CSV file
                        </UncontrolledTooltip>

                        <Button onClick={toggleCreateModal} color="primary">
                            Create User Group
                        </Button>
                    </div>
                </div>
            )}

            {isTablet && (
                <div className="list__utility-row list__utility-row--tablet">
                    <div className="d-flex justify-content-between w-100">
                        <div className="list__search-container">
                            <SearchInputWithSubmit
                                value={tempSearchValue}
                                onChange={setTempSearchValue}
                                placeholder={'Filter by name'}
                                onClear={handleClear}
                                extraParams={null}
                                submitting={false}
                                onSubmit={handleSearchValueSubmit}
                                list
                            />
                        </div>
                    </div>
                    <div className="d-flex mt-2 w-100 justify-content-end">
                        <div className="mr-2 d-flex">
                            <Button onClick={toggleCreateModal} color="primary" className="mb-0">
                                <i className="fa fa-plus" />
                                &nbsp; Create User Group
                            </Button>
                        </div>

                        <div>
                            <CSVLink data={csvData} filename={'ozmosys_user-groups_' + moment().tz(moment.tz.guess()).format('MMDDYYYY') + '.csv'}>
                                <SpinnerButtonSquare
                                    title={<i className="fa fa-download" />}
                                    className="mr-2"
                                    id="user-groups-csv-button-tooltip-tablet"
                                />
                            </CSVLink>

                            <UncontrolledTooltip placement="bottom" target="user-groups-csv-button-tooltip-tablet" delay={{ show: 400, hide: 0 }}>
                                Download as CSV file
                            </UncontrolledTooltip>
                        </div>

                        <UserGroupsMobileFilterDropdown
                            handleMobileFilterSelect={handleMobileFilterSelect}
                            activeMobileFilterValue={activeMobileFilterValue}
                        />
                    </div>
                </div>
            )}

            {isMobile && (
                <div className="list-utility-row list__utility-row--mobile">
                    <div className="d-flex mb-2">
                        <CSVLink
                            data={csvData}
                            className="w-100"
                            filename={'ozmosys_user-groups_' + moment().tz(moment.tz.guess()).format('MMDDYYYY') + '.csv'}>
                            <Button className="mr-2 w-100" id="user-groups-csv-button-tooltip-mobile">
                                <i className="fa fa-download" /> Download CSV
                            </Button>
                        </CSVLink>

                        <UncontrolledTooltip placement="bottom" target="user-groups-csv-button-tooltip-mobile" delay={{ show: 400, hide: 0 }}>
                            Download as CSV file
                        </UncontrolledTooltip>

                        <Button onClick={toggleCreateModal} color="primary" className="ml-1" style={{ flex: '0 0 50%' }}>
                            <i className="fa fa-plus" />
                            &nbsp; Create User Group
                        </Button>
                    </div>

                    <div className="d-flex mb-2">
                        <UserGroupsMobileFilterDropdown
                            handleMobileFilterSelect={handleMobileFilterSelect}
                            activeMobileFilterValue={activeMobileFilterValue}
                        />
                    </div>

                    <div className="mb-3">
                        <SearchInputWithSubmit
                            value={tempSearchValue}
                            onChange={setTempSearchValue}
                            placeholder={'Filter by name'}
                            onClear={handleClear}
                            extraParams={null}
                            submitting={false}
                            onSubmit={handleSearchValueSubmit}
                            list
                        />
                    </div>
                </div>
            )}

            <Table className="responsive-table">
                <tbody>
                    <tr>
                        <th
                            className="responsive-table__sortable-th"
                            onClick={() => handleSortableHeaderClick('name', activeSortProperty, activeSortDirection)}>
                            Name
                            {handleCaret(activeSortProperty, activeSortDirection, 'name')}
                        </th>
                        <th
                            className="responsive-table__sortable-th"
                            onClick={() => handleSortableHeaderClick('users', activeSortProperty, activeSortDirection)}>
                            Users
                            {handleCaret(activeSortProperty, activeSortDirection, 'users')}
                        </th>
                        <th style={{ textAlign: 'center' }}>Edit/Remove</th>
                    </tr>

                    {visibleUserGroups.length > 0 ? (
                        visibleUserGroups.map((userGroup) => (
                            <tr key={userGroup.id}>
                                <td data-label="Name">{userGroup.name}</td>
                                <td data-label="Users">{userGroup.users.length}</td>
                                <td style={{ textAlign: 'center' }} className="responsive-table__edit-block" data-label="Edit/Remove">
                                    <Button color="light" onClick={() => setItemToEdit(userGroup)}>
                                        <i className="fa fa-edit fa-lg" />
                                    </Button>

                                    <Button color="light" onClick={() => toggleDeleteModal(userGroup)}>
                                        <i className="fa fa-trash fa-lg" />
                                    </Button>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td>No results found</td>
                            <td />
                            <td />
                        </tr>
                    )}
                </tbody>
            </Table>

            {createModalOpen && (
                <CreateUserGroupModal
                    isOpen={createModalOpen}
                    toggle={toggleCreateModal}
                    addFlashMessage={addFlashMessage}
                    refreshUserGroups={refreshUserGroups}
                    handleError={handleError}
                />
            )}

            {editModalOpen && (
                <EditUserGroupModal
                    isOpen={editModalOpen}
                    toggle={toggleEditModal}
                    item={itemToEdit}
                    addFlashMessage={addFlashMessage}
                    handleError={handleError}
                    refreshUserGroups={refreshUserGroups}
                />
            )}

            {deleteModalOpen && (
                <DeleteUserGroupModal
                    isOpen={deleteModalOpen}
                    toggle={toggleDeleteModal}
                    item={itemToDelete}
                    addFlashMessage={addFlashMessage}
                    handleError={handleError}
                    refreshUserGroups={refreshUserGroups}
                />
            )}
        </div>
    );
};
