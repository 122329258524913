import React, { createContext, useState } from 'react';

export const FilterContext = createContext();

const FilterContextProvider = ({ children }) => {
    const [alertFilters, setAlertFilters] = useState({
        paginationCurrentPageNumber: 1,
        activeSortProperty: 'alert.createdAt',
        activeSortDirection: 'desc',
        activeFilterLabels: [],
        searchValue: '',
        searchProperty: 'alert.nameOrKeyword',
        useCount: true,
    });

    const [alertCatCompFilters, setAlertCatCompFilters] = useState({
        paginationCurrentPageNumber: 1,
        activeSortProperty: 'alert.name',
        activeSortDirection: 'asc',
        activeFilterLabels: [],
        searchValue: '',
        searchProperty: 'alert.nameOrKeyword',
        useCount: true,
    });

    const [alertGroupFilters, setAlertGroupFilters] = useState({
        searchValue: '',
        activeSortProperty: 'name',
        activeSortDirection: 'asc',
    });

    const [alertLabelFilters, setAlertLabelFilters] = useState({
        searchValue: '',
        activeSortProperty: 'name',
        activeSortDirection: 'asc',
    });

    const [userFilters, setUserFilters] = useState({
        paginationCurrentPageNumber: 1,
        activeSortProperty: 'user.name',
        activeSortDirection: 'asc',
        activeFilterLabels: [],
        searchValue: '',
        searchProperty: 'user.emailOrName',
    });

    const [userGroupFilters, setUserGroupFilters] = useState({
        searchValue: '',
        activeSortProperty: 'name',
        activeSortDirection: 'asc',
    });

    const [userLabelFilters, setUserLabelFilters] = useState({
        searchValue: '',
        activeSortProperty: 'name',
        activeSortDirection: 'asc',
    });

    const [newsletterFilters, setNewsletterFilters] = useState({
        paginationCurrentPageNumber: 1,
        activeSortProperty: 'name',
        activeSortDirection: 'asc',
        searchValue: '',
        searchProperty: 'name',
    });

    const [compSetFilters, setCompSetFilters] = useState({
        paginationCurrentPageNumber: 1,
        activeSortProperty: 'name',
        activeSortDirection: 'asc',
        searchValue: '',
        searchProperty: 'name',
    });

    const setFiltersObject = (type, valueObj) => {
        switch (type) {
            case 'alerts':
                setAlertFilters(valueObj);
                break;
            case 'alertCatComp':
                setAlertCatCompFilters(valueObj);
                break;
            case 'alertGroups':
                setAlertGroupFilters(valueObj);
                break;
            case 'alertLabels':
                setAlertLabelFilters(valueObj);
                break;
            case 'users':
                setUserFilters(valueObj);
                break;
            case 'userGroups':
                setUserGroupFilters(valueObj);
                break;
            case 'userLabels':
                setUserLabelFilters(valueObj);
                break;
            case 'newsletters':
                setNewsletterFilters(valueObj);
                break;
            case 'compSets':
                setCompSetFilters(valueObj);
                break;
            default:
                return;
        }
    };

    const resetAllFilters = () => {
        setFiltersObject('alerts', {
            paginationCurrentPageNumber: 1,
            activeSortProperty: 'alert.createdAt',
            activeSortDirection: 'desc',
            activeFilterLabels: [],
            searchValue: '',
            searchProperty: 'alert.nameOrKeyword',
            useCount: true,
        });

        setFiltersObject('alertCatComp', {
            paginationCurrentPageNumber: 1,
            activeSortProperty: 'alert.name',
            activeSortDirection: 'asc',
            activeFilterLabels: [],
            searchValue: '',
            searchProperty: 'alert.nameOrKeyword',
            useCount: true,
        });

        setFiltersObject('alertGroups', {
            searchValue: '',
            activeSortProperty: 'name',
            activeSortDirection: 'asc',
        });

        setFiltersObject('alertLabels', {
            searchValue: '',
            activeSortProperty: 'name',
            activeSortDirection: 'asc',
        });

        setFiltersObject('users', {
            paginationCurrentPageNumber: 1,
            activeSortProperty: 'user.name',
            activeSortDirection: 'asc',
            activeFilterLabels: [],
            searchValue: '',
            searchProperty: 'user.name',
        });

        setFiltersObject('userGroups', {
            searchValue: '',
            activeSortProperty: 'name',
            activeSortDirection: 'asc',
        });

        setFiltersObject('userLabels', {
            searchValue: '',
            activeSortProperty: 'name',
            activeSortDirection: 'asc',
        });

        setFiltersObject('newsletters', {
            paginationCurrentPageNumber: 1,
            activeSortProperty: 'name',
            activeSortDirection: 'asc',
            searchValue: '',
            searchProperty: 'name',
        });

        setFiltersObject('compSets', {
            paginationCurrentPageNumber: 1,
            activeSortProperty: 'name',
            activeSortDirection: 'asc',
            searchValue: '',
            searchProperty: 'name',
        });
    };

    return (
        <FilterContext.Provider
            value={{
                setFiltersObject,
                alertFilters,
                alertCatCompFilters,
                alertLabelFilters,
                alertGroupFilters,
                userFilters,
                userGroupFilters,
                userLabelFilters,
                newsletterFilters,
                compSetFilters,
                resetAllFilters,
            }}>
            {children}
        </FilterContext.Provider>
    );
};

export default FilterContextProvider;
