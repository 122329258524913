import React from 'react';
import { Table } from 'reactstrap';
import { AdminListItem } from './AdminListItem';

export const AdminList = ({ admins, isSingleUser, refreshProfile, handleError }) => (
    <div className="mt-5">
        <h4>{isSingleUser ? 'Account Info' : 'Admin List'}</h4>

        <div className="mt-3">
            <Table className="table-sm responsive-table">
                <tbody>
                    <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Allow Notifications</th>
                    </tr>

                    {admins &&
                        admins?.map((admin) => (
                            <AdminListItem key={admin.id} admin={admin} refreshProfile={refreshProfile} handleError={handleError} />
                        ))}
                </tbody>
            </Table>
        </div>
    </div>
);
