import React, { useState } from 'react';

export const LabelsBlock = ({ labels }) => {
    const [expanded, setExpanded] = useState(false);

    const toggleExpanded = () => setExpanded(!expanded);

    return (
        <div className="labels-block">
            {labels.length > 3 && !expanded
                ? labels.slice(0, 3).map((label) => (
                      <div key={label.id} className="label">
                          {label.name}
                      </div>
                  ))
                : labels.map((label) => (
                      <div key={label.id} className="label">
                          {label.name}
                      </div>
                  ))}
            {labels.length > 3 && (
                <div className="labels-block-expand" onClick={toggleExpanded}>
                    {!expanded ? '+' + labels.slice(3, labels.length).length + ' More' : 'Hide'}
                </div>
            )}
        </div>
    );
};
