import React, { useState } from 'react';
import { Modal, ModalFooter, Button } from 'reactstrap';
import { SpinnerButton } from 'common/SpinnerButton';
import { ModalBodyWithClose } from 'common/ModalBodyWithClose';
import { InputWithError } from 'common/InputWithError';

export const ConfirmDeleteModal = ({ isOpen, toggle, onDelete, errors, deleting }) => {
    const [confirmValue, setConfirmValue] = useState('');

    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalBodyWithClose toggle={toggle}>
                All Newsdesk feeds and user assignments will be deleted without the option to restore.
                <div className="mt-2 mb-3">Please acknowledge this deletion by typing DELETE in the field below.</div>
                <InputWithError
                    name="jwtToken"
                    value={confirmValue}
                    type="text"
                    onChange={setConfirmValue}
                    errorObj={errors}
                    marginBottomClass="mb-1"
                />
            </ModalBodyWithClose>

            <ModalFooter>
                <Button color="secondary" onClick={toggle}>
                    Back to safety
                </Button>

                <SpinnerButton color="danger" title="Delete" onClick={onDelete} submitting={deleting} disabled={confirmValue !== 'DELETE'} />
            </ModalFooter>
        </Modal>
    );
};
