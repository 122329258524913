import React, { useState, useEffect, useContext } from 'react';
import { Modal, ModalFooter, Button } from 'reactstrap';
import { AuthContext } from 'contexts/AuthContext';
import { FlashMessageContext } from 'contexts/FlashMessageContext';
import { SpinnerButton } from 'common/SpinnerButton';
import { LoadingSpinner } from 'common/LoadingSpinner';
import { DualSelect } from 'common/DualSelect';
import { ModalBodyWithClose } from 'common/ModalBodyWithClose';
import { useDevice } from 'hooks/useMediaQuery';
import { getAlertGroups, getAlertsWithoutParams, getReaderAlertGroups, getReaderAlerts } from 'utils/api/alertsAPI';
import { manageReaderAlerts } from 'utils/api/alertsAPI';
import { updateUserAlertsById } from 'utils/api/usersAPI';

export const ManageAlertsModal = ({ isOpen, toggle, refreshAlerts, userId, assignedAlerts, setActiveView, isPublic }) => {
    const { handleError } = useContext(AuthContext);
    const { addFlashMessage } = useContext(FlashMessageContext);
    const { isDesktop } = useDevice();
    const [fetchingAlerts, setFetchingAlerts] = useState(true);
    const [alerts, setAlerts] = useState([]);
    const [alertGroups, setAlertGroups] = useState([]);
    const [selectedAlerts, setSelectedAlerts] = useState([]);
    const [submitting, setSubmitting] = useState(false);

    useEffect(() => {
        setSelectedAlerts(assignedAlerts);
    }, [isOpen]);

    const fetchAlerts = async () => {
        try {
            const alertData = isPublic ? await getReaderAlerts() : await getAlertsWithoutParams(false, false, 1, 2000);
            const alertGroupData = isPublic ? await getReaderAlertGroups() : await getAlertGroups();

            setAlerts(alertData.result);
            setAlertGroups(alertGroupData);
            setFetchingAlerts(false);
        } catch (err) {
            setFetchingAlerts(false);
            handleError(err);
        }
    };

    const handleManageAlertsSubmit = async () => {
        try {
            setSubmitting(true);
            const params = { alerts: selectedAlerts.map((alert) => alert.id) };
            isPublic ? await manageReaderAlerts(params) : await updateUserAlertsById(userId, params);

            refreshAlerts('initialLoad');
            setSubmitting(false);
            setActiveView && setActiveView('alerts');
            addFlashMessage('success', 'Alert list successfully updated');
            toggle && toggle();
        } catch (err) {
            handleError(err);
            addFlashMessage('danger', 'Unable to update alerts at this time');
            setSubmitting(false);
        }
    };

    useEffect(() => {
        fetchAlerts();
    }, []);

    return (
        <>
            {isDesktop ? (
                <Modal isOpen={isOpen} toggle={toggle} className="reader-manage-alerts-modal modal-xl">
                    {fetchingAlerts ? (
                        <LoadingSpinner padding text="Fetching alert data" />
                    ) : (
                        <div>
                            <ModalBodyWithClose toggle={toggle}>
                                <h2>Manage Alerts</h2>

                                <div className="mt-4">
                                    <DualSelect
                                        selectedList={selectedAlerts}
                                        fullList={alerts}
                                        groups={alertGroups}
                                        onSelect={setSelectedAlerts}
                                        contentType="alerts"
                                    />
                                </div>
                            </ModalBodyWithClose>
                            <ModalFooter>
                                <Button color="secondary" onClick={toggle}>
                                    Cancel
                                </Button>

                                <SpinnerButton
                                    type="modal"
                                    color="primary"
                                    onClick={handleManageAlertsSubmit}
                                    submitting={submitting}
                                    title="Save Changes"
                                />
                            </ModalFooter>
                        </div>
                    )}
                </Modal>
            ) : (
                <div className="mobile-reader-list mobile-reader-manage-alerts">
                    <div className="mobile-reader-list__header mobile-reader-manage-alerts__header">Manage Alerts</div>

                    <div className="mobile-reader-list__body mobile-reader-manage-alerts__body">
                        {fetchingAlerts ? (
                            <LoadingSpinner text="Fetching alert data" />
                        ) : (
                            <div>
                                <DualSelect
                                    selectedList={selectedAlerts}
                                    fullList={alerts}
                                    groups={alertGroups}
                                    onSelect={setSelectedAlerts}
                                    contentType="alerts"
                                />

                                <div className="mt-3">
                                    <SpinnerButton
                                        type="modal"
                                        color="primary"
                                        onClick={handleManageAlertsSubmit}
                                        submitting={submitting}
                                        title="Save Changes"
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};
