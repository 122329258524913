import React, { useState, useContext, useEffect } from 'react';
import { Label } from 'reactstrap';
import { AuthContext } from 'contexts/AuthContext';
import { FlashMessageContext } from 'contexts/FlashMessageContext';
import { SpinnerButton } from 'common/SpinnerButton';
import { InputWithError } from 'common/InputWithError';
import { SelectInputWithError } from 'common/InputWithError/SelectInputWithError';
import { editOrganizationDetails } from 'utils/api/profileAPI';
import { timezones } from 'constants/timezones';
import { AdminList } from './AdminList';

export const General = ({ refreshProfile, profile, isSingleUser }) => {
    const { handleError } = useContext(AuthContext);
    const { addFlashMessage } = useContext(FlashMessageContext);

    const [companyName, setCompanyName] = useState('');
    const [selectedTimezone, setSelectedTimezone] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const [errors, setErrors] = useState(null);

    const updateOrganizationDetails = async () => {
        setSubmitting(true);

        try {
            setErrors(null);
            await editOrganizationDetails({ name: companyName, timezone: selectedTimezone });
            addFlashMessage('success', 'Organization successfully updated');
            refreshProfile();
        } catch (err) {
            handleError(err, setErrors);
        }

        setSubmitting(false);
    };

    useEffect(() => {
        if (profile.organization) {
            setCompanyName(profile.organization.name);
            setSelectedTimezone(profile.organization.timezone);
        }
    }, [profile.organization]);

    return (
        <div>
            {!isSingleUser && (
                <div>
                    <h4 className="settings__page-header">Company Settings</h4>
                    <div className="mt-3">
                        <Label>Company Name</Label>

                        <div style={{ maxWidth: '390px' }}>
                            <InputWithError
                                prependIcon={<i className="fa fa-building" />}
                                name="name"
                                value={companyName}
                                type="text"
                                onChange={setCompanyName}
                                errorObj={errors}
                                marginBottomClass="mb-3"
                            />
                        </div>
                    </div>
                    <div className="mt-3">
                        <Label>Timezone</Label>

                        <div style={{ maxWidth: '390px' }}>
                            <SelectInputWithError
                                prependIcon={<i className="fa fa-globe" />}
                                name="timezone"
                                value={selectedTimezone}
                                type="select"
                                onChange={setSelectedTimezone}>
                                <option value="" disabled>
                                    Timezone
                                </option>

                                {timezones.map((timezone) => (
                                    <option key={timezone.key} value={timezone.key}>
                                        {timezone.name}
                                    </option>
                                ))}
                            </SelectInputWithError>
                        </div>
                    </div>

                    <div className="mt-4">
                        <SpinnerButton color="primary" onClick={updateOrganizationDetails} submitting={submitting} title="Save Changes" />
                    </div>
                </div>
            )}

            <div className="mb-3">
                {profile.organization?.admins?.length && (
                    <AdminList
                        isSingleUser={isSingleUser}
                        admins={profile.organization.admins}
                        refreshProfile={refreshProfile}
                        handleError={handleError}
                    />
                )}
            </div>
        </div>
    );
};
