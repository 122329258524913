import React, { useContext, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { useQuery } from 'react-query';
import { AuthContext } from 'contexts/AuthContext';
import { FlashMessageContext } from 'contexts/FlashMessageContext';
import { previewCompanyBasedAlert, addCompanyBasedAlert } from 'utils/api/alertsAPI';
import { useDevice } from 'hooks/useMediaQuery';
import { LoadingSpinner } from 'common/LoadingSpinner';
import { SpinnerButton } from 'common/SpinnerButton';
import { CompanyPageDescription } from './CompanyPageDescription';
import { CompanyPageHeader } from './CompanyPageHeader';
import { ExtendedInformation } from './ExtendedInformation';
import { ArticleList } from './List/ArticleList';
import './style.scss';

export const CompanyPagePreview = ({ isPublic = false }) => {
    const { symbol } = useParams();
    const navigate = useNavigate();
    const { addFlashMessage } = useContext(FlashMessageContext);
    const { handleError } = useContext(AuthContext);
    const { isMobile } = useDevice();
    const [companyDescription, setCompanyDescription] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const [keyExecutives, setKeyExecutives] = useState([]);

    const { data: alertData, isLoading } = useQuery(['previewCompanyBasedAlert', symbol], symbol ? () => previewCompanyBasedAlert(symbol) : null, {
        onSuccess: (res) => {
            setCompanyDescription(res?.description?.slice(0, 500));
            setKeyExecutives(res?.keyExecutives?.slice(0, 3));
        },
        onError: (err) => {
            if (err?.message === 'No Company Data' || err?.code === 'NOT_FOUND') {
                addFlashMessage('danger', 'The company details you have selected are currently unavailable');
                navigate(-2);
            } else {
                handleError(err, null, true);
            }
        },
    });

    const handleSubmit = async () => {
        const companyBasedSearchValues = { name: alertData.companyName, symbol };
        setSubmitting(true);

        try {
            await addCompanyBasedAlert(companyBasedSearchValues);
            addFlashMessage('success', 'Alert successfully created');
            navigate('/account/manage-companies');
        } catch (err) {
            if (err?.message?.includes('You can add up to')) {
                return addFlashMessage(
                    'danger',
                    "You've reached the limit of allowed number of alerts for your account.\n" + 'Contact Customer Care team for more information.'
                );
            }

            setSubmitting(false);
            addFlashMessage('danger', 'Problems during alert creation.');
        }
    };

    return (
        <div className={'view dig-deeper animated fadeIn preview ' + (isPublic ? 'container dig-deeper--public ' : '')}>
            {isLoading ? (
                <Row className="mt-3">
                    <Col xs="12">
                        <LoadingSpinner text="Fetching company data" />
                    </Col>
                </Row>
            ) : alertData ? (
                <Row className="mt-3 mb-4">
                    <CompanyPageHeader alertData={alertData} />

                    <Col xs="12" className="mt-3">
                        <SpinnerButton
                            color="primary"
                            type="submit"
                            className="px-4 mb-2"
                            onClick={handleSubmit}
                            submitting={submitting}
                            title="Save"
                        />
                    </Col>

                    <Col xs={!isMobile ? '9' : '12'} className="mt-5 mb-5 company-main-block">
                        <CompanyPageDescription
                            alertData={alertData}
                            companyDescription={companyDescription}
                            setCompanyDescription={setCompanyDescription}
                        />

                        {isMobile && <ExtendedInformation alertData={alertData} keyExecutives={keyExecutives} setKeyExecutives={setKeyExecutives} />}

                        {alertData.news && <ArticleList articles={alertData.news} articlesWithoutDuplicates={alertData.news} />}
                    </Col>

                    {!isMobile && (
                        <Col xs="3" className="mt-5 mb-5">
                            <ExtendedInformation alertData={alertData} keyExecutives={keyExecutives} setKeyExecutives={setKeyExecutives} />
                        </Col>
                    )}
                </Row>
            ) : null}
        </div>
    );
};
