import React, { useState, useEffect } from 'react';
import { Card, CardBody, CardHeader, UncontrolledTooltip } from 'reactstrap';
import moment from 'moment-timezone';
import { CSVLink } from 'react-csv';
import { safeTrim } from 'utils/helpers';
import { LoadingSpinner } from 'common/LoadingSpinner';
import { TopStatsChart } from 'components/Dashboard/Charts/TopStatsChart';
import { TopStatsGrid } from './TopStatsGrid';

export const TopStats = ({ labelContainerHeight, topStatsType, setTopStatsListLength, title, data, isLoading }) => {
    const [topStatsData, setTopStatsData] = useState([]);
    const [sum, setSum] = useState(0);
    const [csvData, setCsvData] = useState([]);

    useEffect(() => {
        if (data?.length) {
            const csvDataRaw =
                topStatsType === 'articles' ? [[`Top ${title} (Last 30 Days)`, 'URL', 'Clicks']] : [[`Top ${title} (Last 30 Days)`, 'Clicks']];

            data.map((item) => {
                if (topStatsType === 'articles') {
                    return csvDataRaw.push([safeTrim(item?.name || item?.domain || item?.title), safeTrim(item.url), item.cnt]);
                } else {
                    return csvDataRaw.push([safeTrim(item?.name || item?.domain || item?.title), item.cnt]);
                }
            });

            setTopStatsData(data);
            setCsvData(csvDataRaw);
            setTopStatsListLength((prevState) => ({ ...prevState, [topStatsType]: data.slice(0, 5).length }));

            data?.length && getAlertCountSum(data);
        } else {
            data && setTopStatsListLength((prevState) => ({ ...prevState, [topStatsType]: 1 }));
        }
    }, [data]);

    const getAlertCountSum = (data) => {
        const topStatsCount = data?.slice(0, 5).map((item) => item.cnt);
        const newSum = topStatsCount?.length ? topStatsCount.reduce((a, b) => a + b) : [];

        setSum(newSum);
    };

    const colors = ['#011a54', '#1a94c7', '#03bdea', '#90ccfe', '#c3d2da'];

    return (
        <div>
            <Card className="chart-card">
                <CardHeader className="dashboard__header">
                    Top {title} (Last 30 Days)
                    {topStatsData?.length > 0 && (
                        <div>
                            <CSVLink data={csvData} filename={'ozmosys_top-alerts_' + moment().utc().format('MMDDYYYY') + '.csv'}>
                                <div id="alerts-csv-button-tooltip">
                                    <i className="fa fa-download dashboard-csv-icon" />
                                </div>
                            </CSVLink>
                            <UncontrolledTooltip placement="bottom" target="alerts-csv-button-tooltip" delay={{ show: 400, hide: 0 }}>
                                Download as CSV file
                            </UncontrolledTooltip>
                        </div>
                    )}
                </CardHeader>
                <CardBody>
                    {isLoading || !Number.isInteger(labelContainerHeight) ? (
                        <LoadingSpinner />
                    ) : topStatsData && topStatsData.length >= 1 && sum > 0 ? (
                        <div>
                            <TopStatsChart statsData={topStatsData} />

                            <div className="d-flex align-items-center justify-content-center" style={{ height: labelContainerHeight }}>
                                <ul className="chart-card__label-list">
                                    {topStatsData.slice(0, 5).map((item, i) => (
                                        <li key={i} className="chart-card__label-list-item">
                                            <div className="chart-card__label-title">
                                                <div className="color-block" style={{ backgroundColor: colors[i] }} />
                                                <span className="chart-label-item-text">
                                                    {(item?.name || item?.domain || item?.title).substring(0, 24) + '... '}
                                                </span>
                                            </div>
                                            <div className="chart-card__label-percentage">{Math.round((item.cnt / sum) * 100) + '%'}</div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    ) : (
                        <div>View more {topStatsType} to start collecting data</div>
                    )}
                </CardBody>
            </Card>

            {topStatsData && topStatsData.length >= 1 && <TopStatsGrid title={title} items={topStatsData} type={topStatsType} withLinks />}
        </div>
    );
};
