import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { FlashMessageContext } from 'contexts/FlashMessageContext';
import { AuthContext } from 'contexts/AuthContext';
import { switchToOrganization } from 'utils/api/authAPI';
import { SpinnerButton } from 'common/SpinnerButton';
import { ConfirmModal } from 'common/SingleUserBlock/ConfirmModal';
import './style.scss';

export const SingleUserBlock = ({ itemType, wide }) => {
    const navigate = useNavigate();
    const { addFlashMessage } = useContext(FlashMessageContext);
    const { fetchProfile } = useContext(AuthContext);

    const [submitting, setSubmitting] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);

    const handleAccountTypeChange = async () => {
        setSubmitting(true);

        try {
            await switchToOrganization();
            await fetchProfile();

            navigate('/account');
            addFlashMessage('success', 'Account mode is now set to Organization.');
            setSubmitting(false);
        } catch (err) {
            addFlashMessage('danger', 'Unable to change account mode at this time.');
            setSubmitting(false);
        }
    };

    const toggleConfirmModal = () => setModalOpen(!modalOpen);

    return (
        <div className={'single-user-block ' + (wide ? 'wide' : '')}>
            <p>In order to create {itemType}, your account must be set to organization mode.</p>

            <div className="list-with-csv list-with-csv--desktop">
                <SpinnerButton onClick={toggleConfirmModal} color="primary" submitting={submitting} title="Change Mode">
                    Change Mode
                </SpinnerButton>
            </div>

            <ConfirmModal isOpen={modalOpen} toggle={toggleConfirmModal} onConfirm={handleAccountTypeChange} />
        </div>
    );
};
