import React, { useState, useEffect } from 'react';
import { Modal, ModalFooter, Button, Input, Badge, FormGroup } from 'reactstrap';
import { useQuery } from 'react-query';
import { SpinnerButton } from 'common/SpinnerButton';
import { LoadingSpinner } from 'common/LoadingSpinner';
import { SearchInputWithSubmit } from 'common/SearchInput/SearchInputWithSubmit';
import { InputWithError } from 'common/InputWithError';
import { ModalBodyWithClose } from 'common/ModalBodyWithClose';
import { addComparativeSet, updateComparativeSet, getAlertsForCompSetList, getComparativeSet } from 'utils/api/statisticsAPI';

export const CreateEditCompSetModal = ({ isOpen, toggle, addFlashMessage, refreshCompSets, isEditForm, compSetToEdit, handleError }) => {
    const [name, setName] = useState('');
    const [fetchingAlerts, setFetchingAlerts] = useState(true);
    const [alerts, setAlerts] = useState([]);
    const [visibleAlerts, setVisibleAlerts] = useState([]);
    const [selectedAlerts, setSelectedAlerts] = useState([]);
    const [tempSearchValue, setTempSearchValue] = useState('');
    const [searchValue, setSearchValue] = useState('');
    const [sortProperty, setSortProperty] = useState('alert.name');
    const [sortDirection, setSortDirection] = useState('ASC');
    const [submitting, setSubmitting] = useState(false);
    const [submittingSearch, setSubmittingSearch] = useState(false);
    const [errors, setErrors] = useState(null);
    const [editDataFetched, setEditDataFetched] = useState(false);
    const [activeSortString, setActiveSortString] = useState('name-asc');

    const { data } = useQuery(['getComparativeSet', compSetToEdit?.id], () => getComparativeSet(compSetToEdit.id), {
        enabled: !!compSetToEdit?.id,
    });

    useEffect(() => {
        if (!isOpen) {
            if (!isEditForm) {
                setName('');
                setSelectedAlerts([]);
            }
            setSortProperty('alert.name');
            setSortDirection('ASC');
        }
    }, [isOpen]);

    const fetchAlerts = async () => {
        try {
            setSubmittingSearch(true);
            const alertData = await getAlertsForCompSetList(searchValue, sortProperty, sortDirection);
            setAlerts(alertData.result);
            setFetchingAlerts(false);
            setSubmittingSearch(false);
            !editDataFetched && setEditDataFetched(true);
        } catch (err) {
            setFetchingAlerts(false);
            setSubmittingSearch(false);
            handleError(err);
        }
    };

    useEffect(() => {
        if (selectedAlerts.length > 0) {
            const selectedIdArray = selectedAlerts.map((alert) => alert.id);
            const filteredArr = alerts.filter((item) => selectedIdArray.indexOf(item.id) < 0);

            setVisibleAlerts(filteredArr);
        } else {
            visibleAlerts !== alerts && setVisibleAlerts(alerts);
        }
    }, [alerts, selectedAlerts]);

    useEffect(() => {
        if (data && alerts) {
            setName(data.name);
            const idArray = data.alerts.map((item) => item.id);

            if (idArray?.length) {
                const newSelectedAlerts = [];

                idArray.map((id) => {
                    const foundAlert = alerts.find((alert) => +alert.id === +id);
                    return foundAlert && newSelectedAlerts.push(foundAlert);
                });

                setSelectedAlerts(newSelectedAlerts);
            }
        }
    }, [data, alerts]);

    useEffect(() => {
        fetchAlerts();
    }, [searchValue, sortProperty, sortDirection]);

    const handleClearSearch = () => {
        setTempSearchValue('');
        setSearchValue('');
    };

    const handleSelectAlert = (alert) => {
        const newSelectedAlerts = [...selectedAlerts];
        newSelectedAlerts.push(alert);
        setSelectedAlerts(newSelectedAlerts);
    };

    const handleSearchSubmit = () => {
        setSearchValue(tempSearchValue);
    };

    const handleSubmit = async () => {
        try {
            setSubmitting(true);
            setErrors(null);

            const params = { name, alerts: selectedAlerts.map((alert) => alert.id) };

            if (isEditForm) {
                await updateComparativeSet(compSetToEdit.id, params);
                addFlashMessage('success', 'Comparative set successfully updated');
            } else {
                await addComparativeSet(params);
                addFlashMessage('success', 'Comparative set successfully created');
            }

            await refreshCompSets();
            setSubmitting(false);
            toggle();
        } catch (err) {
            setSubmitting(false);
            handleError(err, setErrors);
        }
    };

    const removeFromSelected = (alert) => {
        const newSelectedAlerts = [...selectedAlerts];
        const foundAlert = newSelectedAlerts.find((item) => item.id === alert.id);

        foundAlert && newSelectedAlerts.splice(newSelectedAlerts.indexOf(foundAlert), 1);
        setSelectedAlerts(newSelectedAlerts);
    };

    useEffect(() => {
        switch (activeSortString) {
            case 'name-asc':
                setSortProperty('alert.name');
                setSortDirection('ASC');
                break;
            case 'name-desc':
                setSortProperty('alert.name');
                setSortDirection('DESC');
                break;
            case 'count-asc':
                setSortProperty('alert.countNews');
                setSortDirection('ASC');
                break;
            case 'count-desc':
                setSortProperty('alert.countNews');
                setSortDirection('DESC');
                break;
            default:
                setSortProperty('alert.name');
                setSortDirection('ASC');
                break;
        }
    }, [activeSortString]);

    return (
        <Modal isOpen={isOpen} toggle={toggle} className="create-comp-set-modal modal-lg">
            {fetchingAlerts ? (
                <LoadingSpinner padding text="Fetching comparative set data" />
            ) : (
                <div>
                    <ModalBodyWithClose toggle={toggle}>
                        <h2>{isEditForm ? 'Edit Comparative Set' : 'Create Comparative Set'}</h2>

                        <div>
                            <InputWithError
                                placeholder="Comparative set name"
                                name="name"
                                value={name}
                                type="text"
                                autoComplete="off"
                                onChange={setName}
                                errorObj={errors}
                                prependIcon={<i className="fa fa-tag" />}
                                marginBottomClass="mt-3"
                            />
                        </div>

                        <div className="create-comp-set-modal__selected-alert-list">
                            {selectedAlerts.length ? (
                                selectedAlerts.map((alert) => (
                                    <div key={alert.id} className="create-comp-set-modal__selected-alert">
                                        {alert.name}
                                        <i onClick={() => removeFromSelected(alert)} className="fa fa-close" />
                                    </div>
                                ))
                            ) : (
                                <div className="text-muted">Alerts that you select below can be managed here</div>
                            )}
                        </div>

                        <div className="d-flex justify-content-between create-comp-set-modal__search-container">
                            <SearchInputWithSubmit
                                placeholder="Search by alert name"
                                onClear={handleClearSearch}
                                onChange={setTempSearchValue}
                                value={tempSearchValue}
                                onSubmit={handleSearchSubmit}
                                submitting={submittingSearch}
                                style={{ padding: '0 0.9rem 0.9rem 0.5rem' }}
                            />

                            <FormGroup className="create-comp-set-modal__sort m-0">
                                <Input onChange={(e) => setActiveSortString(e.target.value)} value={activeSortString} type="select" name="activeSort">
                                    <option value="name-asc">Name (A-Z)</option>
                                    <option value="name-desc">Name (Z-A)</option>
                                    <option value="count-asc">Article count (ASC)</option>
                                    <option value="count-desc">Article count (DESC)</option>
                                </Input>
                            </FormGroup>
                        </div>

                        <div className="mt-4 create-comp-set-modal__list">
                            {visibleAlerts.length < 1 ? (
                                <div>No alerts found</div>
                            ) : (
                                visibleAlerts.map((alert) => (
                                    <div key={alert.id} className="create-comp-set-modal__alert">
                                        <div className="d-flex">
                                            {alert.name}
                                            <Badge color="secondary" pill className="create-comp-set-modal__badge">
                                                {alert.countNews}
                                            </Badge>
                                        </div>
                                        <i className="fa fa-plus" onClick={() => handleSelectAlert(alert)} />
                                    </div>
                                ))
                            )}
                        </div>
                    </ModalBodyWithClose>

                    <ModalFooter>
                        <Button color="secondary" onClick={toggle}>
                            Cancel
                        </Button>

                        <SpinnerButton
                            type="modal"
                            color="primary"
                            onClick={() => handleSubmit()}
                            submitting={submitting}
                            title={isEditForm ? 'Update' : 'Create'}
                        />
                    </ModalFooter>
                </div>
            )}
        </Modal>
    );
};
