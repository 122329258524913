import React, { useEffect, useState } from 'react';
import { Table, Button, UncontrolledTooltip } from 'reactstrap';
import { CSVLink } from 'react-csv';
import moment from 'moment-timezone';
import { filterByValue, sortObjects, sortByValueLength, handleCaret, safeTrim, handleCopyToClipboard } from 'utils/helpers';
import { useDevice } from 'hooks/useMediaQuery';
import { SearchInputWithSubmit } from 'common/SearchInput/SearchInputWithSubmit';
import { SmallSpinnerButtonSquare } from 'common/SpinnerButton/SmallSpinnerButtonSquare';
import { CreateEditGroupModal } from './Modals/CreateEditGroupModal';
import { DeleteAlertModal } from '../components/Modals/DeleteAlertModal';
import { AssignGroupModal } from './Modals/AssignGroupModal';
import { MobileFilterDropdown } from 'components/Alerts/components/MobileFilterDropdown';
import { PreviewGroupModal } from './Modals/PreviewGroupModal';
import RSSLogo from 'images/rss-icon.png';

export const GroupList = (props) => {
    const {
        alertGroups,
        submitting,
        handleError,
        handleDeleteGroup,
        addFlashMessage,
        handleAssignAlertGroup,
        refreshAlertGroups,
        alertGroupFilters,
        setFiltersObject,
    } = props;
    const { searchValue, activeSortProperty, activeSortDirection } = alertGroupFilters;

    const { isDesktop, isTablet, isMobile } = useDevice();
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [assignModalOpen, setAssignModalOpen] = useState(false);
    const [itemToEdit, setItemToEdit] = useState(null);
    const [itemToDelete, setItemToDelete] = useState(null);
    const [itemToAssign, setItemToAssign] = useState(null);
    const [tooltipCopied, setTooltipCopied] = useState(null);
    const [visibleAlertGroups, setVisibleAlertGroups] = useState([]);
    const [tempSearchValue, setTempSearchValue] = useState('');
    const [csvData, setCsvData] = useState([]);
    const [activeMobileFilterValue, setActiveMobileFilterValue] = useState('');
    const [itemToPreview, setItemToPreview] = useState(null);
    const [previewModalOpen, setPreviewModalOpen] = useState(false);

    const togglePreviewModal = () => setPreviewModalOpen(!previewModalOpen);
    const toggleCreateModal = () => setCreateModalOpen(!createModalOpen);
    const tooltipText = tooltipCopied ? 'Copied to Clipboard' : 'Copy to Clipboard';
    const sortAlphabetically = (arr) => arr.sort((a, b) => a.name.localeCompare(b.name));

    const toggleEditModal = () => {
        editModalOpen && setItemToEdit(null);
        setEditModalOpen(!editModalOpen);
    };

    const toggleAssignModal = () => {
        assignModalOpen && setItemToAssign(null);
        setAssignModalOpen(!assignModalOpen);
    };

    const toggleDeleteModal = (item) => {
        setDeleteModalOpen(!deleteModalOpen);
        setItemToDelete(!deleteModalOpen ? item : null);
    };

    const handleSortableHeaderClick = (value, activeSortProperty, activeSortDirection) => {
        let filters = { ...alertGroupFilters };

        if (value === activeSortProperty) {
            activeSortDirection === 'asc' ? (filters.activeSortDirection = 'desc') : (filters.activeSortDirection = 'asc');
        } else {
            filters.activeSortProperty = value;
            filters.activeSortDirection = 'asc';
        }

        setFiltersObject('alertGroups', filters);
    };

    const handleMobileFilterSelect = (val) => {
        const splitValue = val.split('-');
        let filters = { ...alertGroupFilters };

        filters.activeSortProperty = splitValue[0];
        filters.activeSortDirection = splitValue[1];
        setFiltersObject('alertGroups', filters);
    };

    const determineMobileFiltersValue = () => {
        const filterStr = alertGroupFilters.activeSortProperty + '-' + alertGroupFilters.activeSortDirection;
        setActiveMobileFilterValue(filterStr);
    };

    const handleSearchValueSubmit = () => {
        if (tempSearchValue.length > 2048) {
            addFlashMessage('danger', 'Your search exceeds the maximum number of allowed characters (2048)');
            return;
        }

        let filters = { ...alertGroupFilters };
        filters.searchValue = tempSearchValue;
        filters.paginationCurrentPageNumber = 1;
        setFiltersObject('alertGroups', filters);
    };

    const handleClear = () => {
        let filters = { ...alertGroupFilters };
        filters.searchValue = '';
        setFiltersObject('alertGroups', filters);
        setTempSearchValue('');
    };

    const buildCSVFile = (arr) => {
        const csvData = [['Name', 'Alerts']];

        arr.map((item) => {
            item.alerts.length > 1
                ? sortAlphabetically(item.alerts).map((alert) => csvData.push([safeTrim(item.name), alert.name]))
                : csvData.push([safeTrim(item.name)]);
        });
        return csvData;
    };

    useEffect(() => {
        tempSearchValue !== searchValue && setTempSearchValue(searchValue);
    }, []);

    useEffect(() => {
        let filteredList = filterByValue(alertGroups, 'name', searchValue);
        let sortedList =
            activeSortProperty === 'alerts'
                ? sortByValueLength(filteredList, activeSortProperty, activeSortDirection)
                : sortObjects(filteredList, activeSortProperty, activeSortDirection);
        setVisibleAlertGroups(sortedList);
    }, [alertGroupFilters, alertGroups]);

    useEffect(() => {
        itemToEdit && toggleEditModal();
    }, [itemToEdit]);

    useEffect(() => {
        itemToPreview && togglePreviewModal();
    }, [itemToPreview]);

    useEffect(() => {
        itemToAssign && toggleAssignModal();
    }, [itemToAssign]);

    useEffect(() => {
        visibleAlertGroups.length && setCsvData(buildCSVFile(visibleAlertGroups));
    }, [visibleAlertGroups]);

    useEffect(() => {
        alertGroupFilters && determineMobileFiltersValue();
    }, [alertGroupFilters]);

    return (
        <div className="list alert-group-list">
            {isDesktop && (
                <div className="list__utility-row list__utility-row--desktop">
                    <div className="list__search-container">
                        <SearchInputWithSubmit
                            value={tempSearchValue}
                            onChange={setTempSearchValue}
                            placeholder="Filter by name"
                            onClear={handleClear}
                            extraParams={null}
                            submitting={false}
                            onSubmit={handleSearchValueSubmit}
                            list
                        />
                    </div>
                    <div className="d-flex">
                        <CSVLink
                            id="alert-group-csv-button-tooltip"
                            data={csvData}
                            filename={'ozmosys_alert-groups_' + moment().tz(moment.tz.guess()).format('MMDDYYYY') + '.csv'}>
                            <SmallSpinnerButtonSquare defaultSize title={<i className="fa fa-download" />} className="mr-2 d-block" />
                        </CSVLink>
                        <UncontrolledTooltip placement="bottom" target="alert-group-csv-button-tooltip" delay={{ show: 400, hide: 0 }}>
                            Download as CSV file
                        </UncontrolledTooltip>
                        <Button onClick={toggleCreateModal} color="primary">
                            Create Group
                        </Button>
                    </div>
                </div>
            )}
            {isTablet && (
                <div className="list__utility-row list__utility-row--tablet">
                    <div className="d-flex justify-content-between w-100">
                        <div className="list__search-container">
                            <SearchInputWithSubmit
                                value={tempSearchValue}
                                onChange={setTempSearchValue}
                                placeholder="Filter by name"
                                onClear={handleClear}
                                extraParams={null}
                                submitting={false}
                                onSubmit={handleSearchValueSubmit}
                                list
                            />
                        </div>
                    </div>
                    <div className="d-flex mt-2 w-100 justify-content-end">
                        <div className="mr-2 d-flex">
                            <Button onClick={toggleCreateModal} color="primary" className="mb-0">
                                <i className="fa fa-plus" />
                                &nbsp; Create Group
                            </Button>
                        </div>
                        <div>
                            <CSVLink
                                id="alert-group-csv-button-tooltip-tablet"
                                data={csvData}
                                filename={'ozmosys_alert-groups_' + moment().tz(moment.tz.guess()).format('MMDDYYYY') + '.csv'}>
                                <SmallSpinnerButtonSquare defaultSize title={<i className="fa fa-download" />} className="mr-2" />
                            </CSVLink>
                            <UncontrolledTooltip placement="bottom" target="alert-group-csv-button-tooltip-tablet" delay={{ show: 400, hide: 0 }}>
                                Download as CSV file
                            </UncontrolledTooltip>
                        </div>
                        <MobileFilterDropdown handleMobileFilterSelect={handleMobileFilterSelect} activeMobileFilterValue={activeMobileFilterValue} />
                    </div>
                </div>
            )}
            {isMobile && (
                <div className="list-utility-row list__utility-row--mobile">
                    <div className="d-flex mb-2">
                        <CSVLink
                            id="alert-group-csv-button-tooltip-mobile"
                            data={csvData}
                            className="w-100"
                            filename={'ozmosys_alert-groups_' + moment().tz(moment.tz.guess()).format('MMDDYYYY') + '.csv'}>
                            <Button className="mr-1 w-100">
                                <i className="fa fa-download" /> Download CSV
                            </Button>
                        </CSVLink>
                        <UncontrolledTooltip placement="bottom" target="alert-group-csv-button-tooltip-mobile" delay={{ show: 400, hide: 0 }}>
                            Download as CSV file
                        </UncontrolledTooltip>
                        <Button onClick={toggleCreateModal} color="primary" className="ml-1" style={{ flex: '0 0 50%' }}>
                            <i className="fa fa-plus" />
                            &nbsp; Create Group
                        </Button>
                    </div>
                    <div className="d-flex mb-2">
                        <MobileFilterDropdown handleMobileFilterSelect={handleMobileFilterSelect} activeMobileFilterValue={activeMobileFilterValue} />
                    </div>
                    <div className="mb-3">
                        <SearchInputWithSubmit
                            value={tempSearchValue}
                            onChange={setTempSearchValue}
                            placeholder="Filter by name"
                            onClear={handleClear}
                            extraParams={null}
                            submitting={false}
                            onSubmit={handleSearchValueSubmit}
                            list
                        />
                    </div>
                </div>
            )}

            <Table className="responsive-table">
                <tbody>
                    <tr>
                        <th
                            className="responsive-table__sortable-th"
                            onClick={() => handleSortableHeaderClick('name', activeSortProperty, activeSortDirection)}>
                            Name
                            {handleCaret(activeSortProperty, activeSortDirection, 'name')}
                        </th>
                        <th
                            className="responsive-table__sortable-th"
                            onClick={() => handleSortableHeaderClick('alerts', activeSortProperty, activeSortDirection)}>
                            Searches/Sources
                            {handleCaret(activeSortProperty, activeSortDirection, 'alerts')}
                        </th>
                        <th>RSS Feed URL</th>
                        <th>Assignment</th>
                        <th>Edit/Remove</th>
                    </tr>
                    {visibleAlertGroups.length > 0 ? (
                        visibleAlertGroups.map((alertGroup) => (
                            <tr key={alertGroup.id}>
                                <td data-label="Name">
                                    <a className="link-style" onClick={() => setItemToPreview(alertGroup)}>
                                        {alertGroup.name}
                                    </a>
                                </td>
                                <td data-label="Alerts">{alertGroup.alerts.length}</td>
                                <td data-label="RSS Feed URL">
                                    <div className="alert-group-rss-label-container">
                                        <div
                                            className="alert-group-rss-label"
                                            onClick={() => handleCopyToClipboard(alertGroup.rss)}
                                            style={{
                                                wordBreak: 'break-all',
                                                wordWrap: 'break-word',
                                            }}
                                            onMouseEnter={() => setTooltipCopied(false)}>
                                            <div style={{ cursor: 'pointer' }}>
                                                <img
                                                    className="rss-logo"
                                                    height="35px"
                                                    src={RSSLogo}
                                                    alt="rss_logo"
                                                    id={'rss-label-tooltip-' + alertGroup.id}
                                                    onClick={() => setTooltipCopied(true)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <UncontrolledTooltip
                                        placement="right"
                                        autohide={false}
                                        target={'rss-label-tooltip-' + alertGroup.id}
                                        delay={{ show: 200, hide: 0 }}>
                                        {tooltipText}
                                    </UncontrolledTooltip>
                                </td>
                                <td data-label="Assignment">
                                    <Button color={'primary'} onClick={() => setItemToAssign(alertGroup)}>
                                        Assign To
                                    </Button>
                                </td>
                                <td className="responsive-table__edit-block" data-label="Edit/Remove">
                                    <Button color="light" onClick={() => setItemToEdit(alertGroup)}>
                                        <i className="fa fa-edit fa-lg" />
                                    </Button>
                                    <Button color="light" onClick={() => toggleDeleteModal(alertGroup)}>
                                        <i className="fa fa-trash fa-lg" />
                                    </Button>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td>No results found</td>
                            {[1, 2, 3, 40].map((i) => (
                                <td key={i} />
                            ))}
                        </tr>
                    )}
                </tbody>
            </Table>

            {createModalOpen && (
                <CreateEditGroupModal
                    isOpen={createModalOpen}
                    toggle={toggleCreateModal}
                    addFlashMessage={addFlashMessage}
                    refreshAlertGroups={refreshAlertGroups}
                    handleError={handleError}
                />
            )}

            {editModalOpen && (
                <CreateEditGroupModal
                    isOpen={editModalOpen}
                    toggle={toggleEditModal}
                    alertGroup={itemToEdit}
                    addFlashMessage={addFlashMessage}
                    handleError={handleError}
                    refreshAlertGroups={refreshAlertGroups}
                    isEdit
                />
            )}

            {deleteModalOpen && (
                <DeleteAlertModal
                    isOpen={deleteModalOpen}
                    onSubmit={handleDeleteGroup}
                    toggle={toggleDeleteModal}
                    item={itemToDelete}
                    submitting={submitting}
                    addFlashMessage={addFlashMessage}
                />
            )}

            {assignModalOpen && (
                <AssignGroupModal
                    isOpen={assignModalOpen}
                    onSubmit={handleAssignAlertGroup}
                    toggle={toggleAssignModal}
                    alertGroup={itemToAssign}
                    submitting={submitting}
                    addFlashMessage={addFlashMessage}
                />
            )}

            {previewModalOpen && (
                <PreviewGroupModal isOpen={previewModalOpen} toggle={togglePreviewModal} alertGroup={itemToPreview} handleError={handleError} />
            )}
        </div>
    );
};
