import { axios } from 'lib/axios';
import { CATEGORY_TYPE, COMPANY_TYPE, SEARCH_TYPE, SOURCE_TYPE } from 'constants/alerts';
import { getCsv } from './csvAPI';

export const getReaderProfile = () => {
    return axios.get('/api/v3/reader/profile');
};

export const getAlertLabels = () => {
    return axios.get('/api/v3/alert_label');
};

export const getAlerts = (
    count,
    pageNumber,
    itemsPerPage,
    sortProperty,
    sortDirection,
    searchProperty,
    searchValue = '',
    labels = [],
    csv = false,
    title,
    alertType = SEARCH_TYPE,
    searchTypeId = null
) => {
    const encodedSearchValue = encodeURIComponent(searchValue);
    let url = `/api/v3/alert/?help=false&count=${count}&page=${pageNumber}&itemsOnPage=${itemsPerPage}&sort[0][property]=${sortProperty}&sort[0][direction]=${sortDirection.toUpperCase()}&csv=${csv}`;

    if (encodedSearchValue.length > 0) {
        url = url + `&filter[0][property]=${searchProperty}&filter[0][operator]=contains&filter[0][value]=${encodedSearchValue}`;
    }
    labels.length > 0 &&
        labels.map((label, index) => {
            url += `&filter[${index + 1}][property]=alert.labels&filter[${index + 1}][operator]=equals&filter[${index + 1}][value]=${encodeURIComponent(label)}`;
        });

    url += `&filter[${labels.length + 1}][property]=source.type&filter[${labels.length + 1}][operator]=in`;

    const alertTypes = {
        [SEARCH_TYPE]: ['google', 'internal', 'internal_group'],
        [SOURCE_TYPE]: ['rss', 'email_publication', 'courtlink', 'translation'],
        [COMPANY_TYPE]: ['company_based'],
        [CATEGORY_TYPE]: ['ozmosys_categorized'],
    };

    alertTypes[alertType].map((value, index) => {
        url += `&filter[${labels.length + 1}][value][${index}]=${value}`;
    });

    if (searchTypeId) {
        url += `&searchTypeId=${searchTypeId}`;
    }

    if (csv) {
        return getCsv(url, title);
    } else {
        return axios.get(url);
    }
};

export const getAlertsWithoutParams = (csv, title) => {
    let url = `/api/v3/alert/?count=false&page=1&itemsOnPage=3000&sort[0][property]=alert.createdAt&sort[0][direction]=DESC&csv=${csv}`;

    url += `&filter[1][property]=source.type&filter[1][operator]=not_in`;
    ['google_hidden'].map((value, index) => {
        url += `&filter[1][value][${index}]=${value}`;
    });

    if (csv) {
        return getCsv(url, title);
    } else {
        return axios.get(url);
    }
};

export const deleteAlert = (id) => {
    return axios.delete(`/api/v3/alert/${id}`);
};

export const getAlertPreview = (id) => {
    return axios.get(`/api/v3/alert/${id}/preview`);
};

export const getAlertById = (id) => {
    return axios.get(`/api/v3/alert/${id}`);
};

export const assignUsersToAlert = (id, params) => {
    return axios.patch(`/api/v3/alert/${id}/user`, params);
};

export const searchJointSources = (searchValue) => {
    const encodedSearchValue = encodeURIComponent(searchValue);

    return axios.get(`/api/v3/joint_sources/search?term=${encodedSearchValue}`);
};

export const sendRequestSource = (source) => {
    return axios.post(`/api/v3/joint_sources/send_request_source`, { source });
};

export const addAlertLabel = (params) => {
    return axios.post('/api/v3/alert_label', params);
};

export const editAlertLabel = (id, params) => {
    return axios.patch(`/api/v3/alert_label/${id}`, params);
};

export const deleteAlertLabel = (id) => {
    return axios.delete(`/api/v3/alert_label/${id}`);
};

export const addInternalAlert = (params) => {
    return axios.post('/api/v3/alert/internal', params);
};

export const editInternalAlert = (id, params) => {
    return axios.patch(`/api/v3/alert/internal/${id}`, params);
};

export const previewInternalAlert = (params) => {
    return axios.post('/api/v3/alert/internal/preview', params);
};

export const getGeoLocations = () => {
    return axios.get('/api/v3/free/geo_locations');
};

export const getWikiResults = (query) => {
    return axios.get(`/api/v3/free/wiki_autocomplete?query=${query}`);
};

export const assignLabelToAlert = (id, params) => {
    return axios.patch(`/api/v3/alert/${id}/label`, params);
};

export const suspendAlert = (id, params) => {
    return axios.patch(`/api/v3/alert/${id}/status`, params);
};

export const editCommentAlert = (id, comment) => {
    return axios.patch(`/api/v3/alert/${id}/comment`, { comment });
};

export const getDigDeeperContent = (id, days) => {
    return axios.get(`/api/v3/alert/${id}/statistic/${days}`);
};

export const getFreeDigDeeperContent = (id, hash, days) => {
    return axios.get(`/api/v3/free/alert/${id}/${hash}/profile/${days}`);
};

export const getFreeReaderAlerts = (id, hash) => {
    return axios.get(`/api/v3/free/reader/user/${id}/${hash}`);
};

export const getFreeEmailPreview = (id, hash) => {
    return axios.get(`/api/v3/free/email/${id}/${hash}`);
};

export const getFreeNewsletterPreview = (id, hash) => {
    return axios.get(`/api/v3/free/newsletter/${id}/${hash}`);
};

export const getReaderAlerts = () => {
    return axios.get(`/api/v3/reader/alerts`);
};

export const manageReaderAlerts = (params) => {
    return axios.patch(`/api/v3/reader/manage_alerts`, params);
};

export const getReaderAlertGroups = () => {
    return axios.get(`/api/v3/reader/alert_groups`);
};

export const getCategories = (searchTypeId) => {
    return axios.get(`/api/v3/alert/categories${searchTypeId ? `?searchTypeId=${searchTypeId}` : ''}`);
};

export const addOzmosysCategorizedAlert = (params) => {
    return axios.post('/api/v3/alert/ozmosys_categorized', params);
};

export const editOzmosysCategorizedAlert = (id, params) => {
    return axios.patch(`/api/v3/alert/ozmosys_categorized/${id}`, params);
};

export const previewOzmosysCategorizedAlert = (params) => {
    return axios.post('/api/v3/alert/ozmosys_categorized/preview', params);
};

export const deleteOzmosysCategorizedAlert = (ids) => {
    return axios.delete(`/api/v3/alert/ozmosys_categorized/${ids}`);
};

export const getAiCategories = (searchTypeId) => {
    return axios.get(`/api/v3/alert/categories?searchTypeId=${searchTypeId}`);
};

export const addCompanyBasedAlert = (params) => {
    return axios.post('/api/v3/alert/company_based', params);
};

export const previewCompanyBasedAlert = (symbol) => {
    return axios.get(`/api/v3/alert/company_based/preview?symbol=${encodeURIComponent(symbol)}`);
};

export const getCompanyAutocompleteResults = (query) => {
    return axios.get(`/api/v3/alert/company_based/autocomplete?query=${encodeURIComponent(query)}`);
};

export const getCompanyBasedProfile = (id) => {
    return axios.get(`/api/v3/alert/company_based/${id}/profile`);
};

export const checkExistsCompanyBased = (symbol) => {
    return axios.get(`/api/v3/alert/company_based?symbol=${encodeURIComponent(symbol)}`);
};

export const companyCheckExistsCompanyBased = (symbol) => {
    return axios.get(`/api/v3/alert/company_based/company?symbol=${encodeURIComponent(symbol)}`);
};

export const getBitUrl = (alertId, newsId) => {
    return axios.get(`/api/v3/alert/${alertId}/news/${newsId}/url_bit`);
};

export const initBriefingReport = (data) => {
    return axios.post('/api/v3/alert/openai_briefing_report', data);
};

export const receiveOpenaiSummary = (url, hash) => {
    return axios.get(`/api/v3/free/openai_summary/${hash}?url=${url}`);
};

export const getOpenaiAnalysis = (url, hash) => {
    return axios.get(`/api/v3/free/latest_trend/${hash}?url=${url}`);
};

export const getAlertGroups = () => {
    return axios.get('/api/v3/alert_group');
};

export const addAlertGroup = (params) => {
    return axios.post('/api/v3/alert_group', params);
};

export const editAlertGroup = (id, params) => {
    return axios.patch(`/api/v3/alert_group/${id}`, params);
};

export const deleteAlertGroup = (id) => {
    return axios.delete(`/api/v3/alert_group/${id}`);
};

export const universalAssignAlerts = (params) => {
    return axios.post('/api/v3/alert/users', params);
};

export const getAlertsGroupList = () => {
    return axios.get(
        '/api/v3/alert/?count=false&page=1&itemsOnPage=3000&sort[0][property]=alert.createdAt&sort[0][direction]=DESC&filter[1][property]=source.type&filter[1][operator]=in&filter[1][value][0]=rss&filter[1][value][1]=email_publication&filter[1][value][2]=courtlink&filter[1][value][3]=translation'
    );
};

export const addRssAlert = (params) => {
    return axios.post('/api/v3/alert/rss', params);
};

export const editRssAlert = (id, params) => {
    return axios.patch(`/api/v3/alert/rss/${id}`, params);
};

export const previewRssAlert = (searchValue) => {
    const encodedSearchValue = encodeURIComponent(searchValue);

    return axios.get(`/api/v3/alert/rss/preview?url=${encodedSearchValue}`);
};

export const getInternalSearchCategories = () => {
    return axios.get('/api/v3/alert/categories');
};
