import React, { useState } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Nav, Navbar, NavItem } from 'reactstrap';

export const LegacyDropdown = () => {
    const [supportDropdownOpen, setSupportDropdownOpen] = useState(false);

    const toggleSupport = () => setSupportDropdownOpen(!supportDropdownOpen);

    return (
        <Navbar style={{ position: 'absolute', top: '1rem', right: '1rem' }}>
            <Nav navbar className="ml-auto">
                <NavItem>
                    <Dropdown isOpen={supportDropdownOpen} toggle={toggleSupport}>
                        <DropdownToggle
                            color="link"
                            onClick={toggleSupport}
                            data-toggle="dropdown"
                            aria-expanded={supportDropdownOpen}
                            style={{ paddingRight: '.5rem', paddingLeft: '.5rem' }}>
                            Legacy
                        </DropdownToggle>
                        <DropdownMenu end>
                            <DropdownItem href="http://www.ozmosys.com/clippernew/">Clipper</DropdownItem>
                            <DropdownItem href="http://www.ozmosys.com/am/">Alert Manager</DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </NavItem>
            </Nav>
        </Navbar>
    );
};
