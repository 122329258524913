import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { isEmpty } from 'lodash';
import { AuthContext } from 'contexts/AuthContext';
import { LoadingSpinner } from 'common/LoadingSpinner';
import { TabBar } from 'common/TabBar';
import {
    CATEGORY_TYPE,
    COMPANY_TYPE,
    SEARCH_TYPE,
    SOURCE_TYPE,
    categoryTabs,
    companyTabs,
    searchTabs,
    sourceTabs,
    headerByType,
} from 'constants/alerts';
import { AlertsList } from './AlertsList';
import { AlertGroups } from './Groups';
import { Labels } from './Labels';

export const AlertsWrapper = ({ type }) => {
    const { category } = useParams();
    const navigate = useNavigate();
    const { state } = useContext(AuthContext);
    const [tabs, setTabs] = useState([]);
    const [activeTab, setActiveTab] = useState(tabs?.length ? tabs[0]?.type : '');

    useEffect(() => {
        if (type) {
            if (type === CATEGORY_TYPE && category) {
                return setTabs(categoryTabs[category]);
            } else {
                if (type === SEARCH_TYPE) return setTabs(searchTabs);
                if (type === SOURCE_TYPE) return setTabs(sourceTabs);
                if (type === COMPANY_TYPE) return setTabs(companyTabs);
            }
        }
    }, [type, category]);

    useEffect(() => {
        setActiveTab(tabs?.length ? tabs[0]?.type : '');
    }, [tabs]);

    const handleActiveView = () => {
        if (activeTab === 'alerts') return <AlertsList type={type} searchTypeId={category ? categoryTabs[category][0]?.searchTypeId : null} />;
        if (activeTab === 'alertGroups') return <AlertGroups />;
        if (activeTab === 'labels') return <Labels />;
    };

    if (type === COMPANY_TYPE) {
        if (isEmpty(state.profile)) {
            return <LoadingSpinner />;
        } else if (!state.profile.organization?.accesses?.accessCompanyBasedAlerts) {
            navigate('/dashboard');
        }
    }

    return (
        <Row>
            <Col xs="12">
                <Card>
                    <CardHeader>
                        <div className="flex-space-between">
                            <h1>{headerByType[type === CATEGORY_TYPE ? category : type]}</h1>
                        </div>
                    </CardHeader>
                    <CardBody>
                        <TabBar activeItem={activeTab} setActive={setActiveTab} items={tabs} />
                        {handleActiveView()}
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
};
