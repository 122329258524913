import React, { useContext, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { useQuery } from 'react-query';
import { AuthContext } from 'contexts/AuthContext';
import { FlashMessageContext } from 'contexts/FlashMessageContext';
import { getCompanyBasedProfile, getFreeDigDeeperContent } from 'utils/api/alertsAPI';
import { useDevice } from 'hooks/useMediaQuery';
import { LoadingSpinner } from 'common/LoadingSpinner';
import { CompanyPageHeader } from './CompanyPageHeader';
import { ExtendedInformation } from './ExtendedInformation';
import { CompanyPageDescription } from './CompanyPageDescription';
import { NewsPerDayWrapper } from './Charts/NewsPerDayChart/NewsPerDayWrapper';
import { ArticleList } from './List/ArticleList';
import './style.scss';

export const CompanyPage = ({ isPublic = false }) => {
    const { id } = useParams();
    const { search } = useLocation();
    const navigate = useNavigate();
    const { handleError } = useContext(AuthContext);
    const { addFlashMessage } = useContext(FlashMessageContext);
    const { isMobile, isTablet, isDesktop } = useDevice();
    const [keyExecutives, setKeyExecutives] = useState([]);
    const [companyDescription, setCompanyDescription] = useState('');
    const hash = search.slice(3, search.length);

    const { data: alertData, isLoading } = useQuery(
        ['getCompanyBasedProfile', id],
        id ? () => (isPublic ? getFreeDigDeeperContent(id, hash, 30) : getCompanyBasedProfile(id)) : null,
        {
            onSuccess: (res) => {
                setCompanyDescription(res?.description?.slice(0, 500));
                setKeyExecutives(res?.keyExecutives?.slice(0, 3));
            },
            onError: (err) => {
                if (err?.message === 'No Company Data' || err?.code === 'NOT_FOUND') {
                    addFlashMessage('danger', 'The company details are currently unavailable');
                    navigate(-1);
                } else {
                    handleError(err, null, true);
                }
            },
        }
    );

    return (
        <div className={'view dig-deeper animated fadeIn preview ' + (isPublic ? 'dig-deeper--public ' : '')}>
            {isLoading ? (
                <Row className="mt-3">
                    <Col xs="12">
                        <LoadingSpinner text="Fetching company data" />
                    </Col>
                </Row>
            ) : alertData ? (
                <Row className="mt-3 mb-4">
                    <CompanyPageHeader alertData={alertData} publicMode={isPublic} />

                    <Col xs={!isMobile ? '9' : '12'} className="mt-5 mb-5 company-main-block">
                        <CompanyPageDescription
                            alertData={alertData}
                            companyDescription={companyDescription}
                            setCompanyDescription={setCompanyDescription}
                        />

                        {isDesktop && <NewsPerDayWrapper alertData={alertData} fetchingData={isLoading} />}

                        {isMobile && <ExtendedInformation alertData={alertData} keyExecutives={keyExecutives} setKeyExecutives={setKeyExecutives} />}

                        {alertData.news && (
                            <ArticleList
                                articles={alertData.news}
                                articlesWithoutDuplicates={alertData.news.filter((item) => !item.hasOwnProperty('parent'))}
                                alertId={id}
                                alertName={alertData.companyName}
                            />
                        )}
                    </Col>

                    {!isMobile && (
                        <Col xs="3" className="mt-5 mb-5">
                            {isTablet && <NewsPerDayWrapper alertData={alertData} fetchingData={isLoading} />}

                            <ExtendedInformation alertData={alertData} keyExecutives={keyExecutives} setKeyExecutives={setKeyExecutives} />
                        </Col>
                    )}
                </Row>
            ) : null}
        </div>
    );
};
