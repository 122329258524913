import React, { useContext, useEffect, useState } from 'react';
import { Button, UncontrolledTooltip } from 'reactstrap';
import { AuthContext } from 'contexts/AuthContext';
import { FlashMessageContext } from 'contexts/FlashMessageContext';
import { getBitUrl, receiveOpenaiSummary, getOpenaiAnalysis } from 'utils/api/alertsAPI';
import { handleCopyToClipboard, truncateWithoutWordBreak, convertDateString } from 'utils/helpers';
import { useTextTypingAnimation } from 'hooks/useTextTypingAnimation';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { LoadingSpinner } from 'common/LoadingSpinner';
import whiteOpenai from 'images/white-openai.svg';
import blackOpenai from 'images/black-openai.svg';

export const ListItem = ({
    activeTag,
    duplicateArticle,
    withSummary,
    item,
    alertId,
    articlesToEmail,
    setArticlesToEmail,
    accessBriefingReport,
    articlesToBriefingReport,
    setArticlesToBriefingReport,
    isSecFilings,
    handleTagSelect,
}) => {
    const { id, title, abstract, url, created_at, resource, duplicates, hash } = item;
    const isDesktop = useMediaQuery('(min-width: 1300px)');

    const { state, handleError } = useContext(AuthContext);
    const [duplicatesVisible, setDuplicatesVisible] = useState(false);
    const [showSummary, setShowSummary] = useState(false);
    const [summary, setSummary] = useState(null);
    const [showAnalysis, setShowAnalysis] = useState(false);
    const [analysis, setAnalysis] = useState(null);
    const { displayedText: displayedSummary } = useTextTypingAnimation({ text: summary || '', speed: 10 });
    const {
        displayedText: displayedHighRisk,
        isLoading: isLoadingHighRisk,
        withTags: withTagsHighRisk,
    } = useTextTypingAnimation({
        text: analysis ? analysis?.high || '' : null,
        speed: 10,
    });
    const {
        displayedText: displayedLowRisk,
        isLoading: isLoadingLowRisk,
        withTags: withTagsLowRisk,
    } = useTextTypingAnimation({
        text: isLoadingHighRisk === false ? analysis?.low || '' : null,
        speed: 10,
    });
    const { displayedText: displayedMarginalRisk, withTags: withTagsMarginalRisk } = useTextTypingAnimation({
        text: isLoadingLowRisk === false ? analysis?.marginal || '' : null,
        speed: 10,
    });
    const [accessCompanyBasedSummary, setAccessCompanyBasedSummary] = useState(false);
    const [fetchingSummary, setFetchingSummary] = useState(false);
    const [fetchingAnalysis, setFetchingAnalysis] = useState(false);
    const [tooltipCopied, setTooltipCopied] = useState(false);
    const [plainText, setPlainText] = useState('');
    const [analyzePlainText, setAnalyzePlainText] = useState('');
    const [hasSummary, setHasSummary] = useState(null);
    const [hasAnalysis, setHasAnalysis] = useState(null);
    const { addFlashMessage } = useContext(FlashMessageContext);
    const maxArticlesToEmail = 10;
    const maxArticlesBriefingReport = 5;
    const regExp = /<$|\/$|\/>$|\/?>$|<[^>]+\/?>$|<[^>]+\/?>$|<\/?[^>\s]+$/;
    let taggedAbstract;
    let taggedTitle;

    useEffect(() => {
        if (state.profile.organization?.accesses?.accessCompanyBasedSummary) {
            setAccessCompanyBasedSummary(true);
        }
    }, [state.profile]);

    const loadSummary = async () => {
        setFetchingSummary(true);
        try {
            const summaryData = await receiveOpenaiSummary(url, hash);
            if (summaryData['summary']) {
                setSummary(summaryData['summary']);
                setPlainText(summaryData['raw']);
                setHasSummary(true);
            } else {
                setHasSummary(false);
            }
        } catch (err) {
            setHasSummary(false);
        }
        setFetchingSummary(false);
    };

    const loadAnalysis = async () => {
        setFetchingAnalysis(true);

        try {
            const analysisData = await getOpenaiAnalysis(url, hash);

            setAnalysis(analysisData?.latest_trend);

            const highRisk = analysisData?.latest_trend?.high || '';
            const lowRisk = analysisData?.latest_trend?.low || '';
            const marginalRisk = analysisData?.latest_trend?.low || '';
            const noResponseText = 'No Response From ChatGPT';

            if (highRisk || lowRisk || marginalRisk) {
                setHasAnalysis(true);
                setAnalyzePlainText(
                    `1. High risk: ${highRisk || noResponseText}\n\n2. Low risk: ${lowRisk || noResponseText}\n\n3. Marginal risk: ${
                        marginalRisk || noResponseText
                    }`
                );
            } else {
                setAnalyzePlainText('');
                setHasAnalysis(false);
            }
        } catch (err) {
            setHasAnalysis(false);
        }

        setFetchingAnalysis(false);
    };

    const getSummary = () => {
        if (!hasSummary) loadSummary();

        !fetchingAnalysis && hasAnalysis === false && setHasAnalysis(null);
        showAnalysis && setShowAnalysis(false);
        setShowSummary(!showSummary);
    };

    const getAnalysis = () => {
        if (!hasAnalysis) loadAnalysis();

        !fetchingSummary && hasSummary === false && setHasSummary(null);
        showSummary && setShowSummary(false);
        setShowAnalysis(!showAnalysis);
    };

    const buildMailTo = () => {
        const body = url + '\n\n' + plainText;
        return 'mailto:?subject=' + truncateWithoutWordBreak(title) + '&body=' + encodeURIComponent(body);
    };

    const buildAnalysisMailTo = () => {
        const body = url + '\n\n' + analyzePlainText;
        return 'mailto:?subject=' + truncateWithoutWordBreak(title) + '&body=' + encodeURIComponent(body);
    };

    const setTooltipText = () => setTooltipCopied(true);
    const resetTooltipText = () => setTooltipCopied(false);

    const tooltipText = tooltipCopied ? 'Copied to Clipboard' : 'Copy to Clipboard';

    const bitUrl = async () => {
        try {
            const bitUrl = await getBitUrl(alertId, item.id);
            item['bit_url'] = bitUrl['url'];
        } catch (err) {
            handleError(err);
        }
    };

    const handleArticleToBriefingReport = async () => {
        let newArticlesBriefingReport = [...articlesToBriefingReport];
        const ind = getAddedIndex(articlesToBriefingReport);

        if (+ind === -1) {
            if (articlesToBriefingReport.length >= maxArticlesBriefingReport) {
                addFlashMessage('danger', 'You can add only ' + maxArticlesBriefingReport + ' articles...');
                return false;
            }

            newArticlesBriefingReport.push(item.id);
        } else {
            delete newArticlesBriefingReport[ind];
        }

        newArticlesBriefingReport = newArticlesBriefingReport.filter((int) => int > -1);
        setArticlesToBriefingReport(newArticlesBriefingReport);

        return false;
    };

    const handleArticleToEmail = async () => {
        let newArticlesToEmail = [...articlesToEmail];
        const ind = getAddedIndex(articlesToEmail);

        if (+ind === -1) {
            if (articlesToEmail.length >= maxArticlesToEmail) {
                addFlashMessage('danger', 'You can add only ' + maxArticlesToEmail + ' articles...');
                return false;
            }

            await bitUrl();
            newArticlesToEmail.push(item.id);
        } else {
            delete newArticlesToEmail[ind];
        }

        newArticlesToEmail = newArticlesToEmail.filter((int) => int > -1);
        setArticlesToEmail(newArticlesToEmail);

        return false;
    };

    const getAddedIndex = (articles) => (articles !== undefined ? articles.indexOf(item.id) : -1);

    useEffect(() => {
        if (activeTag?.name && activeTag?.name?.length > 0) {
            const escaped = activeTag.name.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
            const regexp = new RegExp(`\\b(${escaped})\\b`, 'gi');
            taggedAbstract = abstract != null && abstract.replace(regexp, '<span class="tag-highlight">$&</span>');
            taggedTitle = title != null && title.replace(regexp, '<span class="tag-highlight">$&</span>');
        }
    }, [activeTag]);

    const Results = () => (
        <div className="col-9">
            {!!displayedSummary && (
                <div
                    className="col-12"
                    dangerouslySetInnerHTML={{ __html: displayedSummary.replace(regExp, '') }}
                    style={{ padding: '10px 5px 5px 35px', color: '#727171FF' }}
                />
            )}

            <div className="row">
                <div className="col-6">
                    <div
                        onClick={() => handleCopyToClipboard(plainText)}
                        style={{ wordBreak: 'break-all', wordWrap: 'break-word', cursor: 'pointer' }}
                        onMouseEnter={resetTooltipText}>
                        <div onClick={setTooltipText} id={'openai-tooltip-' + id}>
                            <div className={'text-center'}>
                                <i className="fa fa-clipboard"></i>
                                &nbsp;Copy to clipboard
                            </div>

                            <UncontrolledTooltip placement="bottom" autohide={false} target={'openai-tooltip-' + id} delay={{ show: 200, hide: 0 }}>
                                {tooltipText}
                            </UncontrolledTooltip>
                        </div>
                    </div>
                </div>

                <div className="col-6">
                    <a target={'_blank'} style={{ color: '#151b1e' }} href={buildMailTo()}>
                        <i className="fa fa-envelope" />
                        &nbsp;Send to Email
                    </a>
                </div>
            </div>
        </div>
    );

    const AnalyzeResults = () => (
        <div className="col-9" style={{ width: '100%', padding: '20px 15px' }}>
            <table className="table">
                <thead>
                    <tr>
                        <th scope="col">Risk</th>
                        <th scope="col">Description</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th style={{ width: '15%' }} scope="row">
                            High
                        </th>

                        {withTagsHighRisk && displayedHighRisk ? (
                            <td dangerouslySetInnerHTML={{ __html: displayedHighRisk.replace(regExp, '') }} />
                        ) : (
                            <td>{displayedHighRisk || 'No Response From ChatGPT'}</td>
                        )}
                    </tr>

                    <tr>
                        <th scope="row">Low</th>

                        {withTagsLowRisk && displayedLowRisk ? (
                            <td dangerouslySetInnerHTML={{ __html: displayedLowRisk.replace(regExp, '') }} />
                        ) : (
                            <td>{isLoadingHighRisk === false ? displayedLowRisk || 'No Response From ChatGPT' : ''}</td>
                        )}
                    </tr>

                    <tr>
                        <th scope="row">Marginal</th>

                        {withTagsMarginalRisk && displayedMarginalRisk ? (
                            <td dangerouslySetInnerHTML={{ __html: displayedMarginalRisk.replace(regExp, '') }} />
                        ) : (
                            <td>{isLoadingLowRisk === false ? displayedMarginalRisk || 'No Response From ChatGPT' : ''}</td>
                        )}
                    </tr>
                </tbody>
            </table>

            <div className="row">
                <div className="col-6">
                    <div
                        onClick={() => handleCopyToClipboard(analyzePlainText)}
                        style={{ wordBreak: 'break-all', wordWrap: 'break-word', cursor: 'pointer' }}
                        onMouseEnter={resetTooltipText}>
                        <div onClick={setTooltipText} id={'openai-tooltip-' + id}>
                            <div className={'text-center'}>
                                <i className="fa fa-clipboard"></i>
                                &nbsp;Copy to clipboard
                            </div>

                            <UncontrolledTooltip placement="bottom" autohide={false} target={'openai-tooltip-' + id} delay={{ show: 200, hide: 0 }}>
                                {tooltipText}
                            </UncontrolledTooltip>
                        </div>
                    </div>
                </div>

                <div className="col-6">
                    <a target={'_blank'} style={{ color: '#151b1e' }} href={buildAnalysisMailTo()}>
                        <i className="fa fa-envelope" />
                        &nbsp;Send to Email
                    </a>
                </div>
            </div>
        </div>
    );

    return (
        <div className="alert-preview-item">
            <div className="row">
                <div className={isDesktop ? 'col-9' : ''}>
                    {!!alertId && (
                        <div>
                            <UncontrolledTooltip placement="bottom" target={'add-to-email-' + item.id} delay={{ show: 200, hide: 0 }}>
                                {getAddedIndex(articlesToEmail) === -1 ? 'Add to email' : 'Remove from email'}
                            </UncontrolledTooltip>

                            <button
                                id={'add-to-email-' + item.id}
                                className="preview-btn"
                                onClick={handleArticleToEmail}
                                style={{ color: getAddedIndex(articlesToEmail) === -1 ? '#bfe1ee' : '' }}>
                                <i className="fa fa-share fa-2x" aria-hidden="true"></i>
                            </button>

                            {!isSecFilings && accessBriefingReport && (
                                <span>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <UncontrolledTooltip placement="bottom" target={'add-to-report-' + item.id} delay={{ show: 200, hide: 0 }}>
                                        {getAddedIndex(articlesToBriefingReport) === -1 ? 'Add to Briefing Report' : 'Remove from Briefing Report'}
                                    </UncontrolledTooltip>
                                    <button
                                        id={'add-to-report-' + item.id}
                                        className="preview-btn"
                                        onClick={handleArticleToBriefingReport}
                                        style={{ color: getAddedIndex(articlesToBriefingReport) === -1 ? '#bfe1ee' : '' }}>
                                        <i className="fa fa-plus-square fa-2x" aria-hidden="true"></i>
                                    </button>
                                </span>
                            )}
                        </div>
                    )}

                    <div className="alert-preview-item__body">
                        <div className="company-alert-preview-item-header">
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={url}
                                className="alert-preview-item__title"
                                dangerouslySetInnerHTML={{ __html: taggedTitle || title }}
                            />
                        </div>

                        <div className="alert-preview-item__info" style={{ color: '#9a9a9a' }}>
                            <span className="alert-preview-item__source" dangerouslySetInnerHTML={{ __html: resource }} />
                            {' · '}
                            <span className="alert-preview-item__date">{convertDateString(created_at, true)}</span>
                            {item.valid !== undefined && false === item.valid && <span style={{ color: 'red', fontWeight: 'bold' }}> ---</span>}
                        </div>

                        {abstract && (
                            <div
                                className="alert-preview-item__abstract"
                                style={{ fontSize: '14px' }}
                                dangerouslySetInnerHTML={{
                                    __html: truncateWithoutWordBreak(taggedAbstract, 280) || truncateWithoutWordBreak(abstract, 280),
                                }}
                            />
                        )}

                        {!isSecFilings && item?.org?.length > 0 && (
                            <div style={{ paddingTop: '10px' }}>
                                <b style={{ color: '#565454' }}>Org: </b>
                                {item.org.map((tag, i) => (
                                    <div
                                        key={tag + '-' + i}
                                        onClick={() => handleTagSelect({ ind: 'org', name: tag ?? '' })}
                                        className={activeTag?.name === tag ? 'dig-deeper-tag-list__tag_active' : 'dig-deeper-tag-list__tag'}>
                                        {tag}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>

                {withSummary && accessCompanyBasedSummary && (
                    <div className={isDesktop ? 'col-3' : ''}>
                        <Button className="gpt-request-button summarize" onClick={getSummary} disabled={fetchingSummary}>
                            <div className="flex-center">
                                <img src={blackOpenai} alt="open ai summarize" width={40} height={40} style={{ marginLeft: '10px' }} />
                                ChatGPT Summarize
                            </div>
                        </Button>

                        <Button className="gpt-request-button analyze" onClick={getAnalysis} disabled={fetchingAnalysis}>
                            <div className="flex-center" style={{ width: '80%', margin: 'auto' }}>
                                <img src={whiteOpenai} alt="open ai analyze" width={40} height={40} style={{ marginRight: '10px' }} />
                                ChatGPT Analyze
                            </div>
                        </Button>
                    </div>
                )}

                {duplicates && !duplicateArticle && (
                    <div className={'col-12'}>
                        <Button size="xs" className="duplicates-btn" onClick={() => setDuplicatesVisible((prevState) => !prevState)}>
                            {duplicatesVisible ? 'Hide' : 'Show'} similar articles
                        </Button>
                    </div>
                )}

                {duplicatesVisible && (
                    <div className="col-12 alert-preview-item__duplicates-container" style={{ background: 'rgb(248 243 243)' }}>
                        {duplicates.map((n) => (
                            <ListItem key={n.id} item={n} activeTag={activeTag} duplicateArticle={true} handleTagSelect={handleTagSelect} />
                        ))}
                    </div>
                )}
            </div>

            <div className="row">
                {fetchingSummary ? (
                    <div className="col-12" style={{ paddingTop: '7px' }}>
                        <LoadingSpinner text={'Waiting for ChatGPT response'} />
                    </div>
                ) : hasSummary && showSummary ? (
                    <Results />
                ) : hasSummary !== false ? null : (
                    <div className="col-12" style={{ paddingTop: '7px', color: 'rgb(154, 154, 154)' }}>
                        No Response From ChatGPT
                    </div>
                )}

                {fetchingAnalysis ? (
                    <div className="col-12" style={{ paddingTop: '7px' }}>
                        <LoadingSpinner text={'Waiting for ChatGPT response'} />
                    </div>
                ) : hasAnalysis && showAnalysis ? (
                    <AnalyzeResults />
                ) : hasAnalysis !== false ? null : (
                    <div className="col-12" style={{ paddingTop: '7px', color: 'rgb(154, 154, 154)' }}>
                        No Response From ChatGPT
                    </div>
                )}
            </div>
        </div>
    );
};
