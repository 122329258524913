import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

export const SectionDropdown = ({ sections, setAssignmentSection, assignmentSection, articleId }) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggle = () => setDropdownOpen((prevState) => !prevState);

    return (
        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
            <DropdownToggle caret color="primary" style={{ minWidth: '120px' }}>
                {assignmentSection && (assignmentSection.name.length > 15 ? assignmentSection.name.substring(0, 15) + '...' : assignmentSection.name)}
            </DropdownToggle>

            <DropdownMenu>
                {sections.map((section) => (
                    <DropdownItem onClick={() => setAssignmentSection(section)} key={articleId + '-dropdown' + section.id}>
                        {section.name.length > 25 ? section.name.substring(0, 25) + '...' : section.name}
                    </DropdownItem>
                ))}
            </DropdownMenu>
        </Dropdown>
    );
};
