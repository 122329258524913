import React, { useState } from 'react';
import { Row, Col, Card, CardHeader, CardBody } from 'reactstrap';
import { TabBar } from 'common/TabBar';
import { UsersList } from './UsersList';
import { UserGroups } from './UserGroups';
import { UserLabels } from './UserLabels';

const tabsItems = [
    { type: 'users', name: 'Users' },
    { type: 'userGroups', name: 'Groups' },
    { type: 'labels', name: 'Labels' },
];

export const Users = () => {
    const [activeView, setActiveView] = useState('users');

    return (
        <Row>
            <Col xs="12">
                <Card>
                    <CardHeader>
                        <div className="flex-space-between">
                            <h1>Users</h1>
                        </div>
                    </CardHeader>
                    <CardBody>
                        <TabBar activeItem={activeView} setActive={setActiveView} items={tabsItems} />
                        {activeView === 'users' && <UsersList />}
                        {activeView === 'userGroups' && <UserGroups />}
                        {activeView === 'labels' && <UserLabels />}
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
};
