import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './index.scss';
import 'font-awesome/css/font-awesome.css';
import '@coreui/coreui/dist/css/coreui.min.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

serviceWorker.unregister();
