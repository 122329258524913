import React from 'react';
import { Input, InputGroup, InputGroupText, Popover } from 'reactstrap';
import { DayPicker } from 'react-day-picker';
import moment from 'moment-timezone';
import 'react-day-picker/dist/style.css';
import './style.scss';

export const DayPickerInput = ({ name, isOpen, setIsOpen, specificDate, setSpecificDate, disabled }) => {
    const handleDayPickerChange = (day) => {
        setSpecificDate(moment(day).format('YYYY-MM-DD'));
        setIsOpen(false);
    };
    const onToggle = () => setIsOpen(!isOpen);

    const handleKeyDown = (e) => {
        setIsOpen(true);

        if (e.keyCode === 8 || e.keyCode === 46) {
            setSpecificDate(moment(new Date()).format('YYYY-MM-DD'));
        }
    };

    return (
        <>
            <InputGroup className="day-picker mb-0">
                <InputGroupText>
                    <i className="fa fa-calendar" />
                </InputGroupText>

                <Input
                    id={(name || 'dayPicker') + '-' + specificDate}
                    onClick={onToggle}
                    placeholder="YYYY-MM-DD"
                    value={specificDate}
                    onChange={() => setIsOpen(true)}
                    onKeyDown={handleKeyDown}
                />

                <Popover isOpen={isOpen} target={(name || 'dayPicker') + '-' + specificDate} trigger="legacy" placement="bottom" toggle={onToggle}>
                    <DayPicker
                        mode="single"
                        selected={new Date(specificDate)}
                        disabled={disabled || { before: new Date() }}
                        onSelect={handleDayPickerChange}
                    />
                </Popover>
            </InputGroup>
        </>
    );
};
