import React, { useState, useEffect, useRef } from 'react';
import { LoadingSpinner } from 'common/LoadingSpinner';
import { PaginationWrapper } from 'common/PaginationWrapper';
import { AlertPreviewItem } from 'components/Alerts/components/Preview/AlertPreviewItem';

export const InternalPreview = ({ preview, updatingPreview, loadingSpinnerText = '' }) => {
    const ref = useRef(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    useEffect(() => {
        setTotalPages(Math.ceil(preview?.content?.length / 10));
        setCurrentPage(1);
    }, [preview]);

    const handlePageClick = (e) => {
        setCurrentPage(e.selected + 1);
        ref?.current &&
            ref.current?.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'start',
            });
    };

    return (
        <div className="mt-2 internal-preview-list bordered-preview-list">
            <div className="mb-3 d-flex">
                <div className="bordered-preview-list__left" ref={ref}>
                    <strong>
                        {preview.count} {preview.count === 1 ? 'article' : 'articles'} matched your filters for the last 30 days&nbsp;&nbsp;
                    </strong>

                    <div style={{ marginTop: '20px' }}>
                        <>
                            {updatingPreview ? (
                                <LoadingSpinner text={loadingSpinnerText} />
                            ) : preview.content.length ? (
                                preview.content
                                    .slice(currentPage * 10 - 10, currentPage * 10)
                                    .map((item) => <AlertPreviewItem key={item.id} item={item} withHour />)
                            ) : (
                                <div>
                                    <h5>No results found</h5>
                                </div>
                            )}
                        </>
                    </div>
                </div>
            </div>

            {updatingPreview ? (
                <div className="relevance-selector-container">
                    <LoadingSpinner text="Applying search relevance" />
                </div>
            ) : (
                totalPages > 1 && <PaginationWrapper handlePageClick={handlePageClick} forcePage={currentPage - 1} totalPages={totalPages} />
            )}
        </div>
    );
};
