import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { Table, Button } from 'reactstrap';
import { useDevice } from 'hooks/useMediaQuery';
import { deleteAlertLabel } from 'utils/api/alertsAPI';
import { filterByValue, sortObjects, handleCaret } from 'utils/helpers';
import { SearchInputWithSubmit } from 'common/SearchInput/SearchInputWithSubmit';
import { CreateEditLabelModal } from './Modals/CreateEditLabelModal';
import { DeleteAlertModal } from 'components/Alerts/components/Modals/DeleteAlertModal';
import { MobileFilterDropdown } from 'components/Alerts/components/MobileFilterDropdown';

export const LabelList = ({ labels, refreshLabels, handleError, addFlashMessage, alertLabelFilters, setFiltersObject }) => {
    const { searchValue, activeSortProperty, activeSortDirection } = alertLabelFilters;

    const { isMobile, isTablet, isDesktop } = useDevice();
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [itemToEdit, setItemToEdit] = useState(null);
    const [itemToDelete, setItemToDelete] = useState(null);
    const [submitting, setSubmitting] = useState(false);
    const [tempSearchValue, setTempSearchValue] = useState('');
    const [visibleLabels, setVisibleLabels] = useState([]);
    const [activeMobileFilterValue, setActiveMobileFilterValue] = useState('');

    useEffect(() => {
        tempSearchValue !== searchValue && setTempSearchValue(searchValue);
    }, []);

    useEffect(() => {
        const filteredList = filterByValue(labels, 'name', searchValue);
        const sortedList = sortObjects(filteredList, activeSortProperty, activeSortDirection);
        setVisibleLabels(sortedList);
    }, [alertLabelFilters, labels]);

    const toggleCreateModal = () => setCreateModalOpen(!createModalOpen);

    const toggleEditModal = (item) => {
        setEditModalOpen(!editModalOpen);
        setItemToEdit(!editModalOpen ? item : null);
    };

    const toggleDeleteModal = (item) => {
        setDeleteModalOpen(!deleteModalOpen);
        if (!deleteModalOpen) {
            setItemToDelete(item);
        } else {
            setItemToDelete(null);
        }
    };

    const handleSearchValueSubmit = () => {
        if (tempSearchValue.length > 2048) {
            addFlashMessage('danger', 'Your search exceeds the maximum number of allowed characters (2048)');
            return;
        }

        let filters = { ...alertLabelFilters };
        filters.searchValue = tempSearchValue;
        setFiltersObject('alertLabels', filters);
    };

    const handleClear = () => {
        let filters = { ...alertLabelFilters };
        filters.searchValue = '';
        setFiltersObject('alertLabels', filters);
        setTempSearchValue('');
    };

    const handleSortableHeaderClick = (value, activeSortProperty, activeSortDirection) => {
        let filters = { ...alertLabelFilters };

        if (value === activeSortProperty) {
            activeSortDirection === 'asc' ? (filters.activeSortDirection = 'desc') : (filters.activeSortDirection = 'asc');
        } else {
            filters.activeSortProperty = value;
            filters.activeSortDirection = 'asc';
        }

        setFiltersObject('alertLabels', filters);
    };

    const handleMobileFilterSelect = (val) => {
        const splitValue = val.split('-');
        let filters = { ...alertLabelFilters };
        filters.activeSortProperty = splitValue[0];
        filters.activeSortDirection = splitValue[1];
        setFiltersObject('alertLabels', filters);
    };

    const determineMobileFiltersValue = () => {
        let filterStr = alertLabelFilters.activeSortProperty + '-' + alertLabelFilters.activeSortDirection;
        setActiveMobileFilterValue(filterStr);
    };

    const handleDeleteLabel = async (id, modalCallback) => {
        try {
            setSubmitting(true);
            await deleteAlertLabel(id);
            await refreshLabels();

            addFlashMessage('success', 'Alert label successfully deleted');
            setSubmitting(false);
            modalCallback && modalCallback();
        } catch (err) {
            setSubmitting(false);
            handleError(err);
        }
    };

    useEffect(() => {
        alertLabelFilters && determineMobileFiltersValue();
    }, [alertLabelFilters]);

    return (
        <div>
            {isDesktop && (
                <div className="list__utility-row list__utility-row--desktop">
                    <div className="list__search-container">
                        <SearchInputWithSubmit
                            value={tempSearchValue}
                            onChange={setTempSearchValue}
                            placeholder={'Filter by label name'}
                            onClear={handleClear}
                            submitting={false}
                            onSubmit={handleSearchValueSubmit}
                            list
                        />
                    </div>
                    <div className="d-flex">
                        <Button onClick={toggleCreateModal} color="primary">
                            Create Label
                        </Button>
                    </div>
                </div>
            )}
            {isTablet && (
                <div className="list__utility-row list__utility-row--tablet">
                    <div className="d-flex justify-content-between w-100">
                        <div className="list__search-container">
                            <SearchInputWithSubmit
                                value={tempSearchValue}
                                onChange={setTempSearchValue}
                                placeholder="Filter by label name"
                                onClear={handleClear}
                                submitting={false}
                                onSubmit={handleSearchValueSubmit}
                                list
                            />
                        </div>
                    </div>
                    <div className="d-flex mt-2 w-100 justify-content-end">
                        <div className="mr-2 d-flex">
                            <Button onClick={toggleCreateModal} color="primary" className="mb-0">
                                <i className="fa fa-plus" />
                                &nbsp; Create Label
                            </Button>
                        </div>
                        <MobileFilterDropdown handleMobileFilterSelect={handleMobileFilterSelect} activeMobileFilterValue={activeMobileFilterValue} />
                    </div>
                </div>
            )}
            {isMobile && (
                <div className="list-utility-row list__utility-row--mobile">
                    <div className="d-flex mb-2">
                        <Button onClick={toggleCreateModal} color="primary" block>
                            <i className="fa fa-plus" />
                            &nbsp; Create Label
                        </Button>
                    </div>
                    <div className="d-flex mb-2">
                        <MobileFilterDropdown handleMobileFilterSelect={handleMobileFilterSelect} activeMobileFilterValue={activeMobileFilterValue} />
                    </div>
                    <div className="mb-3">
                        <SearchInputWithSubmit
                            value={tempSearchValue}
                            onChange={setTempSearchValue}
                            placeholder={'Filter by label name'}
                            onClear={handleClear}
                            submitting={false}
                            onSubmit={handleSearchValueSubmit}
                            list
                        />
                    </div>
                </div>
            )}

            <Table className="responsive-table">
                <tbody>
                    <tr>
                        <th
                            className="responsive-table__sortable-th"
                            onClick={() => handleSortableHeaderClick('name', activeSortProperty, activeSortDirection)}>
                            Name
                            {handleCaret(activeSortProperty, activeSortDirection, 'name')}
                        </th>
                        <th
                            className="responsive-table__sortable-th"
                            onClick={() => handleSortableHeaderClick('createdAt', activeSortProperty, activeSortDirection)}>
                            Created At
                            {handleCaret(activeSortProperty, activeSortDirection, 'createdAt')}
                        </th>
                        <th>Edit/Remove</th>
                    </tr>
                    {visibleLabels.length > 0 ? (
                        visibleLabels.map((label) => (
                            <tr key={label.id}>
                                <td data-label="Name">{label.name}</td>
                                <td data-label="Created At">{moment(label.createdAt).format('MM/DD/YY')}</td>
                                <td className="responsive-table__edit-block" data-label="Edit/Remove">
                                    <Button color="light" onClick={() => toggleEditModal(label)}>
                                        <i className="fa fa-edit fa-lg" />
                                    </Button>
                                    <Button color="light" onClick={() => toggleDeleteModal(label)}>
                                        <i className="fa fa-trash fa-lg" />
                                    </Button>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td>No results found</td>
                            <td />
                            <td />
                        </tr>
                    )}
                </tbody>
            </Table>

            <CreateEditLabelModal
                isOpen={createModalOpen}
                toggle={toggleCreateModal}
                refreshLabels={refreshLabels}
                handleError={handleError}
                addFlashMessage={addFlashMessage}
            />

            <CreateEditLabelModal
                isOpen={editModalOpen}
                toggle={toggleEditModal}
                item={itemToEdit}
                refreshLabels={refreshLabels}
                handleError={handleError}
                addFlashMessage={addFlashMessage}
                isEdit
            />

            <DeleteAlertModal
                isOpen={deleteModalOpen}
                toggle={toggleDeleteModal}
                item={itemToDelete}
                handleError={handleError}
                refreshLabels={refreshLabels}
                addFlashMessage={addFlashMessage}
                onSubmit={handleDeleteLabel}
            />
        </div>
    );
};
