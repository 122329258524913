import React, { useState, useContext, useEffect } from 'react';
import { isEqual } from 'lodash';
import { FlashMessageContext } from 'contexts/FlashMessageContext';
import { AuthContext } from 'contexts/AuthContext';
import { LoadingSpinner } from 'common/LoadingSpinner';
import { CheckReaderAuth } from 'components/Reader/Mobile/ManageAlerts/CheckReaderAuth';
import { MobileReaderNavbar } from './MobileReaderNavbar';
import { AlertList } from './AlertList';
import { ArticleList } from './ArticleList';
import { SelectCategory } from './Filters/SelectCategory';
import { SelectTag } from './Filters/SelectTag';
import './style.scss';

export const MobileReader = ({
    isPublic,
    id,
    alertsData,
    articlesData,
    alertContent,
    publicEmail,
    allowManageAlertsInReader,
    fetchingAlerts,
    fetchingAlertContent,
    fetchAlerts,
    fetchAlertContent,
}) => {
    const { addFlashMessage } = useContext(FlashMessageContext);
    const { state, handleError } = useContext(AuthContext);
    const [activeView, setActiveView] = useState('alerts');
    const [selectedAlert, setSelectedAlert] = useState({});
    const [selectedTag, setSelectedTag] = useState(null);
    const [selectedArticle, setSelectedArticle] = useState(null);
    const [alerts, setAlerts] = useState([]);
    const [articles, setArticles] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);

    const handleActiveView = () => {
        switch (activeView) {
            case 'alerts':
                return <AlertList alerts={alerts} selectedAlert={selectedAlert} handleAlertSelect={handleAlertSelect} />;
            case 'articles':
                return (
                    <ArticleList
                        articles={articles}
                        selectedArticle={selectedArticle}
                        fetchingArticles={fetchingAlertContent}
                        selectedAlert={selectedAlert}
                        setActiveView={setActiveView}
                        selectedTag={selectedTag}
                    />
                );
            case 'filters-select-category':
                return (
                    <SelectCategory
                        categories={alertContent.tags}
                        selectedAlert={selectedAlert}
                        setActiveView={setActiveView}
                        handleCategorySelect={handleCategorySelect}
                    />
                );
            case 'filters-select-tag':
                return (
                    <SelectTag
                        handleTagSelect={handleTagSelect}
                        selectedAlert={selectedAlert}
                        selectedTag={selectedTag}
                        setActiveView={setActiveView}
                        selectedCategory={selectedCategory}
                    />
                );
            case 'manageAlerts':
                return (
                    <CheckReaderAuth
                        setActiveView={setActiveView}
                        addFlashMessage={addFlashMessage}
                        assignedAlerts={alerts}
                        isPublic={isPublic}
                        state={state}
                        handleError={handleError}
                        userId={id}
                        refreshAlerts={fetchAlerts}
                        publicEmail={publicEmail}
                    />
                );
            default:
                return <AlertList alerts={alerts} selectedAlert={selectedAlert} setActiveView={setActiveView} />;
        }
    };

    const handleAlertSelect = (alert) => {
        if (alert.id !== selectedAlert.id) {
            setSelectedAlert(alert);
        } else {
            setActiveView('articles');
        }
    };

    const handleCategorySelect = (category) => {
        if (selectedCategory && category.type === selectedCategory.type) {
            setActiveView('filters-select-tag');
        } else {
            setSelectedCategory(category);
        }
    };

    useEffect(() => {
        selectedCategory && setActiveView('filters-select-tag');
    }, [selectedCategory]);

    const applyTagFilter = (articleArray) => {
        let filteredArray = [...articleArray];

        if (selectedTag) {
            filteredArray = filteredArray.filter((article) => {
                return selectedTag.news.includes(String(article.id));
            });
        }
        setArticles(filteredArray);
    };

    const handleTagSelect = (tag) => {
        if (isEqual(selectedTag, tag)) {
            setSelectedTag(null);
        } else {
            setSelectedTag(tag);
        }
    };

    useEffect(() => {
        if (alertsData) {
            alertsData?.length && setSelectedAlert(alertsData[0]);
            setAlerts(alertsData);
        }
    }, [alertsData]);

    useEffect(() => {
        alertContent && setSelectedArticle(alertContent.news[0]);
    }, [alertContent]);

    useEffect(() => {
        articlesData && setArticles(articlesData);
    }, [articlesData]);

    useEffect(() => {
        fetchingAlertContent && setSelectedTag(null);
    }, [fetchingAlertContent]);

    useEffect(() => {
        if (!alertContent) return;

        if (selectedTag !== null) {
            applyTagFilter(alertContent.news);
            setActiveView('articles');
        } else {
            setArticles(alertContent.news);
        }
    }, [selectedTag]);

    useEffect(() => {
        selectedAlert.id && fetchAlertContent(selectedAlert.id, selectedAlert.hash);
    }, [selectedAlert]);

    if (fetchingAlerts) return <LoadingSpinner padding text="Fetching reader data" />;

    return (
        <div className="mobile-reader">
            {handleActiveView()}

            <MobileReaderNavbar
                profile={state.profile}
                activeView={activeView}
                setActiveView={setActiveView}
                navItems={[
                    { name: 'Alerts', icon: 'fa-bell', value: 'alerts' },
                    { name: 'Articles', icon: 'fa-book', value: 'articles' },
                    ...(allowManageAlertsInReader
                        ? [
                              {
                                  name: 'Manage',
                                  icon: 'fa-list',
                                  value: 'manageAlerts',
                              },
                          ]
                        : []),
                ]}
            />
        </div>
    );
};
