import React from 'react';
import { Modal, ModalFooter, Button } from 'reactstrap';
import { ModalBodyWithClose } from 'common/ModalBodyWithClose';
import { SpinnerButton } from 'common/SpinnerButton';

export const DeleteAlertModal = ({ isOpen, toggle, item, submitting, onSubmit }) => (
    <Modal isOpen={isOpen} toggle={toggle}>
        {!item ? null : (
            <div>
                <ModalBodyWithClose toggle={toggle}>
                    <span>Are you sure you want to delete {item.name}?</span>
                </ModalBodyWithClose>
                <ModalFooter>
                    <Button color="secondary" onClick={toggle}>
                        Cancel
                    </Button>
                    <SpinnerButton type="modal" color="danger" onClick={() => onSubmit(item.id, toggle)} submitting={submitting} title="Delete" />
                </ModalFooter>
            </div>
        )}
    </Modal>
);
