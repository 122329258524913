import React from 'react';
import { UncontrolledTooltip, Button } from 'reactstrap';

export const CsvButton = ({ downloadFilteredCsv, downloading }) => (
    <>
        <Button className="mr-2 mb-0" id="csv-button-tooltip" onClick={downloadFilteredCsv}>
            {downloading ? <i className="fa fa-spinner fa-spin" /> : <i className="fa fa-download" />}
        </Button>

        <UncontrolledTooltip placement="bottom" target="csv-button-tooltip" delay={{ show: 400, hide: 0 }}>
            Download as CSV file
        </UncontrolledTooltip>
    </>
);
