import React, { useState } from 'react';
import { Label, Row, Col } from 'reactstrap';
import { InputWithError } from 'common/InputWithError';
import { SpinnerButton } from 'common/SpinnerButton';
import { addCourtlinkToken } from 'utils/api/profileAPI';
import { Token } from './Token';
import './style.scss';

export const Courtlink = ({ handleError, profile, addFlashMessage, refreshProfile }) => {
    const [clientId, setClientId] = useState('');
    const [clientSecret, setClientSecret] = useState('');
    const [clientName, setClientName] = useState('');
    const [errors, setErrors] = useState(null);
    const [submitting, setSubmitting] = useState(false);

    const createCourtlinkDetails = async () => {
        setSubmitting(true);

        try {
            await addCourtlinkToken({ name: clientName, clientId, clientSecret });
            refreshProfile();
            setClientId('');
            setClientName('');
            setClientSecret('');
            addFlashMessage('success', 'Token successfully created');
        } catch (err) {
            if (err.message && err.message === 'This token already used') {
                addFlashMessage('danger', 'This token is already in use');
            } else {
                addFlashMessage('danger', 'Invalid token details');
            }
        }

        setSubmitting(false);
    };

    return (
        <div>
            <h4 className="settings__page-header">CourtLink</h4>

            <Row className="mt-3">
                <Col lg={6}>
                    <Label>Client Name</Label>

                    <div style={{ maxWidth: '390px' }}>
                        <InputWithError
                            prependIcon={<i className="fa fa-user" />}
                            name="clientName"
                            value={clientName}
                            type="text"
                            onChange={setClientName}
                            errorObj={errors}
                            marginBottomClass="mb-3"
                            placeholder="Client Name"
                        />
                    </div>
                </Col>

                <Col lg={6}></Col>

                <Col lg={6}>
                    <Label>Client ID</Label>

                    <div style={{ maxWidth: '390px' }}>
                        <InputWithError
                            prependIcon={<i className="fa fa-user" />}
                            name="clientId"
                            value={clientId}
                            type="text"
                            onChange={setClientId}
                            errorObj={errors}
                            marginBottomClass="mb-3"
                            placeholder="Client ID"
                        />
                    </div>
                </Col>
            </Row>

            <Row>
                <Col>
                    <Label>Client Secret</Label>

                    <div style={{ maxWidth: '390px' }}>
                        <InputWithError
                            prependIcon={<i className="fa fa-key" />}
                            name="secret"
                            value={clientSecret}
                            type="text"
                            onChange={setClientSecret}
                            errorObj={errors}
                            marginBottomClass="mb-0"
                            placeholder="Client Secret"
                        />
                    </div>
                </Col>
            </Row>

            <div className="mt-4">
                <SpinnerButton color="primary" onClick={createCourtlinkDetails} submitting={submitting} title="Create" />
            </div>

            <div className="mt-4 mb-4">
                {profile.organization.webTokens.map((item) => (
                    <Token tokenPair={item} addFlashMessage={addFlashMessage} handleError={handleError} refreshProfile={refreshProfile} />
                ))}
            </div>
        </div>
    );
};
