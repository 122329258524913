export const navAccessCompanyBasedAlerts = {
    items: [
        {
            name: 'My Content',
            icon: 'fa fa-database',
            children: [
                {
                    name: 'Searches',
                    url: '/account/alerts',
                    icon: 'fa fa-search',
                    class: 'nav-child',
                },
                {
                    name: 'My Sources',
                    url: '/account/manage-sources',
                    icon: 'fa fa-list-ul',
                    class: 'nav-child',
                },
                {
                    name: 'Companies',
                    url: '/account/manage-companies',
                    icon: 'fa fa-university',
                    class: 'nav-child',
                },
                {
                    name: 'Practices',
                    url: '/account/manage-category-based/practices',
                    icon: 'fa fa-th',
                    class: 'nav-child',
                },
                {
                    name: 'Industries',
                    url: '/account/manage-category-based/industries',
                    icon: 'fa fa-industry',
                    class: 'nav-child',
                },
                {
                    name: 'Topics',
                    url: '/account/manage-category-based/topics',
                    icon: 'fa fa-book',
                    class: 'nav-child',
                },
            ],
        },
        {
            name: 'Users',
            url: '/account/users',
            icon: 'fa fa-user',
        },
        {
            name: 'Routing',
            url: '/account/assignment',
            icon: 'fa fa-list',
        },
        {
            name: 'Newsletters',
            url: '/account/newsletters',
            icon: 'fa fa-newspaper-o',
        },
        {
            name: 'Reports',
            url: '/account/reports',
            icon: 'fa fa-paperclip',
        },
        {
            name: 'Contact Us',
            url: 'mailto:support@ozmosys.com',
            icon: 'fa fa-envelope',
            class: 'm-30',
        },
    ],
};

export const nav = {
    items: [
        {
            name: 'My Content',
            icon: 'fa fa-database',
            children: [
                {
                    name: 'Searches',
                    url: '/account/alerts',
                    icon: 'fa fa-search',
                    class: 'nav-child',
                },
                {
                    name: 'My Sources',
                    url: '/account/manage-sources',
                    icon: 'fa fa-list-ul',
                    class: 'nav-child',
                },
                {
                    name: 'Practices',
                    url: '/account/manage-category-based/practices',
                    icon: 'fa fa-th',
                    class: 'nav-child',
                },
                {
                    name: 'Industries',
                    url: '/account/manage-category-based/industries',
                    icon: 'fa fa-industry',
                    class: 'nav-child',
                },
                {
                    name: 'Topics',
                    url: '/account/manage-category-based/topics',
                    icon: 'fa fa-book',
                    class: 'nav-child',
                },
            ],
        },
        {
            name: 'Users',
            url: '/account/users',
            icon: 'fa fa-user',
        },
        {
            name: 'Routing',
            url: '/account/assignment',
            icon: 'fa fa-list',
        },
        {
            name: 'Newsletters',
            url: '/account/newsletters',
            icon: 'fa fa-newspaper-o',
        },
        {
            name: 'Reports',
            url: '/account/reports',
            icon: 'fa fa-paperclip',
        },
        {
            name: 'Contact Us',
            url: 'mailto:support@ozmosys.com',
            icon: 'fa fa-envelope',
            class: 'm-30',
        },
    ],
};

export const mobileNav = {
    items: [
        {
            name: 'Dashboard',
            url: '/dashboard',
            icon: 'fa fa-tachometer',
        },
    ],
};
