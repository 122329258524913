import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { CreateLabelModal } from './Modals/CreateLabelModal';

export const EmptyList = ({ createLabel, submitting, refreshLabels, addFlashMessage, handleError }) => {
    const [createModalOpen, setCreateModalOpen] = useState(false);

    const toggleCreateModal = () => setCreateModalOpen(!createModalOpen);

    return (
        <>
            <p>Create your first user label!</p>

            <Button color="primary" onClick={toggleCreateModal}>
                Create Label
            </Button>

            <CreateLabelModal
                isOpen={createModalOpen}
                onSubmit={createLabel}
                toggle={toggleCreateModal}
                submitting={submitting}
                addFlashMessage={addFlashMessage}
                handleError={handleError}
                refreshLabels={refreshLabels}
            />
        </>
    );
};
