import React, { useState, useEffect } from 'react';
import { Input, InputGroup, InputGroupText, Label, Button } from 'reactstrap';
import { SpinnerButton } from 'common/SpinnerButton';
import { CharacterCount } from 'common/CharacterCount';
import { LoadingSpinner } from 'common/LoadingSpinner';
import { SelectInputWithError } from 'common/InputWithError/SelectInputWithError';
import { editOrganizationEmailSettings } from 'utils/api/profileAPI';
import { suppressSocialStatuses } from 'constants/suppressSocial';
import { EmailSettingsToggle } from './EmailSettingsToggle';
import './style.scss';

export const EmailSettings = ({ addFlashMessage, profile, refreshProfile, handleError }) => {
    const [emailFriendlyName, setEmailFriendlyName] = useState('');
    const [emailSubjectLine, setEmailSubjectLine] = useState('');
    const [emailReplyTo, setEmailReplyTo] = useState('');
    const [euDateFormat, setEuDateFormat] = useState(false);
    const [formatForGmail, setFormatForGmail] = useState(false);
    const [suppressSocial, setSuppressSocial] = useState(suppressSocialStatuses[0].key);
    const [suppressPaywall, setSuppressPaywall] = useState(false);
    const [altEmailTemplate, setAltEmailTemplate] = useState(false);
    const [articlesPerPage, setArticlesPerPage] = useState(10);
    const [newsletterArticlesPerPage, setNewsletterArticlesPerPage] = useState(5);
    const [submitting, setSubmitting] = useState(false);
    const [populatingData, setPopulatingData] = useState(true);

    useEffect(() => {
        const settings = profile.organization?.settings;
        const limits = profile.organization?.limits;

        if (settings) {
            setFormatForGmail(settings.formatForGmail);
            setEmailFriendlyName(settings.fromName || 'Ozmosys Alerts');
            setAltEmailTemplate(settings.altEmailTemplate);
            setEmailReplyTo(settings.replyTo || 'alerts@ozmosys.com');
            setEmailSubjectLine(settings.subjectName || 'Ozmosys' + ' Alerts');
            setEuDateFormat(settings.dateFormat === 'eu');
            setSuppressPaywall(settings.suppressPaywall);
            setSuppressSocial(settings.suppressSocial);

            let tempArticlesPerPage = limits?.articlesPerPage;

            if (profile.organization.articlesPerPage == null) {
                if (settings.formatForGmail) {
                    tempArticlesPerPage = 3;
                } else {
                    tempArticlesPerPage = 10;
                }
            }

            setArticlesPerPage(tempArticlesPerPage);
            setPopulatingData(false);
            setNewsletterArticlesPerPage(limits.newsletterArticlesPerPage);
        }
    }, [profile]);

    const handleUpdateProfile = async () => {
        setSubmitting(true);

        try {
            const params = {
                suppressSocial,
                suppressPaywall,
                formatForGmail,
                altEmailTemplate,
                articlesPerPage,
                newsletterArticlesPerPage,
                replyTo: emailReplyTo,
                fromName: emailFriendlyName,
                subjectName: emailSubjectLine,
                euDateFormat,
            };

            await editOrganizationEmailSettings(params);
            refreshProfile();
            addFlashMessage('success', 'Settings successfully updated');
        } catch (err) {
            handleError(err);
        }

        setSubmitting(false);
    };

    const incrementMultiplier = (type, what) => {
        let value;

        if ('alert' === what) {
            value = articlesPerPage;
        } else {
            value = newsletterArticlesPerPage;
        }

        if (type === 'decrease' && value > 1) {
            value--;
        }

        if (type === 'increase' && value < 499) {
            value++;
        }

        if ('alert' === what) {
            setArticlesPerPage(value);
        } else {
            setNewsletterArticlesPerPage(value);
        }
    };

    if (populatingData) return <LoadingSpinner text="Fetching email settings" />;

    return (
        <div className="email-settings">
            <h4 className="settings__page-header">Email Settings</h4>

            <div className="mt-3 mb-3">
                <div className="email-settings__input-container">
                    <Label>Email Friendly Name</Label>

                    <InputGroup>
                        <InputGroupText>
                            <i className="fa fa-reply" />
                        </InputGroupText>

                        <Input
                            placeholder="Email Friendly Name"
                            name="friendlyName"
                            value={emailFriendlyName}
                            type="text"
                            onChange={(e) => setEmailFriendlyName(e.target.value)}
                        />
                    </InputGroup>

                    <CharacterCount limit={30} value={emailFriendlyName} />
                </div>

                <div className="email-settings__input-container">
                    <Label>Email Subject Line</Label>

                    <InputGroup>
                        <InputGroupText>
                            <i className="fa fa-reply" />
                        </InputGroupText>

                        <Input
                            placeholder="Email Subject Line"
                            name="subjectLine"
                            value={emailSubjectLine}
                            type="text"
                            onChange={(e) => setEmailSubjectLine(e.target.value)}
                        />
                    </InputGroup>

                    <CharacterCount limit={41} value={emailSubjectLine} />
                </div>
                <div className="email-settings__input-container">
                    <Label>Email Reply To</Label>

                    <InputGroup>
                        <InputGroupText>
                            <i className="fa fa-reply" />
                        </InputGroupText>

                        <Input
                            placeholder="Email Reply To"
                            name="replyTo"
                            value={emailReplyTo}
                            type="text"
                            onChange={(e) => setEmailReplyTo(e.target.value)}
                        />
                    </InputGroup>
                </div>

                <div className="email-settings__input-container">
                    <Label>Suppress social media icons in news abstracts</Label>

                    <SelectInputWithError
                        prependIcon={<i className="fa fa-reply" />}
                        name="suppressSocial"
                        value={suppressSocial}
                        type="select"
                        onChange={setSuppressSocial}>
                        {suppressSocialStatuses.map((suppressSocialStatus) => (
                            <option key={suppressSocialStatus.key} value={suppressSocialStatus.key}>
                                {suppressSocialStatus.name}
                            </option>
                        ))}
                    </SelectInputWithError>
                </div>

                <EmailSettingsToggle checked={suppressPaywall} handleCheck={setSuppressPaywall} text="Suppress paywall tags in news abstracts" />

                <EmailSettingsToggle checked={formatForGmail} handleCheck={setFormatForGmail} text={'Format for mobile'} />

                <EmailSettingsToggle checked={euDateFormat} handleCheck={setEuDateFormat} text={'Use EU date format'} />

                <div className="mt-4 d-flex align-items-center">
                    <span className="mr-4">Articles per Alert in the Alert Email</span>

                    <div className="d-flex align-items-center">
                        <Button
                            onClick={() => incrementMultiplier('decrease', 'alert')}
                            disabled={articlesPerPage < 2}
                            style={{
                                border: '1px solid #c2cfd6',
                                margin: 0,
                                borderRight: 'none',
                                color: '#696969',
                            }}
                            color="light">
                            <i className="fa fa-minus" />
                        </Button>

                        <Input
                            style={{
                                width: '60px',
                                textAlign: 'center',
                                pointerEvents: 'none',
                            }}
                            value={articlesPerPage}
                        />

                        <Button
                            onClick={() => incrementMultiplier('increase', 'alert')}
                            style={{
                                border: '1px solid #c2cfd6',
                                color: '#696969',
                                margin: 0,
                                borderLeft: 'none',
                            }}
                            className="mr-2"
                            color="light">
                            <i className="fa fa-plus" />
                        </Button>
                    </div>
                </div>

                <div className="mt-4 d-flex align-items-center">
                    <span className="mr-4">Articles per Alert in the Newsletter</span>

                    <div className="d-flex align-items-center">
                        <Button
                            onClick={() => incrementMultiplier('decrease', 'newsletter')}
                            disabled={newsletterArticlesPerPage < 2}
                            style={{
                                border: '1px solid #c2cfd6',
                                margin: 0,
                                borderRight: 'none',
                                color: '#696969',
                            }}
                            color="light">
                            <i className="fa fa-minus" />
                        </Button>

                        <Input
                            style={{
                                width: '60px',
                                textAlign: 'center',
                                pointerEvents: 'none',
                            }}
                            value={newsletterArticlesPerPage}
                        />

                        <Button
                            onClick={() => incrementMultiplier('increase', 'newsletter')}
                            style={{
                                border: '1px solid #c2cfd6',
                                color: '#696969',
                                margin: 0,
                                borderLeft: 'none',
                            }}
                            className="mr-2"
                            color="light">
                            <i className="fa fa-plus" />
                        </Button>
                    </div>
                </div>

                <div className="mt-4">
                    <SpinnerButton color="primary" submitting={submitting} onClick={handleUpdateProfile} title={'Save Changes'} />
                </div>
            </div>
        </div>
    );
};
