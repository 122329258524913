import React, { useEffect, useState, useContext } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { AuthContext } from 'contexts/AuthContext';
import { useDevice } from 'hooks/useMediaQuery';
import { getDigDeeperContent, getFreeDigDeeperContent, getFreeReaderAlerts } from 'utils/api/alertsAPI';
import { getUserAlertsById } from 'utils/api/usersAPI';
import { MobileReader } from './Mobile';
import { DesktopReader } from './Desktop';

export const Reader = ({ isPublic, companyReader }) => {
    const { id } = useParams();
    const { search } = useLocation();
    const navigate = useNavigate();
    const { state, handleError } = useContext(AuthContext);
    const { isDesktop } = useDevice();
    const [readerAuthChecked, setReaderAuthChecked] = useState(false);
    const [alerts, setAlerts] = useState([]);
    const [articles, setArticles] = useState([]);
    const [fetchingAlerts, setFetchingAlerts] = useState(true);
    const [fetchingAlertContent, setFetchingAlertContent] = useState(false);
    const [alertContent, setAlertContent] = useState(null);
    const [allowManageAlertsInReader, setAllowManageAlertsInReader] = useState(false);
    const [publicEmail, setPublicEmail] = useState('');

    useEffect(() => {
        if (!companyReader) {
            setReaderAuthChecked(true);
            return;
        }

        if (state && !isEmpty(state.readerInfo)) {
            const status = localStorage.getItem('readerInfo');
            if (!status || status === 'undefined') return;
            setReaderAuthChecked(true);
        }
    }, [state]);

    useEffect(() => {
        fetchAlerts('initialLoad');
    }, []);

    useEffect(() => {
        if (!isPublic) {
            state.profile?.organization && setAllowManageAlertsInReader(!!state.profile.organization?.accesses?.myReaderAuthAccess);
        }
    }, [state]);

    const pushEmptyAlertsToEnd = (arr) => {
        let newArr = arr;
        newArr.map((item) => {
            if (item.countNews === 0) {
                let index = newArr.indexOf(item);
                newArr.splice(index, 1);
                newArr.push(item);
            }
        });
        return newArr;
    };

    const fetchAlerts = async () => {
        setFetchingAlerts(true);

        try {
            let userData;

            if (isPublic) {
                if (companyReader && !state.isReaderAuthenticated) return;

                if (companyReader) {
                    userData = await getFreeReaderAlerts(state.readerInfo.id, state.readerInfo.hash, 10);
                } else {
                    const hash = search.slice(3, search.length);
                    userData = await getFreeReaderAlerts(id, hash, 10);
                }
                setAllowManageAlertsInReader(userData.myReaderAuth);
                setPublicEmail(userData.email);
            } else {
                userData = await getUserAlertsById(id);
            }

            const newArr = pushEmptyAlertsToEnd(userData.alerts);
            setAlerts(newArr);
        } catch (err) {
            handleError(err);
        }

        setFetchingAlerts(false);
    };

    const fetchAlertContent = async (alertId, alertHash) => {
        setFetchingAlertContent(true);

        try {
            let alertContent;

            if (isPublic) {
                alertContent = await getFreeDigDeeperContent(alertId, alertHash, 10);
            } else {
                alertContent = await getDigDeeperContent(alertId, 10);
            }

            setAlertContent(alertContent);
            setArticles(alertContent.news);
        } catch (err) {
            handleError(err);
        }

        setFetchingAlertContent(false);
    };

    useEffect(() => {
        alerts?.length && alerts[0]?.id && fetchAlertContent(alerts[0].id, alerts[0].hash);
    }, [alerts]);

    if (companyReader && !state.isReaderAuthenticated) return navigate('/company/reader/signin');
    if (companyReader && !readerAuthChecked) return null;

    if (isDesktop) {
        return (
            <DesktopReader
                id={id}
                isPublic={isPublic}
                alertsData={alerts}
                articlesData={articles}
                alertContent={alertContent}
                publicEmail={publicEmail}
                allowManageAlertsInReader={allowManageAlertsInReader}
                fetchingAlerts={fetchingAlerts}
                fetchingAlertContent={fetchingAlertContent}
                fetchAlerts={fetchAlerts}
                fetchAlertContent={fetchAlertContent}
            />
        );
    } else {
        return (
            <MobileReader
                id={id}
                isPublic={isPublic}
                alertsData={alerts}
                articlesData={articles}
                alertContent={alertContent}
                publicEmail={publicEmail}
                allowManageAlertsInReader={allowManageAlertsInReader}
                fetchingAlerts={fetchingAlerts}
                fetchingAlertContent={fetchingAlertContent}
                fetchAlerts={fetchAlerts}
                fetchAlertContent={fetchAlertContent}
            />
        );
    }
};
