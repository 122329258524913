import React, { useState } from 'react';
import { isEmpty } from 'lodash';
import { Tag } from './Tag';

export const TagCategory = ({ category, handleTagSelect, selectedTag }) => {
    const [tagsOpen, setTagsOpen] = useState(false);

    const toggleTagsOpen = () => setTagsOpen(!tagsOpen);
    const iconClassName = category.type === 'person' ? 'fa-user' : category.type === 'loc' ? 'fa-map-marker' : 'fa-sitemap';
    const rotateIcon = tagsOpen ? 'rotate-90' : '';
    const isActive = tagsOpen ? 'reader-tag-category__title-container--active' : '';

    const sortedCategory = Object.fromEntries(
        Object.entries(category.tags)
            .sort(([, a], [, b]) => (b.length < a.length ? -1 : 1))
            .slice(0, 10)
    );

    return (
        <div className="reader-tag-category">
            <div className={'reader-tag-category__title-container ' + isActive} onClick={toggleTagsOpen}>
                <div className="reader-tag-category__title">
                    <i className={'fa ' + iconClassName + ' reader-tag-category__icon'} />
                    &nbsp;{category.type.charAt(0).toUpperCase() + category.type.slice(1)}
                </div>

                <div className="reader-tag-category__arrow">
                    <i className={'fa fa-angle-left ' + rotateIcon} />
                </div>
            </div>

            {sortedCategory && tagsOpen ? (
                !isEmpty(sortedCategory) ? (
                    <ul className="reader-tag-category__list">
                        {Object.entries(sortedCategory).map(([tag, news], i) => (
                            <Tag
                                key={tag + i}
                                tag={{ name: tag, cnt: news.length, news: news }}
                                handleTagSelect={handleTagSelect}
                                isActive={!selectedTag ? false : tag.toLowerCase() === selectedTag.name.toLowerCase()}
                            />
                        ))}
                    </ul>
                ) : (
                    <ul className="reader-alert__empty-tag">No tags found</ul>
                )
            ) : null}
        </div>
    );
};
