import React, { useContext, useState } from 'react';
import { Table } from 'reactstrap';
import moment from 'moment-timezone';
import { AuthContext } from 'contexts/AuthContext';
import { LoadingSpinner } from 'common/LoadingSpinner';
import { DayPickerInput } from 'common/DayPickerInput';
import { getBrokenSources } from 'utils/api/statisticsAPI';
import { truncateLongWords, handleCaret } from 'utils/helpers';
import { CsvButton } from '../CsvButton';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';

export const BrokenSourcesReport = () => {
    const [isOpenFromDate, setIsOpenFromDate] = useState(false);
    const [isOpenToDate, setIsOpenToDate] = useState(false);
    const { handleError } = useContext(AuthContext);
    const [fetchingCsv, setFetchingCsv] = useState(false);
    const [fromDate, setFromDate] = useState(moment().subtract(1, 'month').format('YYYY-MM-DD'));
    const [toDate, setToDate] = useState(moment().format('YYYY-MM-DD'));
    const [activeSortProperty, setActiveSortProperty] = useState('name');
    const [activeSortDirection, setActiveSortDirection] = useState('desc');

    const { data, isLoading } = useQuery(
        ['getBrokenSources', fromDate, toDate, activeSortProperty, activeSortDirection],
        () => getBrokenSources(fromDate, toDate, activeSortProperty, activeSortDirection, false),
        {
            onError: (err) => handleError(err),
        }
    );

    const downloadFilteredCsv = async () => {
        setFetchingCsv(true);

        try {
            await getBrokenSources(fromDate, toDate, activeSortProperty, activeSortDirection, true);
        } catch (err) {
            handleError(err);
        }

        setFetchingCsv(false);
    };

    const handleSortableHeaderClick = (value) => {
        if (value === activeSortProperty) {
            activeSortDirection === 'asc' ? setActiveSortDirection('desc') : setActiveSortDirection('asc');
        } else {
            setActiveSortProperty(value);
            setActiveSortDirection('asc');
        }
    };

    return (
        <div className="list">
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <div>
                    <div className="list__utility-row">
                        <div className="list__search-container">
                            <DayPickerInput
                                name="brokenSourcesReport-from"
                                isOpen={isOpenFromDate}
                                setIsOpen={setIsOpenFromDate}
                                specificDate={fromDate}
                                setSpecificDate={setFromDate}
                                disabled={{ before: new Date(moment().subtract(60, 'months').format('YYYY-MM-DD')), after: new Date(toDate) }}
                            />

                            <DayPickerInput
                                name="brokenSourcesReport-to"
                                isOpen={isOpenToDate}
                                setIsOpen={setIsOpenToDate}
                                specificDate={toDate}
                                setSpecificDate={setToDate}
                                disabled={{ before: new Date(fromDate), after: new Date() }}
                            />

                            <CsvButton downloadFilteredCsv={downloadFilteredCsv} downloading={fetchingCsv} />
                        </div>
                    </div>

                    {!data?.length ? (
                        <div>No results found</div>
                    ) : (
                        <Table className="responsive-table alert-list-table">
                            <tbody>
                                <tr className="responsive-table header-row">
                                    <th className="responsive-table__sortable-th" onClick={() => handleSortableHeaderClick('name')}>
                                        Source Title {handleCaret(activeSortProperty, activeSortDirection, 'name')}
                                    </th>

                                    <th className="responsive-table__sortable-th" onClick={() => handleSortableHeaderClick('type')}>
                                        Source Type {handleCaret(activeSortProperty, activeSortDirection, 'type')}
                                    </th>

                                    <th>URL</th>
                                    <th>Error Message</th>
                                    <th>Date of Error</th>
                                    <th>Last Updated</th>

                                    <th className="responsive-table__sortable-th" onClick={() => handleSortableHeaderClick('solved')}>
                                        Solved {handleCaret(activeSortProperty, activeSortDirection, 'solved')}
                                    </th>

                                    <th className="responsive-table__sortable-th" onClick={() => handleSortableHeaderClick('users')}>
                                        Assigned Users {handleCaret(activeSortProperty, activeSortDirection, 'users')}
                                    </th>
                                </tr>

                                {data?.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.name}</td>
                                        <td>{item.type}</td>

                                        <td>
                                            <Link className="btn btn-link" target="_blank" to={item.url}>
                                                {truncateLongWords(item.url, 30)}
                                            </Link>
                                        </td>

                                        <td style={{ wordBreak: 'break-all' }}>{item.last_error}</td>
                                        <td>{item.broken_at}</td>
                                        <td>{item.content_updated_at}</td>
                                        <td>{item.solved ? 'Yes' : 'No'}</td>
                                        <td>{item.users}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    )}
                </div>
            )}
        </div>
    );
};
