import { axios } from 'lib/axios';
import { getCsv } from './csvAPI';

export const getUsersWithoutParams = (csv, title) => {
    const url = `/api/v3/user?count=false&page=1&itemsOnPage=3000&sort[0][property]=user.name&sort[0][direction]=ASC&csv=${csv}`;

    if (csv) {
        return getCsv(url, title);
    } else {
        return axios.get(url);
    }
};

export const getUsers = (
    count,
    pageNumber,
    itemsPerPage,
    sortProperty,
    sortDirection = 'asc',
    searchProperty,
    searchValue = '',
    labels = [],
    csv = false,
    title
) => {
    const encodedSearchValue = encodeURIComponent(searchValue);
    let url = `/api/v3/user?count=${count}&page=${pageNumber}&itemsOnPage=${itemsPerPage}&sort[0][property]=${sortProperty}&sort[0][direction]=${sortDirection.toUpperCase()}&csv=${csv}`;

    if (encodedSearchValue.length > 0) {
        url =
            url +
            `&filter[0][property]=user.name&filter[0][operator]=contains&filter[0][value]=${encodedSearchValue}&filter[0][property]=user.email&filter[0][operator]=contains&filter[0][value]=${encodedSearchValue}`;
    }
    labels.length > 0 &&
        labels.map((label, index) => {
            url += `&filter[${index + 1}][property]=user.labels&filter[${index + 1}][operator]=equals&filter[${index + 1}][value]=${encodeURIComponent(label)}`;
        });

    if (csv) {
        return getCsv(url, title);
    } else {
        return axios.get(url);
    }
};

export const addUser = (params) => {
    return axios.post('/api/v3/user', params);
};

export const getUserById = (id) => {
    return axios.get(`/api/v3/user/${id}`);
};

export const updateUserById = (id, params) => {
    return axios.patch(`/api/v3/user/${id}`, params);
};

export const getUserAlertsById = (id) => {
    return axios.get(`/api/v3/user/${id}/alert`);
};

export const updateUserAlertsById = (id, params) => {
    return axios.patch(`/api/v3/user/${id}/alert`, params);
};

export const deleteUser = (id) => {
    return axios.delete(`/api/v3/user/${id}`);
};

export const getUserGroups = () => {
    return axios.get('/api/v3/user_group');
};

export const addUserGroup = (params) => {
    return axios.post('/api/v3/user_group', params);
};

export const editUserGroup = (id, params) => {
    return axios.patch(`/api/v3/user_group/${id}`, params);
};

export const deleteUserGroup = (id) => {
    return axios.delete(`/api/v3/user_group/${id}`);
};

export const getUserLabels = () => {
    return axios.get('/api/v3/user_label');
};

export const addUserLabel = (params) => {
    return axios.post('/api/v3/user_label', params);
};

export const editUserLabel = (id, params) => {
    return axios.patch(`/api/v3/user_label/${id}`, params);
};

export const deleteUserLabel = (id) => {
    return axios.delete(`/api/v3/user_label/${id}`);
};

export const getUserAlertSorting = (id) => {
    return axios.get(`/api/v3/user/${id}/alert`);
};

export const editUserAlertSorting = (id, params) => {
    return axios.patch(`/api/v3/user/${id}/alert`, params);
};

export const getLiveOrLastPreview = (id, type) => {
    return axios.get(`/api/v3/user/${id}/email/${type}`);
};
