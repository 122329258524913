import React from 'react';
import { Label } from 'reactstrap';
import { LoadingSpinner } from 'common/LoadingSpinner';
import { CheckboxInput, RadioInput } from 'common/Input';
import { ScheduleSelector } from './ScheduleSelector';

export const EmailSchedule = ({
    errors,
    timezoneDisplay,
    useCustomSchedule,
    frequency,
    setFrequency,
    firstAlertDays,
    firstAlertHour,
    firstAlertPeriod,
    secondAlertDays,
    secondAlertHour,
    secondAlertPeriod,
    fetchingScheduleData,
    setFirstAlertPeriod,
    setFirstAlertHour,
    setSecondAlertPeriod,
    setSecondAlertHour,
    setFirstAlertDays,
    setSecondAlertDays,
    setUseCustomSchedule,
}) => {
    const handleDaySelect = (position, day) => {
        const newDayArray = position === 'first' ? [...firstAlertDays] : [...secondAlertDays];

        if (newDayArray.indexOf(day) > -1) {
            newDayArray.splice(newDayArray.indexOf(day), 1);
        } else {
            newDayArray.push(day);
        }

        position === 'first' ? setFirstAlertDays(newDayArray) : setSecondAlertDays(newDayArray);
    };

    return (
        <div className="mb-3">
            <h4 className="user-form__header">Schedule</h4>

            <div className="mt-3">
                {fetchingScheduleData ? (
                    <LoadingSpinner />
                ) : (
                    <div>
                        <div className="mt-3">
                            <CheckboxInput
                                name="useCustomSchedule"
                                text="Use custom schedule"
                                checked={useCustomSchedule}
                                onChange={() => setUseCustomSchedule(!useCustomSchedule)}
                            />
                        </div>

                        {useCustomSchedule && (
                            <div className="mt-3">
                                <div className="user-form__frequency">
                                    <Label>Daily Frequency</Label>

                                    <RadioInput
                                        name="once"
                                        text="Once"
                                        checked={frequency === 'once'}
                                        onChange={(e) => setFrequency(e.target.name)}
                                        classNames="daily__schedule form-check form-check-inline"
                                    />

                                    <RadioInput
                                        name="twice"
                                        text="Twice"
                                        checked={frequency === 'twice'}
                                        onChange={(e) => setFrequency(e.target.name)}
                                        isInline
                                    />
                                </div>

                                <ScheduleSelector
                                    position="first"
                                    selectedDays={firstAlertDays}
                                    selectedHour={firstAlertHour}
                                    handleHourSelect={setFirstAlertHour}
                                    selectedPeriod={firstAlertPeriod}
                                    handlePeriodSelect={setFirstAlertPeriod}
                                    handleDaySelect={handleDaySelect}
                                    timezone={timezoneDisplay}
                                    errorObj={errors}
                                />

                                {frequency === 'twice' && (
                                    <ScheduleSelector
                                        position="second"
                                        selectedDays={secondAlertDays}
                                        selectedHour={secondAlertHour}
                                        handleHourSelect={setSecondAlertHour}
                                        selectedPeriod={secondAlertPeriod}
                                        handlePeriodSelect={setSecondAlertPeriod}
                                        handleDaySelect={handleDaySelect}
                                        timezone={timezoneDisplay}
                                        errorObj={errors}
                                    />
                                )}
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};
