import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'reactstrap';
import { useQuery } from 'react-query';
import moment from 'moment-timezone';
import { AuthContext } from 'contexts/AuthContext';
import { LoadingSpinner } from 'common/LoadingSpinner';
import { DayPickerInput } from 'common/DayPickerInput';
import { getPendingSources } from 'utils/api/statisticsAPI';
import { handleCaret, truncateLongWords } from 'utils/helpers';
import { CsvButton } from '../CsvButton';

export const PendingSourcesReport = () => {
    const { handleError } = useContext(AuthContext);
    const [isOpenFromDate, setIsOpenFromDate] = useState(false);
    const [isOpenToDate, setIsOpenToDate] = useState(false);
    const [fetchingCsv, setFetchingCsv] = useState(false);
    const [fromDate, setFromDate] = useState(moment().subtract(1, 'month').format('YYYY-MM-DD'));
    const [toDate, setToDate] = useState(moment().format('YYYY-MM-DD'));
    const [activeSortProperty, setActiveSortProperty] = useState('content_updated_at');
    const [activeSortDirection, setActiveSortDirection] = useState('desc');

    const { data, isLoading } = useQuery(
        ['getPendingSources', fromDate, toDate, activeSortProperty, activeSortDirection],
        () => getPendingSources(fromDate, toDate, activeSortProperty, activeSortDirection, false),
        {
            onError: (err) => handleError(err),
        }
    );

    const downloadFilteredCsv = async () => {
        setFetchingCsv(true);

        try {
            await getPendingSources(fromDate, toDate, activeSortProperty, activeSortDirection, true);
        } catch (err) {
            handleError(err);
        }

        setFetchingCsv(false);
    };

    const handleSortableHeaderClick = (value) => {
        if (value === activeSortProperty) {
            activeSortDirection === 'asc' ? setActiveSortDirection('desc') : setActiveSortDirection('asc');
        } else {
            setActiveSortProperty(value);
            setActiveSortDirection('asc');
        }
    };

    return (
        <div className="list">
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <div>
                    <div className="list__utility-row">
                        <div className="list__search-container">
                            <DayPickerInput
                                name="pendingSourcesReport-from"
                                isOpen={isOpenFromDate}
                                setIsOpen={setIsOpenFromDate}
                                specificDate={fromDate}
                                setSpecificDate={setFromDate}
                                disabled={{ before: new Date(moment().subtract(60, 'months').format('YYYY-MM-DD')), after: new Date(toDate) }}
                            />

                            <DayPickerInput
                                name="pendingSourcesReport-to"
                                isOpen={isOpenToDate}
                                setIsOpen={setIsOpenToDate}
                                specificDate={toDate}
                                setSpecificDate={setToDate}
                                disabled={{ before: new Date(fromDate), after: new Date() }}
                            />

                            <CsvButton downloadFilteredCsv={downloadFilteredCsv} downloading={fetchingCsv} />
                        </div>
                    </div>

                    {!data?.length ? (
                        <div>No results found</div>
                    ) : (
                        <Table className="responsive-table alert-list-table">
                            <tbody>
                                <tr className="responsive-table header-row">
                                    <th className="responsive-table__sortable-th" onClick={() => handleSortableHeaderClick('name')}>
                                        Source Title {handleCaret(activeSortProperty, activeSortDirection, 'name')}
                                    </th>

                                    <th className="responsive-table__sortable-th" onClick={() => handleSortableHeaderClick('type')}>
                                        Source Type {handleCaret(activeSortProperty, activeSortDirection, 'type')}
                                    </th>

                                    <th>URL</th>

                                    <th className="responsive-table__sortable-th" onClick={() => handleSortableHeaderClick('created_at')}>
                                        Date Created {handleCaret(activeSortProperty, activeSortDirection, 'created_at')}
                                    </th>

                                    <th className="responsive-table__sortable-th" onClick={() => handleSortableHeaderClick('content_updated_at')}>
                                        Last Updated Date {handleCaret(activeSortProperty, activeSortDirection, 'content_updated_at')}
                                    </th>

                                    <th className="responsive-table__sortable-th" onClick={() => handleSortableHeaderClick('suspended')}>
                                        Is suspended {handleCaret(activeSortProperty, activeSortDirection, 'suspended')}
                                    </th>

                                    <th className="responsive-table__sortable-th" onClick={() => handleSortableHeaderClick('users')}>
                                        Assigned Users {handleCaret(activeSortProperty, activeSortDirection, 'users')}
                                    </th>
                                </tr>

                                {data?.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.name}</td>
                                        <td>{item.type}</td>
                                        <td>
                                            <Link className="btn btn-link" target="_blank" to={item.url}>
                                                {truncateLongWords(item.url, 30)}
                                            </Link>
                                        </td>
                                        <td>{item.created_at}</td>
                                        <td>{item.content_updated_at}</td>
                                        <td>{item.suspended ? 'Yes' : 'No'}</td>
                                        <td>{item.users}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    )}
                </div>
            )}
        </div>
    );
};
