import React from 'react';
import { Modal, ModalFooter, Button } from 'reactstrap';
import { SpinnerButton } from 'common/SpinnerButton';
import { ModalBodyWithClose } from 'common/ModalBodyWithClose';

export const DeleteNewsletterModal = ({ isOpen, toggle, onSubmit, newsletter, deleting }) => (
    <Modal isOpen={isOpen} toggle={toggle}>
        {!newsletter ? null : (
            <div>
                <ModalBodyWithClose toggle={toggle}>
                    <span>Are you sure you want to delete {newsletter.name}?</span>
                </ModalBodyWithClose>

                <ModalFooter>
                    <Button color="secondary" onClick={toggle}>
                        Cancel
                    </Button>

                    <SpinnerButton type="modal" color="danger" onClick={() => onSubmit(newsletter.id, toggle)} submitting={deleting} title="Delete" />
                </ModalFooter>
            </div>
        )}
    </Modal>
);
