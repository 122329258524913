import React, { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import { useQuery } from 'react-query';
import { getStatistics } from 'utils/api/statisticsAPI';
import { ClicksPerDay } from './ClicksPerDay';
import { TopStats } from './TopStats';

const topStatsData = [
    { type: 'articles', title: 'Articles' },
    { type: 'publishers', title: 'Publishers' },
    { type: 'alerts', title: 'Sources/Searches' },
];

export const StatisticsTab = ({ type, handleError }) => {
    const [labelContainerHeight, setLabelContainerHeight] = useState(null);
    const [topStatsListLength, setTopStatsListLength] = useState({ articles: null, publishers: null, alerts: null });

    const { data, isLoading } = useQuery(['statistics', type], () => getStatistics({ limit: 30, days: 30, isNewsletter: type === 'newsletter' }), {
        onError: (err) => {
            handleError(err);
        },
    });

    const isNewsletter = type === 'newsletter';
    const clicksPerDayData = (isNewsletter ? data?.newsletter_clicks_per_day : data?.clicks_per_day) || [];

    const getTopStatsData = (index) => {
        if (data) {
            if (isNewsletter) {
                if (index === 0) return data?.newsletter_top_articles;
                if (index === 1) return data?.newsletter_top_publishers;
                if (index === 2) return data?.newsletter_top_alerts;
            } else {
                if (index === 0) return data?.top_articles;
                if (index === 1) return data?.top_publishers;
                if (index === 2) return data?.top_alerts;
            }
        } else return null;
    };

    useEffect(() => {
        const articlesLength = topStatsListLength?.articles;
        const alertsLength = topStatsListLength?.alerts;
        const publishersLength = topStatsListLength?.publishers;

        if (Number.isInteger(articlesLength) && Number.isInteger(alertsLength) && Number.isInteger(publishersLength)) {
            const largest = Math.max(articlesLength, alertsLength, publishersLength);
            const containerHeight = largest > 0 ? largest * 21 + 20 : 41;

            setLabelContainerHeight(containerHeight);
        }
    }, [topStatsListLength]);

    return (
        <div>
            <Row>
                <Col xs={12}>
                    <ClicksPerDay data={clicksPerDayData} isLoading={isLoading} />
                </Col>
            </Row>
            <Row>
                {topStatsData.map((item, index) => (
                    <Col key={index} xl={4} lg={6} md={12} xs={12}>
                        <TopStats
                            title={item.title}
                            topStatsType={item.type}
                            labelContainerHeight={labelContainerHeight}
                            setTopStatsListLength={setTopStatsListLength}
                            data={getTopStatsData(index)}
                            isLoading={isLoading}
                        />
                    </Col>
                ))}
            </Row>
        </div>
    );
};
