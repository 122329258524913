import React, { useState } from 'react';
import { Input } from 'reactstrap';
import { SmallSpinnerButtonSquare } from 'common/SpinnerButton/SmallSpinnerButtonSquare';
import { addNewsletterSection } from 'utils/api/newslettersAPI';

export const AddSectionForm = ({ addFlashMessage, newsletterId, refreshNewsletter }) => {
    const [newSectionTitle, setNewSectionTitle] = useState('');
    const [submitting, setSubmitting] = useState(false);

    const createNewsletterSection = async () => {
        setSubmitting(true);

        try {
            await addNewsletterSection(newsletterId, { name: newSectionTitle });
            refreshNewsletter();
            setNewSectionTitle('');
        } catch (err) {
            if (err.message === 'You can add up to 10 Sections') {
                addFlashMessage('danger', 'Maximum limit of 10 sections has been reached');
            } else {
                addFlashMessage('danger', 'Unable to add section');
            }
        }

        setSubmitting(false);
    };

    return (
        <div className="d-flex">
            <Input
                value={newSectionTitle}
                onChange={(e) => setNewSectionTitle(e.target.value)}
                placeholder="New section title"
                autoComplete="off"
                className="mr-2"
            />

            <SmallSpinnerButtonSquare
                color="primary"
                title={<i className="fa fa-plus" />}
                submitting={submitting}
                disabled={newSectionTitle.length < 1}
                onClick={createNewsletterSection}
                style={{ minWidth: '39px', minHeight: '35px' }}
            />
        </div>
    );
};
