import React, { useState, useEffect, useRef, useMemo } from 'react';
import { AutoSizer, List, CellMeasurer, CellMeasurerCache } from 'react-virtualized';
import Toggle from 'react-toggle';
import { cloneDeep } from 'lodash';
import { LoadingSpinner } from 'common/LoadingSpinner';
import { SearchInput } from 'common/SearchInput';
import { filterByValue } from 'utils/helpers';
import { addArticleToSection, getFreeCuratedArticlesByDate, removeArticleFromSection } from 'utils/api/newslettersAPI';
import { Article } from './Article';
import { AssignedArticle } from './AssignedArticle';

const cache = new CellMeasurerCache({ fixedWidth: true });

export const AddArticles = ({ newsletterData, section, setNewsletterForDom, handleError }) => {
    const [unassignedSearchValue, setUnassignedSearchValue] = useState('');
    const [assignedSearchValue, setAssignedSearchValue] = useState('');
    const [filteredUnassignedList, setFilteredUnassignedList] = useState([]);
    const [filteredAssignedList, setFilteredAssignedList] = useState([]);
    const [retrievalDate, setRetrievalDate] = useState(5);
    const [fetchingArticles, setFetchingArticles] = useState(true);
    const [articles, setArticles] = useState([]);
    const [includeAbstracts, setIncludeAbstracts] = useState(true);
    const [tempSliderValue, setTempSliderValue] = useState(1);
    const [updatingList, setUpdatingList] = useState(false);
    const listRef = useRef(null);

    const articlesDates = useMemo(() => {
        const max = +retrievalDate;
        const dates = {};

        for (let i = 1; ; ) {
            if (max < i) {
                break;
            } else if (i !== 1 && max === i) {
                dates[i - 5] = max;
                break;
            } else {
                dates[i] = max < i + 4 ? max : i + 4;
                i += 5;
            }
        }

        return Object.entries(dates).map(([key, value]) => ({ to: +key === 1 ? 'now' : +key, from: value }));
    }, [retrievalDate]);

    const getArticlesByDays = async (updating) => {
        if (updating) {
            setUpdatingList(true);
        } else if (!fetchingArticles) {
            setFetchingArticles(true);
        }

        try {
            const articles = await getFreeCuratedArticlesByDate(newsletterData.id, articlesDates, 'desc');
            setArticles(articles);
            applyFilters('unassigned', articles);
        } catch (err) {
            handleError(err);
        }

        updating ? setUpdatingList(false) : setFetchingArticles(false);
    };

    const applyFilters = (list, arr) => {
        if (list === 'unassigned') {
            let newArr = filterByValue(arr, 'title', unassignedSearchValue);
            setFilteredUnassignedList(newArr);
        } else {
            let newArr = filterAssignedByValue(arr, 'title', assignedSearchValue);
            setFilteredAssignedList(newArr);
        }

        cache.clearAll();
        listRef && listRef.current && listRef.current.recomputeRowHeights();
    };

    const filterAssignedByValue = (arr, propertyToFilterBy, searchValue) => {
        let newArr = [...arr];

        newArr = newArr.filter((item) => {
            if (item.news[propertyToFilterBy]) {
                return item.news[propertyToFilterBy].toLowerCase().includes(searchValue.toLowerCase());
            }
        });

        return newArr;
    };

    const toggleIncludeAbstracts = () => setIncludeAbstracts(!includeAbstracts);
    const handleSliderChange = (e) => setTempSliderValue(e);
    const handleSliderConfirm = () => setRetrievalDate(tempSliderValue);

    const handleAddArticle = async (articleId) => {
        try {
            const { sections } = newsletterData;

            const nextColumn = sections.find((obj) => obj.id === section.id);
            const nextColumnIndex = sections.indexOf(nextColumn);
            const newStateUnassignedNews = cloneDeep(articles);
            const selectedArticle = newStateUnassignedNews.find((article) => article.id === articleId);

            if (!selectedArticle) return;

            const nextColumnNews = Array.from(nextColumn.newsletterSectionNews);
            const newSortOrder = nextColumnNews.length + 1;
            const { abstract, created_at, id, name, resource, sec_id, title, url } = selectedArticle;
            const newArticleObj = {
                comment: null,
                news: { abstract, createdAt: created_at, id, name, resource, sec_id, title, url },
                sortOrder: newSortOrder,
            };
            nextColumnNews.push(newArticleObj);

            const newNextColumn = { ...nextColumn, newsletterSectionNews: nextColumnNews };
            newStateUnassignedNews.splice(newStateUnassignedNews.indexOf(selectedArticle), 1);
            const newStateSections = cloneDeep(sections);
            newStateSections[nextColumnIndex] = newNextColumn;
            const newNewsletter = { ...newsletterData, sections: newStateSections };
            setNewsletterForDom(newNewsletter);
            setArticles(newStateUnassignedNews);

            await addArticleToSection(newsletterData.id, section.id, { news: [{ id: articleId, sort: 1 }] });
        } catch (err) {
            handleError(err);
        }
    };

    const handleRemoveArticle = async (articleId) => {
        try {
            const { sections } = newsletterData;

            const selectedSection = sections.find((obj) => obj.id === section.id);
            const selectedSectionIndex = sections.indexOf(selectedSection);

            const newStateUnassignedNews = cloneDeep(articles);
            const newStateSections = cloneDeep(sections);
            const selectedArticle = selectedSection.newsletterSectionNews.find((article) => article.news.id === articleId);

            if (!selectedArticle) return;

            const selectedSectionNews = Array.from(selectedSection.newsletterSectionNews);
            selectedSectionNews.splice(selectedSectionNews.indexOf(selectedArticle), 1);
            const newSelectedSection = {
                ...selectedSection,
                newsletterSectionNews: selectedSectionNews,
            };
            const { createdAt, id, name, title } = selectedArticle.news;
            const newArticleObj = { abstract: null, createdAt, id, resource: selectedArticle.news.newsResource?.domain, sec_id: null, name, title };

            newStateUnassignedNews.unshift(newArticleObj);
            newStateSections[selectedSectionIndex] = newSelectedSection;
            const newNewsletter = {
                ...newsletterData,
                sections: newStateSections,
            };
            setNewsletterForDom(newNewsletter);
            setArticles(newStateUnassignedNews);

            await removeArticleFromSection(newsletterData.id, section.id, articleId);
        } catch (err) {
            handleError(err);
        }
    };

    const rowRenderer = ({ index, key, style, parent }) => {
        if (filteredUnassignedList[index]) {
            return (
                <CellMeasurer key={key} cache={cache} parent={parent} columnIndex={0} rowIndex={index}>
                    <div className="mobile-article-row-item" style={{ ...style }}>
                        <Article article={filteredUnassignedList[index]} includeAbstracts={includeAbstracts} handleAddArticle={handleAddArticle} />
                    </div>
                </CellMeasurer>
            );
        }
    };

    useEffect(() => {
        getArticlesByDays();
        setTempSliderValue(5);
        cache.clearAll();
    }, []);

    useEffect(() => {
        applyFilters('unassigned', articles);
    }, [unassignedSearchValue]);

    useEffect(() => {
        applyFilters('assigned', section.newsletterSectionNews);
    }, [assignedSearchValue]);

    useEffect(() => {
        applyFilters('unassigned', articles);
    }, [newsletterData, section]);

    useEffect(() => {
        applyFilters('assigned', section.newsletterSectionNews);
    }, [section.newsletterSectionNews]);

    useEffect(() => {
        cache.clearAll();
        listRef && listRef.current && listRef.current.recomputeRowHeights();
    }, [includeAbstracts]);

    useEffect(() => {
        articles.length > 0 && getArticlesByDays(true);
    }, [retrievalDate]);

    return (
        <div className="mobile-newsletter-add-alerts">
            <h5>{section.name}</h5>

            {!fetchingArticles && articles.length && (
                <div className="mobile-newsletter-toggle-container">
                    <span id="suspend-label">View Abstracts</span>
                    <label>
                        <Toggle checked={includeAbstracts} onChange={toggleIncludeAbstracts} icons={false} />
                    </label>
                </div>
            )}

            <div className="d-flex align-items-center justify-content-between mt-3 mb-1" style={{ overflow: 'hidden' }}>
                <h6 className="m-0">Articles List</h6>

                {!fetchingArticles && articles.length && (
                    <div className="mobile-newsletter-slider-container">
                        <div className="mobile-newsletter-slider-container__label">
                            {Number(tempSliderValue) === 1 ? tempSliderValue + ' day' : tempSliderValue + ' days'}
                        </div>

                        <input
                            className="days-slider__input"
                            disabled={updatingList}
                            style={{ width: '180px', margin: '0 10px' }}
                            min="1"
                            max="30"
                            onMouseUp={(e) => handleSliderConfirm(e)}
                            value={tempSliderValue}
                            onChange={(e) => handleSliderChange(e)}
                            type="range"
                        />
                    </div>
                )}
            </div>

            {fetchingArticles ? (
                <div className="pt-1">
                    <LoadingSpinner text={'Fetching articles'} />
                </div>
            ) : (
                <>
                    <div className="mb-2">
                        <SearchInput
                            onClear={() => setUnassignedSearchValue('')}
                            onChange={setUnassignedSearchValue}
                            placeholder="Search by article name"
                            value={unassignedSearchValue}
                        />
                    </div>

                    <div className="mobile-newsletter-add-alerts__list mb-4" style={{ height: '400px' }}>
                        {articles.length ? (
                            updatingList ? (
                                <div className="p-3">
                                    <LoadingSpinner text={'Refreshing articles'} />
                                </div>
                            ) : filteredUnassignedList.length > 0 ? (
                                <div style={{ height: '100%' }}>
                                    <AutoSizer>
                                        {({ height, width }) => (
                                            <List
                                                ref={listRef}
                                                width={width}
                                                height={height}
                                                deferredMeasurementCache={cache}
                                                rowHeight={cache.rowHeight}
                                                rowCount={filteredUnassignedList.length}
                                                rowRenderer={rowRenderer}
                                            />
                                        )}
                                    </AutoSizer>
                                </div>
                            ) : (
                                <div style={{ padding: '.5rem' }}>No results found</div>
                            )
                        ) : (
                            <div style={{ padding: '.5rem' }}>No articles found</div>
                        )}
                    </div>
                </>
            )}

            <h6 className="mt-3">Assigned Articles</h6>

            <div className="mb-2">
                <SearchInput
                    onClear={() => setAssignedSearchValue('')}
                    onChange={setAssignedSearchValue}
                    placeholder="Search by article name"
                    value={assignedSearchValue}
                />
            </div>

            <div className="mobile-newsletter-add-alerts__list mb-4">
                {section.newsletterSectionNews.length > 0 ? (
                    filteredAssignedList.length > 0 ? (
                        filteredAssignedList.map((article, i) => (
                            <AssignedArticle
                                key={article.news.id + i}
                                article={article}
                                includeAbstracts={includeAbstracts}
                                handleRemoveArticle={handleRemoveArticle}
                                withControls
                            />
                        ))
                    ) : (
                        <div style={{ padding: '.5rem' }}>No results found</div>
                    )
                ) : (
                    <div style={{ padding: '.5rem' }}>No assigned articles</div>
                )}
            </div>
        </div>
    );
};
