import React from 'react';
import { Button } from 'reactstrap';

export const EmptyList = ({ toggleCreateNewsletter }) => (
    <>
        <p>Create your first newsletter!</p>

        <Button onClick={toggleCreateNewsletter} color="primary">
            Create Newsletter
        </Button>
    </>
);
