import React, { useState, useContext } from 'react';
import { Modal } from 'reactstrap';
import { FlashMessageContext } from 'contexts/FlashMessageContext';
import { AuthContext } from 'contexts/AuthContext';
import { addNewsletter } from 'utils/api/newslettersAPI';
import { NewsletterForm } from './NewsletterForm';
import './style.scss';

export const CreateNewsletterModal = ({ isOpen, toggle, refreshNewsletters }) => {
    const { addFlashMessage } = useContext(FlashMessageContext);
    const { handleError } = useContext(AuthContext);
    const [submitting, setSubmitting] = useState(false);
    const [errors, setErrors] = useState(false);

    const handleCreateNewsletter = async (params) => {
        setSubmitting(true);

        try {
            setErrors(null);
            await addNewsletter(params);
            refreshNewsletters();
            addFlashMessage('success', 'Newsletter successfully created');
            toggle();
        } catch (err) {
            if (err?.message?.includes('You can add up to')) {
                addFlashMessage('danger', 'Your maximum newsletter limit has been reached.');
                return;
            }

            handleError(err, setErrors);
        }

        setSubmitting(false);
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle} className="create-newsletter-modal modal-lg">
            <NewsletterForm
                formTitle="Create Newsletter"
                submitting={submitting}
                onSubmit={handleCreateNewsletter}
                toggle={toggle}
                submitButtonTitle="Create Newsletter"
                errors={errors}
                setErrors={setErrors}
            />
        </Modal>
    );
};
