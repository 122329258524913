import React from 'react';
import { Modal, ModalFooter, Button } from 'reactstrap';
import { SpinnerButton } from 'common/SpinnerButton';
import { ModalBodyWithClose } from 'common/ModalBodyWithClose';

export const ConfirmSuspend = ({ isOpen, toggle, onSubmit, submitting }) => (
    <Modal isOpen={isOpen} toggle={toggle} className="confirm-suspend-modal">
        <ModalBodyWithClose toggle={toggle}>
            <span>Are you sure you want to suspend your account?</span>
        </ModalBodyWithClose>

        <ModalFooter>
            <Button color="secondary" onClick={toggle}>
                Cancel
            </Button>

            <SpinnerButton type={'modal'} color="danger" onClick={() => onSubmit()} submitting={submitting} title="Delete" />
        </ModalFooter>
    </Modal>
);
