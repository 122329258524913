import React from 'react';
import { LoadingSpinner } from 'common/LoadingSpinner';
import { ArticleListItem } from './ArticleListItem';

export const ArticleList = ({ articles, selectedAlert, handleArticleSelect, fetchingArticles, selectedTag, setActiveView }) => (
    <div className="mobile-reader-list mobile-reader-article-list">
        <div className="mobile-reader-list__header mobile-reader-article-list__header">
            Showing Articles for "{selectedAlert.name}"
            {selectedTag && (
                <div className="mobile-reader-list__active-tag">
                    Active Filter: <strong>{selectedTag.name}</strong>
                </div>
            )}
        </div>

        {!fetchingArticles && (
            <div className="mobile-reader-article-list__subheader">
                <div>
                    {articles.length} {articles.length === 1 ? 'Result' : 'Results'}
                </div>

                <div onClick={() => setActiveView('filters-select-category')}>
                    Filters
                    <i className="fa fa-angle-right" />
                </div>
            </div>
        )}

        {fetchingArticles ? (
            <div className="mobile-reader-article mobile-reader-article--loading">
                <LoadingSpinner text="Fetching articles" />
            </div>
        ) : (
            <div className="mobile-reader-list__body mobile-reader-article-list__body">
                {articles.length < 1 ? (
                    <div className="mobile-reader-article">No articles found</div>
                ) : (
                    articles.map((article) => (
                        <ArticleListItem
                            article={article}
                            key={article.id}
                            isSelected={article.id === selectedAlert.id}
                            handleArticleSelect={handleArticleSelect}
                        />
                    ))
                )}
            </div>
        )}
    </div>
);
