import { axios } from 'lib/axios';
import FileDownload from 'js-file-download';

export const getCsv = (url, title, pdf) => {
    return axios
        .get(url, { responseType: 'blob' })
        .then((res) => {
            const headerLine = res.headers['content-disposition'].split('=');

            if (headerLine?.length > 2) {
                FileDownload(res.data, `${title}.${pdf ? 'pdf' : 'csv'}`);
            } else {
                FileDownload(res.data, `${headerLine[1]}`);
            }
        })
        .catch((err) => {
            if (err.response) {
                throw err.response;
            } else {
                throw {
                    code: 'GATEWAY_ERROR',
                    errors: {},
                    message: 'Gateway error',
                };
            }
        });
};
