import React, { useContext } from 'react';
import { AuthContext } from 'contexts/AuthContext';

export const MobileReaderNavbar = ({ activeView, navItems, setActiveView }) => {
    const { logout } = useContext(AuthContext);

    const handleLogout = () => logout();

    return (
        <div className="mobile-reader__navbar">
            {navItems.map((navItem) => (
                <div
                    key={navItem.value}
                    className={'mobile-reader__nav-item ' + (activeView === navItem.value ? 'mobile-reader__nav-item--active' : '')}
                    onClick={() => setActiveView(navItem.value)}>
                    <i className={'mobile-reader__nav-item-icon fa ' + navItem.icon} />
                    <div className="mobile-reader__nav-item-title">{navItem.name}</div>
                </div>
            ))}

            <div key="logout" className={'mobile-reader__nav-item'} onClick={handleLogout}>
                <i className={'mobile-reader__nav-item-icon fa fa-lock'} />
                <div className="mobile-reader__nav-item-title">Logout</div>
            </div>
        </div>
    );
};
