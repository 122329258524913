import React, { useState, useRef, useEffect } from 'react';
import { Button } from 'reactstrap';
import { CSVLink } from 'react-csv';
import moment from 'moment-timezone';
import { LoadingSpinnerSmall } from 'common/LoadingSpinner/LoadingSpinnerSmall';

export const ListItem = ({ compSet, handleCompSetSelect, fetchCompSetStatistics, setCompSetToDelete, setCompSetToEdit, activeSet, handleError }) => {
    const [downloadingCSV, setDownloadingCSV] = useState(false);
    const [csvDownloadData, setCsvDownloadData] = useState(false);
    const csvLinkRef = useRef();

    const getCSVData = async () => {
        setDownloadingCSV(true);

        try {
            const data = await fetchCompSetStatistics(compSet?.id, true);
            const csvData = [['DATE', ...data.map((item) => item.name)]];
            csvData.push(['', ...data.map(() => 'Articles')]);

            data.forEach((item) =>
                item.statistics_month.forEach((item, i) =>
                    csvData.push([`As of ${item.date}`, ...data.map((value) => value.statistics_month[i].cnt)])
                )
            );

            csvData.push(['', ...data.map(() => '')]);

            data.forEach((item) =>
                item.statistics.newsPerDay.forEach((item, i) => csvData.push([item.day, ...data.map((value) => value.statistics.newsPerDay[i].cnt)]))
            );
            setCsvDownloadData(csvData);
        } catch (err) {
            handleError(err);
        }

        setDownloadingCSV(false);
    };

    useEffect(() => {
        if (csvDownloadData) {
            setTimeout(() => {
                csvLinkRef.current.link.click();
                setCsvDownloadData(false);
            });
        }
    }, [csvDownloadData]);

    return (
        <tr className="alert-list-item">
            <td data-label="Name">{compSet.name}</td>
            <td data-label="Alerts">{compSet.count_alerts}</td>

            <td data-label="Compare">
                <Button
                    color={activeSet && activeSet.id === compSet.id ? 'primary' : 'light'}
                    onClick={() => handleCompSetSelect(compSet)}
                    disabled={!compSet.count_alerts}>
                    <i className="fa fa-bar-chart" />
                </Button>
            </td>

            <td data-label="Download">
                {downloadingCSV ? (
                    <Button color="light" style={{ height: '35px' }}>
                        <LoadingSpinnerSmall />
                    </Button>
                ) : (
                    <Button color="light" onClick={getCSVData}>
                        <i className="fa fa-download" />
                    </Button>
                )}

                {csvDownloadData ? (
                    <CSVLink
                        ref={csvLinkRef}
                        data={csvDownloadData}
                        filename={`ozmosys_cs_${compSet.name.replace(/ /g, '_')}_` + moment().utc().format('MMDDYYYY') + '.csv'}
                    />
                ) : null}
            </td>

            <td data-label="Edit/Delete">
                <div className="responsive-table__edit-block">
                    <Button className="edit-delete-button" color="light" onClick={() => setCompSetToEdit(compSet)}>
                        <i className="fa fa-edit fa-lg" />
                    </Button>

                    <Button className="delete-button" color="light" onClick={() => setCompSetToDelete(compSet)}>
                        <i className="fa fa-trash fa-lg" />
                    </Button>
                </div>
            </td>
        </tr>
    );
};
