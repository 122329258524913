import React, { useState, useEffect } from 'react';
import { CheckboxInput } from 'common/Input';
import { setAllowNotifications } from 'utils/api/profileAPI';
import { useDevice } from 'hooks/useMediaQuery';

export const AdminListItem = ({ admin, refreshProfile, handleError }) => {
    const { isDesktop } = useDevice();
    const [allowNotificationsSwitch, setAllowNotificationsSwitch] = useState(false);
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        setAllowNotificationsSwitch(admin.allowNotifications);
    }, [admin.allowNotifications]);

    const handleNotificationsChange = async (admin) => {
        setSaving(true);

        try {
            if (allowNotificationsSwitch) {
                setAllowNotificationsSwitch(false);
                await setAllowNotifications(admin.id, { allowNotifications: false });
            } else {
                setAllowNotificationsSwitch(true);
                await setAllowNotifications(admin.id, { allowNotifications: true });
            }

            await refreshProfile();
        } catch (err) {
            setAllowNotificationsSwitch((prevState) => !prevState);
            handleError(err);
        }

        setSaving(false);
    };

    return (
        <tr key={admin.id}>
            <td data-label="Name">{admin.name}</td>
            <td data-label="Email">{admin.email}</td>

            <td data-label="Allow Notifications">
                <CheckboxInput checked={allowNotificationsSwitch} onChange={() => handleNotificationsChange(admin)} />
                &nbsp;{saving && isDesktop && <i className="fa fa-spinner fa-spin" />}
            </td>
        </tr>
    );
};
