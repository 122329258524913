import React, { useContext } from 'react';
import { useQuery } from 'react-query';
import { AuthContext } from 'contexts/AuthContext';
import { FlashMessageContext } from 'contexts/FlashMessageContext';
import { FilterContext } from 'contexts/FilterContext';
import { SingleUserBlock } from 'common/SingleUserBlock';
import { LoadingSpinner } from 'common/LoadingSpinner';
import { getUserGroups } from 'utils/api/usersAPI';
import { UserGroupList } from './UserGroupList';
import { EmptyList } from './EmptyList';

export const UserGroups = () => {
    const { state, handleError } = useContext(AuthContext);
    const { addFlashMessage } = useContext(FlashMessageContext);
    const { userGroupFilters, setFiltersObject } = useContext(FilterContext);

    const { data, isLoading, refetch } = useQuery(['getUserGroups', state.isSingleUser], () => (!state.isSingleUser ? getUserGroups() : null), {
        onError: (err) => handleError(err),
    });

    return (
        <>
            {state.isSingleUser ? (
                <SingleUserBlock itemType="User groups" />
            ) : isLoading ? (
                <LoadingSpinner />
            ) : data?.length > 0 ? (
                <UserGroupList
                    userGroups={data}
                    fetchingData={isLoading}
                    addFlashMessage={addFlashMessage}
                    refreshUserGroups={refetch}
                    handleError={handleError}
                    userGroupFilters={userGroupFilters}
                    setFiltersObject={setFiltersObject}
                />
            ) : (
                <EmptyList addFlashMessage={addFlashMessage} refreshUserGroups={refetch} handleError={handleError} />
            )}
        </>
    );
};
