import React, { useState, useEffect } from 'react';
import { InputGroup, Button } from 'reactstrap';
import { LabelsListItem } from './LabelsListItem';
import { LoadingSpinnerSmall } from 'common/LoadingSpinner/LoadingSpinnerSmall';
import { SearchInput } from 'common/SearchInput';
import '../style.scss';

export const LabelsDropdown = ({ selectedLabels, extraPadding, labels, addLabel, creatingLabel, addToSelected, removeFromSelected }) => {
    const [filteredLabels, setFilteredLabels] = useState([]);
    const [filterSearch, setFilterSearch] = useState('');

    useEffect(() => {
        setFilteredLabels(labels);
    }, []);

    useEffect(() => {
        setFilteredLabels(labels);

        if (filterSearch.length > 1) {
            resetSearch();
        }
    }, [labels]);

    const resetSearch = () => {
        setFilterSearch('');
        setFilteredLabels(labels);
    };

    const handleSearchChange = (text) => {
        const filteredLabels = labels.filter((label) => {
            return label.name.toLowerCase().indexOf(text.toLowerCase()) > -1;
        });
        setFilteredLabels(filteredLabels);
        setFilterSearch(text);
    };

    const handleCreateLabel = () => {
        if (filterSearch.length > 0) {
            addLabel(filterSearch);
        }
    };

    const sortAlphabetically = (arr) => {
        return arr.sort((a, b) => a.name.localeCompare(b.name));
    };

    return (
        <div className={'external-labels-dropdown ' + (extraPadding ? 'external-labels-dropdown--extra-padding' : '')}>
            <div>
                <div className="external-labels-dropdown__form">
                    <InputGroup id="company-header" className="external-labels-dropdown__searchbar-container m-0">
                        <SearchInput
                            placeholder="Find or create labels"
                            type="text"
                            name="filterSearch"
                            value={filterSearch}
                            autoComplete="off"
                            onChange={handleSearchChange}
                            onClear={() => setFilterSearch('')}
                        />
                        <div style={{ cursor: 'pointer' }} className="alert-list-searchbar-icon-container">
                            {filterSearch.length > 0 ? (
                                <i onClick={resetSearch} style={{ marginLeft: '-17px' }} className="fa fa-times-circle" />
                            ) : (
                                <i style={{ marginLeft: '-17px' }} className="fa fa-search" />
                            )}
                        </div>
                    </InputGroup>
                    <Button
                        color="primary"
                        disabled={filterSearch.length < 1}
                        className="ml-2 external-labels-dropdown__btn"
                        onClick={handleCreateLabel}>
                        {creatingLabel ? <LoadingSpinnerSmall /> : 'Create'}
                    </Button>
                </div>
                <div className="external-labels-dropdown__list">
                    {labels.length > 0 ? (
                        <div>
                            {selectedLabels &&
                                sortAlphabetically(selectedLabels).map((label) => {
                                    return (
                                        <LabelsListItem
                                            key={label.id}
                                            label={label}
                                            active={true}
                                            addToSelected={addToSelected}
                                            removeFromSelected={removeFromSelected}
                                        />
                                    );
                                })}
                            {filteredLabels.length > 0 ? (
                                <div>
                                    {sortAlphabetically(filteredLabels).map((label) => {
                                        return (
                                            selectedLabels.map((sLabel) => sLabel.id).indexOf(label.id) === -1 && (
                                                <LabelsListItem
                                                    key={label.id}
                                                    label={label}
                                                    selectedLabels={selectedLabels}
                                                    addToSelected={addToSelected}
                                                    removeFromSelected={removeFromSelected}
                                                />
                                            )
                                        );
                                    })}
                                </div>
                            ) : (
                                <div className="mt-2">No results found.</div>
                            )}
                        </div>
                    ) : (
                        <div className="mt-2">No labels have been created yet.</div>
                    )}
                </div>
            </div>
        </div>
    );
};
