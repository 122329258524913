import React, { useState, useContext } from 'react';
import { Modal, ModalFooter, Button, Input, FormGroup } from 'reactstrap';
import Dropzone from 'react-dropzone';
import { FlashMessageContext } from 'contexts/FlashMessageContext';
import { AuthContext } from 'contexts/AuthContext';
import { SpinnerButton } from 'common/SpinnerButton';
import { CharacterCount } from 'common/CharacterCount';
import { InputWithError } from 'common/InputWithError';
import { ModalBodyWithClose } from 'common/ModalBodyWithClose';
import { SelectInputWithError } from 'common/InputWithError/SelectInputWithError';
import { RadioInput } from 'common/Input';
import { addArticleToCuratedNewsletter } from 'utils/api/newslettersAPI';
import './style.scss';

export const AddCustomArticleModal = ({ isOpen, toggle, refreshNewsletter, sections, newsletterId }) => {
    const { addFlashMessage } = useContext(FlashMessageContext);
    const { handleError } = useContext(AuthContext);
    const [submitting, setSubmitting] = useState(false);
    const [title, setTitle] = useState('');
    const [url, setUrl] = useState('');
    const [uploadType, setUploadType] = useState('url');
    const [abstract, setAbstract] = useState('');
    const [comment, setComment] = useState('');
    const [selectedSectionId, setSelectedSectionId] = useState(null);
    const [uploadedPdfFile, setUploadedPdfFile] = useState(null);
    const [errors, setErrors] = useState(null);

    const handleDropAccept = async (file) => setUploadedPdfFile(file[0]);
    const handleDropReject = () => addFlashMessage('danger', 'File must be in .PDF format and cannot exceed 20MB');
    const handleClearFile = () => setUploadedPdfFile(null);
    const handleDisabled = () => abstract.length > 300 || comment.length > 500;

    const handleSubmit = async () => {
        setSubmitting(true);

        try {
            if (!selectedSectionId) {
                const testObj = {
                    code: 'VALIDATION_FAILED',
                    errors: {
                        section: [
                            {
                                message: 'A section must be selected.',
                                code: 'IS_BLANK_ERROR',
                                payload: null,
                            },
                        ],
                    },
                };
                handleError(testObj, setErrors);
                return;
            }
            setErrors(null);

            const formData = new FormData();
            uploadType === 'pdf' && formData.append('file', uploadedPdfFile);
            uploadType === 'url' && formData.append('url', url);
            formData.append('abstract', abstract);
            formData.append('comment', comment);
            formData.append('title', title);

            await addArticleToCuratedNewsletter(newsletterId, selectedSectionId, formData);
            refreshNewsletter();
            addFlashMessage('success', 'Article successfully added');
            toggle();
        } catch (err) {
            handleError(err, setErrors);
        }

        setSubmitting(false);
    };

    return (
        <Modal className="add-custom-article modal-lg" isOpen={isOpen}>
            <ModalBodyWithClose toggle={toggle} className="pb-0">
                <h2>Add Article</h2>

                <div className="mb-3">
                    <InputWithError
                        errorObj={errors}
                        prependIcon={<i className="fa fa-newspaper-o" />}
                        placeholder="Title"
                        name="title"
                        value={title}
                        type="text"
                        onChange={setTitle}
                        marginBottomClass="mb-3"
                    />
                </div>

                <div className="mb-3">
                    <div className="add-custom-article__upload-container">
                        <div className="form-group radio-container">
                            <RadioInput
                                text="Enter URL"
                                name="url-type"
                                checked={uploadType === 'url'}
                                onChange={() => setUploadType('url')}
                                classNames="url"
                            />

                            <RadioInput
                                text="Upload PDF"
                                name="pdf-type"
                                checked={uploadType === 'pdf'}
                                onChange={() => setUploadType('pdf')}
                                classNames="url"
                            />
                        </div>

                        {uploadType === 'url' ? (
                            <div className="add-custom-article__url-container">
                                <InputWithError
                                    errorObj={errors}
                                    prependIcon="@"
                                    placeholder="URL"
                                    name="url"
                                    value={url}
                                    type="text"
                                    onChange={setUrl}
                                    marginBottomClass="mb-0"
                                />
                            </div>
                        ) : (
                            <div className="add-custom-article__pdf-container">
                                <div className="d-flex align-items-center">
                                    <Dropzone
                                        className="add-custom-article__dropzone"
                                        onDropAccepted={handleDropAccept}
                                        onDropRejected={handleDropReject}
                                        maxSize={20000000}
                                        multiple={false}>
                                        {({ getRootProps, getInputProps }) => (
                                            <section>
                                                <div {...getRootProps()}>
                                                    <input {...getInputProps()} />

                                                    <Button>
                                                        <i className="fa fa-cloud-upload" style={{ marginRight: '.4rem' }} />
                                                        Upload PDF
                                                    </Button>
                                                </div>
                                            </section>
                                        )}
                                    </Dropzone>

                                    {uploadedPdfFile && (
                                        <div className="add-custom-article__uploaded-pdf">
                                            &nbsp;{uploadedPdfFile.name}&nbsp;
                                            <span onClick={handleClearFile}>×</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                <div>
                    <FormGroup>
                        <Input
                            type="textarea"
                            name="abstract"
                            value={abstract}
                            placeholder="Abstract"
                            autoComplete="off"
                            onChange={(e) => setAbstract(e.target.value)}
                        />

                        <CharacterCount value={abstract} limit={300} />
                    </FormGroup>

                    <FormGroup>
                        <Input
                            type="textarea"
                            name="comment"
                            value={comment}
                            placeholder="Comment"
                            autoComplete="off"
                            onChange={(e) => setComment(e.target.value)}
                        />

                        <CharacterCount value={comment} limit={500} />
                    </FormGroup>

                    <SelectInputWithError
                        name="section"
                        value={selectedSectionId ? selectedSectionId : 'Select a section'}
                        type="select"
                        onChange={setSelectedSectionId}
                        errorObj={errors}
                        noDefault>
                        <option disabled>Select a section</option>

                        {sections.map((section) => (
                            <option key={section.id} value={section.id}>
                                {section.name}
                            </option>
                        ))}
                    </SelectInputWithError>
                </div>
            </ModalBodyWithClose>

            <ModalFooter>
                <Button type="button" onClick={toggle}>
                    Cancel
                </Button>

                <SpinnerButton
                    color="primary"
                    style={{ minWidth: '71.53px' }}
                    onClick={handleSubmit}
                    title="Create"
                    disabled={handleDisabled()}
                    submitting={submitting}
                />
            </ModalFooter>
        </Modal>
    );
};
