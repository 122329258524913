import React, { useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { AuthContext } from 'contexts/AuthContext';
import { FlashMessageContext } from 'contexts/FlashMessageContext';
import { LoadingSpinner } from 'common/LoadingSpinner';
import { getNewsletterById } from 'utils/api/newslettersAPI';
import { useDevice } from 'hooks/useMediaQuery';
import { ManageContentDesktop } from './Desktop';
import { ManageContentMobile } from './Mobile';

export const ManageNewsletterContent = () => {
    const { id } = useParams();
    const { addFlashMessage } = useContext(FlashMessageContext);
    const { handleError } = useContext(AuthContext);
    const { isMobile } = useDevice();
    const [initialLoad, setInitialLoad] = useState(true);

    const {
        data: newsletterData,
        isLoading,
        refetch: refetchNewsletter,
    } = useQuery(['getNewsletterById', id], () => (id ? getNewsletterById(id) : null), {
        onSuccess: () => setTimeout(() => setInitialLoad(false), 200),
        onError: (err) => handleError(err),
    });

    if (isLoading) return <LoadingSpinner text="Fetching newsletter data" padding />;

    return (
        <div className="animated fadeIn h-100" style={{ paddingTop: '1rem' }}>
            {isMobile ? (
                <ManageContentMobile newsletterData={newsletterData} refreshNewsletter={refetchNewsletter} />
            ) : (
                <ManageContentDesktop
                    addFlashMessage={addFlashMessage}
                    newsletterData={newsletterData}
                    refreshNewsletter={refetchNewsletter}
                    handleError={handleError}
                    initialLoad={initialLoad}
                />
            )}
        </div>
    );
};
