import React from 'react';
import { Input } from 'reactstrap';
import './style.scss';

export const SearchInputWithSubmit = ({
    value,
    onChange,
    placeholder,
    onClear,
    classNames,
    extraParams,
    onSubmit,
    list,
    iconClassNames,
    submitting,
    autoComplete,
    hasError,
    disabled,
    style,
}) => {
    const handleKeydown = (e) => {
        if (e.keyCode === 13) {
            onSubmit();
        }
    };

    const handleChange = (e) => {
        if (extraParams) {
            onChange(extraParams, e.target.value);
        } else {
            onChange(e.target.value);
        }
    };

    return (
        <div className={'search-input-container search-input-container--with-submit ' + (list ? 'search-input-container--list ' : '')}>
            <Input
                placeholder={placeholder}
                type="text"
                autoComplete={autoComplete}
                className={'search-input ' + (hasError ? 'input-has-error ' : ' ') + (classNames && classNames.join(' '))}
                value={value}
                onKeyDown={handleKeydown}
                disabled={disabled}
                onChange={(e) => handleChange(e)}
            />

            {submitting ? (
                <div className="search-input__icon-container" style={style}>
                    <i className="fa fa-spinner fa-spin search-input__icon search-input__icon--search" />
                </div>
            ) : (
                <div className="search-input__icon-container d-flex" style={style}>
                    {value.length > 0 && (
                        <i
                            className={
                                'fa fa-times-circle search-input__icon search-input__icon--close ' + (disabled ? 'search-input__icon--disabled' : '')
                            }
                            onClick={() => !disabled && onClear()}
                        />
                    )}
                    <i
                        className={
                            'fa fa-search search-input__icon search-input__icon--search ' +
                            (disabled ? 'search-input__icon--disabled ' : '') +
                            (iconClassNames && iconClassNames.join(' '))
                        }
                        onClick={() => !disabled && onSubmit()}
                    />
                </div>
            )}
        </div>
    );
};
