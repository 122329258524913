import React, { useEffect, useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import { ListItem } from './ListItem';
import { SearchInput } from 'common/SearchInput';
import { filterByValue, sortObjects } from 'utils/helpers';

export const ListLabelDropdown = ({ labels, activeFilterLabels, handleActiveFilterLabelSelect, useIconOnly }) => {
    const [searchValue, setSearchValue] = useState('');
    const [visibleLabels, setVisibleLabels] = useState([]);
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggle = () => setDropdownOpen((prevState) => !prevState);

    useEffect(() => {
        labels?.length > 0 && setVisibleLabels(labels);
    }, [labels]);

    useEffect(() => {
        const newVisibleArray = filterByValue(labels, 'name', searchValue);
        const sortedArray = sortObjects(newVisibleArray, 'name', 'asc');

        setVisibleLabels(sortedArray);
    }, [searchValue]);
    return (
        <Dropdown isOpen={dropdownOpen} toggle={toggle} style={{ zIndex: 1019 }}>
            <DropdownToggle caret className="mb-0">
                {useIconOnly ? <i className="fa fa-tag" /> : 'Filter by Labels'}
            </DropdownToggle>
            <DropdownMenu className="list-label-dropdown">
                <SearchInput placeholder="Find a label" value={searchValue} onChange={setSearchValue} onClear={() => setSearchValue('')} />
                <div className="list-label-dropdown__list">
                    {activeFilterLabels.map((labelName) => (
                        <ListItem
                            key={labelName}
                            label={labels.find((label) => label.name === labelName)}
                            handleSelect={handleActiveFilterLabelSelect}
                            isActive
                        />
                    ))}
                    {visibleLabels.length > 0 ? (
                        visibleLabels.map(
                            (label) =>
                                activeFilterLabels.indexOf(label.name) === -1 && (
                                    <ListItem key={label.id} label={label} handleSelect={handleActiveFilterLabelSelect} />
                                )
                        )
                    ) : (
                        <div style={{ marginBottom: '.2rem', padding: '.1rem .3rem' }}>No labels found</div>
                    )}
                </div>
            </DropdownMenu>
        </Dropdown>
    );
};
