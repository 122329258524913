import React from 'react';
import { convertDateString } from 'utils/helpers';
import RSSLogo from 'images/rss-icon.png';
import BlueLogo from 'images/ozmosys_blue_cropped.png';
import OpenEnvelope from 'images/open-envelope.svg';

export const Source = ({ source, assignAlertToSection, activeQuickAddSection }) => {
    const handleAlertTypeIcon = (sourceType) => {
        switch (sourceType) {
            case 'rss':
                return <img height={16} src={RSSLogo} alt="rss-logo" />;
            case 'google':
                return <i style={{ fontSize: '.8rem' }} className="google-icon fa fa-google " />;
            case 'internal':
                return <img height={16} src={BlueLogo} alt="internal-logo" />;
            case 'email_publication':
                return <img height={16} src={OpenEnvelope} alt="email-logo" />;
            case 'company_based':
                return <i style={{ fontSize: '.8rem' }} className="fa fa-building " />;
            default:
                return null;
        }
    };

    return (
        <div className="newsletter-source">
            <div className="newsletter-source__block-1">
                <div className="newsletter-source__title d-flex">
                    <div className="newsletter-source__icon">{handleAlertTypeIcon(source.type)}</div>

                    <span>{source.name}</span>
                </div>

                <div className="newsletter-source__date">
                    {source.content_updated_at ? convertDateString(source.content_updated_at) : 'Pending updates'}
                </div>
            </div>

            {activeQuickAddSection && (
                <div className="newsletter-source__add-btn" onClick={() => assignAlertToSection(source.id, activeQuickAddSection.id)}>
                    <i className="fa fa-plus" />
                </div>
            )}
        </div>
    );
};
