import React, { useContext, useState } from 'react';
import { useQuery } from 'react-query';
import { AuthContext } from 'contexts/AuthContext';
import { FilterContext } from 'contexts/FilterContext';
import { FlashMessageContext } from 'contexts/FlashMessageContext';
import { getAlertGroups, deleteAlertGroup, universalAssignAlerts } from 'utils/api/alertsAPI';
import { SingleUserBlock } from 'common/SingleUserBlock';
import { LoadingSpinner } from 'common/LoadingSpinner';
import { GroupList } from './GroupList';
import EmptyList from './EmptyList';

export const AlertGroups = () => {
    const { state, handleError } = useContext(AuthContext);
    const { addFlashMessage } = useContext(FlashMessageContext);
    const { alertGroupFilters, setFiltersObject } = useContext(FilterContext);
    const [submitting, setSubmitting] = useState(false);

    const { data, isLoading, refetch } = useQuery(['alertGroups', state.isSingleUser], () => (!state.isSingleUser ? getAlertGroups() : null), {
        onError: (err) => handleError(err, null, addFlashMessage),
    });

    const handleDeleteGroup = async (id, modalCallback) => {
        try {
            setSubmitting(true);
            await deleteAlertGroup(id);
            await refetch();

            addFlashMessage('success', 'Alert group successfully deleted');
            setSubmitting(false);
            modalCallback && modalCallback();
        } catch (err) {
            setSubmitting(false);
            handleError(err, null, addFlashMessage);
        }
    };

    const handleAssignAlertGroup = async (alertGroup, selectedUsers, modalCallback) => {
        try {
            setSubmitting(true);
            const params = {
                alerts: alertGroup.alerts.map((alert) => alert.id),
                users: selectedUsers.map((user) => user.id),
            };
            await universalAssignAlerts(params);
            await refetch();

            addFlashMessage('success', 'Alert group successfully assigned');
            setSubmitting(false);
            modalCallback();
        } catch (err) {
            setSubmitting(false);
            handleError(err);
        }
    };

    return (
        <>
            {state.isSingleUser ? (
                <SingleUserBlock itemType="alert groups" />
            ) : isLoading ? (
                <LoadingSpinner />
            ) : data?.length > 0 ? (
                <GroupList
                    alertGroups={data}
                    fetchingData={isLoading}
                    handleDeleteGroup={handleDeleteGroup}
                    submitting={submitting}
                    addFlashMessage={addFlashMessage}
                    handleAssignAlertGroup={handleAssignAlertGroup}
                    refreshAlertGroups={refetch}
                    handleError={handleError}
                    alertGroupFilters={alertGroupFilters}
                    setFiltersObject={setFiltersObject}
                />
            ) : (
                <EmptyList submitting={submitting} addFlashMessage={addFlashMessage} refreshAlertGroups={refetch} handleError={handleError} />
            )}
        </>
    );
};
