import React, { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import { InputWithError } from 'common/InputWithError';
import { SpinnerButton } from 'common/SpinnerButton';
import { SmallSpinnerButtonSquare } from 'common/SpinnerButton/SmallSpinnerButtonSquare';
import { deleteCourtlinkToken, editCourtlinkToken } from 'utils/api/profileAPI';
import { handleCopyToClipboard } from 'utils/helpers';

export const Token = ({ tokenPair, addFlashMessage, handleError, refreshProfile }) => {
    const [editMode, setEditMode] = useState(false);
    const [clientName, setClientName] = useState('');
    const [tempClientName, setTempClientName] = useState('');
    const [clientId, setClientId] = useState('');
    const [tempClientId, setTempClientId] = useState('');
    const [clientSecret, setClientSecret] = useState('');
    const [tempClientSecret, setTempClientSecret] = useState('');
    const [errors, setErrors] = useState(null);
    const [submitting, setSubmitting] = useState(false);
    const [deleting, setDeleting] = useState(false);

    useEffect(() => {
        setClientName(tokenPair.name);
        setTempClientName(tokenPair.name);
        setClientId(tokenPair.token.client_id);
        setTempClientId(tokenPair.token.client_id);
        setClientSecret(tokenPair.token.client_secret);
        setTempClientSecret(tokenPair.token.client_secret);
    }, [tokenPair]);

    if (tokenPair.type !== 2) {
        return null;
    }

    const resetFields = () => {
        setTempClientName(clientName);
        setTempClientId(clientId);
        setTempClientSecret(clientSecret);
        setEditMode(false);
    };

    const updateCourtlinkDetails = async () => {
        try {
            setSubmitting(true);
            const params = {
                clientId: tempClientId,
                name: tempClientName,
                clientSecret: tempClientSecret,
            };
            await editCourtlinkToken(tokenPair.id, params);
            addFlashMessage('success', 'Token successfully updated');
            setSubmitting(false);
            setClientName(tempClientName);
            setClientId(tempClientId);
            setTempClientSecret(tempClientSecret);
            setEditMode(false);
            refreshProfile();
        } catch (err) {
            if (err.message && err.message === 'This token already used') {
                addFlashMessage('danger', 'This token is already in use');
            } else {
                addFlashMessage('danger', 'Invalid token details');
            }
            setSubmitting(false);
        }
    };

    const deleteCourtlinkDetails = async () => {
        try {
            setDeleting(true);
            await deleteCourtlinkToken(tokenPair.id);
            addFlashMessage('success', 'Token successfully deleted');
            setDeleting(false);
            refreshProfile();
        } catch (err) {
            handleError(err, setErrors);
            setDeleting(false);
        }
    };

    return (
        <div className="courtlink-token">
            {editMode ? (
                <div className="courtlink-token__info">
                    <div style={{ maxWidth: '390px', width: '100%' }}>
                        <InputWithError
                            prependIcon={<i className="fa fa-user" />}
                            name="clientName"
                            value={tempClientName}
                            type="text"
                            onChange={setTempClientName}
                            errorObj={errors}
                            marginBottomClass="mb-3"
                            placeholder="Client Name"
                        />
                    </div>

                    <div style={{ maxWidth: '390px', width: '100%' }}>
                        <InputWithError
                            prependIcon={<i className="fa fa-user" />}
                            name="clientId"
                            value={tempClientId}
                            type="text"
                            onChange={setTempClientId}
                            errorObj={errors}
                            marginBottomClass="mb-3"
                            placeholder="Client ID"
                        />
                    </div>

                    <div style={{ maxWidth: '390px' }}>
                        <InputWithError
                            prependIcon={<i className="fa fa-key" />}
                            name="secret"
                            value={tempClientSecret}
                            type="text"
                            onChange={setTempClientSecret}
                            errorObj={errors}
                            marginBottomClass="mb-0"
                            placeholder="Client Secret"
                        />
                    </div>
                </div>
            ) : (
                <div className="courtlink-token__info">
                    <div className="courtlink-token-info-item" onClick={() => handleCopyToClipboard(clientName)}>
                        <strong>Client Name:</strong> {clientName}
                    </div>

                    <div className="courtlink-token-info-item" onClick={() => handleCopyToClipboard(clientId)}>
                        <strong>Client ID:</strong> {clientId}
                    </div>

                    <div className="courtlink-token-info-item" onClick={() => handleCopyToClipboard(clientSecret)}>
                        <strong>Client Secret:</strong> {clientSecret}
                    </div>
                </div>
            )}

            {editMode ? (
                <div className="courtlink-token__actions">
                    <Button className="px-4 mr-2 courtlink-field-btn" onClick={resetFields}>
                        Cancel
                    </Button>

                    <SpinnerButton
                        className="courtlink-field-btn"
                        color="primary"
                        submitting={submitting}
                        onClick={updateCourtlinkDetails}
                        title="Update"
                    />
                </div>
            ) : (
                <div className="courtlink-token__actions">
                    <Button className="btn-light mr-2" onClick={() => setEditMode(true)}>
                        <i className="fa fa-edit" />
                    </Button>

                    <SmallSpinnerButtonSquare
                        color="danger"
                        onClick={() => deleteCourtlinkDetails(tokenPair.id)}
                        disabled={false}
                        submitting={deleting}
                        defaultSize
                        title={<i className="fa fa-trash" />}
                    />
                </div>
            )}
        </div>
    );
};
