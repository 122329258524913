import React, { useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { useQuery } from 'react-query';
import { AuthContext } from 'contexts/AuthContext';
import { getAlertById, getAlertLabels, getInternalSearchCategories } from 'utils/api/alertsAPI';
import { LoadingSpinner } from 'common/LoadingSpinner';
import { Rss } from './RSS';
import { InternalAlerts } from './InternalAlerts';
import { OzmosysCategorizedAlerts } from './OzmosysCategorizedAlerts';
import './style.scss';

export const CreateEditAlert = ({ isSources = false, isEdit = false }) => {
    const { id } = useParams();
    const { handleError } = useContext(AuthContext);
    const [formTitle, setFormTitle] = useState(null);

    const { data: labelsData, refetch: refetchLabels } = useQuery(['getAlertLabels'], () => getAlertLabels(), { onError: (err) => handleError(err) });
    const { data: alertData, isLoading: isLoadingAlert } = useQuery(['getAlertById', id], () => (id ? getAlertById(id) : null), {
        onSuccess: (res) => {
            const searchTypeId = res?.source?.newsCategory?.searchTypeId;

            if (searchTypeId) {
                const titleByType = searchTypeId === 1 ? 'Practice' : searchTypeId === 2 ? 'Industry' : 'Topic';
                setFormTitle(`Edit ${titleByType}`);
            } else {
                setFormTitle('Edit ' + (isSources ? 'Source' : 'Search'));
            }
        },
        onError: (err) => handleError(err),
    });
    const { data: categoriesData, isLoading: isLoadingCategories } = useQuery(
        ['getInternalSearchCategories', alertData?.source?.type, isEdit],
        () => (alertData?.source?.type === 'internal' || !isEdit ? getInternalSearchCategories() : null),
        {
            onError: (err) => handleError(err),
        }
    );

    const getAlertForm = () => {
        const type = isEdit ? alertData?.source?.type : 'internal';

        if (type === 'internal') {
            return (
                <InternalAlerts
                    foundAlert={alertData}
                    isEdit={isEdit}
                    labels={labelsData}
                    categories={categoriesData}
                    categoriesLoading={isLoadingCategories}
                />
            );
        } else if (type === 'rss' || type === 'internal_group' || type === 'email_publication') {
            return (
                <Rss
                    internalGroup={type === 'internal_group'}
                    isEdit={isEdit}
                    foundAlert={alertData}
                    labels={labelsData}
                    refreshLabels={refetchLabels}
                />
            );
        } else if (type === 'ozmosys_categorized') {
            return <OzmosysCategorizedAlerts isEdit={isEdit} foundAlert={alertData} />;
        } else {
            return (
                <InternalAlerts
                    foundAlert={alertData}
                    isEdit={isEdit}
                    labels={labelsData}
                    categories={categoriesData}
                    categoriesLoading={isLoadingCategories}
                />
            );
        }
    };

    return (
        <div className="animated fadeIn view">
            <Row className="mt-3">
                <Col xs="12">
                    <div className="alert-container">
                        <h1>{isEdit ? formTitle : 'Create Search'}</h1>
                        {isEdit ? (
                            isLoadingAlert ? (
                                <LoadingSpinner />
                            ) : alertData ? (
                                getAlertForm()
                            ) : null
                        ) : (
                            <>
                                <div className="alert-type-button-container justify-content-between">
                                    <div className="d-flex alert-container-block-1">
                                        <div className="alert-type-button active">
                                            <i className="fa fa-search" />
                                            <span className="i-space">&nbsp;</span> Search
                                        </div>
                                    </div>
                                </div>
                                {getAlertForm()}
                            </>
                        )}
                    </div>
                </Col>
            </Row>
        </div>
    );
};
