import React, { Suspense, useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Table } from 'reactstrap';
import moment from 'moment-timezone';
import { deleteAlert, getAlertLabels, getAlerts } from 'utils/api/alertsAPI';
import { FlashMessageContext } from 'contexts/FlashMessageContext';
import { AuthContext } from 'contexts/AuthContext';
import { FilterContext } from 'contexts/FilterContext';
import { CATEGORY_TYPE, COMPANY_TYPE } from 'constants/alerts';
import { LoadingSpinner } from 'common/LoadingSpinner';
import { PaginationWrapper } from 'common/PaginationWrapper';
import { EmptyList } from './EmptyList';
import { AlertsListHeader } from './AlertListHeader';
import { AlertListItem } from './AlertListItem';
import { DeleteAlertModal } from '../components/Modals/DeleteAlertModal';
import { TableHeader } from './TableHeader';

export const AlertsList = ({ type, searchTypeId }) => {
    const { addFlashMessage } = useContext(FlashMessageContext);
    const { handleError, state } = useContext(AuthContext);
    const { setFiltersObject, alertFilters, alertCatCompFilters } = useContext(FilterContext);

    const isSortByName = type === COMPANY_TYPE || type === CATEGORY_TYPE;
    const currentFilters = isSortByName ? alertCatCompFilters : alertFilters;
    const { paginationCurrentPageNumber, activeSortProperty, activeSortDirection, activeFilterLabels, searchValue, searchProperty } = currentFilters;

    const [alerts, setAlerts] = useState([]);
    const [labels, setLabels] = useState([]);
    const [tempSearchValue, setTempSearchValue] = useState('');
    const [submittingSearch, setSubmittingSearch] = useState(false);
    const [submittingDelete, setSubmittingDelete] = useState(false);
    const [showFirstTimeMessage, setShowFirstTimeMessage] = useState(true);
    const [totalPages, setTotalPages] = useState(null);
    const [initialLoad, setInitialLoad] = useState(true);
    const [fetchingCsv, setFetchingCsv] = useState(false);
    const [itemToDelete, setItemToDelete] = useState(null);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [activeMobileFilterValue, setActiveMobileFilterValue] = useState('');

    const newAlertsData = localStorage?.getItem('newAlertsData');

    const { data, isLoading, refetch, error } = useQuery(
        [
            'alerts',
            paginationCurrentPageNumber,
            activeSortProperty,
            activeSortDirection,
            searchProperty,
            searchValue,
            activeFilterLabels,
            type,
            searchTypeId,
        ],
        () =>
            type === CATEGORY_TYPE && !searchTypeId
                ? null
                : getAlerts(
                      true,
                      paginationCurrentPageNumber,
                      50,
                      activeSortProperty,
                      activeSortDirection,
                      searchProperty,
                      searchValue,
                      activeFilterLabels,
                      false,
                      '',
                      type,
                      type === CATEGORY_TYPE ? searchTypeId : null
                  )
    );

    const {
        data: labelsData,
        isLoading: isLoadingLabels,
        error: errorLabels,
        refetch: refetchLabels,
    } = useQuery(['labels', type], () => getAlertLabels());

    const handleClearSearchValue = () => {
        const filters = { ...alertFilters };
        setSubmittingSearch(true);
        filters.searchValue = '';
        setFiltersObject(isSortByName ? 'alertCatComp' : 'alerts', filters);
    };
    const toggleDeleteModal = (item) => {
        setDeleteModalOpen(!deleteModalOpen);

        if (!deleteModalOpen) {
            setItemToDelete(item);
        } else {
            setItemToDelete(null);
        }
    };

    const handlePageClick = (data) => {
        let filters = { ...alertFilters };
        filters.paginationCurrentPageNumber = data.selected + 1;
        filters.useCount = false;
        setFiltersObject(isSortByName ? 'alertCatComp' : 'alerts', filters);
    };

    const handleMobileFilterSelect = (val) => {
        const splitValue = val.split('-');
        const filters = { ...alertFilters };

        filters.activeSortProperty = splitValue[0];
        filters.activeSortDirection = splitValue[1];
        setFiltersObject(isSortByName ? 'alertCatComp' : 'alerts', filters);
    };

    const determineMobileFiltersValue = () => {
        const filterStr = alertFilters.activeSortProperty + '-' + alertFilters.activeSortDirection;
        setActiveMobileFilterValue(filterStr);
    };

    const clearActiveLabels = () => {
        const filters = { ...alertFilters };
        filters.activeFilterLabels = [];
        filters.paginationCurrentPageNumber = 1;
        setFiltersObject(isSortByName ? 'alertCatComp' : 'alerts', filters);
    };

    const handleActiveFilterLabelSelect = (labelName) => {
        const filterArr = [...activeFilterLabels];

        if (filterArr.indexOf(labelName) > -1) {
            filterArr.splice(filterArr.indexOf(labelName), 1);
        } else {
            filterArr.push(labelName);
        }

        const filters = { ...currentFilters };
        filters.activeFilterLabels = filterArr;
        filters.paginationCurrentPageNumber = 1;
        setFiltersObject(isSortByName ? 'alertCatComp' : 'alerts', filters);
    };

    const handleSearchValueSubmit = () => {
        if (tempSearchValue.length > 2048) {
            addFlashMessage('danger', 'Your search exceeds the maximum number of allowed characters (2048)');
            return;
        }
        const filters = { ...currentFilters };

        filters.searchValue = tempSearchValue;
        filters.paginationCurrentPageNumber = 1;
        setFiltersObject(isSortByName ? 'alertCatComp' : 'alerts', filters);
    };

    const downloadFilteredCsv = async () => {
        try {
            setFetchingCsv(true);
            const title = 'ozmosys_alerts_' + moment().tz(moment.tz.guess()).format('MMDDYYYY');

            await getAlerts(
                false,
                paginationCurrentPageNumber,
                50,
                activeSortProperty,
                activeSortDirection,
                searchProperty,
                searchValue,
                activeFilterLabels,
                true,
                title,
                type,
                searchTypeId
            );
            setFetchingCsv(false);
        } catch (err) {
            setSubmittingSearch(false);
            fetchingCsv && setFetchingCsv(false);
            handleError(err);
        }
    };

    const handleDeleteAlert = async (id) => {
        try {
            setSubmittingDelete(true);

            await deleteAlert(id);
            await refetch();

            setSubmittingDelete(false);
            toggleDeleteModal();
            addFlashMessage('success', 'Alert successfully deleted');
        } catch (err) {
            setSubmittingDelete(false);

            if (err?.hasOwnProperty('message') && err?.message === 'This Source has children and can not be deleted') {
                addFlashMessage('danger', err?.message);
            } else {
                handleError(err, err, addFlashMessage);
            }
        }
    };

    useEffect(() => {
        alertFilters && determineMobileFiltersValue();
    }, [alertFilters]);

    useEffect(() => {
        const refreshCount = initialLoad || paginationCurrentPageNumber === 1 || !data?.result?.length;

        if (data) {
            if (newAlertsData && newAlertsData !== 'success') {
                const alertsList = [
                    {
                        id: null,
                        labels: [],
                        name: JSON.parse(newAlertsData)?.name,
                        createdAt: '2024-01-09 13:06:53+00',
                        countClicks: 0,
                        source: { keyword: JSON.parse(newAlertsData)?.keyword, type: 'internal' },
                        countAssignedUsers: 0,
                    },
                    ...data?.result,
                ];
                setAlerts(alertsList);
            } else {
                setAlerts(data?.result);
            }

            refreshCount && setTotalPages(data?.navigation?.total_pages || null);
            setSubmittingSearch(false);

            if (tempSearchValue !== searchValue) setTempSearchValue(searchValue);
            if (data?.result?.length) {
                showFirstTimeMessage && setShowFirstTimeMessage(false);
            }
            initialLoad && setInitialLoad(false);
        }
    }, [data, newAlertsData]);

    useEffect(() => {
        if (newAlertsData === 'success') {
            localStorage.removeItem('newAlertsData');
            refetch();
        }
    }, [newAlertsData]);

    useEffect(() => {
        alertFilters && determineMobileFiltersValue();
    }, []);

    useEffect(() => {
        setLabels(labelsData || []);
    }, [labelsData]);

    useEffect(() => {
        errorLabels && addFlashMessage('danger', 'Unable to fetch labels');

        if (error) {
            handleError(error);
            addFlashMessage('danger', 'Unable to fetch alerts');
        }
    }, [errorLabels, error]);

    if (error) return null;

    return (
        <div>
            {!isLoading && !alerts?.length && !submittingSearch && !searchValue && !currentFilters.activeFilterLabels.length && !searchTypeId ? (
                <EmptyList type={type} />
            ) : (
                <>
                    <AlertsListHeader
                        type={type}
                        searchValue={tempSearchValue}
                        setSearchValue={setTempSearchValue}
                        handleClearSearchValue={handleClearSearchValue}
                        handleSearchValueSubmit={handleSearchValueSubmit}
                        submittingSearch={submittingSearch}
                        allLabels={labels}
                        activeFilterLabels={activeFilterLabels}
                        handleActiveFilterLabelSelect={handleActiveFilterLabelSelect}
                        clearActiveLabels={clearActiveLabels}
                        downloadFilteredCsv={downloadFilteredCsv}
                        fetchingCsv={fetchingCsv}
                        handleMobileFilterSelect={handleMobileFilterSelect}
                        activeMobileFilterValue={activeMobileFilterValue}
                        searchTypeId={searchTypeId}
                        refetch={refetch}
                    />
                    {isLoading ? (
                        <LoadingSpinner />
                    ) : alerts.length < 1 ? (
                        <div>No results found</div>
                    ) : (
                        <Suspense fallback={<LoadingSpinner />}>
                            <Table className="responsive-table alert-list-table">
                                <tbody>
                                    <TableHeader
                                        type={type}
                                        activeSortProperty={activeSortProperty}
                                        activeSortDirection={activeSortDirection}
                                        alertFilters={alertFilters}
                                        setFiltersObject={setFiltersObject}
                                        isSortByName={isSortByName}
                                        isSingleUser={state.isSingleUser}
                                    />
                                    {alerts.map((alert, index) => {
                                        return (
                                            <AlertListItem
                                                key={alert.id || index}
                                                alert={alert}
                                                toggleDeleteModal={toggleDeleteModal}
                                                addFlashMessage={addFlashMessage}
                                                refreshAlerts={refetch}
                                                allLabels={labels}
                                                fetchingLabels={isLoadingLabels}
                                                refreshLabels={refetchLabels}
                                                isSingleUser={state.isSingleUser}
                                                type={type}
                                            />
                                        );
                                    })}
                                </tbody>
                            </Table>
                            <PaginationWrapper
                                totalPages={totalPages}
                                handlePageClick={handlePageClick}
                                forcePage={paginationCurrentPageNumber - 1}
                            />
                        </Suspense>
                    )}
                </>
            )}
            {deleteModalOpen && (
                <DeleteAlertModal
                    isOpen={deleteModalOpen}
                    toggle={toggleDeleteModal}
                    item={itemToDelete}
                    submitting={submittingDelete}
                    onSubmit={handleDeleteAlert}
                />
            )}
        </div>
    );
};
