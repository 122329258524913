import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import { LabelsDropdown } from 'common/Labels/FormLabelDropdown';
import { addAlertLabel, assignLabelToAlert } from 'utils/api/alertsAPI';

export const LabelListItemDropdown = ({ allLabels, selectedLabels, alert, refreshList, refreshLabels, disabled }) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [creatingLabel, setCreatingLabel] = useState(false);
    const [loadingChanges, setLoadingChanges] = useState(false);

    const toggleDropdown = () => {
        if (disabled) return;
        setDropdownOpen(!dropdownOpen);
    };

    const handleLabelSelect = async (label) => {
        if (!loadingChanges) {
            setLoadingChanges(true);
            const previousSelectedAlerts = alert.labels.length > 0 ? alert.labels.map((item) => item.id) : [];

            if (alert.labels.indexOf(label) > -1) {
                previousSelectedAlerts.splice(previousSelectedAlerts.indexOf(label.id), 1);

                await assignLabelToAlert(alert.id, { ids: previousSelectedAlerts });
                await refreshList();
                setLoadingChanges(false);
            } else {
                previousSelectedAlerts.push(label.id);

                await assignLabelToAlert(alert.id, { ids: previousSelectedAlerts });
                await refreshList();
                setLoadingChanges(false);
            }
        }
    };

    const addLabel = async (name) => {
        setCreatingLabel(true);
        const newLabel = await addAlertLabel({ name });

        await refreshLabels();
        await assignLabelToAlert(alert.id, { ids: [...alert.labels.map((item) => item.id), newLabel.id] });
        await refreshList();

        setCreatingLabel(false);
    };

    return (
        <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} className="alert-list-item__label-dropdown">
            <DropdownToggle
                color="primary"
                className="alert-list-item__add-label-btn"
                disabled={disabled}
                style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}>
                <i className="fa fa-plus" />
            </DropdownToggle>
            <DropdownMenu end>
                <div onClick={() => setDropdownOpen(false)} className="alert-list-item__label-dropdown-close-btn">
                    <span aria-hidden="true">×</span>
                </div>
                <LabelsDropdown
                    labels={allLabels}
                    selectedLabels={selectedLabels}
                    creatingLabel={creatingLabel}
                    addLabel={addLabel}
                    addToSelected={handleLabelSelect}
                    loadingChanges={loadingChanges}
                    removeFromSelected={handleLabelSelect}
                    extraPadding
                />
            </DropdownMenu>
        </Dropdown>
    );
};
