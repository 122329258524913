import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Button } from 'reactstrap';
import Logo from 'images/logo.png';

export const InviteExpired = () => {
    const navigate = useNavigate();

    const redirectToLogin = () => {
        navigate('/signin');
    };

    return (
        <div className="auth-block">
            <div className="auth-block__inner">
                <div className="animated fadeIn">
                    <Row className="justify-content-center mt-5" style={{ marginBottom: '.5rem' }}>
                        <Col md={9}>
                            <div className="auth-block__image-container">
                                <a href="/">
                                    <img src={Logo} alt="Ozmosys" className="auth-block__image img-fluid" />
                                </a>
                            </div>
                            <div className="text-center">
                                <h2>Expired Link</h2>
                                <p className="mt-4 mb-3 text-muted">
                                    The link that you clicked has expired or has already been used. Please return to the login screen or contact your
                                    administrator to request a new link.
                                </p>
                                <Button block className="mt-4 " color="primary" onClick={redirectToLogin}>
                                    Return to Login
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
};
