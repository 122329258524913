import React, { useState, useEffect, useContext } from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { FlashMessageContext } from 'contexts/FlashMessageContext';
import { AuthContext } from 'contexts/AuthContext';
import { FilterContext } from 'contexts/FilterContext';
import { getComparativeSetStatisticsById } from 'utils/api/statisticsAPI';
import Chart from 'components/Dashboard/Charts/ComparativeAnalysisChart';
import { EmptyChart } from 'common/EmptyChart';
import { List } from './List';
import './style.scss';

export const ComparativeAnalysis = () => {
    const { addFlashMessage } = useContext(FlashMessageContext);
    const { handleError } = useContext(AuthContext);
    const { compSetFilters, setFiltersObject } = useContext(FilterContext);

    const [activeSet, setActiveSet] = useState(null);
    const [activeSetStats, setActiveSetStats] = useState(null);
    const [fetchingCompSetStatistics, setFetchingCompSetStatistics] = useState(false);

    const handleCompSetSelect = async (set) => setActiveSet(set);

    const fetchCompSetStatistics = async (id, fromCSV) => {
        !fromCSV && setFetchingCompSetStatistics(true);

        try {
            const compSetStats = await getComparativeSetStatisticsById(id);

            if (fromCSV) {
                return compSetStats;
            } else {
                setActiveSetStats(compSetStats);
            }
        } catch (err) {
            handleError(err);
        }

        setFetchingCompSetStatistics(false);
    };

    useEffect(() => {
        activeSet && fetchCompSetStatistics(activeSet.id);
    }, [activeSet]);

    return (
        <div className="comparative-analysis">
            <Card className="chart-card">
                <CardHeader>Comparative Analysis</CardHeader>

                <CardBody>
                    {fetchingCompSetStatistics ? (
                        <EmptyChart loading />
                    ) : activeSetStats && activeSet?.id ? (
                        <Chart activeSetStats={activeSetStats} chartKey={activeSet.id} activeIndex={-1} />
                    ) : (
                        <EmptyChart />
                    )}
                </CardBody>

                <CardBody style={{ minHeight: 0 }}>
                    <List
                        handleCompSetSelect={handleCompSetSelect}
                        fetchCompSetStatistics={fetchCompSetStatistics}
                        activeSet={activeSet}
                        handleError={handleError}
                        addFlashMessage={addFlashMessage}
                        compSetFilters={compSetFilters}
                        setFiltersObject={setFiltersObject}
                    />
                </CardBody>
            </Card>
        </div>
    );
};
