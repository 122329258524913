import React from 'react';
import { CATEGORY_TYPE, COMPANY_TYPE, SEARCH_TYPE, SOURCE_TYPE } from 'constants/alerts';
import { handleCaret } from 'utils/helpers';

export const TableHeader = ({ type, activeSortProperty, activeSortDirection, alertFilters, setFiltersObject, isSortByName, isSingleUser }) => {
    const handleSortableHeaderClick = (value) => {
        const newFilters = { ...alertFilters };

        if (value === activeSortProperty) {
            activeSortDirection === 'asc' ? (newFilters.activeSortDirection = 'desc') : (newFilters.activeSortDirection = 'asc');
        } else {
            newFilters.activeSortProperty = value;
            newFilters.activeSortDirection = 'asc';
        }

        newFilters.paginationCurrentPageNumber = 1;
        setFiltersObject(isSortByName ? 'alertCatComp' : 'alerts', newFilters);
    };

    const getTHHeader = () => {
        switch (type) {
            case SOURCE_TYPE:
                return 'Type';
            case CATEGORY_TYPE:
                return 'Type';
            case COMPANY_TYPE:
                return 'Ticker';
            case SEARCH_TYPE:
            default:
                return 'Keyword';
        }
    };

    return (
        <tr className="responsive-table header-row">
            <th
                className={'responsive-table__sortable-th alert-list-table__th responsive-table--long-th' + (type === CATEGORY_TYPE ? ' w-35' : '')}
                onClick={() => handleSortableHeaderClick('alert.name')}>
                Title
                {handleCaret('alert.name')}
            </th>
            <th
                className={'responsive-table__sortable-th responsive-table--long-th' + (type === CATEGORY_TYPE ? ' w-150' : '')}
                onClick={() => handleSortableHeaderClick('source.keyword')}>
                {getTHHeader()}
                {handleCaret('source.keyword')}
            </th>
            {!isSingleUser && <th className={'responsive-table--label-th' + (type === CATEGORY_TYPE ? ' w-150' : '')}>Labels</th>}
            <th
                className="responsive-table__sortable-th alert-list-table__th--assigned-to responsive-table--number-th"
                onClick={() => handleSortableHeaderClick('alert.countAssignedUsers')}>
                Assigned To
                {handleCaret('alert.countAssignedUsers')}
            </th>
            <th className="responsive-table__sortable-th" onClick={() => handleSortableHeaderClick('alert.createdAt')}>
                Created At
                {handleCaret('alert.createdAt')}
            </th>
            <th className="responsive-table__sortable-th" onClick={() => handleSortableHeaderClick('source.contentUpdatedAt')}>
                Updated At
                {handleCaret('source.contentUpdatedAt')}
            </th>
            <th>Assignment</th>
            <th className="responsive-table--number-th">Dig Deeper</th>
            <th className="responsive-table__sortable-th responsive-table--number-th" onClick={() => handleSortableHeaderClick('alert.countClicks')}>
                Clicks
                {handleCaret('alert.countClicks')}
            </th>
            <th>Actions</th>
        </tr>
    );
};
