import React from 'react';
import Toggle from 'react-toggle';
import { InputWithError } from 'common/InputWithError';
import { SelectInputWithError } from 'common/InputWithError/SelectInputWithError';
import { RelevanceContainerLabels } from 'common/Labels/RelevanceContainerLabels';
import { CheckboxInput } from 'common/Input';
import { timezones } from 'constants/timezones';

export const General = ({
    email,
    setEmail,
    fullName,
    errors,
    isSuspended,
    setIsSuspended,
    setFullName,
    setTimezone,
    selectedTimezone,
    isSingleUser,
    allLabels,
    selectedLabels,
    addToSelected,
    removeFromSelected,
    addLabel,
    creatingLabel,
    isAdmin,
    setIsAdmin,
    sendConfirmation,
    setSendConfirmation,
    changeEmail,
    setChangeEmail,
    isEditForm,
    newEmail,
    setNewEmail,
    disableAdminSelection,
}) => {
    const toggleSuspended = () => setIsSuspended(!isSuspended);

    return (
        <div>
            <h4 className="user-form__header">General</h4>
            <div className="mt-3">
                <InputWithError
                    placeholder="Full name"
                    prependIcon={<i className="fa fa-user" />}
                    name="fullName"
                    value={fullName}
                    type="text"
                    onChange={setFullName}
                    errorObj={errors}
                    marginBottomClass="mb-3"
                />
            </div>

            <div className="mt-3">
                <InputWithError
                    placeholder="Email"
                    prependIcon="@"
                    name="email"
                    value={email}
                    type="email"
                    onChange={setEmail}
                    disabled={isEditForm}
                    errorObj={errors}
                    marginBottomClass="mb-3"
                />
            </div>

            <div className="mt-3">
                <SelectInputWithError
                    name="timezone"
                    value={selectedTimezone}
                    type="select"
                    style={{ maxWidth: '350px' }}
                    options={timezones}
                    onChange={setTimezone}
                    prependIcon={<i className="fa fa-globe" />}
                    errorObj={errors}
                    marginBottomClass="mb-3">
                    <option value={''} disabled>
                        Timezone
                    </option>

                    {timezones.map((timezone) => (
                        <option key={timezone.key} value={timezone.key}>
                            {timezone.name}
                        </option>
                    ))}
                </SelectInputWithError>
            </div>

            {isEditForm && changeEmail && (
                <div className="mt-3">
                    <InputWithError
                        placeholder="New email"
                        prependIcon="@"
                        name="plannedEmail"
                        value={newEmail}
                        type="email"
                        onChange={setNewEmail}
                        errorObj={errors}
                        autoComplete={'off'}
                    />
                </div>
            )}

            {isEditForm ? (
                <div>
                    <div className="d-flex">
                        <CheckboxInput name="changeEmail" text="Change Email" checked={changeEmail} onChange={() => setChangeEmail(!changeEmail)} />

                        {changeEmail && (
                            <CheckboxInput
                                name="sendConfirmation"
                                text="Send Confirmation Email"
                                checked={(changeEmail && isAdmin) || (changeEmail && sendConfirmation)}
                                disabled={changeEmail && isAdmin}
                                onChange={() => setSendConfirmation(!sendConfirmation)}
                            />
                        )}
                    </div>

                    <div className="mt-3">
                        <CheckboxInput
                            name="isAdmin"
                            text="Administrator"
                            checked={isAdmin}
                            disabled={disableAdminSelection}
                            onChange={() => setIsAdmin(!isAdmin)}
                        />
                    </div>
                </div>
            ) : (
                <div>
                    <div className="mt-3">
                        <CheckboxInput
                            name="isAdmin"
                            text="Administrator"
                            checked={isAdmin}
                            disabled={disableAdminSelection}
                            onChange={() => setIsAdmin(!isAdmin)}
                        />
                    </div>

                    <div className="mt-3">
                        <CheckboxInput
                            name="sendConfirmation"
                            text="Send Confirmation Email"
                            checked={(changeEmail && isAdmin) || (changeEmail && sendConfirmation)}
                            disabled={changeEmail && isAdmin}
                            onChange={() => setSendConfirmation(!sendConfirmation)}
                        />

                        <span className="gray">(Optional, user can be created without confirmation. The record will be locked until confirmed.)</span>
                    </div>
                </div>
            )}

            {isEditForm && (
                <div className="suspended-toggle-container mt-3">
                    <label>
                        <Toggle defaultChecked={isSuspended} icons={false} onChange={toggleSuspended} />
                    </label>

                    <span id="suspend-label">Suspend Daily Email Delivery</span>
                </div>
            )}

            {!isSingleUser && (
                <div className="mt-3">
                    <RelevanceContainerLabels
                        selectedLabels={selectedLabels}
                        labels={allLabels}
                        removeFromSelected={removeFromSelected}
                        addToSelected={addToSelected}
                        addLabel={addLabel}
                        creatingLabel={creatingLabel}
                    />
                </div>
            )}
        </div>
    );
};
