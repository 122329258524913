import React, { useState, useEffect } from 'react';
import { Modal, ModalFooter, Button } from 'reactstrap';
import { useQuery } from 'react-query';
import { addAlertGroup, editAlertGroup, getAlertsGroupList } from 'utils/api/alertsAPI';
import { SpinnerButton } from 'common/SpinnerButton';
import { DualSelect } from 'common/DualSelect';
import { LoadingSpinner } from 'common/LoadingSpinner';
import { InputWithError } from 'common/InputWithError';
import { ModalBodyWithClose } from 'common/ModalBodyWithClose';

export const CreateEditGroupModal = ({ isOpen, toggle, handleError, alertGroup, addFlashMessage, refreshAlertGroups, isEdit = false }) => {
    const [name, setName] = useState('');
    const [selectedAlerts, setSelectedAlerts] = useState([]);
    const [submitting, setSubmitting] = useState(false);
    const [errors, setErrors] = useState(null);

    const { data, isLoading } = useQuery(['alertsGroupList', isEdit], () => getAlertsGroupList(), {
        onError: (err) => handleError(err, setErrors, addFlashMessage),
    });

    useEffect(() => {
        if (alertGroup) {
            setName(alertGroup.name);
            setSelectedAlerts(alertGroup.alerts);
        }
    }, [alertGroup]);

    const handleSubmit = async () => {
        try {
            setSubmitting(true);
            const entities = selectedAlerts.map((item) => item.id);

            if (isEdit) {
                await editAlertGroup(alertGroup.id, { name, entities });
            } else {
                await addAlertGroup({ name, entities });
            }

            await refreshAlertGroups();
            addFlashMessage('success', 'Alert group successfully edited');
            setSubmitting(false);
            toggle();
        } catch (err) {
            setSubmitting(false);

            if (isEdit) {
                handleError(err, setErrors);
            } else {
                handleError(err, setErrors, addFlashMessage);
            }
        }
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle} className="modal-xl">
            {isEdit && !alertGroup ? null : isLoading ? (
                <LoadingSpinner padding text="Fetching alert data" />
            ) : (
                <div>
                    <ModalBodyWithClose toggle={toggle}>
                        <h2>{isEdit ? 'Edit Group' : 'Create Group'}</h2>
                        <InputWithError
                            prependIcon={<i className="fa fa-tag" />}
                            placeholder="Alert group name"
                            name="name"
                            value={name}
                            type="text"
                            autoComplete="off"
                            onChange={setName}
                            errorObj={errors}
                        />
                        <div className="mt-3">
                            <DualSelect
                                selectedList={selectedAlerts}
                                fullList={data?.result}
                                onSelect={setSelectedAlerts}
                                contentType="users"
                                isSourcesOnly={true}
                            />
                        </div>
                    </ModalBodyWithClose>
                    <ModalFooter>
                        <Button color="secondary" onClick={toggle}>
                            Cancel
                        </Button>
                        <SpinnerButton
                            type="modal"
                            color="primary"
                            onClick={handleSubmit}
                            submitting={submitting}
                            title={isEdit ? 'Update' : 'Create'}
                        />
                    </ModalFooter>
                </div>
            )}
        </Modal>
    );
};
