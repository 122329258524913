import React, { useContext, useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { Col, Row, Card, CardHeader, CardBody } from 'reactstrap';
import { cloneDeep, isEqual } from 'lodash';
import { AuthContext } from 'contexts/AuthContext';
import { LoadingSpinner } from 'common/LoadingSpinner';
import { EmptyChart } from 'common/EmptyChart';
import { getDigDeeperContent, getFreeDigDeeperContent } from 'utils/api/alertsAPI';
import { ArticlesPerDayChart } from './Charts/ArticlesPerDayChart';
import { SentimentChart } from './Charts/SentimentChart';
import { ArticleList } from './List/ArticleList';
import Logo from 'images/logo.png';
import './style.scss';

export const DigDeeper = ({ isPublic }) => {
    const { id } = useParams();
    const { search } = useLocation();

    const { handleError } = useContext(AuthContext);
    const [visibleTags, setVisibleTags] = useState([]);
    const [filteredArticleList, setFilteredArticleList] = useState([]);
    const [activeTag, setActiveTag] = useState('');
    const [activeDate, setActiveDate] = useState('');
    const [activeDateNews, setActiveDateNews] = useState([]);
    const [colorArray, setColorArray] = useState([]);
    const [mainColor, setMainColor] = useState('rgba(37,102,166,.5)');
    const [activeIndex, setActiveIndex] = useState(-1);

    const { data: alertData, isLoading } = useQuery(
        ['dig-deeper', id],
        () => (id ? (isPublic ? getFreeDigDeeperContent(id, search.slice(3, search.length), 30) : getDigDeeperContent(id, 30)) : null),
        {
            onSuccess: (res) => {
                const newColorArray = [];

                res.newsPerDay.map(() => {
                    newColorArray.push({
                        backgroundColor: 'rgba(37,102,166,.5)',
                        borderColor: 'rgba(37,102,166,1)',
                        hoverBackgroundColor: 'rgba(37,102,166,0.9)',
                        hoverBorderColor: 'rgba(37,102,166,1)',
                    });
                });

                setVisibleTags(res.tags);
                setColorArray(newColorArray);
                setFilteredArticleList(res.news);
            },
            onError: (err) => {
                handleError(err, null, true);
            },
        }
    );

    const resetAllFilters = () => {
        setActiveTag('');
        setActiveDate(null);
        setActiveDateNews([]);
        resetColor();
    };

    const resetColor = () => {
        let newColorArray = [...colorArray];

        newColorArray.map((item) => {
            item.backgroundColor = mainColor;
            item.borderColor = 'rgba(37,102,166,1)';
            item.hoverBackgroundColor = 'rgba(37,102,166,0.9)';
            item.hoverBorderColor = 'rgba(37,102,166,1)';
            return item;
        });
        setColorArray(newColorArray);
        setActiveIndex(-1);
    };

    const changeColor = (index) => {
        resetColor();
        let newColorArray = [...colorArray];

        newColorArray[index] = {
            backgroundColor: 'rgba(31, 128, 224,.9)',
            borderColor: 'rgba(31, 128, 224,1)',
            hoverBackgroundColor: 'rgba(31, 128, 224,0.9)',
            hoverBorderColor: 'rgba(31, 128, 224,1)',
        };
        setColorArray(newColorArray);
        setActiveIndex(index);
    };

    const handleTagSelect = (tag) => {
        setActiveTag(isEqual(activeTag, tag) ? '' : tag);
    };

    const handleDateSelect = (date) => {
        if (isEqual(activeDate, date)) {
            setActiveDate('');
            setActiveDateNews([]);
        } else {
            setActiveDate(date?.day ?? '');
            setActiveDateNews(date?.news ?? '');
        }
    };

    const applyDateFilterToTags = () => {
        const tags = cloneDeep(visibleTags);

        tags?.forEach((item) => {
            item.tags = item.tags.filter((innerItem) => {
                return innerItem.news.some((tag) => {
                    return activeDateNews.indexOf(tag) > -1;
                });
            });
        });
        setVisibleTags(tags);
    };

    useEffect(() => {
        if (activeDateNews && activeDateNews.length) {
            applyDateFilterToTags();
        } else {
            alertData?.tags && setVisibleTags(alertData.tags);
        }
    }, [activeDateNews]);

    const applyFilters = (articleArray) => {
        let filteredArray = [...articleArray];

        if (activeTag) {
            filteredArray = filteredArray.filter((article, index) => article[activeTag.ind].includes(activeTag.name));
        }

        if (activeDate) {
            filteredArray = filteredArray.filter((article) => article.created_at?.slice(0, 10) === activeDate);
        }

        setFilteredArticleList(filteredArray);
    };

    useEffect(() => {
        alertData?.news && applyFilters(alertData.news);
    }, [activeTag, activeDate]);

    return (
        <div className={isPublic ? 'container dig-deeper--public ' : ''}>
            {isLoading ? (
                <Row className="mt-3">
                    <Col xs="12">
                        <LoadingSpinner text="Fetching alert data" />
                    </Col>
                </Row>
            ) : (
                alertData && (
                    <Row className="mb-4">
                        <Col xs="12">
                            <div className="d-flex justify-content-between">
                                <div className="dig-deeper__header-container">
                                    <h1 className="mb-0">{alertData.alertName}</h1>

                                    {alertData.categories && alertData.categories.hasOwnProperty('name') && (
                                        <div className="alert-category">
                                            <div className="alert-category__type">{alertData.categories.type}</div>
                                            {alertData.categories.name}
                                        </div>
                                    )}
                                </div>

                                {isPublic && (
                                    <div className="public-dig-deeper-logo">
                                        <img src={Logo} alt="Logo" />
                                    </div>
                                )}
                            </div>
                        </Col>

                        <Col xs="12">
                            <Row>
                                <Col xs="12" md="12" lg={alertData.news && alertData.news.length > 0 ? '7' : '12'}>
                                    <Card>
                                        <CardHeader>Articles per Day (Last 30 Days)</CardHeader>
                                        <CardBody>
                                            {isLoading ? (
                                                <LoadingSpinner />
                                            ) : alertData.news && alertData.news.length > 0 ? (
                                                <ArticlesPerDayChart
                                                    articles={filteredArticleList}
                                                    articlesPerDay={alertData.newsPerDay}
                                                    handleDateSelect={handleDateSelect}
                                                    resetAllFilters={resetAllFilters}
                                                    activeIndex={activeIndex}
                                                    changeColor={changeColor}
                                                    resetColor={resetColor}
                                                    colorArray={colorArray}
                                                />
                                            ) : (
                                                <EmptyChart isAlerts />
                                            )}
                                        </CardBody>
                                    </Card>
                                </Col>

                                {alertData.trader_sma && alertData.news && alertData.news.length > 0 && (
                                    <Col xs="12" md="12" lg="5">
                                        <Card>
                                            <CardHeader className="sma-header" style={{ height: '46px', maxHeight: '46px' }}>
                                                Sentiment Analysis
                                            </CardHeader>

                                            <CardBody>
                                                <SentimentChart data={alertData.trader_sma} />
                                            </CardBody>
                                        </Card>
                                    </Col>
                                )}
                            </Row>

                            {alertData.news && alertData.news.length > 0 && (
                                <ArticleList
                                    articles={filteredArticleList}
                                    articlesWithoutDuplicates={filteredArticleList.filter((item) => !item.hasOwnProperty('parent'))}
                                    activeTag={activeTag}
                                    activeDate={activeDate}
                                    resetAllFilters={resetAllFilters}
                                    alertId={id}
                                    alertName={alertData.alertName}
                                    handleTagSelect={handleTagSelect}
                                />
                            )}
                        </Col>
                    </Row>
                )
            )}
        </div>
    );
};
