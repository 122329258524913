import React from 'react';
import { FormGroup, Input } from 'reactstrap';

export const UserGroupsMobileFilterDropdown = ({ activeMobileFilterValue, handleMobileFilterSelect }) => (
    <div className="list__mobile-select">
        <FormGroup className="m-0">
            <Input
                onChange={(e) => handleMobileFilterSelect(e.target.value)}
                value={activeMobileFilterValue}
                type="select"
                name="userGroupsMobileFilters">
                <option value="name-asc">Name(A-Z)</option>
                <option value="name-desc">Name(Z-A)</option>
                <option value="users-asc">User Count(Ascending)</option>
                <option value="users-desc">User Count(Descending)</option>
            </Input>
        </FormGroup>
    </div>
);
