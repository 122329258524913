import React from 'react';
import { Input } from 'reactstrap';
import { useDevice } from 'hooks/useMediaQuery';

export const FilterDropdown = ({ title, activeFilter, setActiveFilter, list }) => {
    const { isMobile } = useDevice();
    const activeCategoryName = title === 'AI Category' && activeFilter && list?.find((item) => item.id === Number(activeFilter));
    const activeCategoryLength = activeCategoryName && activeCategoryName?.name?.length;
    const handleSortDropdownSelect = (val) => setActiveFilter(val);

    return (
        <div className="d-flex align-items-center md-2 ml-2">
            {!isMobile && (
                <>
                    <span style={{ color: 'black', width: '65px' }}>Filter by</span> &nbsp;
                </>
            )}
            <div className="m-0 mr-2">
                <Input
                    style={{ width: activeCategoryLength ? `${7 * activeCategoryLength + 25}px` : '80px' }}
                    type="select"
                    name="select"
                    placeholder={title}
                    value={activeFilter || ''}
                    onChange={(e) => handleSortDropdownSelect(e.target.value)}
                    className="countries-select">
                    <option value="">{title}</option>

                    {list &&
                        list.length > 0 &&
                        list.map((item) => (
                            <option key={item.id} value={item.id}>
                                {item.name}
                            </option>
                        ))}
                </Input>
            </div>
        </div>
    );
};
