import React, { useState, useEffect, useContext } from 'react';
import { Modal } from 'reactstrap';
import { FlashMessageContext } from 'contexts/FlashMessageContext';
import { AuthContext } from 'contexts/AuthContext';
import { LoadingSpinner } from 'common/LoadingSpinner';
import { editNewsletterById } from 'utils/api/newslettersAPI';
import { NewsletterForm } from './NewsletterForm';
import './style.scss';

export const EditNewsletterModal = ({ isOpen, toggle, refreshNewsletters, newsletter }) => {
    const { addFlashMessage } = useContext(FlashMessageContext);
    const { handleError } = useContext(AuthContext);
    const [submitting, setSubmitting] = useState(false);
    const [fetchingData, setFetchingData] = useState(true);
    const [errors, setErrors] = useState(null);

    useEffect(() => {
        if (newsletter) setFetchingData(false);
    }, [newsletter]);

    const handleUpdateNewsletter = async (params) => {
        setSubmitting(true);

        try {
            setErrors(null);
            await editNewsletterById(newsletter.id, params);
            refreshNewsletters();
            addFlashMessage('success', 'Newsletter successfully updated');
            toggle();
        } catch (err) {
            handleError(err, setErrors);
        }

        setSubmitting(false);
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle} className="edit-newsletter-modal modal-lg">
            {fetchingData ? (
                <LoadingSpinner text="Fetching newsletter data" padding />
            ) : (
                <NewsletterForm
                    isEditForm
                    foundNewsletter={newsletter}
                    formTitle="Edit Newsletter"
                    submitting={submitting}
                    onSubmit={handleUpdateNewsletter}
                    toggle={toggle}
                    submitButtonTitle="Update"
                    errors={errors}
                    setErrors={setErrors}
                />
            )}
        </Modal>
    );
};
