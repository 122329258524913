import React, { useRef, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { useOnClickOutside } from 'hooks/useOnClickOutside';
import { getWikiResults } from 'utils/api/alertsAPI';
import './style.scss';

export const TextareaAutocomplete = ({
    value,
    searchValueHasError,
    error,
    onClear,
    onPressEnter,
    handleInternalSearchValue,
    handleAutocompleteSelect,
    onSubmit,
    preSubmitError,
    setPreSubmitError,
}) => {
    const [items, setItems] = useState([]);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const ref = useRef();
    useOnClickOutside(ref, () => setDropdownOpen(false));

    const handleChange = async (e) => {
        const query = e.target.value;
        const wordsLength = query.split(' ')?.length;
        handleInternalSearchValue(query);

        const format = /[!@#$%^*_+=\[\]{};\\|,.<>\/?]/;
        const strippedOfSpaces = e.target.value.replace(/ /g, '');

        if (format.test(e.target.value) || (strippedOfSpaces.includes('-') && strippedOfSpaces.indexOf('-') < 3)) {
            const errObj = {
                code: 'VALIDATION_FAILED',
                errors: {
                    keyword: [
                        {
                            message: format.test(e.target.value)
                                ? 'Keyword search contains invalid characters.'
                                : 'The search keyword cannot start with excluded keywords.',
                            code: 'INVALID_CHARACTERS_ERROR',
                            payload: null,
                        },
                    ],
                },
            };
            return setPreSubmitError(errObj);
        } else {
            setPreSubmitError(null);
        }

        if (query.length > 2 && wordsLength < 3 && !isSubmitting) {
            const options = await getWikiResults(query);
            setItems(options.map((item) => ({ label: item })));

            setDropdownOpen(options.length > 0);
        }
    };

    const handleClear = () => {
        setItems([]);
        setDropdownOpen(false);
        setIsSubmitting(false);
        onClear();
    };

    const onAutocompleteSelect = (term) => {
        handleAutocompleteSelect(term);
        setIsSubmitting(true);
        setDropdownOpen(false);
    };

    const handlePressEnter = (e) => {
        onPressEnter(e);
        setDropdownOpen(false);
    };

    const handleSubmit = () => {
        dropdownOpen && setDropdownOpen(false);
        onSubmit();
    };

    return (
        <div style={{ width: '100%', minHeight: 35.65, position: 'relative' }} ref={ref}>
            <TextareaAutosize
                placeholder="Search for anything... keyword(s) or topics"
                className="internal-search-textarea"
                value={value}
                style={{
                    width: '100%',
                    paddingRight: '65px',
                    border: error || preSubmitError ? '1px solid red' : '',
                }}
                onChange={handleChange}
                onKeyDown={handlePressEnter}
            />
            <div className="search-input__icon-container d-flex" style={{ zIndex: 2, right: '12px', paddingRight: '.3rem' }}>
                {value.length > 0 && <i className="fa fa-times-circle search-input__icon search-input__icon--close" onClick={handleClear} />}
                <i className="fa fa-search search-input__icon search-input__icon--search" onClick={handleSubmit} />
            </div>

            <div style={{ position: 'relative', width: '100%' }}>
                <div className="textarea-autocomplete" style={{ display: dropdownOpen && !searchValueHasError ? 'block' : 'none' }}>
                    {items.map((item, index) => (
                        <div className="textarea-autocomplete-list-item" key={index} onClick={() => onAutocompleteSelect(item.label)}>
                            {item.label}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};
