import React, { useState, useEffect } from 'react';
import { DualSelect } from 'common/DualSelect';
import { LoadingSpinner } from 'common/LoadingSpinner';
import { getAlertGroups, getAlertsWithoutParams } from 'utils/api/alertsAPI';

export const AssignAlerts = ({ selectedAlerts, setSelectedAlerts, addFlashMessage, fetchingSelectedAlerts }) => {
    const [alerts, setAlerts] = useState([]);
    const [alertGroups, setAlertGroups] = useState([]);
    const [fetchingAlerts, setFetchingAlerts] = useState(true);

    useEffect(() => {
        fetchAlerts();
    }, []);

    const fetchAlerts = async () => {
        try {
            const alertData = await getAlertsWithoutParams();
            const alertGroupData = await getAlertGroups();
            setAlerts(alertData.result);
            setAlertGroups(alertGroupData);
            setFetchingAlerts(false);
        } catch (err) {
            addFlashMessage('danger', 'Unable to fetch alert data');
            setFetchingAlerts(false);
        }
    };

    return (
        <div className="mb-3">
            <h4 className="user-form__header">Assign Content</h4>

            <div className="mt-3">
                {fetchingAlerts || fetchingSelectedAlerts ? (
                    <LoadingSpinner />
                ) : (
                    <DualSelect
                        selectedList={selectedAlerts}
                        fullList={alerts}
                        onSelect={setSelectedAlerts}
                        contentType="alerts"
                        groups={alertGroups}
                        withConfirm
                    />
                )}
            </div>
        </div>
    );
};
