import React from 'react';
import { truncate } from 'lodash';
import { convertDateString, truncateWithoutWordBreak, truncateLongWords } from 'utils/helpers';

export const Article = ({ handleAddArticle, article, includeAbstracts }) => {
    const { title, resource, createdAt, id, abstract } = article;

    return (
        <div className="mobile-newsletter-article" id={id}>
            <div className="flex-grow-1">
                {title.length > 100 ? (
                    <div className="mobile-newsletter-article__title">
                        <strong>{truncateWithoutWordBreak(truncateLongWords(title, 50), 100)}</strong>
                    </div>
                ) : (
                    <div className="mobile-newsletter-article__title">
                        <strong>{title}</strong>
                    </div>
                )}

                {includeAbstracts && (
                    <div className="mobile-newsletter-article__abstract">
                        {truncate(truncateLongWords(abstract, 50), { length: 150, separator: / / })}
                    </div>
                )}

                <div className="mobile-newsletter-article__info d-flex justify-content-between">
                    <div className="mobile-newsletter-article__publisher">{truncateLongWords(resource, 80)}</div>
                    <div className="mobile-newsletter-article__date">{convertDateString(createdAt)}</div>
                </div>
            </div>

            <div className="mobile-newsletter-article__actions">
                <div onClick={() => handleAddArticle(id)}>
                    <i className="fa fa-plus" />
                </div>
            </div>
        </div>
    );
};
