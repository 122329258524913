import React, { useMemo } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import AuthContextProvider from 'contexts/AuthContext';
import FlashMessageContextProvider from 'contexts/FlashMessageContext';
import FilterContextProvider from 'contexts/FilterContext';
import { FlashMessagesContainer } from './common/FlashMessage/FlashMessagesContainer';
import ErrorBoundary from './common/ErrorBoundary';
import { AppRoutes } from './routes/AppRoutes';

const App = () => {
    const queryClient = useMemo(
        () =>
            new QueryClient({
                defaultOptions: {
                    queries: {
                        refetchOnWindowFocus: false,
                        retry: false,
                    },
                },
            }),
        []
    );

    return (
        <Router>
            <QueryClientProvider client={queryClient}>
                <FlashMessageContextProvider>
                    <AuthContextProvider>
                        <FilterContextProvider>
                            <div style={{ height: '100%' }}>
                                <FlashMessagesContainer />
                                <ErrorBoundary>
                                    <AppRoutes />
                                </ErrorBoundary>
                            </div>
                        </FilterContextProvider>
                    </AuthContextProvider>
                </FlashMessageContextProvider>
            </QueryClientProvider>
        </Router>
    );
};

export default App;
