import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalFooter } from 'reactstrap';
import { SpinnerButton } from 'common/SpinnerButton';
import { DualSelect } from 'common/DualSelect';
import { LoadingSpinner } from 'common/LoadingSpinner';
import { ModalBodyWithClose } from 'common/ModalBodyWithClose';
import { assignUsersToNewsletter } from 'utils/api/newslettersAPI';
import { getUserGroups, getUsersWithoutParams } from 'utils/api/usersAPI';

export const AssignNewsletterModal = ({ isOpen, toggle, refreshNewsletter, newsletter, addFlashMessage }) => {
    const [submitting, setSubmitting] = useState(false);
    const [users, setUsers] = useState([]);
    const [userGroups, setUserGroups] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [fetchingUsers, setFetchingUsers] = useState(true);

    useEffect(() => {
        if (newsletter) {
            setSelectedUsers(newsletter.assignedUsers);
            fetchUsers();
        }
    }, []);

    const fetchUsers = async () => {
        try {
            const userData = await getUsersWithoutParams(false, false, 1, 2000);
            const userGroupData = await getUserGroups();

            setUsers(userData.result);
            setUserGroups(userGroupData);
            setUsers(userData.result);
        } catch (err) {
            addFlashMessage('danger', 'Unable to fetch users');
        }

        setFetchingUsers(false);
    };

    const onSubmit = async () => {
        setSubmitting(true);

        try {
            const params = { users: selectedUsers.map((user) => user.id) };
            await assignUsersToNewsletter(newsletter.id, params);
            await refreshNewsletter();
            addFlashMessage('success', 'Newsletter successfully updated');
            toggle();
        } catch (err) {
            addFlashMessage('danger', 'Unable to update newsletter at this time');
        }

        setSubmitting(false);
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle} className="assign-newsletter-modal modal-xl">
            {fetchingUsers ? (
                <LoadingSpinner padding text="Fetching users" />
            ) : (
                <>
                    <ModalBodyWithClose toggle={toggle}>
                        <h2>Assign Newsletter</h2>

                        <div>
                            <div className="assign-newsletter__info mb-3">
                                <h6 className="mt-2">{newsletter.name}</h6>
                            </div>

                            <div className="mt-5">
                                <DualSelect
                                    selectedList={selectedUsers}
                                    fullList={users}
                                    groups={userGroups}
                                    onSelect={setSelectedUsers}
                                    contentType="users"
                                />
                            </div>
                        </div>
                    </ModalBodyWithClose>

                    <ModalFooter>
                        <Button color="secondary" onClick={toggle}>
                            Cancel
                        </Button>

                        <SpinnerButton type="modal" color="primary" onClick={onSubmit} submitting={submitting} title="Assign" />
                    </ModalFooter>
                </>
            )}
        </Modal>
    );
};
