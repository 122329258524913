import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { Col, Row } from 'reactstrap';
import { isEmpty } from 'lodash';
import moment from 'moment-timezone';
import { FlashMessageContext } from 'contexts/FlashMessageContext';
import { AuthContext } from 'contexts/AuthContext';
import { TabBar } from 'common/TabBar';
import { SpinnerButton } from 'common/SpinnerButton';
import { LoadingSpinner } from 'common/LoadingSpinner';
import { updateUserById, addUserLabel, getUserById, getUserLabels, getUserAlertsById, addUser } from 'utils/api/usersAPI';
import { useDevice } from 'hooks/useMediaQuery';
import { timezones } from 'constants/timezones';
import { General } from './General';
import { EmailSchedule } from './EmailSchedule';
import { AssignAlerts } from './AssignAlerts';
import './style.scss';

export const CreateEditUser = ({ isEdit = false }) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { addFlashMessage } = useContext(FlashMessageContext);
    const { state, handleError } = useContext(AuthContext);
    const { isMobile } = useDevice();

    const [activeView, setActiveView] = useState('general');
    const [timezone, setTimezone] = useState('');
    const [email, setEmail] = useState('');
    const [fullName, setFullName] = useState('');
    const [sendConfirmation, setSendConfirmation] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [isSuspended, setIsSuspended] = useState(false);
    const [selectedAlerts, setSelectedAlerts] = useState([]);
    const [selectedLabels, setSelectedLabels] = useState([]);
    const [submitting, setSubmitting] = useState(false);
    const [creatingLabel, setCreatingLabel] = useState(false);
    const [newLabelToAdd, setNewLabelToAdd] = useState(null);
    const [useCustomSchedule, setUseCustomSchedule] = useState(false);
    const [frequency, setFrequency] = useState('once');
    const [firstAlertDays, setFirstAlertDays] = useState([]);
    const [firstAlertHour, setFirstAlertHour] = useState('9');
    const [firstAlertPeriod, setFirstAlertPeriod] = useState('AM');
    const [secondAlertDays, setSecondAlertDays] = useState([]);
    const [secondAlertHour, setSecondAlertHour] = useState('5');
    const [secondAlertPeriod, setSecondAlertPeriod] = useState('PM');
    const [fetchingScheduleData, setFetchingScheduleData] = useState(isEdit);
    const [fetchingUserData, setFetchingUserData] = useState(isEdit);
    const [fetchingAlertData, setFetchingAlertData] = useState(isEdit);
    const [changeEmail, setChangeEmail] = useState(false);
    const [foundUser, setFoundUser] = useState(null);
    const [newEmail, setNewEmail] = useState('');
    const [disableAdminSelection, setDisableAdminSelection] = useState(false);
    const [errors, setErrors] = useState(false);
    const [timezoneDisplay, setTimezoneDisplay] = useState(false);

    const { data: allLabels, refetch: refetchLabels } = useQuery('getUserLabels', () => getUserLabels(), {
        onError: () => addFlashMessage('danger', 'Unable to fetch label data'),
    });

    const fetchUserData = async (id) => {
        try {
            let userData = await getUserById(id);
            setFoundUser(userData);
            setTimezone(userData.timezone ? userData.timezone : state.profile.organization.timezone);
            setFullName(userData.name);
            setEmail(userData.email);
            setIsAdmin(userData.roles[0] === 'ROLE_ADMIN');
            setIsSuspended(userData.suspendSubscriber);

            if (state.profile.email === userData.email) {
                setDisableAdminSelection(true);
            }
            setSelectedLabels(userData.labels);

            if (userData.schedule?.daily) {
                const first = userData.schedule?.daily?.first;
                const second = userData.schedule?.daily?.second;

                if (first) {
                    setUseCustomSchedule(true);
                    setFirstAlertDays(first.days_of_week);
                    destructureTime(first.run_time, 'first');
                }

                if (second) {
                    setFrequency('twice');
                    setSecondAlertDays(second.days_of_week);
                    destructureTime(second.run_time, 'second');
                }
            }
            setFetchingScheduleData(false);
        } catch (err) {
            addFlashMessage('danger', 'Unable to fetch user data');
        }

        setFetchingUserData(false);
    };

    const destructureTime = (time, schedule) => {
        const hour = moment(time, ['hh:mm A']).format('h:mm');
        const period = moment(time, ['hh:mm A']).format('A');

        if (schedule === 'first') {
            setFirstAlertPeriod(period);
            setFirstAlertHour(hour);
        } else {
            setSecondAlertPeriod(period);
            setSecondAlertHour(hour);
        }
    };

    const fetchUserAlertData = async (id) => {
        try {
            let userAlerts = await getUserAlertsById(id);
            setSelectedAlerts(userAlerts.alerts);
            setFetchingAlertData(false);
        } catch (err) {
            addFlashMessage('danger', 'Unable to fetch alert data');
            setFetchingAlertData(false);
        }
    };

    const handleSubmit = async () => {
        if (isEdit && state.profile.email === foundUser.email && isAdmin !== foundUser.enabled) {
            addFlashMessage('danger', 'You cannot change administrator status for your own account');
            return;
        }
        if (useCustomSchedule && frequency === 'twice' && firstAlertHour === secondAlertHour && firstAlertPeriod === secondAlertPeriod) {
            const customError = {
                code: 'VALIDATION_FAILED',
                errors: { sameSchedule: [{ message: 'Alert schedule times must be unique', code: 'IS_BLANK_ERROR', payload: null }] },
                message: 'Validation failed',
            };
            handleError(customError, setErrors);
            return;
        }
        try {
            setSubmitting(true);
            setErrors(null);

            const firstScheduleParams = {
                run_time: moment(firstAlertHour + firstAlertPeriod, ['h:mmA']).format('HH:mm'),
                days_of_week: firstAlertDays.sort(),
            };

            const secondScheduleParams =
                frequency === 'twice'
                    ? {
                          run_time: moment(secondAlertHour + secondAlertPeriod, ['hh:mm A']).format('HH:mm'),
                          days_of_week: secondAlertDays.sort(),
                      }
                    : null;

            const params = {
                email,
                ...(isEdit ? { plannedEmail: changeEmail ? newEmail : null } : {}),
                targetUrl: window.location.protocol + '//' + window.location.host + '/invite',
                fullName,
                admin: isAdmin,
                suspend: isSuspended,
                withoutEmailSend: isEdit && isAdmin ? false : !sendConfirmation,
                timezone,
                alerts: selectedAlerts.map((alert) => alert.id),
                userLabels: selectedLabels.map((label) => +label.id),
                schedule: useCustomSchedule
                    ? {
                          daily: {
                              first: firstScheduleParams,
                              ...(secondScheduleParams ? { second: secondScheduleParams } : {}),
                          },
                      }
                    : null,
            };

            isEdit ? await updateUserById(id, params) : await addUser(params);

            addFlashMessage('success', `User successfully ${isEdit ? 'updated' : 'created'}`);
            navigate('/account/users');
        } catch (err) {
            setSubmitting(false);
            handleError(err, setErrors);
        }
    };

    const addLabel = async (name) => {
        setCreatingLabel(true);

        try {
            const newLabel = await addUserLabel({ name });
            setNewLabelToAdd(newLabel.content);
            await refetchLabels();
        } catch (res) {
            if (res?.errors?.name[0]?.message) {
                addFlashMessage('danger', res.errors.name[0].message);
            }
        }

        setCreatingLabel(false);
    };

    const addToSelected = (label) => {
        const newSelectedLabels = [...selectedLabels];

        if (newSelectedLabels.indexOf(label) === -1) {
            newSelectedLabels.push(label);
            setSelectedLabels(newSelectedLabels);
        }
    };

    const removeFromSelected = (label) => {
        const newSelectedLabels = [...selectedLabels];

        if (newSelectedLabels.indexOf(label) > -1) {
            newSelectedLabels.splice(newSelectedLabels.indexOf(label), 1);
            setSelectedLabels(newSelectedLabels);
        }
    };

    useEffect(() => {
        id && !isEmpty(state.profile) && fetchUserData(id);
    }, [id, state.profile]);

    useEffect(() => {
        const foundTimezone = timezones.find((obj) => obj.key === timezone);
        foundTimezone && setTimezoneDisplay(foundTimezone.name);
    }, [timezone]);

    useEffect(() => {
        foundUser && foundUser.confirmed && fetchUserAlertData(id);
    }, [foundUser]);

    useEffect(() => {
        if (newLabelToAdd) {
            const foundLabel = allLabels.find((label) => label.id === newLabelToAdd.id);
            foundLabel && addToSelected(foundLabel);
            setNewLabelToAdd(null);
        }
    }, [allLabels]);

    if (isEdit && fetchingUserData) return <LoadingSpinner padding text="Fetching user data" />;
    if (isEdit && !fetchingUserData && !foundUser) return <div>Unable to retrieve user data. Please try again later</div>;

    return (
        <div className="animated fadeIn view create-edit-user">
            <Row className="mt-3">
                <Col xs="12" className="col-no-padding-mobile">
                    <div className="add-user">
                        <h1>{isEdit ? 'Edit' : 'Create'} User</h1>

                        {isEdit && (
                            <>
                                <h5 className="add-user" style={{ color: '#656565' }}>
                                    {foundUser.email}
                                </h5>

                                {foundUser && !foundUser.confirmed && (
                                    <div className="mt-6 mb-3">
                                        <div className="error-block d-flex">
                                            <i className="fa fa-exclamation-circle" />
                                            <div>This user has not confirmed their account and cannot be edited at this time.</div>
                                        </div>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                    {(!isEdit || foundUser.confirmed) && (
                        <>
                            <TabBar
                                activeItem={activeView}
                                setActive={setActiveView}
                                errorObj={errors}
                                shrink
                                items={[
                                    { data: 'general', name: 'General' },
                                    { data: 'assignAlerts', name: 'Assign Content' },
                                    { data: 'emailSchedule', name: 'Schedule' },
                                ]}
                            />

                            {activeView === 'general' && (
                                <General
                                    selectedTimezone={timezone}
                                    setTimezone={setTimezone}
                                    allLabels={allLabels}
                                    selectedLabels={selectedLabels}
                                    addToSelected={addToSelected}
                                    removeFromSelected={removeFromSelected}
                                    addLabel={addLabel}
                                    creatingLabel={creatingLabel}
                                    sendConfirmation={sendConfirmation}
                                    setSendConfirmation={setSendConfirmation}
                                    isAdmin={isAdmin}
                                    setIsAdmin={setIsAdmin}
                                    fullName={fullName}
                                    setFullName={setFullName}
                                    email={email}
                                    setEmail={setEmail}
                                    changeEmail={changeEmail}
                                    setChangeEmail={setChangeEmail}
                                    isEditForm={isEdit}
                                    disableAdminSelection={disableAdminSelection}
                                    errors={errors}
                                    newEmail={newEmail}
                                    setNewEmail={setNewEmail}
                                    isSuspended={isSuspended}
                                    setIsSuspended={setIsSuspended}
                                    isSingleUser={state.isSingleUser}
                                />
                            )}

                            {activeView === 'assignAlerts' && (
                                <AssignAlerts
                                    addFlashMessage={addFlashMessage}
                                    selectedAlerts={selectedAlerts}
                                    setSelectedAlerts={setSelectedAlerts}
                                    fetchingSelectedAlerts={fetchingAlertData}
                                />
                            )}

                            {activeView === 'emailSchedule' && (
                                <EmailSchedule
                                    useCustomSchedule={useCustomSchedule}
                                    frequency={frequency}
                                    setFrequency={setFrequency}
                                    firstAlertDays={firstAlertDays}
                                    firstAlertHour={firstAlertHour}
                                    firstAlertPeriod={firstAlertPeriod}
                                    secondAlertDays={secondAlertDays}
                                    secondAlertHour={secondAlertHour}
                                    secondAlertPeriod={secondAlertPeriod}
                                    fetchingScheduleData={fetchingScheduleData}
                                    setFirstAlertPeriod={setFirstAlertPeriod}
                                    setFirstAlertHour={setFirstAlertHour}
                                    setSecondAlertPeriod={setSecondAlertPeriod}
                                    setSecondAlertHour={setSecondAlertHour}
                                    setFirstAlertDays={setFirstAlertDays}
                                    setSecondAlertDays={setSecondAlertDays}
                                    setUseCustomSchedule={setUseCustomSchedule}
                                    errors={errors}
                                    timezoneDisplay={timezoneDisplay}
                                />
                            )}
                        </>
                    )}
                </Col>

                {(!isEdit || foundUser.confirmed) && (
                    <Col className={'col-no-padding-mobile ' + (isMobile ? 'mt-2' : 'mt-4')}>
                        <div className="d-flex justify-content-end">
                            <SpinnerButton
                                submitting={submitting}
                                onClick={handleSubmit}
                                color="primary"
                                title={isEdit ? 'Save Changes' : 'Create User'}
                                block={isMobile}
                            />
                        </div>
                    </Col>
                )}
            </Row>
        </div>
    );
};
