import React, { useState, useEffect, useContext } from 'react';
import { Row, Col, FormGroup, Label } from 'reactstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { SpinnerButton } from 'common/SpinnerButton';
import { RadioInput } from 'common/Input';
import { InputWithError } from 'common/InputWithError';
import { SelectInputWithError } from 'common/InputWithError/SelectInputWithError';
import { checkRegistrationToken, finishRegistration } from 'utils/api/authAPI';
import { timezones } from 'constants/timezones';
import { AuthContext } from 'contexts/AuthContext';
import Logo from 'images/logo.png';

export const ConfirmRegistration = () => {
    const navigate = useNavigate();
    const queryParams = useLocation();
    const { signIn, handleError } = useContext(AuthContext);

    const [token, setToken] = useState(null);
    const [tokenChecked, setTokenChecked] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [fullName, setFullName] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [timezone, setTimezone] = useState('');
    const [accountType, setAccountType] = useState('single');
    const [organizationName, setOrganizationName] = useState('');
    const [errors, setErrors] = useState(null);

    const redirectToInvalidLink = () => {
        navigate('/invite-expired');
    };

    const checkToken = async () => {
        try {
            const parsedQuery = queryString.parse(queryParams.search);
            await checkRegistrationToken(parsedQuery.token);

            setToken(parsedQuery.token);
            setTokenChecked(true);
        } catch (err) {
            redirectToInvalidLink();
        }
    };

    const onSubmit = async () => {
        try {
            if (accountType === 'organization' && organizationName === '') {
                setSubmitting(false);
                let err = {
                    code: 'VALIDATION_FAILED',
                    errors: {
                        organizationName: [{ message: 'This field is required', code: 'NULL', payload: null }],
                    },
                    message: 'Validation failed',
                };
                handleError(err, setErrors);
                return;
            }
            setSubmitting(true);

            const params = {
                token,
                plainPassword: {
                    first: password,
                    second: confirmPassword,
                },
                fullName,
                timezone,
                organization: accountType === 'organization' ? organizationName : null,
            };
            const res = await finishRegistration(params);

            signIn(res.token);
            navigate('/account');
        } catch (err) {
            setSubmitting(false);
            handleError(err, setErrors);
        }
    };

    useEffect(() => {
        checkToken();
    }, []);

    if (!tokenChecked) {
        return null;
    }
    return (
        <div className="auth-block">
            <div className="auth-block__form">
                <div className="animated fadeIn">
                    <Row className="justify-content-center mt-5" style={{ marginBottom: '.5rem' }}>
                        <Col md={9}>
                            <div className="auth-block__image-container">
                                <a href="/">
                                    <img src={Logo} alt="Ozmosys" className="auth-block__image img-fluid" />
                                </a>
                            </div>
                            <div>
                                <div className="text-center mb-4">
                                    <h2>Complete Registration</h2>
                                </div>

                                <div>
                                    <FormGroup tag="fieldset">
                                        <RadioInput
                                            onChange={() => setAccountType('single')}
                                            checked={accountType === 'single'}
                                            name="single"
                                            text="Single User"
                                            style={{ marginBottom: '10px' }}
                                        />
                                        <RadioInput
                                            onChange={() => setAccountType('organization')}
                                            checked={accountType === 'organization'}
                                            name="organization"
                                            text="Organization"
                                        />
                                    </FormGroup>
                                </div>

                                {accountType === 'organization' && (
                                    <div>
                                        <Label>Organization Info</Label>
                                        <InputWithError
                                            placeholder="Organization Name"
                                            prependIcon={<i className="fa fa-building" />}
                                            name="organizationName"
                                            value={organizationName}
                                            type="text"
                                            onChange={setOrganizationName}
                                            errorObj={errors}
                                            autoComplete="off"
                                            marginBottomClass="mb-3"
                                        />
                                    </div>
                                )}

                                <div>
                                    {accountType === 'organization' && <Label>User Info</Label>}
                                    <div>
                                        <InputWithError
                                            placeholder="Full Name"
                                            prependIcon={<i className="fa fa-user" />}
                                            name="fullName"
                                            value={fullName}
                                            type="text"
                                            onChange={setFullName}
                                            errorObj={errors}
                                            autoComplete="off"
                                            marginBottomClass="mb-3"
                                        />
                                    </div>
                                </div>

                                <div>
                                    <InputWithError
                                        placeholder="Password (Min 6 Characters)"
                                        prependIcon={<i className="fa fa-lock" />}
                                        name="password"
                                        value={password}
                                        type="password"
                                        onChange={setPassword}
                                        errorObj={errors}
                                        autoComplete="off"
                                        marginBottomClass="mb-3"
                                    />
                                </div>

                                <div>
                                    <InputWithError
                                        placeholder="Confirm Password"
                                        prependIcon={<i className="fa fa-lock" />}
                                        name="plainPassword"
                                        value={confirmPassword}
                                        type="password"
                                        onChange={setConfirmPassword}
                                        errorObj={errors}
                                        autoComplete="off"
                                        marginBottomClass="mb-3"
                                    />
                                </div>

                                <SelectInputWithError
                                    name="timezone"
                                    value={timezone}
                                    type="select"
                                    options={timezones}
                                    onChange={setTimezone}
                                    prependIcon={<i className="fa fa-globe" />}
                                    errorObj={errors}
                                    marginBottomClass="mb-3">
                                    <option value={''} disabled>
                                        Timezone
                                    </option>
                                    {timezones.map((timezone) => {
                                        return (
                                            <option key={timezone.key} value={timezone.key}>
                                                {timezone.name}
                                            </option>
                                        );
                                    })}
                                </SelectInputWithError>

                                <Row>
                                    <Col xs="12" className="text-right">
                                        <SpinnerButton
                                            type="submit"
                                            color="primary"
                                            className="px-4"
                                            block
                                            onClick={onSubmit}
                                            submitting={submitting}
                                            title={'Sign In'}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
};
