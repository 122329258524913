import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import Toggle from 'react-toggle';
import { Section } from './Section';
import { UnassignedSection } from './UnassignedSection';
import './style.scss';

export const Sections = ({
    refreshNewsletter,
    addFlashMessage,
    newsletterData,
    removeAllArticlesFromSection,
    handleSectionSorting,
    savingSectionChanges,
    handleRemoveAssignedArticle,
    moveArticleBetweenSections,
    resortArticlesInSameSection,
    handleError,
    includeAbstracts,
    toggleIncludeAbstracts,
}) => {
    const [sections, setSections] = useState([]);

    useEffect(() => {
        setSections(newsletterData.sections);
    }, [newsletterData.sections]);

    const onDragEnd = (result) => {
        const { destination, source, draggableId, type } = result;

        if (!destination || savingSectionChanges) return;

        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        }

        if (type === 'section') {
            const existingSectionOrder = Array.from(newsletterData.sections);
            const sectionToMove = newsletterData.sections.find((obj) => obj.id == draggableId);

            existingSectionOrder.splice(existingSectionOrder.indexOf(sectionToMove), 1);
            existingSectionOrder.splice(destination.index, 0, sectionToMove);

            const reversedArr = [...existingSectionOrder].reverse();
            setSections(existingSectionOrder);
            const sectionIdArray = reversedArr.map((item, index) => ({ id: item.id, sortOrder: index })).filter((item) => item.sortOrder !== 0);
            handleSectionSorting(sectionIdArray);
        } else {
            if (source.droppableId === destination.droppableId) {
                resortArticlesInSameSection(Number(draggableId), Number(destination.droppableId), Number(destination.index));
            } else {
                moveArticleBetweenSections(
                    Number(draggableId),
                    Number(source.droppableId),
                    Number(destination.droppableId),
                    Number(destination.index) + 1
                );
            }
        }
    };

    return (
        <div className="newsletter-content-panel newsletter-content-panel--left">
            <div className="newsletter-content-panel__header">
                <h4>Sections</h4>

                {savingSectionChanges ? (
                    <div style={{ color: 'gray' }}>Saving...</div>
                ) : (
                    <div className="newsletter-toggle-container flex align-content-center">
                        <span id="suspend-label">View Abstracts</span>

                        <label>
                            <Toggle checked={includeAbstracts} onChange={toggleIncludeAbstracts} icons={false} />
                        </label>
                    </div>
                )}
            </div>

            <div className="newsletter-content-panel__body">
                <div className="newsletter-content-panel__section-instructions">
                    Drag and drop sections or articles to change their order. Click on section titles to edit.
                    <br />
                    <strong>*Removing an article from a section will delete any associated comments.</strong>
                </div>

                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId={'section-list'} type="section">
                        {(provided) => (
                            <div ref={provided.innerRef} className="newsletter-content-panel__section-list" {...provided.droppableProps}>
                                {sections &&
                                    sections.map(
                                        (section, index) =>
                                            section.name !== 'Unassigned' && (
                                                <Section
                                                    section={section}
                                                    index={index}
                                                    key={section.id}
                                                    removeAllArticlesFromSection={removeAllArticlesFromSection}
                                                    handleRemoveAssignedArticle={handleRemoveAssignedArticle}
                                                    newsletterId={newsletterData.id}
                                                    refreshNewsletter={refreshNewsletter}
                                                    addFlashMessage={addFlashMessage}
                                                    handleError={handleError}
                                                    includeAbstracts={includeAbstracts}
                                                />
                                            )
                                    )}

                                {provided.placeholder}

                                {sections.find((section) => section.name === 'Unassigned') && (
                                    <UnassignedSection
                                        section={sections.find((section) => section.name === 'Unassigned')}
                                        removeAllArticlesFromSection={removeAllArticlesFromSection}
                                        handleRemoveAssignedArticle={handleRemoveAssignedArticle}
                                        includeAbstracts={includeAbstracts}
                                        refreshNewsletter={refreshNewsletter}
                                        newsletterId={newsletterData.id}
                                        addFlashMessage={addFlashMessage}
                                    />
                                )}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
        </div>
    );
};
