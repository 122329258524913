import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { Table, Button } from 'reactstrap';
import { SearchInputWithSubmit } from 'common/SearchInput/SearchInputWithSubmit';
import { filterByValue, sortObjects, handleCaret } from 'utils/helpers';
import { useDevice } from 'hooks/useMediaQuery';
import { CreateLabelModal } from './Modals/CreateLabelModal';
import { EditLabelModal } from './Modals/EditLabelModal';
import { DeleteLabelModal } from './Modals/DeleteLabelModal';
import { UserLabelsMobileFilterDropdown } from './UserLabelsMobileFilterDropdown';

export const UserLabelsList = ({ labels, handleError, addFlashMessage, refreshLabels, userLabelFilters, setFiltersObject }) => {
    const { isDesktop, isTablet, isMobile } = useDevice();
    const { searchValue, activeSortProperty, activeSortDirection } = userLabelFilters;

    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [itemToEdit, setItemToEdit] = useState(null);
    const [itemToDelete, setItemToDelete] = useState(null);
    const [tempSearchValue, setTempSearchValue] = useState('');
    const [visibleLabels, setVisibleLabels] = useState([]);
    const [activeMobileFilterValue, setActiveMobileFilterValue] = useState('');

    useEffect(() => {
        tempSearchValue !== searchValue && setTempSearchValue(searchValue);
    }, []);

    useEffect(() => {
        setVisibleLabels(labels);
    }, [labels]);

    useEffect(() => {
        const filteredList = filterByValue(labels, 'name', searchValue);
        const sortedList = sortObjects(filteredList, activeSortProperty, activeSortDirection);
        setVisibleLabels(sortedList);
    }, [userLabelFilters, labels]);

    const toggleCreateModal = () => setCreateModalOpen(!createModalOpen);

    const toggleEditModal = () => {
        editModalOpen && setItemToEdit(null);
        setEditModalOpen(!editModalOpen);
    };

    useEffect(() => {
        itemToEdit && toggleEditModal();
    }, [itemToEdit]);

    const toggleDeleteModal = () => {
        deleteModalOpen && setItemToDelete(null);
        setDeleteModalOpen(!deleteModalOpen);
    };

    useEffect(() => {
        itemToDelete && toggleDeleteModal();
    }, [itemToDelete]);

    const handleSearchValueSubmit = () => {
        if (tempSearchValue.length > 2048) {
            addFlashMessage('danger', 'Your search exceeds the maximum number of allowed characters (2048)');
            return;
        }

        const filters = { ...userLabelFilters };
        filters.searchValue = tempSearchValue;
        setFiltersObject('userLabels', filters);
    };

    const handleClear = () => {
        const filters = { ...userLabelFilters };
        filters.searchValue = '';
        setFiltersObject('userLabels', filters);
        setTempSearchValue('');
    };

    const handleSortableHeaderClick = (value, activeSortProperty, activeSortDirection) => {
        const filters = { ...userLabelFilters };

        if (value === activeSortProperty) {
            activeSortDirection === 'asc' ? (filters.activeSortDirection = 'desc') : (filters.activeSortDirection = 'asc');
        } else {
            filters.activeSortProperty = value;
            filters.activeSortDirection = 'asc';
        }

        setFiltersObject('userLabels', filters);
    };

    const handleMobileFilterSelect = (val) => {
        const splitValue = val.split('-');
        const filters = { ...userLabelFilters };

        filters.activeSortProperty = splitValue[0];
        filters.activeSortDirection = splitValue[1];
        setFiltersObject('userLabels', filters);
    };

    const determineMobileFiltersValue = () => {
        const filterStr = userLabelFilters.activeSortProperty + '-' + userLabelFilters.activeSortDirection;
        setActiveMobileFilterValue(filterStr);
    };

    useEffect(() => {
        userLabelFilters && determineMobileFiltersValue();
    }, [userLabelFilters]);

    return (
        <>
            {isDesktop && (
                <div className="list__utility-row list__utility-row--desktop">
                    <div className="list__search-container">
                        <SearchInputWithSubmit
                            value={tempSearchValue}
                            onChange={setTempSearchValue}
                            placeholder="Search by label name"
                            onClear={handleClear}
                            submitting={false}
                            onSubmit={handleSearchValueSubmit}
                            list
                        />
                    </div>

                    <div className="d-flex">
                        <Button onClick={toggleCreateModal} color="primary">
                            Create Label
                        </Button>
                    </div>
                </div>
            )}

            {isTablet && (
                <div className="list__utility-row list__utility-row--tablet">
                    <div className="d-flex justify-content-between w-100">
                        <div className="list__search-container">
                            <SearchInputWithSubmit
                                value={tempSearchValue}
                                onChange={setTempSearchValue}
                                placeholder="Search by label name"
                                onClear={handleClear}
                                submitting={false}
                                onSubmit={handleSearchValueSubmit}
                                list
                            />
                        </div>
                    </div>

                    <div className="d-flex mt-2 w-100 justify-content-end">
                        <div className="mr-2 d-flex">
                            <Button onClick={toggleCreateModal} color="primary" className="mb-0">
                                <i className="fa fa-plus" />
                                &nbsp; Create Label
                            </Button>
                        </div>

                        <UserLabelsMobileFilterDropdown
                            handleMobileFilterSelect={handleMobileFilterSelect}
                            activeMobileFilterValue={activeMobileFilterValue}
                        />
                    </div>
                </div>
            )}

            {isMobile && (
                <div className="list-utility-row list__utility-row--mobile">
                    <div className="d-flex mb-2">
                        <Button onClick={toggleCreateModal} color="primary" block>
                            <i className="fa fa-plus" />
                            &nbsp; Create Label
                        </Button>
                    </div>

                    <div className="d-flex mb-2">
                        <UserLabelsMobileFilterDropdown
                            handleMobileFilterSelect={handleMobileFilterSelect}
                            activeMobileFilterValue={activeMobileFilterValue}
                        />
                    </div>

                    <div className="mb-3">
                        <SearchInputWithSubmit
                            value={tempSearchValue}
                            onChange={setTempSearchValue}
                            placeholder="Search by label name"
                            onClear={handleClear}
                            submitting={false}
                            onSubmit={handleSearchValueSubmit}
                            list
                        />
                    </div>
                </div>
            )}

            <Table className="responsive-table">
                <tbody>
                    <tr>
                        <th
                            className="responsive-table__sortable-th"
                            onClick={() => handleSortableHeaderClick('name', activeSortProperty, activeSortDirection)}>
                            Name
                            {handleCaret(activeSortProperty, activeSortDirection, 'name')}
                        </th>
                        <th
                            className="responsive-table__sortable-th"
                            onClick={() => handleSortableHeaderClick('createdAt', activeSortProperty, activeSortDirection)}>
                            Created At
                            {handleCaret(activeSortProperty, activeSortDirection, 'createdAt')}
                        </th>
                        <th style={{ textAlign: 'center' }}>Edit/Remove</th>
                    </tr>

                    {visibleLabels.length > 0 ? (
                        visibleLabels.map((label) => (
                            <tr key={label.id}>
                                <td data-label="Name">{label.name}</td>
                                <td data-label="Users">{moment(label.createdAt).format('MM/DD/YY')}</td>
                                <td style={{ textAlign: 'center' }} className="responsive-table__edit-block" data-label="Edit/Remove">
                                    <Button color="light" onClick={() => setItemToEdit(label)}>
                                        <i className="fa fa-edit fa-lg" />
                                    </Button>

                                    <Button color="light" onClick={() => setItemToDelete(label)}>
                                        <i className="fa fa-trash fa-lg" />
                                    </Button>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td>No results found</td>
                            <td />
                            <td />
                        </tr>
                    )}
                </tbody>
            </Table>

            <CreateLabelModal
                isOpen={createModalOpen}
                toggle={toggleCreateModal}
                addFlashMessage={addFlashMessage}
                handleError={handleError}
                refreshLabels={refreshLabels}
            />

            <EditLabelModal
                isOpen={editModalOpen}
                toggle={toggleEditModal}
                item={itemToEdit}
                addFlashMessage={addFlashMessage}
                handleError={handleError}
                refreshLabels={refreshLabels}
            />

            <DeleteLabelModal
                isOpen={deleteModalOpen}
                toggle={toggleDeleteModal}
                item={itemToDelete}
                addFlashMessage={addFlashMessage}
                handleError={handleError}
                refreshLabels={refreshLabels}
            />
        </>
    );
};
