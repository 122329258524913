import React, { useState, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { useQuery } from 'react-query';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import Toggle from 'react-toggle';
import { FlashMessageContext } from 'contexts/FlashMessageContext';
import { AuthContext } from 'contexts/AuthContext';
import { LoadingSpinner } from 'common/LoadingSpinner';
import { SpinnerButton } from 'common/SpinnerButton';
import { getUserAlertSorting, editUserAlertSorting } from 'utils/api/usersAPI';
import { SortableAlert } from './SortableAlert';
import './style.scss';

export const SortAlerts = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const { addFlashMessage } = useContext(FlashMessageContext);
    const { handleError } = useContext(AuthContext);

    const [assignedAlerts, setAssignedAlerts] = useState([]);
    const [useCustomSorting, setUseCustomSorting] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    const { data, isLoading, isError } = useQuery(['getUserAlertSorting', id], () => (id ? getUserAlertSorting(id) : null), {
        onSuccess: (res) => {
            const { alerts_sorting, alerts } = res;
            setAssignedAlerts(alerts_sorting?.length ? alerts.sort((a, b) => alerts_sorting.indexOf(a.id) - alerts_sorting.indexOf(b.id)) : alerts);
            setAssignedAlerts(res.alerts);
            setUseCustomSorting(!!res.alerts_sorting?.length);
        },
        onError: (err) => handleError(err),
    });

    const onDragEnd = (result) => {
        const { destination, source, draggableId } = result;
        if (!destination) {
            return;
        }
        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        }

        const newAlertIds = Array.from([]);
        newAlertIds.splice(source.index, 1);
        newAlertIds.splice(destination.index, 0, draggableId);

        const newAlertList = Array.from(assignedAlerts);

        const selectedAlert = assignedAlerts.find((alert) => alert.id == draggableId);
        newAlertList.splice(newAlertList.indexOf(selectedAlert), 1);
        newAlertList.splice(destination.index, 0, selectedAlert);
        setAssignedAlerts(newAlertList);
    };

    const toggleCustomSorting = () => setUseCustomSorting(!useCustomSorting);

    const onSubmit = async () => {
        setSubmitting(true);

        try {
            await editUserAlertSorting(id, { sort: useCustomSorting ? assignedAlerts.map((item) => item.id) : null });

            addFlashMessage('success', 'Sort order successfully updated');
            navigate('/account/users');
        } catch (err) {
            handleError(err);
        }

        setSubmitting(false);
    };

    return (
        <div className="animated fadeIn view sort-alerts">
            <Row className="mt-3">
                <Col xs="12">
                    <div className="sort-alerts__container">
                        <h1>Sort Alerts</h1>
                        {isError ? (
                            <div className="mt-4 mb-3">
                                <div className="error-block d-flex" style={{ maxWidth: '600px' }}>
                                    <i className="fa fa-exclamation-circle" />
                                    <div>User not found.</div>
                                </div>
                            </div>
                        ) : isLoading ? (
                            <div>
                                <LoadingSpinner text="Fetching sort data" />
                            </div>
                        ) : (
                            <div className="sort-alerts__inner">
                                <h4 style={{ color: 'gray' }}>{data && data?.name}</h4>
                                <div>
                                    <div className="sort-alerts__toggle toggle">
                                        <label className="toggle-label">
                                            <Toggle
                                                defaultChecked={useCustomSorting}
                                                checked={useCustomSorting}
                                                icons={false}
                                                disabled={assignedAlerts.length < 1}
                                                onChange={toggleCustomSorting}
                                            />
                                        </label>
                                        <span className="toggle-text">Use Custom Sorting</span>
                                    </div>
                                    {assignedAlerts.length < 1 ? (
                                        <div>Alerts that you assign will appear here.</div>
                                    ) : (
                                        <div>
                                            <DragDropContext onDragEnd={onDragEnd}>
                                                <div className={'sort-alerts__list ' + (!useCustomSorting ? 'sort-alerts__list--disabled' : '')}>
                                                    <div className="sort-alerts__list-index">
                                                        {assignedAlerts.map((item, i) => (
                                                            <div key={item.id} className="sort-alert-list-index-num">
                                                                {i + 1}
                                                            </div>
                                                        ))}
                                                    </div>
                                                    <Droppable droppableId="column-1">
                                                        {(provided) => (
                                                            <div
                                                                className="sort-alert-list-inner"
                                                                ref={provided.innerRef}
                                                                {...provided.droppableProps}>
                                                                {assignedAlerts.length > 0 &&
                                                                    assignedAlerts.map((alert, i) => (
                                                                        <div key={alert.id} style={{ flex: '1' }}>
                                                                            <SortableAlert alert={alert} index={i} />
                                                                        </div>
                                                                    ))}
                                                                {provided.placeholder}
                                                            </div>
                                                        )}
                                                    </Droppable>
                                                </div>
                                            </DragDropContext>
                                            <div className="add-user mt-3 mb-4 d-flex justify-content-end" style={{ borderTop: 'none' }}>
                                                <SpinnerButton color="primary" onClick={onSubmit} title="Save Changes" submitting={submitting} />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </Col>
            </Row>
        </div>
    );
};
