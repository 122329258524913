import React from 'react';
import { Table, FormGroup, Input, Label, Col, UncontrolledTooltip } from 'reactstrap';

export const TableColCategories = ({ categories, handleChangeCategories }) => (
    <Col className="pr-1" xs="4">
        <Table className="table-striped" size="sm">
            <tbody>
                {categories?.map((item, index) => {
                    return (
                        <tr key={index}>
                            <td>
                                <FormGroup id={`category-item-${item.id}`} check inline>
                                    <Input
                                        id={item.id + '--'}
                                        type="checkbox"
                                        value={item.id}
                                        onChange={(e) => handleChangeCategories(e.target.value, e.target.checked)}
                                        disabled={item.assigned > 0 || !!item?.children}
                                        defaultChecked={item.alerts > 0}
                                    />
                                    <Label style={{ margin: '0', fontSize: 14 }} for={item.id + '--'}>
                                        {item.name}
                                    </Label>
                                </FormGroup>
                                {item?.children > 0 && (
                                    <UncontrolledTooltip placement="bottom" target={`category-item-${item.id}`}>
                                        There are sources associated with this category that needs to be removed first
                                    </UncontrolledTooltip>
                                )}
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </Table>
    </Col>
);
