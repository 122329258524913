import React, { useEffect, useState } from 'react';
import { convertDateString, truncateLongWords, truncateWithoutWordBreak } from 'utils/helpers';
import { SectionDropdown } from './SectionDropdown';

export const Article = ({ article, assignUnassignedArticleToSection, hideArticle, sections, includeAbstracts }) => {
    const [assignmentSection, setAssignmentSection] = useState();

    useEffect(() => {
        article.sec_id && setAssignmentSection({ id: article.sec_id, name: article.name });
    }, [article]);

    return (
        <div className="newsletter-content-article">
            <div className="newsletter-content-article__content">
                <div className="newsletter-content-article__title">
                    <a href={article.url} target="_blank">
                        {article.title.length > 95 ? (
                            <strong>{truncateLongWords(truncateWithoutWordBreak(article.title, 95), 50)}</strong>
                        ) : (
                            <strong>{truncateLongWords(article.title, 100)}</strong>
                        )}
                    </a>
                </div>

                {includeAbstracts && article.abstract && (
                    <div className="newsletter-content-article__abstract">
                        {article.abstract.length > 250 ? truncateWithoutWordBreak(article.abstract, 250) : article.abstract}
                    </div>
                )}

                <div className="newsletter-content-article__info">
                    <span className="newsletter-content-article__source">{truncateLongWords(article.resource, 100)}</span>

                    <span className="newsletter-content-article__date">{convertDateString(article.created_at)}</span>
                </div>
            </div>

            <div className="newsletter-content-article__utility">
                <SectionDropdown
                    assignmentSection={assignmentSection}
                    setAssignmentSection={setAssignmentSection}
                    sections={sections}
                    articleId={article.id}
                />

                <i className="fa fa-plus" onClick={() => assignUnassignedArticleToSection(article.id, assignmentSection, true)} />

                <i className="fa fa-trash" onClick={() => hideArticle(article.id)} />
            </div>
        </div>
    );
};
