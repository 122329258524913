import React, { useEffect, useState } from 'react';
import { Card, CardHeader, CardBody, UncontrolledTooltip } from 'reactstrap';
import { CSVLink } from 'react-csv';
import moment from 'moment-timezone';
import { safeTrim } from 'utils/helpers';
import { LoadingSpinner } from 'common/LoadingSpinner';
import { ClicksPerDayChart } from 'components/Dashboard/Charts/ClicksPerDayChart';

export const ClicksPerDay = ({ data, isLoading }) => {
    const [csvData, setCsvData] = useState([]);

    useEffect(() => {
        if (data?.length) {
            const csvDataRaw = [['Clicks Per Day (Last 30 Days)'], ['Date', 'Clicks']];

            data.map((item) => {
                return csvDataRaw.push([safeTrim(item.day), item.cnt]);
            });

            setCsvData(csvDataRaw);
        }
    }, [data]);

    return (
        <div>
            <Card className="chart-card">
                <CardHeader className="dashboard__header">
                    <span>Clicks Per Day (Last 30 Days)</span>
                    <div>
                        <CSVLink data={csvData} filename={'ozmosys_clicks-per-day_' + moment().utc().format('MMDDYYYY') + '.csv'}>
                            <div id="clicks-csv-button-tooltip">
                                <i className="fa fa-download dashboard-csv-icon" />
                            </div>
                        </CSVLink>
                        <UncontrolledTooltip placement="bottom" target="clicks-csv-button-tooltip" delay={{ show: 400, hide: 0 }}>
                            Download as CSV file
                        </UncontrolledTooltip>
                    </div>
                </CardHeader>
                <CardBody>
                    {isLoading ? (
                        <LoadingSpinner />
                    ) : data && data.length > 0 ? (
                        <ClicksPerDayChart clicks={data} />
                    ) : (
                        <div>View more articles to start collecting data</div>
                    )}
                </CardBody>
            </Card>
        </div>
    );
};
