import React, { useContext } from 'react';
import { Routes, Route } from 'react-router-dom';
import { AuthContext } from 'contexts/AuthContext';
import { Page404 } from 'components/Page404';
import { SignIn } from 'components/Authentication/SignIn';
import { SignUp } from 'components/Authentication/SignUp';
import { ConfirmRegistration } from 'components/Authentication/ConfirmRegistration';
import { InviteExpired } from 'components/Authentication/InviteExpired';
import { ResetPassword } from 'components/Authentication/ResetPassword';
import { ForgotPassword } from 'components/Authentication/ForgotPassword';
import { ConfirmInvite } from 'components/Authentication/ConfirmInvite';
import { AccountSuspended } from 'components/Authentication/AccountSuspended';
import { AccountSuspendedForm } from 'components/Authentication/AccountSuspendedForm';
import { DigDeeper } from 'components/Alerts/components/DigDeeper';
import { CompanyPage } from 'components/Alerts/components/CompanyPage';
import { Reader } from 'components/Reader';
import { CompanyReaderSignIn } from 'components/Reader/CompanyReaderSignIn';
import { Layout } from 'components/Layout';
import { PublicEmailPreview } from 'components/PublicEmailPreview';
import { protectedRoutes } from './protectedRoutes';

export const AppRoutes = () => {
    const { state } = useContext(AuthContext);

    if (!state.authChecked) return null;

    return (
        <Routes>
            <Route path="/404" element={<Page404 />} />
            <Route path="/signin" element={<SignIn />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/registration/confirm" element={<ConfirmRegistration />} />
            <Route exact path="/invite" element={<ConfirmInvite />} />
            <Route path="/invite-expired" element={<InviteExpired />} />
            <Route exact path="/reset-password" element={<ResetPassword />} />
            <Route exact path="/forgot-password" element={<ForgotPassword />} />
            <Route exact path="/suspended" element={<AccountSuspended />} />
            <Route exact path="/suspended/request" element={<AccountSuspendedForm />} />
            <Route exact path="/company/reader/signin" element={<CompanyReaderSignIn />} />
            <Route exact path="/company/reader/forgot-password" element={<ForgotPassword isFromReader />} />
            <Route exact path="/company/reader/reset-password" element={<ResetPassword isFromReader />} />
            <Route exact path="/company/reader" element={<Reader isPublic companyReader />} />
            <Route exact path="/public/dig-deeper/:id" element={<DigDeeper isPublic />} />
            <Route exact path="/public/company-profile/:id" element={<CompanyPage isPublic />} />
            <Route exact path="/public/reader/:id" element={<Reader isPublic />} />
            <Route exact path="/public/newsletter-preview/:id" element={<PublicEmailPreview isNewsletter />} />
            <Route exact path="/public/email-preview/:id" element={<PublicEmailPreview />} />

            {protectedRoutes.map(({ path, element }) => (
                <Route key={path} path={path} element={<Layout>{element}</Layout>} />
            ))}
        </Routes>
    );
};
