import React, { useEffect, useState, useContext, useMemo } from 'react';
import { cloneDeep } from 'lodash';
import { useQuery } from 'react-query';
import { FlashMessageContext } from 'contexts/FlashMessageContext';
import { AuthContext } from 'contexts/AuthContext';
import {
    addArticleToSection,
    clearCuratedNewsletterSection,
    getFreeCuratedArticlesByDate,
    hideArticleFromList,
    moveArticleBetweenNewsletterSections,
    removeArticleFromSection,
    sortNewsletterSections,
} from 'utils/api/newslettersAPI';
import { Articles } from './Articles';
import { Sections } from './Sections';

export const ReviewArticles = ({ newsletterData, refreshNewsletter }) => {
    const { addFlashMessage } = useContext(FlashMessageContext);
    const { handleError } = useContext(AuthContext);
    const [retrievalDate, setRetrievalDate] = useState(1);
    const [sortByDate, setSortByDate] = useState('desc');
    const [articles, setArticles] = useState([]);
    const [newsletterForDom, setNewsletterForDom] = useState(null);
    const [includeAbstracts, setIncludeAbstracts] = useState(true);

    const articlesDates = useMemo(() => {
        const max = +retrievalDate;
        const dates = {};

        for (let i = 1; ; ) {
            if (max < i) {
                break;
            } else if (i !== 1 && max === i) {
                dates[i - 5] = max;
                break;
            } else {
                dates[i] = max < i + 4 ? max : i + 4;
                i += 5;
            }
        }

        return Object.entries(dates).map(([key, value]) => ({ to: +key === 1 ? 'now' : +key, from: value }));
    }, [retrievalDate]);

    const {
        isLoading: isLoadingArticles,
        isFetching: isFetchingArticles,
        refetch: refetchArticles,
    } = useQuery(
        ['getFreeCuratedArticlesByDate', articlesDates, sortByDate, newsletterData?.id],
        () => (newsletterData?.id ? getFreeCuratedArticlesByDate(newsletterData.id, articlesDates, sortByDate) : null),
        {
            onSuccess: (res) => setArticles(res),
            onError: (err) => handleError(err),
        }
    );

    useEffect(() => {
        setNewsletterForDom(newsletterData);
    }, [newsletterData]);

    const assignUnassignedArticleToSection = async (articleId, section, isReviewArticles = false) => {
        try {
            const { sections } = newsletterForDom;

            const nextColumn = sections.find((obj) => obj.id === section.id);

            if (!nextColumn) {
                addFlashMessage('danger', 'Unable to assign article');
                return;
            }
            const nextColumnIndex = sections.indexOf(nextColumn);

            const newStateUnassignedNews = cloneDeep(articles);
            const selectedArticle = newStateUnassignedNews.find((article) => article.id === articleId);

            if (!selectedArticle) return;

            const nextColumnNews = Array.from(nextColumn.newsletterSectionNews);
            const newSortOrder = nextColumnNews.length + 1;

            const { abstract, created_at, id, name, resource, sec_id, title, url } = selectedArticle;
            const newArticleObj = {
                comment: null,
                news: { abstract, created_at, id, name, resource, sec_id, title, url },
                sortOrder: newSortOrder,
            };

            isReviewArticles ? nextColumnNews.unshift(newArticleObj) : nextColumnNews.push(newArticleObj);

            const newNextColumn = { ...nextColumn, newsletterSectionNews: nextColumnNews };
            newStateUnassignedNews.splice(newStateUnassignedNews.indexOf(selectedArticle), 1);
            const newStateSections = cloneDeep(sections);
            newStateSections[nextColumnIndex] = newNextColumn;
            const newNewsletter = { ...newsletterData, sections: newStateSections };
            setNewsletterForDom(newNewsletter);
            setArticles(newStateUnassignedNews);

            await addArticleToSection(newsletterData.id, section.id, { news: [{ id: articleId, sort: 1 }] });
        } catch (err) {
            handleError(err);
        }
    };

    const handleRemoveAssignedArticle = async (articleId, section) => {
        try {
            const { sections } = newsletterForDom;
            const selectedSection = sections.find((obj) => obj.id === section.id);
            const selectedSectionIndex = sections.indexOf(selectedSection);

            const newStateUnassignedNews = cloneDeep(articles);
            const newStateSections = cloneDeep(sections);

            const selectedArticle = selectedSection.newsletterSectionNews.find((article) => article.news.id === articleId);

            if (!selectedArticle) return;

            const selectedSectionNews = Array.from(selectedSection.newsletterSectionNews);
            selectedSectionNews.splice(selectedSectionNews.indexOf(selectedArticle), 1);
            const newSelectedSection = { ...selectedSection, newsletterSectionNews: selectedSectionNews };

            const { abstract, created_at, id, name, resource, sec_id, title, url } = selectedArticle?.news;
            const newArticleObj = { abstract, created_at, id, name, resource, sec_id, title, url };

            newStateUnassignedNews.unshift(newArticleObj);
            newStateSections[selectedSectionIndex] = newSelectedSection;
            const newNewsletter = { ...newsletterData, sections: newStateSections };
            setNewsletterForDom(newNewsletter);
            setArticles(newStateUnassignedNews);

            await removeArticleFromSection(newsletterData.id, section.id, articleId);
        } catch (err) {
            handleError();
        }
    };

    const moveArticleBetweenSections = async (articleId, previousSectionId, nextSectionId, sortOrder) => {
        try {
            const nextColumn = newsletterForDom.sections.find((obj) => obj.id === nextSectionId);
            const prevColumn = newsletterForDom.sections.find((obj) => obj.id === previousSectionId);

            if (!nextColumn || !prevColumn) {
                addFlashMessage('danger', 'Unable to assign article');
                return;
            }

            const nextColumnIndex = newsletterForDom.sections.indexOf(nextColumn);
            const prevColumnIndex = newsletterForDom.sections.indexOf(prevColumn);
            const selectedArticle = prevColumn.newsletterSectionNews.find((article) => article.news.id === articleId);

            if (!selectedArticle) {
                addFlashMessage('danger', 'Unable to assign article');
                return;
            }

            const nextColumnNews = Array.from(nextColumn.newsletterSectionNews);
            const prevColumnNews = Array.from(prevColumn.newsletterSectionNews);

            selectedArticle.sortOrder = sortOrder;
            nextColumnNews.splice(sortOrder - 1, 0, selectedArticle);

            const newNextColumn = { ...nextColumn, newsletterSectionNews: nextColumnNews };
            const newPrevColumn = { ...prevColumn, newsletterSectionNews: prevColumnNews };

            prevColumnNews.splice(prevColumnNews.indexOf(selectedArticle), 1);

            const newStateSections = cloneDeep(newsletterForDom.sections);

            newStateSections[nextColumnIndex] = newNextColumn;
            newStateSections[prevColumnIndex] = newPrevColumn;
            const newNewsletter = { ...newsletterData, sections: newStateSections };
            setNewsletterForDom(newNewsletter);

            await moveArticleBetweenNewsletterSections(newsletterData.id, articleId, previousSectionId, { section: nextSectionId, sort: sortOrder });
        } catch (err) {
            handleError(err);
        }
    };

    const resortArticlesInSameSection = async (articleId, sectionId, newIndex) => {
        try {
            const section = newsletterForDom.sections.find((obj) => obj.id === sectionId);
            const newSection = { ...section };
            const selectedArticle = section.newsletterSectionNews.find((article) => article.news.id === articleId);
            const oldIndex = section.newsletterSectionNews.indexOf(selectedArticle);

            if (newIndex >= section.newsletterSectionNews.length) {
                let k = newIndex - newSection.newsletterSectionNews.length + 1;
                while (k--) {
                    newSection.newsletterSectionNews.push(undefined);
                }
            }

            newSection.newsletterSectionNews.splice(newIndex, 0, newSection.newsletterSectionNews.splice(oldIndex, 1)[0]);
            await moveArticleBetweenNewsletterSections(newsletterData.id, articleId, sectionId, { section: sectionId, sort: newIndex + 1 });
        } catch (err) {
            handleError(err);
        }
    };

    const removeAllArticlesFromSection = async (e, sectionId) => {
        try {
            e.stopPropagation();
            await clearCuratedNewsletterSection(newsletterData.id, sectionId);
            refreshNewsletter();
            await refetchArticles();
        } catch (err) {
            handleError(err);
        }
    };

    const handleSectionSorting = async (sortOrderArr) => {
        try {
            await sortNewsletterSections(newsletterData.id, { section: sortOrderArr });
            refreshNewsletter();
        } catch (err) {
            handleError(err);
        }
    };

    const hideArticle = async (articleId) => {
        try {
            const newStateUnassignedNews = cloneDeep(articles);
            const selectedArticle = newStateUnassignedNews.find((article) => article.id === articleId);

            if (!selectedArticle) {
                addFlashMessage('danger', 'Unable to complete your request at this time');
                return;
            }

            newStateUnassignedNews.splice(newStateUnassignedNews.indexOf(selectedArticle), 1);
            setArticles(newStateUnassignedNews);
            await hideArticleFromList(newsletterForDom.id, { news: [articleId] });
        } catch (err) {
            handleError(err);
        }
    };

    const toggleIncludeAbstracts = () => setIncludeAbstracts(!includeAbstracts);

    return (
        <div className="d-flex flex-1">
            {newsletterForDom && (
                <Sections
                    newsletterData={newsletterForDom}
                    removeAllArticlesFromSection={removeAllArticlesFromSection}
                    addFlashMessage={addFlashMessage}
                    handleError={handleError}
                    handleSectionSorting={handleSectionSorting}
                    handleRemoveAssignedArticle={handleRemoveAssignedArticle}
                    refreshNewsletter={refreshNewsletter}
                    moveArticleBetweenSections={moveArticleBetweenSections}
                    resortArticlesInSameSection={resortArticlesInSameSection}
                    toggleIncludeAbstracts={toggleIncludeAbstracts}
                    includeAbstracts={includeAbstracts}
                />
            )}

            <Articles
                newsletterData={newsletterForDom}
                retrievalDate={retrievalDate}
                setRetrievalDate={setRetrievalDate}
                sortByDate={sortByDate}
                setSortByDate={setSortByDate}
                articles={articles}
                assignUnassignedArticleToSection={assignUnassignedArticleToSection}
                fetchingArticles={isLoadingArticles || isFetchingArticles}
                hideArticle={hideArticle}
                refreshNewsletter={refreshNewsletter}
                includeAbstracts={includeAbstracts}
            />
        </div>
    );
};
