import React, { useEffect, useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Row, Col, Button, Input, InputGroup, InputGroupText } from 'reactstrap';
import { AuthContext } from 'contexts/AuthContext';
import { SpinnerButton } from 'common/SpinnerButton';
import { loginToReader } from 'utils/api/authAPI';
import { LegacyDropdown } from './LegacyDropdown';
import Logo from 'images/logo.png';

export const CompanyReaderSignIn = () => {
    const navigate = useNavigate();
    const { readerSignIn, handleError, state } = useContext(AuthContext);

    const [submitting, setSubmitting] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    useEffect(() => {
        if (state.isReaderAuthenticated) navigate('/company/reader');
    }, [state]);

    const onSubmit = async () => {
        setSubmitting(true);

        try {
            const res = await loginToReader(email, password);
            readerSignIn(res);
            navigate('/company/reader');
        } catch (err) {
            handleError(err);
        }

        setSubmitting(false);
    };

    return (
        <div className="auth-block">
            <LegacyDropdown />
            <div className="auth-block__inner">
                <div className="animated fadeIn">
                    <Row className="justify-content-center mt-5" style={{ marginBottom: '.5rem' }}>
                        <Col md={9}>
                            <div className="auth-block__image-container">
                                <a href="/">
                                    <img src={Logo} alt="Ozmosys" className="auth-block__image img-fluid" />
                                </a>
                            </div>
                            <div className="text-center">
                                <h2>Sign In</h2>
                                <p className="mb-4 mt-3 text-muted">Enter your company email and password</p>
                            </div>

                            <InputGroup>
                                <InputGroupText>@</InputGroupText>
                                <Input
                                    placeholder="Email"
                                    name="email"
                                    value={email}
                                    type="email"
                                    autoComplete="email"
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </InputGroup>

                            <InputGroup>
                                <InputGroupText>
                                    <i className="fa fa-lock" />
                                </InputGroupText>
                                <Input
                                    placeholder="Password"
                                    name="password"
                                    value={password}
                                    autoComplete="password"
                                    onChange={(e) => setPassword(e.target.value)}
                                    type="password"
                                />
                            </InputGroup>

                            <Row>
                                <Col xs="12" className="text-left">
                                    <SpinnerButton
                                        color="primary"
                                        block
                                        disabled={email.length < 1 || password.length < 1}
                                        onClick={onSubmit}
                                        submitting={submitting}
                                        title={'Login'}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row>
                        <Col className="text-center mt-2">
                            <span className="align-middle text-muted">Don't have an account?</span>
                            <Link to="/signup">
                                <Button type="button" color="link">
                                    Sign up
                                </Button>
                            </Link>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
};
