import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Collapse, Button, CardBody, CardHeader, Input, UncontrolledTooltip } from 'reactstrap';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { ContentState } from 'draft-js';
import { SmallSpinnerButtonSquare } from 'common/SpinnerButton/SmallSpinnerButtonSquare';
import { deleteNewsletterSection, editNewsletterSection } from 'utils/api/newslettersAPI';
import { AssignedArticle } from './AssignedArticle';
import { CommentForm } from 'components/Newsletters/ManageContent/Desktop/CommentForm';

export const Section = ({
    section,
    index,
    handleRemoveAssignedArticle,
    includeAbstracts,
    removeAllArticlesFromSection,
    newsletterId,
    refreshNewsletter,
    addFlashMessage,
    handleError,
}) => {
    const [articleListOpen, setArticleListOpen] = useState(false);
    const [commentFormOpen, setCommentFormOpen] = useState(false);
    const [titleFormOpen, setTitleFormOpen] = useState(false);
    const [commentValue, setCommentValue] = useState(EditorState.createEmpty());
    const [titleValue, setTitleValue] = useState('');
    const [deletingSection, setDeletingSection] = useState(false);
    const inputRef = useRef(null);

    useEffect(() => {
        if (section && section.comment) {
            const blocksFromHtml = htmlToDraft(section.comment);
            const { contentBlocks, entityMap } = blocksFromHtml;
            const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
            const editorState = EditorState.createWithContent(contentState);
            setCommentValue(editorState);
        } else {
            setCommentValue(EditorState.createEmpty());
        }

        setTitleValue(section.name);
    }, []);

    const toggleArticleListOpen = () => setArticleListOpen(!articleListOpen);
    const toggleCommentFormOpen = (e) => {
        e.stopPropagation();
        setCommentFormOpen(!commentFormOpen);
    };

    const handleCommentSubmit = async () => {
        try {
            let commentValueAsHtml;

            if (commentValue) {
                const rawContentState = convertToRaw(commentValue.getCurrentContent());
                commentValueAsHtml = draftToHtml(rawContentState);
            }

            const trimmedHtmlTagsValue = commentValueAsHtml?.replace(new RegExp('<[^>]*>', 'g'), '')?.trim();
            const params = { name: section.name, comment: trimmedHtmlTagsValue.length ? commentValueAsHtml : '' };

            await editNewsletterSection(newsletterId, section.id, params);
            refreshNewsletter();
            setCommentFormOpen(false);
            addFlashMessage('success', 'Comment successfully saved');
        } catch (err) {
            handleError(err);
        }
    };

    const deleteSection = async (e, sectionId) => {
        e.stopPropagation();
        try {
            setDeletingSection(true);
            await deleteNewsletterSection(newsletterId, sectionId);
            refreshNewsletter();
        } catch (err) {
            setDeletingSection(false);
            handleError(err);
        }
    };

    const toggleTitleForm = () => setTitleFormOpen(!titleFormOpen);

    useLayoutEffect(() => {
        if (titleFormOpen) {
            const input = document.getElementById(`sectionTitleForm${section.id}`);
            input.focus();
        }
    }, [titleFormOpen, inputRef]);

    const submitTitleForm = async () => {
        try {
            let commentValueAsHtml;

            if (commentValue) {
                const rawContentState = convertToRaw(commentValue.getCurrentContent());
                commentValueAsHtml = draftToHtml(rawContentState);
            }

            const trimmedHtmlTagsValue = commentValueAsHtml?.replace(new RegExp('<[^>]*>', 'g'), '')?.trim();
            const params = { name: titleValue, comment: trimmedHtmlTagsValue.length ? commentValueAsHtml : '' };

            await editNewsletterSection(newsletterId, section.id, params);
            refreshNewsletter();
            toggleTitleForm();
        } catch (err) {
            if (err.errors.name && err.errors.name) {
                if (err.errors.name[0].code === 'NOT_UNIQUE_ERROR') {
                    addFlashMessage('danger', 'This section name is already in use for this newsletter');
                } else {
                    addFlashMessage('danger', 'Unable to save section name');
                }
            } else {
                addFlashMessage('danger', 'Unable to save section name');
            }
        }
    };

    const handlePressEnter = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
            submitTitleForm();
        }
    };

    return (
        <Draggable draggableId={String(section.id)} index={index} type="section">
            {(draggableProvided, snapshot) => (
                <div className="mt-2" {...draggableProvided.draggableProps} ref={draggableProvided.innerRef}>
                    <Droppable droppableId={String(section.id)} type="article">
                        {(provided, snapshot) => (
                            <div
                                className={'newsletter-section ' + (snapshot.isDraggingOver ? 'section-is-dragging-over' : '')}
                                {...provided.droppableProps}
                                ref={provided.innerRef}>
                                <div className="section-collapse d-flex flex-column">
                                    <CardHeader
                                        className={
                                            'section-collapse-toggle newsletter-section__header ' +
                                            (snapshot.isDragging ? 'newsletter-section__header--dragging' : '')
                                        }
                                        color="primary"
                                        onClick={toggleArticleListOpen}>
                                        <div className="newsletter-section__block-1">
                                            <i className={'fa fa-angle-right ' + (articleListOpen ? 'rotate-90-down' : '')} />
                                            <div className="newsletter-section__title ml-2">
                                                {titleFormOpen ? (
                                                    <Input
                                                        className="newsletter-section__title-form"
                                                        name="sectionTitle"
                                                        value={titleValue}
                                                        id={`sectionTitleForm${section.id}`}
                                                        onBlur={submitTitleForm}
                                                        onKeyDown={(e) => handlePressEnter(e)}
                                                        autofocus
                                                        ref={inputRef}
                                                        onChange={(e) => setTitleValue(e.target.value)}
                                                    />
                                                ) : (
                                                    <span>{section.name.length > 30 ? section.name.substring(0, 30) + '...' : section.name}</span>
                                                )}
                                            </div>
                                            <i className="fa fa-edit ml-2 dark-gray" onClick={toggleTitleForm} />
                                            <i
                                                className={
                                                    'fa fa-comment ml-2 ' +
                                                    (section.comment && section.comment.length > 0 ? 'theme-text-color' : 'dark-gray')
                                                }
                                                onClick={(e) => toggleCommentFormOpen(e)}
                                            />
                                            &nbsp;
                                            <div className="newsletter-section__item-cnt">
                                                {section.newsletterSectionNews.length === 1
                                                    ? section.newsletterSectionNews.length + ' Article'
                                                    : section.newsletterSectionNews.length + ' Articles'}
                                            </div>
                                        </div>

                                        <div className="newsletter-section__block-2">
                                            <Button
                                                color="secondary"
                                                id={'clear-articles-' + section.id}
                                                className="newsletter-section__header-btn newsletter-section__header-btn--clear"
                                                onClick={(e) => removeAllArticlesFromSection(e, section.id)}
                                                disabled={section.newsletterSectionNews.length < 1}>
                                                Clear
                                            </Button>

                                            <UncontrolledTooltip
                                                placement="bottom"
                                                delay={{ show: 200, hide: 0 }}
                                                target={'clear-articles-' + section.id}>
                                                Remove all articles from this section
                                            </UncontrolledTooltip>

                                            <SmallSpinnerButtonSquare
                                                color="danger"
                                                className="newsletter-section__header-btn"
                                                onClick={(e) => deleteSection(e, section.id)}
                                                disabled={deletingSection}
                                                submitting={deletingSection}
                                                title={<i className="fa fa-trash" />}
                                            />
                                            <i className="fa fa-bars ml-2" {...draggableProvided.dragHandleProps} />
                                        </div>
                                    </CardHeader>

                                    <Collapse isOpen={commentFormOpen}>
                                        <CommentForm value={commentValue} handleValueChange={setCommentValue} onSubmit={handleCommentSubmit} />
                                    </Collapse>

                                    <Collapse isOpen={articleListOpen}>
                                        <CardBody className="section-collapse-body p-0">
                                            <div className="section-body">
                                                <div className="section-list">
                                                    {section.newsletterSectionNews.length === 0 && (
                                                        <div className="empty-section-item">No Articles have been added to this section yet</div>
                                                    )}

                                                    {section.newsletterSectionNews.length > 0 &&
                                                        articleListOpen &&
                                                        section.newsletterSectionNews.map((article, i) => (
                                                            <AssignedArticle
                                                                key={article.news.id}
                                                                article={article}
                                                                index={i}
                                                                section={section}
                                                                handleRemoveAssignedArticle={handleRemoveAssignedArticle}
                                                                refreshNewsletter={refreshNewsletter}
                                                                newsletterId={newsletterId}
                                                                listOpen={articleListOpen}
                                                                handleError={handleError}
                                                                addFlashMessage={addFlashMessage}
                                                                includeAbstracts={includeAbstracts}
                                                            />
                                                        ))}
                                                    {provided.placeholder}
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Collapse>
                                </div>
                            </div>
                        )}
                    </Droppable>
                </div>
            )}
        </Draggable>
    );
};
