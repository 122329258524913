import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Table } from 'reactstrap';
import moment from 'moment-timezone';
import { AuthContext } from 'contexts/AuthContext';
import { FilterContext } from 'contexts/FilterContext';
import { FlashMessageContext } from 'contexts/FlashMessageContext';
import { LoadingSpinner } from 'common/LoadingSpinner';
import { SearchInputWithSubmit } from 'common/SearchInput/SearchInputWithSubmit';
import { PaginationWrapper } from 'common/PaginationWrapper';
import { deleteNewsletter, getNewsletters } from 'utils/api/newslettersAPI';
import { handleCaret } from 'utils/helpers';
import { useDevice } from 'hooks/useMediaQuery';
import { NewsletterListItem } from './NewsletterListItem';
import { EmptyList } from './EmptyList';
import { MobileFilterDropdown } from './MobileFilterDropdown';
import { DeleteNewsletterModal } from '../Modals/DeleteNewsletterModal';
import { EditNewsletterModal } from '../Modals/CreateAndEdit/EditNewsletterModal';
import { CreateNewsletterModal } from '../Modals/CreateAndEdit/CreateNewsletter';
import { PreviewNewsletterModal } from '../Modals/PreviewNewsletterModal';

export const NewslettersList = () => {
    const navigate = useNavigate();
    const { addFlashMessage } = useContext(FlashMessageContext);
    const { handleError } = useContext(AuthContext);
    const { setFiltersObject, newsletterFilters } = useContext(FilterContext);
    const { isDesktop, isTablet, isMobile } = useDevice();

    const { paginationCurrentPageNumber, activeSortProperty, activeSortDirection, searchValue, searchProperty } = newsletterFilters;

    const [newsletters, setNewsletters] = useState([]);
    const [createNewsletterModalOpen, setCreateNewsletterModalOpen] = useState(false);
    const [editNewsletterModalOpen, setEditNewsletterModalOpen] = useState(false);
    const [deleteNewsletterModalOpen, setDeleteNewsletterModalOpen] = useState(false);
    const [previewNewsletterModalOpen, setPreviewNewsletterModalOpen] = useState(false);
    const [lastSentNewsletterModalOpen, setLastSentNewsletterModalOpen] = useState(false);
    const [itemToEdit, setItemToEdit] = useState(null);
    const [itemToDelete, setItemToDelete] = useState(null);
    const [itemToPreview, setItemToPreview] = useState(null);
    const [lastSentItemToPreview, setLastSentItemToPreview] = useState(null);
    const [fetchingData, setFetchingData] = useState(true);
    const [tempSearchValue, setTempSearchValue] = useState('');
    const [submittingSearch, setSubmittingSearch] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [totalPages, setTotalPages] = useState(1);
    const [initialLoad, setInitialLoad] = useState(true);
    const [activeMobileFilterValue, setActiveMobileFilterValue] = useState('');
    const [fetchingCsv, setFetchingCsv] = useState(false);

    const fetchNewsletters = async (csv, clearSearchValue) => {
        try {
            if (csv) {
                setFetchingCsv(true);
                const title = 'ozmosys_newsletters_' + moment().tz(moment.tz.guess()).format('MMDDYYYY');

                await getNewsletters(
                    false,
                    paginationCurrentPageNumber,
                    50,
                    activeSortProperty,
                    activeSortDirection,
                    searchProperty,
                    searchValue,
                    true,
                    title
                );

                setFetchingCsv(false);
            } else {
                !submittingSearch && setSubmittingSearch(true);
                const refreshCount = initialLoad || paginationCurrentPageNumber === 1 || !newsletters.length;

                const newslettersData = await getNewsletters(
                    refreshCount,
                    paginationCurrentPageNumber,
                    50,
                    activeSortProperty,
                    activeSortDirection,
                    searchProperty,
                    clearSearchValue ? '' : searchValue
                );
                setNewsletters(newslettersData.result);

                if (refreshCount) setTotalPages(newslettersData.navigation.total_pages);
                if (tempSearchValue !== searchValue) setTempSearchValue(searchValue);
                setFetchingData(false);
                setSubmittingSearch(false);
                initialLoad && setInitialLoad(false);
            }
        } catch (err) {
            setFetchingData(false);
            setSubmittingSearch(false);
            fetchingCsv && setFetchingCsv(false);
            handleError(err);
        }
    };

    useEffect(() => {
        fetchNewsletters();
    }, [newsletterFilters]);

    const toggleCreateNewsletter = async () => setCreateNewsletterModalOpen(!createNewsletterModalOpen);

    const handleDeleteNewsletter = async (id, modalCallback) => {
        try {
            setDeleting(true);
            await deleteNewsletter(id);
            modalCallback && modalCallback();

            await fetchNewsletters(false, true);
            addFlashMessage('success', 'Newsletter successfully deleted');
            setDeleting(false);
        } catch (err) {
            setDeleting(false);
            handleError(err);
        }
    };

    const toggleEditNewsletter = (newsletter) => {
        setItemToEdit(newsletter);
        setEditNewsletterModalOpen(!editNewsletterModalOpen);
    };

    const toggleDeleteNewsletter = () => {
        if (deleteNewsletterModalOpen) setItemToDelete(null);
        setDeleteNewsletterModalOpen(!deleteNewsletterModalOpen);
    };

    const toggleNewsletterPreview = () => {
        if (previewNewsletterModalOpen) setItemToPreview(null);
        setPreviewNewsletterModalOpen(!previewNewsletterModalOpen);
    };

    const toggleLastSentNewsletterPreview = () => {
        if (lastSentNewsletterModalOpen) setLastSentItemToPreview(null);
        setLastSentNewsletterModalOpen(!lastSentNewsletterModalOpen);
    };

    const handleSearchValueSubmit = () => {
        if (tempSearchValue.length > 2048) {
            addFlashMessage('danger', 'Your search exceeds the maximum number of allowed characters (2048)');
            return;
        }

        const filters = { ...newsletterFilters };
        filters.searchValue = tempSearchValue;
        filters.paginationCurrentPageNumber = 1;
        setFiltersObject('newsletters', filters);
    };

    const handlePageClick = (data) => {
        const filters = { ...newsletterFilters };
        filters.paginationCurrentPageNumber = data.selected + 1;
        setFiltersObject('newsletters', filters);
    };

    const handleClearSearchValue = async () => {
        const filters = { ...newsletterFilters };
        setSubmittingSearch(true);
        filters.paginationCurrentPageNumber = 1;
        filters.searchValue = '';
        setFiltersObject('newsletters', filters);
        setTempSearchValue('');
    };

    const handleSortableHeaderClick = (value) => {
        const filters = { ...newsletterFilters };

        if (value === activeSortProperty) {
            activeSortDirection === 'asc' ? (filters.activeSortDirection = 'desc') : (filters.activeSortDirection = 'asc');
        } else {
            filters.activeSortProperty = value;
            filters.activeSortDirection = 'asc';
        }

        filters.paginationCurrentPageNumber = 1;
        setFiltersObject('newsletters', filters);
    };

    const handleMobileFilterSelect = (val) => {
        const splitValue = val.split('-');
        const filters = { ...newsletterFilters };

        filters.activeSortProperty = splitValue[0];
        filters.activeSortDirection = splitValue[1];
        setFiltersObject('newsletters', filters);
    };

    const determineMobileFiltersValue = () => {
        const filterStr = newsletterFilters.activeSortProperty + '-' + newsletterFilters.activeSortDirection;
        setActiveMobileFilterValue(filterStr);
    };

    useEffect(() => {
        if (itemToPreview) toggleNewsletterPreview();
        if (itemToDelete) toggleDeleteNewsletter();
        if (lastSentItemToPreview) toggleLastSentNewsletterPreview();

        newsletterFilters && determineMobileFiltersValue();
    }, [itemToPreview, itemToDelete, lastSentItemToPreview, newsletterFilters]);

    return (
        <>
            {fetchingData ? (
                <LoadingSpinner />
            ) : newsletters.length === 0 && searchValue === '' && !submittingSearch ? (
                <EmptyList toggleCreateNewsletter={toggleCreateNewsletter} />
            ) : (
                <>
                    {isDesktop && (
                        <div className="list__utility-row list__utility-row--desktop">
                            <div className="list__search-container">
                                <SearchInputWithSubmit
                                    value={tempSearchValue}
                                    onChange={setTempSearchValue}
                                    placeholder="Filter by newsletter name"
                                    onClear={handleClearSearchValue}
                                    onSubmit={handleSearchValueSubmit}
                                    submitting={submittingSearch}
                                    list
                                />
                            </div>

                            <div className="d-flex">
                                <Button onClick={toggleCreateNewsletter} color="primary">
                                    Create Newsletter
                                </Button>
                            </div>
                        </div>
                    )}

                    {isTablet && (
                        <div className="list__utility-row list__utility-row--tablet">
                            <div className="d-flex justify-content-between w-100">
                                <div className="list__search-container">
                                    <SearchInputWithSubmit
                                        value={tempSearchValue}
                                        onChange={setTempSearchValue}
                                        placeholder="Filter by newsletter name"
                                        onClear={handleClearSearchValue}
                                        onSubmit={handleSearchValueSubmit}
                                        submitting={submittingSearch}
                                        list
                                    />
                                </div>
                            </div>

                            <div className="d-flex mt-2 w-100 justify-content-end">
                                <div className="mr-2 d-flex">
                                    <Button onClick={toggleCreateNewsletter} color="primary" className="mb-0">
                                        <i className="fa fa-plus" />
                                        &nbsp; Create Newsletter
                                    </Button>
                                </div>

                                <MobileFilterDropdown
                                    handleMobileFilterSelect={handleMobileFilterSelect}
                                    activeMobileFilterValue={activeMobileFilterValue}
                                />
                            </div>
                        </div>
                    )}

                    {isMobile && (
                        <div className="list-utility-row list__utility-row--mobile">
                            <div className="d-flex mb-2">
                                <Button onClick={toggleCreateNewsletter} color="primary" className="ml-1" style={{ flex: '0 0 50%' }}>
                                    <i className="fa fa-plus" />
                                    &nbsp; Create Newsletter
                                </Button>
                            </div>

                            <div className="d-flex mb-2">
                                <MobileFilterDropdown
                                    handleMobileFilterSelect={handleMobileFilterSelect}
                                    activeMobileFilterValue={activeMobileFilterValue}
                                />
                            </div>

                            <div className="mb-3">
                                <SearchInputWithSubmit
                                    value={tempSearchValue}
                                    onChange={setTempSearchValue}
                                    placeholder="Filter by newsletter name"
                                    onClear={handleClearSearchValue}
                                    onSubmit={handleSearchValueSubmit}
                                    submitting={submittingSearch}
                                    list
                                />
                            </div>
                        </div>
                    )}

                    {newsletters.length < 1 ? (
                        <div>No results found</div>
                    ) : (
                        <>
                            <Table className="single-users-table responsive-table">
                                <tbody>
                                    <tr className="responsive-table header-row">
                                        <th
                                            className="responsive-table__sortable-th responsive-table--long-th"
                                            onClick={() => handleSortableHeaderClick('name', activeSortProperty, activeSortDirection)}>
                                            Name
                                            {handleCaret(activeSortProperty, activeSortDirection, 'name')}
                                        </th>

                                        <th
                                            className="responsive-table__sortable-th"
                                            onClick={() => handleSortableHeaderClick('type', activeSortProperty, activeSortDirection)}>
                                            Type
                                            {handleCaret(activeSortProperty, activeSortDirection, 'type')}
                                        </th>

                                        <th
                                            className="responsive-table__sortable-th"
                                            onClick={() => handleSortableHeaderClick('createdAt', activeSortProperty, activeSortDirection)}>
                                            Created At
                                            {handleCaret(activeSortProperty, activeSortDirection, 'createdAt')}
                                        </th>

                                        <th
                                            className="responsive-table__sortable-th"
                                            onClick={() => handleSortableHeaderClick('lastSentEmail', activeSortProperty, activeSortDirection)}>
                                            Last Sent Date
                                            {handleCaret(activeSortProperty, activeSortDirection, 'lastSentEmail')}
                                        </th>

                                        <th>Active</th>
                                        <th>Content</th>
                                        <th>Assignment</th>
                                        <th className="responsive-table--long-th">Schedule</th>

                                        <th
                                            className="responsive-table__sortable-th responsive-table--number-th"
                                            onClick={() => handleSortableHeaderClick('countClicks', activeSortProperty, activeSortDirection)}>
                                            {isDesktop ? 'Clicks' : 'Last 30 Days Clicks'}
                                            {handleCaret(activeSortProperty, activeSortDirection, 'countClicks')}
                                        </th>

                                        <th>Send Now</th>
                                        <th>Edit/Remove</th>
                                    </tr>

                                    {newsletters.map((newsletter) => (
                                        <NewsletterListItem
                                            key={newsletter.id}
                                            newsletter={newsletter}
                                            toggleEditModal={toggleEditNewsletter}
                                            addFlashMessage={addFlashMessage}
                                            setLastSentItemToPreview={setLastSentItemToPreview}
                                            setItemToDelete={setItemToDelete}
                                            handleError={handleError}
                                        />
                                    ))}
                                </tbody>
                            </Table>

                            <PaginationWrapper
                                totalPages={totalPages}
                                handlePageClick={handlePageClick}
                                forcePage={paginationCurrentPageNumber - 1}
                            />
                        </>
                    )}
                </>
            )}

            {deleteNewsletterModalOpen && (
                <DeleteNewsletterModal
                    newsletter={itemToDelete}
                    isOpen={deleteNewsletterModalOpen}
                    toggle={toggleDeleteNewsletter}
                    onSubmit={handleDeleteNewsletter}
                    deleting={deleting}
                />
            )}

            {editNewsletterModalOpen && (
                <EditNewsletterModal
                    isOpen={editNewsletterModalOpen}
                    toggle={toggleEditNewsletter}
                    refreshNewsletters={fetchNewsletters}
                    newsletter={itemToEdit}
                />
            )}

            {createNewsletterModalOpen && (
                <CreateNewsletterModal isOpen={createNewsletterModalOpen} toggle={toggleCreateNewsletter} refreshNewsletters={fetchNewsletters} />
            )}

            {previewNewsletterModalOpen && (
                <PreviewNewsletterModal
                    isOpen={previewNewsletterModalOpen}
                    toggle={toggleNewsletterPreview}
                    newsletter={itemToPreview}
                    addFlashMessage={addFlashMessage}
                />
            )}

            {lastSentNewsletterModalOpen && (
                <PreviewNewsletterModal
                    isOpen={lastSentNewsletterModalOpen}
                    toggle={toggleLastSentNewsletterPreview}
                    newsletter={lastSentItemToPreview}
                    lastSentId={lastSentItemToPreview.lastSentEmail.id}
                />
            )}
        </>
    );
};
