import React, { useContext } from 'react';
import { Row, Col, Container } from 'reactstrap';
import { FlashMessageContext } from 'contexts/FlashMessageContext';
import { FlashMessage } from './FlashMessage';
import './style.scss';

export const FlashMessagesContainer = (props) => {
    const { flashMessages, deleteFlashMessage, handleAutoDelete } = useContext(FlashMessageContext);

    const messages = flashMessages.map((item) => {
        handleAutoDelete(item);

        return <FlashMessage key={item.id} id={item.id} type={item.type} message={item.message} deleteFlashMessage={deleteFlashMessage} />;
    });

    return (
        <Container className="flash-message-list-wrapper">
            <Row>
                <Col xs={{ size: 6, offset: 3 }} className="flash-message-list-container">
                    {messages}
                </Col>
            </Row>
        </Container>
    );
};
