import React, { useState, useEffect, useContext } from 'react';
import { isEqual } from 'lodash';
import { FlashMessageContext } from 'contexts/FlashMessageContext';
import { LoadingSpinner } from 'common/LoadingSpinner';
import { AlertList } from './AlertList';
import { ArticleList } from './ArticleList';
import { ArticleView } from './ArticleView';
import './style.scss';

export const DesktopReader = ({
    isPublic,
    id,
    alertsData,
    articlesData,
    alertContent,
    publicEmail,
    allowManageAlertsInReader,
    fetchingAlerts,
    fetchingAlertContent,
    fetchAlerts,
    fetchAlertContent,
}) => {
    const { addFlashMessage } = useContext(FlashMessageContext);

    const [selectedAlert, setSelectedAlert] = useState({});
    const [selectedTag, setSelectedTag] = useState(null);
    const [selectedArticle, setSelectedArticle] = useState(null);
    const [alerts, setAlerts] = useState([]);
    const [articles, setArticles] = useState([]);
    const [tagData, setTagData] = useState({ loc: {}, org: {}, person: {} });

    const filterArticlesData = (data, id) => data.reduce((acc, locItem) => ({ ...acc, [locItem]: id }), {});

    useEffect(() => {
        if (alertsData) {
            alertsData?.length && setSelectedAlert(alertsData[0]);
            setAlerts(alertsData);
        }
    }, [alertsData]);

    useEffect(() => {
        if (alertContent) setSelectedArticle(alertContent.news[0]);
    }, [alertContent]);

    useEffect(() => {
        articlesData && setArticles(articlesData);

        if (articlesData?.length) {
            const tags = { loc: {}, org: {}, person: {} };

            articlesData
                .map((item) => ({
                    loc: filterArticlesData(item.loc, item.id),
                    org: filterArticlesData(item.org, item.id),
                    person: filterArticlesData(item.person, item.id),
                }))
                .forEach((item) =>
                    Object.entries(item).forEach(([key, tag]) => {
                        Object.entries(tag).forEach(([tagName, articleId]) => {
                            const tagKey = tags[key][tagName];

                            tags[key] = {
                                ...tags[key],
                                [tagName]: tagKey ? [...tagKey, articleId] : [articleId],
                            };
                        });
                    })
                );

            setTagData(tags);
        }
    }, [articlesData]);

    useEffect(() => {
        fetchingAlertContent && setSelectedTag(null);
    }, [fetchingAlertContent]);

    const handleTagSelect = (tag) => {
        if (isEqual(selectedTag, tag)) {
            setSelectedTag(null);
        } else {
            setSelectedTag(tag);
        }
    };

    const applyTagFilter = (articleArray) => {
        let filteredArray = [...articleArray];

        if (selectedTag) {
            filteredArray = filteredArray.filter((article) => selectedTag.news.includes(article.id));
        }

        setArticles(filteredArray);
    };

    useEffect(() => {
        if (!alertContent) return;

        if (selectedTag !== null) {
            applyTagFilter(alertContent.news);
        } else {
            setArticles(alertContent.news);
        }
    }, [selectedTag]);

    useEffect(() => {
        selectedAlert.id && fetchAlertContent(selectedAlert.id, selectedAlert.hash);
    }, [selectedAlert]);

    if (fetchingAlerts) return <LoadingSpinner padding text="Fetching reader data" />;

    return (
        <div className="reader">
            <AlertList
                alerts={alerts}
                setSelectedAlert={setSelectedAlert}
                fetchingAlertContent={fetchingAlertContent}
                selectedAlert={selectedAlert}
                tagData={tagData}
                handleTagSelect={handleTagSelect}
                selectedTag={selectedTag}
                addFlashMessage={addFlashMessage}
                refreshAlerts={fetchAlerts}
                userId={id}
                allowManageAlertsInReader={allowManageAlertsInReader}
                isPublic={isPublic}
                publicEmail={publicEmail}
            />

            <ArticleList
                articles={articles}
                handleArticleSelect={setSelectedArticle}
                fetchingArticles={fetchingAlertContent}
                selectedArticle={selectedArticle}
            />

            <ArticleView selectedArticle={selectedArticle} toolbar={!isPublic} isPublic={isPublic} />
        </div>
    );
};
