import React, { useContext, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { SpinnerButton } from 'common/SpinnerButton';
import { AuthContext } from 'contexts/AuthContext';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

export const CommentForm = ({ value, onSubmit, handleValueChange }) => {
    const { handleError } = useContext(AuthContext);
    const [submitting, setSubmitting] = useState(false);

    const handleSubmit = async () => {
        setSubmitting(true);

        try {
            await onSubmit();
        } catch (err) {
            handleError(err);
        }

        setSubmitting(false);
    };

    const commentOptions = {
        options: ['link'],
        link: {
            inDropdown: false,
            className: undefined,
            component: undefined,
            popupClassName: 'comment-link-dropdown',
            showOpenOptionOnHover: true,
            defaultTargetOption: '_blank',
            options: ['link'],
            linkCallback: undefined,
            link: { className: 'comment-link-icon' },
        },
    };

    return (
        <div className="newsletter-comment">
            <div className="newsletter-comment__textarea-container">
                <div className="newsletter-comment__textarea-label">
                    <strong>Comment:</strong>
                </div>

                <Editor
                    editorState={value}
                    toolbarClassName="comment-link-toolbar"
                    wrapperClassName="newsletter-comment__textarea-container"
                    editorClassName="newsletter-comment__textarea"
                    onEditorStateChange={handleValueChange}
                    toolbar={commentOptions}
                />
            </div>

            <div className="d-flex justify-content-end">
                <div className="newsletter-comment__submit-btn-container">
                    <SpinnerButton color="primary" onClick={handleSubmit} title="Save" submitting={submitting} />
                </div>
            </div>
        </div>
    );
};
