import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { CreateEditLabelModal } from './Modals/CreateEditLabelModal';

export const EmptyList = ({ createLabel, submitting, refreshLabels, handleError, addFlashMessage }) => {
    const [createModalOpen, setCreateModalOpen] = useState(false);

    const toggleCreateModal = () => setCreateModalOpen(!createModalOpen);

    return (
        <div>
            <p>Create your first alert label!</p>
            <Button color="primary" onClick={toggleCreateModal}>
                Create Label
            </Button>
            <CreateEditLabelModal
                isOpen={createModalOpen}
                onSubmit={createLabel}
                toggle={toggleCreateModal}
                submitting={submitting}
                handleError={handleError}
                addFlashMessage={addFlashMessage}
                refreshLabels={refreshLabels}
            />
        </div>
    );
};
