import React from 'react';
import { Input } from 'reactstrap';
import { RadioInput } from 'common/Input';
import { SearchInput } from 'common/SearchInput';
import { DualSelectItem } from './DualSelectItem';

export const DualSelectBoxContainer = (props) => {
    const {
        list,
        handleItemSelect,
        selected,
        source,
        handleSearchFilter,
        handleClearSearch,
        handleSortDirection,
        searchValue,
        handleViewTypeChange,
        viewType,
        sortDirection,
        isSourcesOnly,
        isSearchLeftSide,
        isSearchRightSide,
    } = props;

    return (
        <div className="dual-select__box-container dual-select__box-container--left">
            <div className="dual-select__options">
                <SearchInput
                    onClear={() => handleClearSearch(source)}
                    onChange={handleSearchFilter}
                    placeholder={isSourcesOnly ? 'Filter by Source Name' : 'Filter by name'}
                    value={searchValue}
                    extraParams={source}
                />
                <div className="d-flex" style={{ flex: 1 }}>
                    <Input
                        type="select"
                        name="sortDirection"
                        className="dual-select__direction"
                        value={sortDirection}
                        onChange={(e) => handleSortDirection(e.target.value)}>
                        <option value="asc">{window.innerWidth > 640 ? 'Name(A-Z)' : 'A-Z'}</option>
                        <option value="desc">{window.innerWidth > 640 ? 'Name(Z-A)' : 'Z-A'}</option>
                    </Input>
                </div>
            </div>
            {!!isSearchLeftSide && (
                <div className="mt-3 mb-3">
                    <RadioInput name="sources" text="Sources" onChange={() => handleViewTypeChange('sources')} checked={viewType === 'sources'} />
                    <RadioInput name="groups" text="Groups" onChange={() => handleViewTypeChange('groups')} checked={viewType === 'groups'} />
                </div>
            )}
            {!!isSearchRightSide && <div className="m-3 flex-center" style={{ height: '21px' }} />}
            <div className="dual-select__box">
                {list.map((item, i) => (
                    <DualSelectItem
                        key={i + `${item.id}`}
                        item={item}
                        selectedItems={selected}
                        isSearch={!!isSearchLeftSide}
                        handleItemSelect={handleItemSelect}
                        source={source}
                    />
                ))}
            </div>
        </div>
    );
};
