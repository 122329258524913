import { axios } from 'lib/axios';

export const setCustomLogo = (params) => {
    return axios.post('/api/v3/organization/logo', params);
};

export const editOrganizationDetails = (params) => {
    return axios.patch('/api/v3/organization/general', params);
};

export const editOrganizationEmailSettings = (params) => {
    return axios.patch('/api/v3/organization/email_settings', params);
};

export const editOrganizationLogoPosition = (params) => {
    return axios.patch('/api/v3/organization/logo_position', params);
};

export const editOrganizationReaderSettings = (params) => {
    return axios.patch('/api/v3/organization/reader_settings', params);
};

export const getOrganizationLoginToReader = () => {
    return axios.get(`/api/v3/organization/login_to_reader`);
};

export const editOrganizationSchedule = (params) => {
    return axios.patch(`/api/v3/organization/schedule`, params);
};

export const suspendOrganizationAccount = () => {
    return axios.patch('/api/v3/organization/suspended');
};

export const setAllowNotifications = (id, params) => {
    return axios.patch(`/api/v3/user/${id}/allow_notification`, params);
};

export const addCourtlinkToken = (params) => {
    return axios.post('/api/v3/organization/courtlink_token', params);
};

export const editCourtlinkToken = (id, params) => {
    return axios.patch(`/api/v3/organization/courtlink_token/${id}`, params);
};

export const deleteCourtlinkToken = (id) => {
    return axios.delete(`/api/v3/organization/courtlink_token/${id}`);
};

export const addJwtToken = (params) => {
    return axios.post('/api/v3/organization/jwt_token', params);
};

export const editJwtToken = (id, params) => {
    return axios.patch(`/api/v3/organization/jwt_token/${id}`, params);
};

export const deleteJwtToken = (id) => {
    return axios.delete(`/api/v3/organization/jwt_token/${id}`);
};

export const getNotifications = () => {
    return axios.get('/api/v3/profile/notifications');
};

export const readNotifications = (id) => {
    return axios.patch(`/api/v3/profile/read_notifications/${id}`);
};
