export const authReducer = (state, action) => {
    switch (action.type) {
        case 'SET_AUTH':
            return {
                ...state,
                tokenData: { ...action.payload },
                isAuthenticated: true,
            };
        case 'SET_READER_AUTH':
            return {
                ...state,
                readerInfo: { ...action.payload },
                isReaderAuthenticated: true,
            };
        case 'CHECK_AUTH':
            return {
                ...state,
                authChecked: true,
            };
        case 'LOGOUT':
            return {
                ...state,
                profile: {},
                isSingleUser: false,
                isAuthenticated: false,
            };
        case 'SET_PROFILE':
            return {
                ...state,
                profile: { ...action.payload },
                isSingleUser: action.isSingle,
            };
        case 'SET_READER_PROFILE':
            return {
                ...state,
                profile: { ...action.payload },
                tokenData: { ...action.payload },
                isReaderProfileAuthenticated: true,
            };
        default:
            return state;
    }
};
