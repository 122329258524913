import React, { useState } from 'react';
import { Label, Row, Col } from 'reactstrap';
import { InputWithError } from 'common/InputWithError';
import { SpinnerButton } from 'common/SpinnerButton';
import { addJwtToken } from 'utils/api/profileAPI';
import { Token } from './Token';

export const JWT = ({ handleError, profile, addFlashMessage, refreshProfile }) => {
    const [name, setName] = useState('');
    const [token, setToken] = useState('');
    const [errors, setErrors] = useState(null);
    const [submitting, setSubmitting] = useState(false);

    const resetFields = () => {
        setName('');
        setToken('');
    };

    const createJwtToken = async () => {
        setSubmitting(true);

        try {
            await addJwtToken({ name, token });
            refreshProfile();
            setToken('');
            addFlashMessage('success', 'Token successfully created');
            resetFields();
        } catch (err) {
            if (err.message && err.message === 'This token already used') {
                addFlashMessage('danger', 'This token is already in use');
            } else {
                addFlashMessage('danger', 'Invalid token details');
            }

            handleError(err, setErrors);
        }

        setSubmitting(false);
    };

    return (
        <div>
            <h4 className="settings__page-header">JWT</h4>
            <Row className="mt-3">
                <Col xl={12}>
                    <Label>Token Name</Label>

                    <div>
                        <InputWithError
                            prependIcon={<i className="fa fa-tag" />}
                            name="name"
                            value={name}
                            type="text"
                            onChange={setName}
                            errorObj={errors}
                            marginBottomClass="mb-3"
                            placeholder="Token Name"
                        />
                    </div>
                </Col>

                <Col>
                    <Label>Token Value</Label>

                    <div>
                        <InputWithError
                            prependIcon={<i className="fa fa-key" />}
                            name="token"
                            value={token}
                            type="text"
                            onChange={setToken}
                            errorObj={errors}
                            marginBottomClass="mb-3"
                            placeholder="Token Value"
                        />
                    </div>
                </Col>
            </Row>

            <div className="mt-2">
                <SpinnerButton color="primary" onClick={createJwtToken} submitting={submitting} title="Create" />
            </div>

            <div className="mt-4 mb-4">
                {profile.organization.webTokens?.map((item) => (
                    <Token jwtTokenObj={item} addFlashMessage={addFlashMessage} handleError={handleError} refreshProfile={refreshProfile} />
                ))}
            </div>
        </div>
    );
};
