import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { CSidebar, CSidebarNav, CNavGroup, CSidebarToggler } from '@coreui/react';
import { AuthContext } from 'contexts/AuthContext';
import { nav, navAccessCompanyBasedAlerts, mobileNav } from 'constants/nav';
import { useDevice } from 'hooks/useMediaQuery';
import './style.scss';

export const Sidebar = ({ isCloseSidebar, setIsCloseSidebar, isCloseBurger }) => {
    const { isMobile } = useDevice();
    const { pathname } = useLocation();
    const { state } = useContext(AuthContext);
    const accessCompanyBasedAlerts = state?.profile?.organization?.accesses?.accessCompanyBasedAlerts;

    return (
        <CSidebar className={(isCloseSidebar ? 'sidebar-minimized' : '') + (isCloseBurger ? ' closeBurger' : ' show')} narrow={isCloseSidebar}>
            <CSidebarNav>
                {isMobile &&
                    mobileNav.items.map((item, index) => {
                        const classNames = (pathname === item.url ? 'active ' : '') + (item?.class || '');

                        return (
                            <li key={index} className={'nav-item ' + classNames}>
                                <Link className={'nav-link ' + classNames} to={item.url}>
                                    <i className={item.icon}></i>
                                    {item.name}
                                </Link>
                            </li>
                        );
                    })}

                {(accessCompanyBasedAlerts ? navAccessCompanyBasedAlerts : nav).items.map((item, index) => {
                    const classNames = 'nav-item ' + (pathname === item.url ? 'active ' : '') + (item?.class || '');

                    return item?.children?.length ? (
                        <CNavGroup
                            key={index}
                            toggler={
                                <>
                                    <i className={item.icon} />
                                    {item.name}
                                </>
                            }
                            className="show">
                            {item.children.map((child, index) => {
                                const classNames = 'nav-item ' + (pathname === child.url ? 'active ' : '') + (child?.class || '');

                                return (
                                    <li key={index} className={classNames}>
                                        <Link className="nav-link" to={child.url}>
                                            <i className={child.icon}></i>
                                            {child.name}
                                        </Link>
                                    </li>
                                );
                            })}
                        </CNavGroup>
                    ) : (
                        <li key={index} className={classNames}>
                            <Link className={'nav-link ' + (pathname === item.url ? 'active ' : '') + (item?.class || '')} to={item.url}>
                                <i className={item.icon}></i>
                                {item.name}
                            </Link>
                        </li>
                    );
                })}
            </CSidebarNav>
            <CSidebarToggler className="fixed" onClick={() => setIsCloseSidebar(!isCloseSidebar)} />
        </CSidebar>
    );
};
