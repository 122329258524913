import React from 'react';
import { FormGroup, Input } from 'reactstrap';

const options = [
    { value: 'alert.name-asc', name: 'Name(A-Z)' },
    { value: 'alert.name-desc', name: 'Name(Z-A)' },
    { value: 'source.keyword-asc', name: 'Keyword(A-Z)' },
    { value: 'source.keyword-desc', name: 'Keyword(Z-A)' },
    { value: 'alert.countAssignedUsers-asc', name: 'Assigned Users(Ascending)' },
    { value: 'alert.countAssignedUsers-desc', name: 'Assigned Users(Descending)' },
    { value: 'alert.createdAt-asc', name: 'Created At(Ascending)' },
    { value: 'alert.createdAt-desc', name: 'Created At(Descending)' },
    { value: 'source.contentUpdatedAt-asc', name: 'Updated At(Ascending)' },
    { value: 'source.contentUpdatedAt-desc', name: 'Updated At(Descending)' },
    { value: 'alert.countClicks-asc', name: 'Last 30 Days Clicks(Ascending)' },
    { value: 'alert.countClicks-desc', name: 'Last 30 Days Clicks(Descending)' },
];

export const AlertListMobileFilterDropdown = ({ activeMobileFilterValue, handleMobileFilterSelect }) => {
    return (
        <div className="list__mobile-select">
            <FormGroup className="m-0 mb-0">
                <Input
                    onChange={(e) => handleMobileFilterSelect(e.target.value)}
                    value={activeMobileFilterValue}
                    type="select"
                    name="alertListMobileFilters">
                    {options.map((item, index) => (
                        <option key={index} value={item.value}>
                            {item.name}
                        </option>
                    ))}
                </Input>
            </FormGroup>
        </div>
    );
};
