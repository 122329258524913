import React, { useEffect, useState } from 'react';
import { convertToRaw } from 'draft-js';
import './style.scss';

export const CharacterCount = ({ value, limit, asHtml }) => {
    const [parsedHtmlValue, setParsedHtmlValue] = useState('');

    useEffect(() => {
        if (value && typeof value === 'object' && asHtml) {
            const rawContentState = convertToRaw(value.getCurrentContent());
            setParsedHtmlValue(rawContentState.blocks[0].text);
        }
    }, [value]);

    return (
        <div className={'character-count ' + (asHtml ? parsedHtmlValue.length : value.length > limit ? 'character-count--invalid' : '')}>
            Characters remaining: {limit - (asHtml ? parsedHtmlValue.length : value.length)}
        </div>
    );
};
