import React from 'react';
import { FormGroup, Label, Input as ReactStrapInput } from 'reactstrap';

export const RadioInput = ({ onChange, checked = false, name, text = '', classNames = '', isInline, style }) => (
    <div className={classNames + (isInline ? 'form-check form-check-inline' : '') + (style ? '' : ' mb-1')} style={style}>
        <input className="mr-1" type="radio" name={name} checked={checked} onChange={onChange} />

        <label className="mb-0" htmlFor={name}>
            {text}
        </label>
    </div>
);

export const CheckboxInput = ({ isCheck = true, isInline = true, onChange, value, checked = false, disabled = false, name, text, style }) => (
    <FormGroup check={isCheck} inline={isInline}>
        <Label check={isCheck}>
            <ReactStrapInput type="checkbox" value={value} onChange={onChange} name={name} checked={checked} disabled={disabled} style={style} />
            {text}
        </Label>
    </FormGroup>
);
