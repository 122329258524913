import React from 'react';

export const UnassignedSectionListItem = ({ section, handleSectionSelect }) => (
    <div onClick={() => handleSectionSelect(section)} className="sections-list-item sections-list-item--unassigned">
        <div className="sections-list-item__title">
            {section.name}

            <span className="ml-1" style={{ color: 'gray' }}>
                (Will not be delivered)
            </span>
        </div>
    </div>
);
