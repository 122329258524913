import React, { useState } from 'react';
import './style.scss';

export const CompanyPageDescription = ({ alertData, companyDescription, setCompanyDescription }) => {
    const [expandedCompanyDescription, setExpandedCompanyDescription] = useState(false);

    const expandDescription = () => {
        setCompanyDescription(alertData.description);
        setExpandedCompanyDescription(true);
    };

    const hideDescription = () => {
        setCompanyDescription(alertData.description?.slice(0, 500));
        setExpandedCompanyDescription(false);
    };

    return (
        <>
            <div className="company-header-description">
                <h4 className="company-header">Description</h4>
            </div>
            <div className="company-description">
                <p>
                    {companyDescription}&nbsp;
                    {alertData.description?.length > 500 && (
                        <button
                            onClick={!expandedCompanyDescription ? expandDescription : hideDescription}
                            style={{
                                color: '#20a8d8',
                                textDecoration: 'none',
                                backgroundColor: 'transparent',
                                border: 'none',
                            }}>
                            {!expandedCompanyDescription ? 'more' : 'hide'}
                        </button>
                    )}
                </p>
            </div>
        </>
    );
};
