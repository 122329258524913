import React, { useRef } from 'react';
import { Button, Modal } from 'reactstrap';
import { useQuery } from 'react-query';
import moment from 'moment-timezone';
import { convertDateString } from 'utils/helpers';
import { previewRssAlert } from 'utils/api/alertsAPI';
import { useDevice } from 'hooks/useMediaQuery';
import { LoadingSpinner } from 'common/LoadingSpinner';
import { AlertPreviewItem } from 'components/Alerts/components/Preview/AlertPreviewItem';
import { ModalBodyWithClose } from 'common/ModalBodyWithClose';

export const PreviewGroupModal = ({ toggle, isOpen, alertGroup, handleError }) => {
    const ref = useRef(null);
    const { isMobile } = useDevice();

    const { data, isLoading, isError } = useQuery(['previewRssAlert', alertGroup], () => (alertGroup?.rss ? previewRssAlert(alertGroup.rss) : null), {
        onError: (err) => handleError(err),
    });

    const handleScroll = () => ref?.current && ref.current.scrollIntoView({ behavior: 'smooth' });

    if (!alert) return null;

    return (
        <Modal isOpen={isOpen} toggle={toggle} className="newsletter-preview-modal modal-lg">
            <div>
                <ModalBodyWithClose toggle={toggle}>
                    <div className="d-flex justify-content-between mb-2">
                        <div className="newsletter-preview-modal__header">
                            <h2 className="mb-1">Group Preview</h2>
                            <h5 className="mb-1">{alertGroup.name}</h5>
                            <h5 className="mb-0">{convertDateString(moment())}</h5>
                        </div>
                        <div className="d-flex align-items-end">
                            {data?.length > 0 && (
                                <Button onClick={handleScroll}>{!isMobile ? 'Back to top' : <i className="fa fa-chevron-up" />}</Button>
                            )}
                        </div>
                    </div>
                    {isError ? (
                        <div className="mt-4 mb-3">
                            <div className="error-block d-flex">
                                <i className="fa fa-exclamation-circle" />
                                <div>Unable to fetch alert group content at this time. Please try again later.</div>
                            </div>
                        </div>
                    ) : (
                        <div className="newsletter-preview-modal__body">
                            <div ref={ref} />
                            {isLoading ? (
                                <div style={{ padding: '1rem 0' }}>
                                    <LoadingSpinner text="Fetching content" />
                                </div>
                            ) : data?.length ? (
                                <div className="pr-3 mt-2">
                                    {data.map((item, index) => (
                                        <AlertPreviewItem key={index} item={item} />
                                    ))}
                                </div>
                            ) : (
                                <div style={{ padding: '1rem 0' }}>
                                    No alert group content is available yet. Please check again later for updated content.
                                </div>
                            )}
                        </div>
                    )}
                </ModalBodyWithClose>
            </div>
        </Modal>
    );
};
