import React, { useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import { SpinnerButton } from 'common/SpinnerButton';
import { CharacterCount } from 'common/CharacterCount';
import { editNewsletterComment } from 'utils/api/newslettersAPI';

export const NotesForm = ({ newsletterData, refreshNewsletter, addFlashMessage }) => {
    const LIMIT_SYMBOLS = 500;
    const [noteValue, setNoteValue] = useState(EditorState.createEmpty());
    const [submitting, setSubmitting] = useState(false);

    useEffect(() => {
        if (newsletterData && newsletterData.comment) {
            const blocksFromHtml = htmlToDraft(newsletterData.comment);
            const { contentBlocks, entityMap } = blocksFromHtml;
            const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
            const editorState = EditorState.createWithContent(contentState);
            setNoteValue(editorState);
        } else {
            setNoteValue(EditorState.createEmpty());
        }
    }, [newsletterData]);

    useEffect(() => {
        if (noteValue.getCurrentContent().getPlainText('').length > LIMIT_SYMBOLS) {
            const rawContentState = convertToRaw(noteValue.getCurrentContent());
            let lengthText = rawContentState.blocks.length - 1;
            const blocks = [];

            for (let block of rawContentState.blocks) {
                if (lengthText >= LIMIT_SYMBOLS) {
                    break;
                }

                if (lengthText + block.text.length > LIMIT_SYMBOLS) {
                    block.text = block.text.slice(0, LIMIT_SYMBOLS - lengthText);
                    lengthText = LIMIT_SYMBOLS;
                    blocks.push(block);
                    continue;
                }

                lengthText += block.text.length;
                blocks.push(block);
            }

            rawContentState.blocks = blocks;
            const blocksFromHtml = htmlToDraft(draftToHtml(rawContentState));
            const { contentBlocks, entityMap } = blocksFromHtml;
            const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
            const editorState = EditorState.createWithContent(contentState);
            setNoteValue(editorState);
        }
    }, [noteValue]);

    const onSubmit = async () => {
        setSubmitting(true);

        try {
            let noteValueAsHtml;

            if (noteValue) {
                const rawContentState = convertToRaw(noteValue.getCurrentContent());
                noteValueAsHtml = draftToHtml(rawContentState);
            }

            const trimmedHtmlTagsValue = noteValueAsHtml?.replace(new RegExp('<[^>]*>', 'g'), '')?.trim();

            if (trimmedHtmlTagsValue?.length > LIMIT_SYMBOLS) {
                setSubmitting(false);
                addFlashMessage('warning', 'The comment is too long to be saved');
                return;
            }

            const params = { comment: trimmedHtmlTagsValue.length ? noteValueAsHtml : '' };
            await editNewsletterComment(newsletterData.id, params);
            refreshNewsletter();
            addFlashMessage('success', 'Note successfully saved');
        } catch (err) {
            addFlashMessage('danger', 'Error during saving');
        }

        setSubmitting(false);
    };

    const commentOptions = {
        options: ['link'],
        link: {
            inDropdown: false,
            className: undefined,
            component: undefined,
            popupClassName: 'comment-link-dropdown',
            showOpenOptionOnHover: true,
            defaultTargetOption: '_blank',
            options: ['link'],
            linkCallback: undefined,
            link: { className: 'comment-link-icon' },
        },
    };

    const handleBeforeInput = (val) => {
        const textLength = noteValue.getCurrentContent().getPlainText().length;

        if (val && textLength >= LIMIT_SYMBOLS) return 'handled';
        return 'not-handled';
    };

    const handlePastedText = () => {
        const textLength = noteValue.getCurrentContent().getPlainText().length;
        return textLength === LIMIT_SYMBOLS;
    };

    return (
        <>
            <h4>Of note</h4>

            <div className="newsletter-comment__textarea-container newsletter-comment__textarea-container--with-top-border">
                <Editor
                    editorState={noteValue}
                    toolbarClassName="comment-link-toolbar comment-link-toolbar--for-notes"
                    wrapperClassName="newsletter-comment__textarea-container"
                    editorClassName="newsletter-comment__textarea"
                    onEditorStateChange={setNoteValue}
                    toolbar={commentOptions}
                    stripPastedStyles={true}
                    handleBeforeInput={handleBeforeInput}
                    handlePastedText={handlePastedText}
                />
            </div>

            <div className="d-flex justify-content-end">
                <div className="newsletter-comment__submit-btn-container newsletter-note-submit-btn-container">
                    <CharacterCount asHtml value={noteValue ? noteValue : ''} limit={LIMIT_SYMBOLS} />

                    <SpinnerButton color="primary" onClick={onSubmit} title="Save" submitting={submitting} />
                </div>
            </div>
        </>
    );
};
