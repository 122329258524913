import React from 'react';
import { Draggable } from 'react-beautiful-dnd';

export const SectionsListItem = ({ section, handleSectionSelect, index }) => {
    const countOfAlerts = section?.alerts?.length;

    return (
        <Draggable draggableId={String(section.id)} index={index}>
            {(provided, snapshot) => (
                <div
                    onClick={() => handleSectionSelect(section)}
                    className={'sections-list-item ' + (snapshot.isDragging ? 'section-is-dragging' : '')}
                    ref={provided.innerRef}
                    {...provided.draggableProps}>
                    <div className="sections-list-item__title">
                        {section.name} &nbsp;
                        <span style={{ color: 'gray', marginRight: '7px' }}>
                            {countOfAlerts ? (countOfAlerts === 1 ? countOfAlerts + ' Alert' : countOfAlerts + ' Alerts') : null}
                        </span>
                    </div>

                    <div className="sections-list-item__actions" {...provided.dragHandleProps}>
                        <i className="fa fa-bars" />
                    </div>
                </div>
            )}
        </Draggable>
    );
};
