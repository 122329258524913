import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { Row, Col, Card, CardHeader, CardBody } from 'reactstrap';
import { getAlertById, assignUsersToAlert } from 'utils/api/alertsAPI';
import { getUserGroups, getUsersWithoutParams } from 'utils/api/usersAPI';
import { FlashMessageContext } from 'contexts/FlashMessageContext';
import { AuthContext } from 'contexts/AuthContext';
import { SpinnerButton } from 'common/SpinnerButton';
import { LoadingSpinner } from 'common/LoadingSpinner';
import { DualSelect } from 'common/DualSelect';

export const AssignAlert = () => {
    const navigate = useNavigate();
    const { id } = useParams();

    const { addFlashMessage } = useContext(FlashMessageContext);
    const { handleError } = useContext(AuthContext);
    const [submitting, setSubmitting] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState([]);

    const { data: alertData, isLoading: isLoadingAlertData, error: errorAlertData } = useQuery(['alert', id], () => (id ? getAlertById(id) : null));

    const {
        data: usersData,
        isLoading: isLoadingUsersData,
        error: errorUsersData,
    } = useQuery(['users', id], () => getUsersWithoutParams(false, false, 1, 2000));

    const {
        data: userGroupsData,
        isLoading: isLoadingUserGroupsData,
        error: errorUserGroupsData,
    } = useQuery(['user-groups', id], () => getUserGroups());

    const onSubmit = async () => {
        try {
            setSubmitting(true);
            const params = {
                ids: selectedUsers.map((user) => user.id),
            };
            await assignUsersToAlert(id, params);
            setSubmitting(false);
            addFlashMessage('success', 'Alert successfully assigned');

            navigate(-1);
        } catch (err) {
            setSubmitting(false);
            addFlashMessage('danger', 'Unable to assign alerts at this time');
        }
    };

    useEffect(() => {
        alertData && setSelectedUsers(alertData?.assignedUsers);
    }, [alertData, isLoadingAlertData]);

    useEffect(() => {
        const error = errorAlertData || errorUsersData || errorUserGroupsData;

        if (error) {
            handleError(error);
        }
    }, [errorAlertData, errorUsersData, errorUserGroupsData]);

    if (isLoadingAlertData || isLoadingUsersData || isLoadingUserGroupsData) return <LoadingSpinner padding text="Fetching alert data" />;
    if (!alertData || errorAlertData) return null;

    return (
        <div className="animated fadeIn view view--mobile-full">
            <Row>
                <Col xs="12">
                    <Card>
                        <CardHeader>
                            <h1>Assign</h1>
                        </CardHeader>
                        <CardBody>
                            {alertData && (
                                <div className="assign-alert__info">
                                    <h2>{alertData.name}</h2>
                                    <h6>
                                        Alert Keyword: &nbsp;
                                        {alertData.source.type === 'rss' ? (
                                            <strong>RSS / XML</strong>
                                        ) : alertData.source.type === 'email_publication' ? (
                                            <strong>Email Publication</strong>
                                        ) : (
                                            <strong>
                                                {alertData.source.keyword && alertData.source.keyword.slice(0, 8) === 'intitle:'
                                                    ? alertData.source.keyword.slice(8, alertData.source.keyword.length)
                                                    : alertData.source.keyword}
                                            </strong>
                                        )}
                                    </h6>
                                </div>
                            )}
                            <div className="mt-6">
                                <DualSelect
                                    selectedList={selectedUsers}
                                    fullList={usersData?.result}
                                    groups={userGroupsData}
                                    onSelect={setSelectedUsers}
                                    contentType="users"
                                />
                                <SpinnerButton
                                    className="pull-right mt-4"
                                    color="primary"
                                    submitting={submitting}
                                    onClick={onSubmit}
                                    title="Assign"
                                />
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};
