import React from 'react';
import { InputGroup, Col, Row } from 'reactstrap';
import { SearchInputWithSubmit } from 'common/SearchInput/SearchInputWithSubmit';

export const SearchForm = ({
    searchValue,
    searchSubmitting,
    resetForm,
    handleCreatePreview,
    searchValueEditWarning,
    errorObj,
    internalGroup,
    handleSearchValueChange,
}) => (
    <Row>
        <Col xs={12} md={12}>
            <InputGroup className="rss-search-form mb-0">
                <SearchInputWithSubmit
                    onChange={handleSearchValueChange}
                    placeholder="RSS Feed URL"
                    value={searchValue}
                    hasError={errorObj && errorObj.errors.hasOwnProperty('url')}
                    autoComplete="true"
                    onSubmit={handleCreatePreview}
                    submitting={searchSubmitting}
                    onClear={resetForm}
                    disabled={internalGroup}
                />
            </InputGroup>
            {errorObj && errorObj.errors.hasOwnProperty('url') && (
                <div className="mt-1 error-text">{errorObj.errors['url'][0].message || errorObj.errors['url']}</div>
            )}
        </Col>
        <Col xs={12}>
            {searchValueEditWarning && (
                <div className="error-text" style={{ marginTop: '.5rem', paddingBottom: '.5rem', maxWidth: '600px' }}>
                    The existing feed will be deleted and a new one will be created. The new feed will be auto-assigned to the same users.
                </div>
            )}
        </Col>
    </Row>
);
