import React from 'react';
import { UncontrolledTooltip, Button } from 'reactstrap';

export const AlertListCsvButton = ({ downloadFilteredCsv, showText, downloading, desktopSize, mobileSize }) => {
    return (
        <div className={(desktopSize ? '' : mobileSize ? 'w-50' : 'w-100 ') + (showText ? ' mr-1' : '')}>
            {showText ? (
                <Button id="alerts-csv-button-tooltip" className="flex-1 w-100" onClick={downloadFilteredCsv}>
                    <i className="fa fa-download" /> &nbsp; Download CSV
                </Button>
            ) : (
                <div>
                    <Button className="mr-2 mb-0" id="alerts-csv-button-tooltip" onClick={downloadFilteredCsv}>
                        {downloading ? <i className="fa fa-spinner fa-spin" /> : <i className="fa fa-download" />}
                    </Button>
                    <UncontrolledTooltip placement="bottom" target="alerts-csv-button-tooltip" delay={{ show: 400, hide: 0 }}>
                        Download as CSV file
                    </UncontrolledTooltip>
                </div>
            )}
        </div>
    );
};
