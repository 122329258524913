import React, { useContext, useEffect, useState, useRef, useCallback } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { AuthContext } from 'contexts/AuthContext';
import { LoadingSpinner } from 'common/LoadingSpinner';
import { getFreeEmailPreview, getFreeNewsletterPreview } from 'utils/api/alertsAPI';
import './style.scss';

export const PublicEmailPreview = ({ isNewsletter }) => {
    const { id } = useParams();
    const { search } = useLocation();
    const ref = useRef(null);
    const { handleError } = useContext(AuthContext);
    const [fetchingData, setFetchingData] = useState(true);
    const [contentData, setContentData] = useState(null);
    const [scrollLink, setScrollLink] = useState(null);
    const [showScroll, setShowScroll] = useState(null);

    const fetchEmailContent = async () => {
        try {
            const hash = search.slice(3, search.length);
            const hashLink = window.location.hash.substr(1);
            hashLink && setScrollLink(hashLink);

            let data;
            isNewsletter ? (data = await getFreeNewsletterPreview(id, hash)) : (data = await getFreeEmailPreview(id, hash));

            setContentData(data.html);
            setFetchingData(false);
        } catch (err) {
            setFetchingData(false);
            handleError(err);
        }
    };

    const handleScrollTop = () => ref?.current && ref.current.scrollIntoView({ behavior: 'smooth' });

    useEffect(() => {
        fetchEmailContent();
    }, []);

    useEffect(() => {
        if (contentData && scrollLink) {
            setTimeout(() => {
                const elementToScrollTo = document.getElementById(scrollLink);
                elementToScrollTo && elementToScrollTo.scrollIntoView({ behavior: 'smooth' });
            }, 700);
        }
    }, [contentData]);

    useEffect(() => {
        if (contentData) {
            setTimeout(() => {
                const elList = document.querySelectorAll('.scroll-to-link-button');

                elList.forEach((el) => {
                    el.addEventListener('click', () => {
                        const name = el.getAttribute('tabindex');
                        const elementToScrollTo = document.getElementById(name);
                        elementToScrollTo.scrollIntoView({ behavior: 'smooth' });
                    });
                });
            }, 700);
        }
    }, [contentData]);

    const checkScrollTop = useCallback(() => {
        const headerHeight = 400;

        if (!showScroll && window.pageYOffset > headerHeight) {
            setShowScroll(true);
        } else if (showScroll && window.pageYOffset <= headerHeight) {
            setShowScroll(false);
        }
    }, [showScroll]);

    useEffect(() => {
        window.addEventListener('scroll', checkScrollTop);

        return () => window.removeEventListener('scroll', checkScrollTop);
    }, [checkScrollTop]);

    return (
        <div className="public-email-preview position-relative" ref={ref}>
            {!fetchingData ? (
                contentData ? (
                    <div>
                        <div dangerouslySetInnerHTML={{ __html: contentData }} />
                    </div>
                ) : (
                    <div className="p-1">Invalid Link</div>
                )
            ) : (
                <div style={{ padding: '2rem 3rem' }}>
                    <LoadingSpinner text={isNewsletter ? 'Fetching newsletter content' : 'Fetching email content'} />
                </div>
            )}

            <i
                className="scroll-to-top-btn fa fa-chevron-up"
                onClick={handleScrollTop}
                style={{ height: 40, display: contentData && showScroll ? 'flex' : 'none' }}
            />
        </div>
    );
};
