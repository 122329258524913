import React, { useEffect, useRef, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { useOnClickOutside } from 'hooks/useOnClickOutside';
import { getCompanyAutocompleteResults } from 'utils/api/alertsAPI';
import './style.scss';

export const CompanyAutocomplete = ({
    value,
    inputValue,
    searchValueHasError,
    error,
    submitting,
    onSubmit,
    onClear,
    onPressEnter,
    handleAutocompleteSelect,
    preSubmitError,
    handleSearchValueChange,
}) => {
    const [items, setItems] = useState([]);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [query, setQuery] = useState('');

    const ref = useRef();
    useOnClickOutside(ref, () => setDropdownOpen(false));

    const handleChange = async (e) => {
        const query = e.target.value;
        setQuery(query);
        handleSearchValueChange(query);

        if (query.length > 0) {
            const options = await getCompanyAutocompleteResults(query);
            setItems(options);
            if (!submitting) {
                setDropdownOpen(options.length > 0);
            }
        }
    };

    useEffect(() => {
        setQuery(inputValue);
        onPressEnter(query);
    }, [inputValue]);

    const handleClear = () => {
        setQuery('');
        setItems([]);
        setDropdownOpen(false);
        onClear();
    };

    const onAutocompleteSelect = (term) => {
        handleAutocompleteSelect(term);
        setDropdownOpen(false);
    };

    const handlePressEnter = (e) => {
        onPressEnter(e);
        setDropdownOpen(false);
    };

    useEffect(() => {
        if (submitting) dropdownOpen && setDropdownOpen(false);
    }, [submitting]);

    const handleSubmit = () => {
        dropdownOpen && setDropdownOpen(false);
        onSubmit();
    };

    return (
        <div style={{ width: '100%', minHeight: '35px', position: 'relative' }} ref={ref}>
            <TextareaAutosize
                placeholder="Search by a company name or ticker symbol"
                className="internal-search-textarea"
                style={{ width: '100%', paddingRight: '65px', border: error || preSubmitError ? '1px solid red' : '' }}
                value={value}
                onChange={handleChange}
                onKeyDown={handlePressEnter}
            />
            {submitting ? (
                <div className="search-input__icon-container">
                    <i className="fa fa-spinner fa-spin search-input__icon search-input__icon--search" />
                </div>
            ) : (
                <div className="search-input__icon-container d-flex" style={{ zIndex: 2, right: '12px', paddingRight: '.3rem' }}>
                    {value.length > 0 && <i className="fa fa-times-circle search-input__icon search-input__icon--close" onClick={handleClear} />}
                    <i className="fa fa-search search-input__icon search-input__icon--search" onClick={() => handleSubmit()} />
                </div>
            )}
            <div style={{ position: 'relative', width: '100%' }}>
                <div className="textarea-autocomplete" style={{ display: dropdownOpen && !searchValueHasError ? 'block' : 'none' }}>
                    {items.map((item) => (
                        <div className="textarea-autocomplete-list-item" key={item.symbol} onClick={() => onAutocompleteSelect(item.symbol)}>
                            {item.name} <br />
                            {item.symbol}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};
