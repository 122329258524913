import React, { useState, useContext, useCallback } from 'react';
import { Row, Col } from 'reactstrap';
import isEmpty from 'lodash';
import { AuthContext } from 'contexts/AuthContext';
import { FlashMessageContext } from 'contexts/FlashMessageContext';
import { TabBar } from 'common/TabBar';
import { General } from './General';
import { AlertsSchedule } from './AlertsSchedule';
import { Appearance } from './Appearance';
import { SuspendAccount } from './SuspendAccount';
import { EmailSettings } from './EmailSettings';
import { ReaderSettings } from './ReaderSettings';
import { JWT } from './JWT';
import { API } from './API';
import { Courtlink } from './Courtlink';
import './style.scss';

export const Settings = () => {
    const { state, fetchProfile, handleError } = useContext(AuthContext);
    const { addFlashMessage } = useContext(FlashMessageContext);

    const [activeView, setActiveView] = useState('general');
    const access = state?.profile?.organization?.accesses;

    const handleActiveView = useCallback(() => {
        switch (activeView) {
            case 'alerts':
                return (
                    <General profile={state.profile} isSingleUser={state.isSingleUser} addFlashMessage={addFlashMessage} handleError={handleError} />
                );

            case 'emailSettings':
                return (
                    <EmailSettings
                        profile={state.profile}
                        isSingleUser={state.isSingleUser}
                        addFlashMessage={addFlashMessage}
                        refreshProfile={fetchProfile}
                        handleError={handleError}
                    />
                );

            case 'alertsSchedule':
                return (
                    <AlertsSchedule
                        profile={state.profile}
                        isSingleUser={state.isSingleUser}
                        addFlashMessage={addFlashMessage}
                        refreshProfile={fetchProfile}
                        handleError={handleError}
                    />
                );

            case 'appearance':
                return (
                    <Appearance
                        profile={state.profile}
                        isSingleUser={state.isSingleUser}
                        addFlashMessage={addFlashMessage}
                        refreshProfile={fetchProfile}
                        handleError={handleError}
                    />
                );

            case 'suspendAccount':
                return (
                    <SuspendAccount
                        profile={state.profile}
                        isSingleUser={state.isSingleUser}
                        addFlashMessage={addFlashMessage}
                        refreshProfile={fetchProfile}
                        handleError={handleError}
                    />
                );

            case 'reader':
                return (
                    <ReaderSettings
                        profile={state.profile}
                        isSingleUser={state.isSingleUser}
                        addFlashMessage={addFlashMessage}
                        refreshProfile={fetchProfile}
                        handleError={handleError}
                    />
                );

            case 'courtlink':
                return (
                    <Courtlink
                        profile={state.profile}
                        isSingleUser={state.isSingleUser}
                        addFlashMessage={addFlashMessage}
                        refreshProfile={fetchProfile}
                        handleError={handleError}
                    />
                );

            case 'jwt':
                return (
                    <JWT
                        profile={state.profile}
                        isSingleUser={state.isSingleUser}
                        addFlashMessage={addFlashMessage}
                        refreshProfile={fetchProfile}
                        handleError={handleError}
                    />
                );

            case 'api':
                return (
                    <API
                        profile={state.profile}
                        isSingleUser={state.isSingleUser}
                        addFlashMessage={addFlashMessage}
                        refreshProfile={fetchProfile}
                        handleError={handleError}
                    />
                );

            default:
                return <General profile={state.profile} isSingleUser={state.isSingleUser} refreshProfile={fetchProfile} handleError={handleError} />;
        }
    }, [activeView, state, addFlashMessage, fetchProfile, handleError]);

    if (!isEmpty(state.profile)) return null;

    return (
        <div className="animated fadeIn view">
            <Row className="mt-3">
                <Col xs="12" className="col-no-padding-mobile">
                    <div className="settings mb-5">
                        <h1>Settings</h1>

                        <TabBar
                            activeItem={activeView}
                            setActive={setActiveView}
                            useMobileSelect
                            items={[
                                { data: 'general', name: 'General' },
                                { data: 'emailSettings', name: 'Email Settings' },
                                { data: 'reader', name: 'Reader' },
                                { data: 'alertsSchedule', name: 'Schedule' },
                                { data: 'appearance', name: 'Appearance' },
                                { data: 'suspendAccount', name: 'Suspend Account' },
                                ...(access?.apiAccess ? [{ data: 'api', name: 'API' }] : []),
                                ...(access?.jwtAccess ? [{ data: 'jwt', name: 'JWT' }] : []),
                                ...(access?.courtlinkAccess ? [{ data: 'courtlink', name: 'CourtLink' }] : []),
                            ]}
                        />

                        {handleActiveView()}
                    </div>
                </Col>
            </Row>
        </div>
    );
};
