import React from 'react';
import { RelevanceContainerLabels } from 'common/Labels/RelevanceContainerLabels';

export const AddLabelsBlock = ({ selectedLabels, labels, addToSelected, addLabel, removeFromSelected, creatingLabel, isSingleUser }) => (
    <div className="alert-form-submit">
        {!isSingleUser && (
            <div className="alert-form-submit__labels-container">
                <div style={{ fontWeight: 500, marginBottom: '.5rem' }}>Labels</div>
                {labels && (
                    <RelevanceContainerLabels
                        selectedLabels={selectedLabels}
                        labels={labels}
                        removeFromSelected={removeFromSelected}
                        addToSelected={addToSelected}
                        addLabel={addLabel}
                        creatingLabel={creatingLabel}
                        hideBottomMarginWithEmptyList
                        fullWidth
                    />
                )}
            </div>
        )}
    </div>
);
