import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col, Row, Button, UncontrolledTooltip } from 'reactstrap';
import { LoadingSpinnerSmall } from 'common/LoadingSpinner/LoadingSpinnerSmall';
import { TabBar } from 'common/TabBar';
import { sendInstantNewsletter } from 'utils/api/newslettersAPI';
import { ManageSources } from './ManageSources';
import { ReviewArticles } from './ReviewArticles';
import { PreviewNewsletterModal } from 'components/Newsletters/Modals/PreviewNewsletterModal';
import { AssignNewsletterModal } from 'components/Newsletters/Modals/AssignNewsletterModal';
import { EditNewsletterModal } from 'components/Newsletters/Modals/CreateAndEdit/EditNewsletterModal';
import './style.scss';

export const ManageContentDesktop = ({ addFlashMessage, newsletterData, refreshNewsletter, handleError, initialLoad }) => {
    const [activeView, setActiveView] = useState('sources');
    const [previewNewsletterModalOpen, setPreviewNewsletterModalOpen] = useState(false);
    const [assignNewsletterModalOpen, setAssignNewsletterModalOpen] = useState(false);
    const [editNewsletterModalOpen, setEditNewsletterModal] = useState(false);
    const [sendingNewsletter, setSendingNewsletter] = useState(false);
    const schedule = newsletterData?.schedule;

    useEffect(() => {
        !initialLoad && refreshNewsletter();
    }, [activeView]);

    const sendInstantDeliveryNewsletter = async (id) => {
        setSendingNewsletter(true);

        try {
            await sendInstantNewsletter(id);
            addFlashMessage('success', 'Newsletter successfully sent');
            refreshNewsletter();
        } catch (err) {
            if (err.message === 'Content is empty') {
                addFlashMessage('danger', 'This newsletter is empty. Articles must be added before a newsletter can be sent.');
            } else {
                addFlashMessage('danger', 'Unable to send newsletter at this time');
            }
        }

        setSendingNewsletter(false);
    };

    const toggleNewsletterPreviewModal = () => setPreviewNewsletterModalOpen(!previewNewsletterModalOpen);
    const toggleAssignNewsletterModal = () => setAssignNewsletterModalOpen(!assignNewsletterModalOpen);
    const toggleEditNewsletterModal = () => setEditNewsletterModal(!editNewsletterModalOpen);

    return (
        <div className="view mt-0 d-flex h-100">
            <Row className="flex-1">
                <Col xs="12" className="d-flex">
                    <Card className="flex-1">
                        <CardHeader>
                            <div className="flex-space-between">
                                <h2 className="card-header--small">
                                    Newsletter Content: {newsletterData.name} {newsletterData.type === 'curated' ? '(Curated)' : '(Automatic)'}
                                </h2>
                            </div>
                        </CardHeader>

                        <CardBody className="card-body--small d-flex flex-column">
                            <div className="newsletter-content__header">
                                <TabBar
                                    activeItem={activeView}
                                    setActive={setActiveView}
                                    items={[
                                        { data: 'sources', name: 'Manage Sources' },
                                        ...(newsletterData.type === 'curated'
                                            ? [
                                                  {
                                                      data: 'articles',
                                                      name: 'Review' + ' Articles',
                                                  },
                                              ]
                                            : []),
                                    ]}
                                />

                                <div className="newsletter-content__top-button-row">
                                    <Link to="/account/newsletters">
                                        <Button color="primary">
                                            <i className="fa fa-chevron-left" /> Newsletters
                                        </Button>
                                    </Link>

                                    <Button color="primary" onClick={toggleNewsletterPreviewModal}>
                                        Preview
                                    </Button>

                                    {!schedule?.instant_delivery_email && (
                                        <Button color="primary" onClick={toggleAssignNewsletterModal}>
                                            Assign
                                        </Button>
                                    )}

                                    <Button color="primary" onClick={toggleEditNewsletterModal}>
                                        Schedule
                                    </Button>

                                    {schedule?.instant_delivery_email && (
                                        <>
                                            <UncontrolledTooltip
                                                placement="bottom"
                                                target="send-instant-delivery-button"
                                                delay={{ show: 200, hide: 0 }}>
                                                Send Now
                                            </UncontrolledTooltip>

                                            <Button
                                                id="send-instant-delivery-button"
                                                color="light"
                                                onClick={() => sendInstantDeliveryNewsletter(newsletterData.id)}>
                                                {sendingNewsletter ? <LoadingSpinnerSmall /> : <i className="fa fa-paper-plane" />}
                                            </Button>
                                        </>
                                    )}
                                </div>
                            </div>

                            {activeView === 'articles' ? (
                                <ReviewArticles newsletterData={newsletterData} refreshNewsletter={refreshNewsletter} />
                            ) : (
                                <ManageSources
                                    newsletterData={newsletterData}
                                    addFlashMessage={addFlashMessage}
                                    refreshNewsletter={refreshNewsletter}
                                    handleError={handleError}
                                />
                            )}
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            {previewNewsletterModalOpen && (
                <PreviewNewsletterModal
                    isOpen={previewNewsletterModalOpen}
                    toggle={toggleNewsletterPreviewModal}
                    newsletter={newsletterData}
                    addFlashMessage={addFlashMessage}
                />
            )}

            {assignNewsletterModalOpen && (
                <AssignNewsletterModal
                    isOpen={assignNewsletterModalOpen}
                    toggle={toggleAssignNewsletterModal}
                    newsletter={newsletterData}
                    addFlashMessage={addFlashMessage}
                    refreshNewsletter={refreshNewsletter}
                />
            )}

            {editNewsletterModalOpen && (
                <EditNewsletterModal
                    isOpen={editNewsletterModalOpen}
                    toggle={toggleEditNewsletterModal}
                    newsletter={newsletterData}
                    refreshNewsletters={refreshNewsletter}
                />
            )}
        </div>
    );
};
