import React, { useState, useEffect } from 'react';
import { convertDateString } from 'utils/helpers';
import './style.scss';

export const ExtendedInformation = ({ alertData, keyExecutives, setKeyExecutives }) => {
    const [expandedKeyExecutives, setExpandedKeyExecutives] = useState(false);

    useEffect(() => {
        if (expandedKeyExecutives === true) {
            setKeyExecutives(alertData.keyExecutives);
        } else {
            setKeyExecutives(alertData.keyExecutives ? alertData.keyExecutives.slice(0, 3) : []);
        }
    }, [expandedKeyExecutives]);

    return (
        <>
            <h4 className="company-header">Key Executives</h4>
            {keyExecutives?.map((item, index) => {
                return (
                    <div key={index}>
                        <p>
                            {item.name}
                            <br />
                            {item.title}
                            {index === keyExecutives.length - 1 &&
                                (expandedKeyExecutives === false ? (
                                    <a href="#" onClick={() => setExpandedKeyExecutives(true)}>
                                        &nbsp;more
                                    </a>
                                ) : (
                                    <a href="#" onClick={() => setExpandedKeyExecutives(false)}>
                                        &nbsp;hide
                                    </a>
                                ))}
                        </p>
                    </div>
                );
            })}
            {alertData.numberEmployees?.filingDate && alertData.numberEmployees?.employeeCount > 0 && (
                <>
                    <h4 className="company-header">Number of Employees</h4>
                    <p>
                        {alertData.numberEmployees.employeeCount.toLocaleString('en-US')} as of{' '}
                        {convertDateString(alertData.numberEmployees.filingDate)}
                    </p>
                </>
            )}
        </>
    );
};
