import React, { useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { useQuery } from 'react-query';
import { AuthContext } from 'contexts/AuthContext';
import { getAlertLabels } from 'utils/api/alertsAPI';
import { EmailToRss } from 'components/Alerts/components/CreateAndEdit/EmailToRss';
import { Rss } from 'components/Alerts/components/CreateAndEdit/RSS';
import './style.scss';

export const CreateSource = () => {
    const { search } = useLocation();
    const { handleError } = useContext(AuthContext);
    const type = new URLSearchParams(search)?.get('type');
    const [activeAlertType, setActiveAlertType] = useState(type);

    const { data, refetch } = useQuery(['getAlertLabels'], () => getAlertLabels(), { onError: (err) => handleError(err) });

    const rssIsActive = activeAlertType === 'rss' ? 'active' : '';
    const emailToRssIsActive = activeAlertType === 'email_to_rss' ? 'active' : '';

    return (
        <div className="animated fadeIn view">
            <Row className="mt-3">
                <Col xs="12">
                    <div className="alert-container">
                        <h1>Add Content</h1>
                        <div className="desktop-rss-tab-container">
                            <div className={'alert-type-button-label'}>Add:</div>
                            <div className={'alert-type-button ' + rssIsActive} onClick={() => setActiveAlertType('rss')}>
                                <i className="fa fa-rss" />
                                <span className="i-space">&nbsp;</span> RSS
                            </div>
                            <div className={'alert-type-button ' + emailToRssIsActive} onClick={() => setActiveAlertType('email_to_rss')}>
                                <i className="fa fa-envelope-o" />
                                <span className="i-space">&nbsp;</span> Inbox/Email
                            </div>
                        </div>
                        {activeAlertType === 'rss' && <Rss labels={data} refreshLabels={refetch} />}
                        {activeAlertType === 'email_to_rss' && <EmailToRss />}
                    </div>
                </Col>
            </Row>
        </div>
    );
};
