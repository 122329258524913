import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { FlashMessageContext } from 'contexts/FlashMessageContext';
import { AuthContext } from 'contexts/AuthContext';
import { checkExistsCompanyBased, companyCheckExistsCompanyBased } from 'utils/api/alertsAPI';
import { LoadingSpinner } from 'common/LoadingSpinner';
import { SearchForm } from './SearchForm';
import './style.scss';

export const CompanyBasedAlerts = () => {
    const navigate = useNavigate();
    const { addFlashMessage } = useContext(FlashMessageContext);
    const { handleError } = useContext(AuthContext);

    const [searchValue, setSearchValue] = useState('');
    const [searchValueError, setSearchValueError] = useState(false);
    const [preview, setPreview] = useState(null);
    const [searchSubmitting, setSearchSubmitting] = useState(false);
    const [errors, setErrors] = useState(null);

    const handleCreatePreview = async (initialLoadValue) => {
        const tempSearchValue = initialLoadValue ? initialLoadValue : searchValue;

        try {
            if (tempSearchValue.length > 0) {
                setSearchSubmitting(true);
                const response = await companyCheckExistsCompanyBased(tempSearchValue);
                if (response.errors) {
                    handleCompanyBasedErrors(preview);
                    return;
                }
                if (response.length === 0) {
                    addFlashMessage('warning', 'Company not found');
                    setSearchSubmitting(false);
                    return;
                }

                const { alertId } = await checkExistsCompanyBased(tempSearchValue);

                if (alertId) {
                    navigate(`/account/alerts/${alertId}/company-profile`);
                    return;
                }
                navigate(`/account/alerts/${tempSearchValue}/company-preview`);
            }
        } catch (err) {
            handleCompanyBasedErrors(err);
        }
    };

    const handleCompanyBasedErrors = (err) => {
        handleError(err, setErrors);
        setSearchSubmitting(false);
    };

    const handleSearchValueChange = (text) => setSearchValue(text);

    const resetForm = () => {
        setPreview(null);
        setSearchValue('');
        setErrors(null);
    };

    return (
        <div className="animated fadeIn view">
            <Row className="mt-3">
                <Col xs="12">
                    <div className="alert-container">
                        <h1>Add a Company</h1>
                        <div className="mb-4">
                            <SearchForm
                                searchValue={searchValue}
                                handleSearchValueChange={handleSearchValueChange}
                                handleCreatePreview={handleCreatePreview}
                                searchValueError={searchValueError}
                                setSearchValueError={setSearchValueError}
                                resetForm={resetForm}
                                errorObj={errors}
                            />

                            {searchSubmitting && (
                                <div className="mt-4">
                                    <LoadingSpinner text="Fetching preview" />
                                </div>
                            )}
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
};
