import React from 'react';

export const SelectCategory = ({ selectedAlert, handleCategorySelect, categories, setActiveView }) => {
    const capitalizeFirstLetter = (string) => {
        if (!string) return '';

        const str = string.toLowerCase();
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    return (
        <div className="mobile-reader-list mobile-reader-tag-list">
            <div className="mobile-reader-list__header mobile-reader-tag-list__header">
                <div onClick={() => setActiveView('articles')} className="mobile-reader-back-btn">
                    <i className="fa fa-angle-left" />
                    Back
                </div>

                <div>Select a Category</div>

                <div className="mobile-reader-tag-list__active-alert">
                    Active alert: <strong>{selectedAlert.name}</strong>
                </div>
            </div>
            <div className="mobile-reader-list__body">
                {!categories?.length ? (
                    <div style={{ padding: '.5rem 1rem' }}>No tags found</div>
                ) : (
                    categories.map((category) => (
                        <div key={category.type} onClick={() => handleCategorySelect(category)} className="mobile-reader-tag-list__category">
                            <div className="mobile-reader-tag-list__category-inner">
                                <i
                                    className={
                                        'fa ' + category.type === 'PERSON' ? 'fa-user' : category.type === 'LOCATION' ? 'fa-map-marker' : 'fa-sitemap'
                                    }
                                />
                                <div>{capitalizeFirstLetter(category.type)}</div>
                            </div>

                            <i className="fa fa-angle-right" />
                        </div>
                    ))
                )}
            </div>
        </div>
    );
};
