import React from 'react';
import { Button } from 'reactstrap';

export const SpinnerButton = ({ type, submitting, title, disabled, block, color, onClick, className }) => (
    <Button
        color={color}
        style={{ minWidth: '91px', minHeight: type === 'modal' ? '35px' : '0', cursor: disabled ? 'not-allowed' : 'pointer' }}
        type="button"
        className={'px-4 ' + className}
        disabled={disabled}
        block={window.innerWidth < 450 || block}
        onClick={() => !submitting && onClick()}>
        {submitting ? <i className="fa fa-spinner fa-spin" /> : title}
    </Button>
);
