import React, { useState, useEffect } from 'react';
import { Modal, ModalFooter, Button } from 'reactstrap';
import { SpinnerButton } from 'common/SpinnerButton';
import { InputWithError } from 'common/InputWithError';
import { ModalBodyWithClose } from 'common/ModalBodyWithClose';
import { addUserLabel } from 'utils/api/usersAPI';

export const CreateLabelModal = ({ isOpen, toggle, addFlashMessage, handleError, refreshLabels }) => {
    const [name, setName] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const [errors, setErrors] = useState(null);

    useEffect(() => {
        setName('');
    }, [isOpen]);

    const handleSubmit = async () => {
        try {
            setSubmitting(true);
            setErrors(null);

            await addUserLabel({ name });
            refreshLabels();

            addFlashMessage('success', 'User label successfully created');
            setSubmitting(false);
            toggle();
        } catch (err) {
            setSubmitting(false);
            handleError(err, setErrors);
        }
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle} className="create-label-modal">
            <ModalBodyWithClose toggle={toggle} className="pb-0">
                <h2>Create Label</h2>

                <InputWithError
                    placeholder="Label name"
                    name="name"
                    value={name}
                    autoComplete="off"
                    type="text"
                    onChange={setName}
                    errorObj={errors}
                    prependIcon={<i className="fa fa-tag" />}
                    marginBottomClass="mb-3"
                />
            </ModalBodyWithClose>

            <ModalFooter>
                <Button color="secondary" onClick={toggle}>
                    Cancel
                </Button>

                <SpinnerButton type="modal" color="primary" onClick={handleSubmit} submitting={submitting} title="Create" />
            </ModalFooter>
        </Modal>
    );
};
