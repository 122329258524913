import moment from 'moment-timezone';
import { AsYouType } from 'libphonenumber-js';

export const safeTrim = (string) => {
    return string && typeof string === 'string' && string.length > 0 ? string.trim() : ' ';
};

export const handleCaret = (activeSortProperty, activeSortDirection, value) => {
    if (activeSortProperty === value) {
        return activeSortDirection === 'asc' ? (
            <i className="fa fa-caret-up list-sort-caret list-sort-caret--active" />
        ) : (
            <i className="fa fa-caret-down list-sort-caret list-sort-caret--active" />
        );
    } else {
        return <i className="fa fa-caret-down list-sort-caret" />;
    }
};

export const filterByValue = (arr, propertyToFilterBy, searchValue) => {
    let newArr = [...arr];

    if (typeof propertyToFilterBy === 'string') {
        newArr = newArr.filter((item) => {
            if (item[propertyToFilterBy]) {
                return item[propertyToFilterBy].toLowerCase().includes(searchValue.toLowerCase());
            }
        });
    } else if (propertyToFilterBy.constructor === Array) {
        newArr = newArr.filter((item) => {
            let flag = false;

            propertyToFilterBy.forEach((ind) => {
                if (item[ind] !== null && item[ind].toLowerCase().includes(searchValue.toLowerCase())) {
                    flag = true;
                }
            });

            if (flag) {
                return item;
            }
        });
    }

    return newArr;
};

export const sortObjects = (arr, objectStringValue, direction) => {
    return direction === 'asc'
        ? arr.sort((a, b) => String(a[objectStringValue]).localeCompare(String(b[objectStringValue])))
        : arr.sort((a, b) => String(b[objectStringValue]).localeCompare(String(a[objectStringValue])));
};

export const sortByValueLength = (arr, valueToMeasure, direction) => {
    return direction === 'asc'
        ? arr.sort((a, b) => a[valueToMeasure].length - b[valueToMeasure].length)
        : arr.sort((a, b) => b[valueToMeasure].length - a[valueToMeasure].length);
};

export const handleCopyToClipboard = (text = '') => navigator.clipboard.writeText(text);

export const convertDateString = (dateString, withHour) => {
    if (!dateString) return null;

    const obj = moment(dateString);
    const tz = moment.tz.guess();

    return withHour ? moment(obj).tz(tz).format('MM/DD/YYYY [at] h:mm A') : moment(obj).tz(tz).format('MM/DD/YYYY');
};

export const truncateWithoutWordBreak = (str, n) => {
    if (!str) return '';

    let trimmedString = str.substr(0, n);

    if (str.length > trimmedString.length) {
        trimmedString = trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(' '))) + '...';
    }

    return trimmedString;
};

export const convertFormatString = (phoneString, country) => {
    if (!phoneString) return null;

    phoneString = phoneString.replace(/ /g, '');
    const asYouType = new AsYouType(country);
    asYouType.input(phoneString);

    return asYouType.getNumber()?.formatNational();
};

export const truncateLongWords = (str, num) => {
    if (str) {
        const strArr = str.split(' ');

        for (let i = 0; i < strArr.length; i++) {
            if (strArr[i].length > num) {
                strArr[i] = strArr[i].substring(0, num);
            }
        }

        return strArr.join(' ');
    }
};

export const getValueByKey = (obj, key) => {
    const search = (obj, key) => {
        if (typeof obj === 'object' && obj !== null) {
            if (Array.isArray(obj)) {
                for (let i = 0; i < obj.length; i++) {
                    const result = search(obj[i], key);
                    if (result !== undefined) {
                        return result;
                    }
                }
            } else {
                for (let prop in obj) {
                    if (prop === key) {
                        return obj[prop];
                    } else {
                        const result = search(obj[prop], key);
                        if (result !== undefined) {
                            return result;
                        }
                    }
                }
            }
        }
    };

    return search(obj, key);
};

export const getAlertsSort = (activeSortValue) => {
    let sortValue;
    let sortDirection;

    switch (activeSortValue) {
        case 'date-new':
            sortValue = 'created_at';
            sortDirection = 'DESC';
            break;
        case 'date-old':
            sortValue = 'created_at';
            sortDirection = 'ASC';
            break;
        case 'rel-low':
            sortValue = 'rank';
            sortDirection = 'ASC';
            break;
        case 'rel-high':
            sortValue = 'rank';
            sortDirection = 'DESC';
            break;
        default:
            sortValue = 'created_at';
            sortDirection = 'DESC';
            break;
    }

    return { sortValue, sortDirection };
};
