import React, { useState, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import { Button, Modal, ModalFooter, Row } from 'reactstrap';
import { getCategories, addOzmosysCategorizedAlert, deleteOzmosysCategorizedAlert } from 'utils/api/alertsAPI';
import { FlashMessageContext } from 'contexts/FlashMessageContext';
import { AuthContext } from 'contexts/AuthContext';
import { ModalBodyWithClose } from 'common/ModalBodyWithClose';
import { LoadingSpinner } from 'common/LoadingSpinner';
import { SpinnerButton } from 'common/SpinnerButton';
import { TableColCategories } from './TableColCategories';
import './style.scss';

export const ManageCategoryModal = ({ isOpen, setIsOpen, searchType, searchTypeId, refetch }) => {
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const { addFlashMessage } = useContext(FlashMessageContext);
    const { handleError } = useContext(AuthContext);
    const [categories, setCategories] = useState({ 0: [], 1: [], 2: [] });
    const [originalCategories, setOriginalCategories] = useState([]);
    const [submitting, setSubmitting] = useState(false);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [savedCategories, setSavedCategories] = useState([]);
    const [categoriesForDelete, setCategoriesForDelete] = useState([]);

    const { isLoading } = useQuery(['getCategory', searchTypeId], () => (searchTypeId ? getCategories(searchTypeId) : null), {
        onSuccess: (res) => {
            setOriginalCategories(res);

            const convertedList = { 0: [], 1: [], 2: [] };
            const saved = [];

            res.forEach((element, index) => {
                convertedList[(index + 1) % 3].push(element);
                if (element.alerts > 0) {
                    saved.push(element.id);
                }
            });
            setCategories(convertedList);
            setSavedCategories(saved);
        },
        onError: (err) => {
            handleError(err, err, addFlashMessage);
        },
    });

    const toggle = () => setIsOpen(!isOpen);

    const handleChangeCategories = (value, checked) => {
        let newSelectedCategories = [...selectedCategories];

        if (true === checked) {
            newSelectedCategories.push({
                id: value,
                name: originalCategories.find((item) => +item.id === +value)?.name,
            });
        } else {
            addForDelete(value);
            newSelectedCategories = newSelectedCategories.filter((item) => item.id !== value);
        }

        setSelectedCategories(newSelectedCategories);
    };
    const handleAssign = async (selectedCategories) => {
        try {
            setSubmitting(true);
            await addOzmosysCategorizedAlert({
                alerts: selectedCategories.map((item) => ({ name: item.name, newsCategory: +item.id })),
            });

            if (categoriesForDelete.length) {
                await deleteOzmosysCategorizedAlert(categoriesForDelete.join());
            }

            refetch && (await refetch());
            setSubmitting(false);
            if (pathname !== '/account/manage-category-based/' + searchType) {
                navigate('/account/manage-category-based/' + searchType);
            }
            toggle();
        } catch (err) {
            setSubmitting(false);
            addFlashMessage('danger', 'Unable to create/assign alerts at this time');
        }
    };

    const addForDelete = (id) => {
        if (savedCategories.indexOf(+id) !== -1 && categoriesForDelete.indexOf(+id) === -1) {
            setCategoriesForDelete([...categoriesForDelete, +id]);
        }
    };

    return (
        <div>
            <Modal isOpen={isOpen} fade={false} className="info-modal">
                <ModalBodyWithClose toggle={toggle}>
                    <h2>Select {searchType}</h2>
                    {isLoading ? (
                        <LoadingSpinner />
                    ) : (
                        <div className="table-container">
                            <Row>
                                <TableColCategories categories={categories[1]} handleChangeCategories={handleChangeCategories} />
                                <TableColCategories categories={categories[2]} handleChangeCategories={handleChangeCategories} />
                                <TableColCategories categories={categories[0]} handleChangeCategories={handleChangeCategories} />
                            </Row>
                        </div>
                    )}
                </ModalBodyWithClose>
                <ModalFooter>
                    <Button color="secondary" onClick={toggle}>
                        Cancel
                    </Button>
                    <SpinnerButton
                        type="modal"
                        color="primary"
                        onClick={() => handleAssign(selectedCategories)}
                        submitting={submitting}
                        title="Confirm"
                    />
                </ModalFooter>
            </Modal>
        </div>
    );
};
