import React from 'react';
import { ManageAlertsModal } from 'components/Reader/Modals/ManageAlertsModal';
import { MobileSignIn } from './MobileSignIn';

export const CheckReaderAuth = ({
    setActiveView,
    addFlashMessage,
    assignedAlerts,
    isPublic,
    state,
    handleError,
    userId,
    refreshAlerts,
    publicEmail,
}) => {
    if (state.isAuthenticated || state.isReaderProfileAuthenticated) {
        return (
            <ManageAlertsModal
                setActiveView={setActiveView}
                addFlashMessage={addFlashMessage}
                assignedAlerts={assignedAlerts}
                isPublic={isPublic}
                state={state}
                handleError={handleError}
                userId={userId}
                publicEmail={publicEmail}
                refreshAlerts={refreshAlerts}
            />
        );
    } else {
        return <MobileSignIn state={state} addFlashMessage={addFlashMessage} handleError={handleError} publicEmail={publicEmail} />;
    }
};
