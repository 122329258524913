import React, { useEffect, useState } from 'react';
import { LoadingSpinnerSmall } from 'common/LoadingSpinner/LoadingSpinnerSmall';
import { SearchInput } from 'common/SearchInput';
import { filterByValue } from 'utils/helpers';
import { ArticleListItem } from './ArticleListItem';

export const ArticleList = ({ articles, handleArticleSelect, fetchingArticles, selectedArticle }) => {
    const [searchValue, setSearchValue] = useState('');
    const [visibleArticles, setVisibleArticles] = useState([]);

    useEffect(() => {
        setVisibleArticles(articles);
    }, [articles]);

    useEffect(() => {
        const newList = filterByValue(articles, 'title', searchValue);
        setVisibleArticles(newList);
    }, [searchValue]);

    return (
        <div className="reader-column reader-article-list">
            <div className="reader-column__wrapper">
                <div className="reader-column__header reader-article-list__header">
                    <span>Articles</span>
                </div>

                <div className="reader-column__searchbar reader-article-list__searchbar">
                    <SearchInput
                        placeholder="Search by article title"
                        value={searchValue}
                        classNames={['reader-column__input reader-article-list__input']}
                        iconClassNames={['reader-article-list__searchbar-icon']}
                        closeClassNames={['reader-article-list__searchbar-icon']}
                        onChange={setSearchValue}
                        onClear={() => setSearchValue('')}
                    />
                </div>

                <div className="reader-column__body reader-article-list__body">
                    {fetchingArticles ? (
                        <div className="d-flex" style={{ padding: '.5rem 1rem' }}>
                            <LoadingSpinnerSmall />
                        </div>
                    ) : !visibleArticles.length ? (
                        <div style={{ padding: '0.375rem 0.75rem' }}>No articles found</div>
                    ) : (
                        visibleArticles.map((article) => (
                            <ArticleListItem
                                article={article}
                                key={article.id}
                                handleArticleSelect={handleArticleSelect}
                                selectedArticle={selectedArticle}
                            />
                        ))
                    )}
                </div>

                <div className="reader-column__footer reader-article-list__footer">
                    {visibleArticles.length} {visibleArticles.length === 1 ? 'Article' : 'Articles'}
                </div>
            </div>
        </div>
    );
};
