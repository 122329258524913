import React from 'react';
import { Input } from 'reactstrap';

export const MobileFilterDropdown = ({ activeMobileFilterValue, handleMobileFilterSelect }) => (
    <div className="list__mobile-select">
        <div className="m-0">
            <Input
                onChange={(e) => handleMobileFilterSelect(e.target.value)}
                value={activeMobileFilterValue}
                type="select"
                name="newsletterListMobileFilters">
                <option value="name-asc">Name(A-Z)</option>
                <option value="name-desc">Name(Z-A)</option>
                <option value="createdAt-asc">Created At(Ascending)</option>
                <option value="createdAt-desc">Created At(Descending)</option>
                <option value="lastSentEmail-desc">Last Sent Email(Ascending)</option>
                <option value="lastSentEmail-desc">Last Sent Email(Descending)</option>
            </Input>
        </div>
    </div>
);
