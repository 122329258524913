import React, { createContext, useState } from 'react';
import shortid from 'shortid';

export const FlashMessageContext = createContext();

const FlashMessageContextProvider = ({ children }) => {
    const [flashMessages, setFlashMessages] = useState([]);

    const addFlashMessage = (type, message) => {
        if (flashMessages.length === 0) {
            const newArr = [...flashMessages];
            newArr.push({ type, message, id: shortid.generate() });
            setFlashMessages(newArr);
        }
    };

    const handleAutoDelete = (message) => {
        setTimeout(function () {
            deleteFlashMessage(message.id);
        }, 5000);
    };

    const deleteFlashMessage = (messageId) => {
        const newArr = [...flashMessages];
        const foundMessage = newArr.find((item) => item.id === messageId);

        if (foundMessage) {
            newArr.splice(newArr.indexOf(foundMessage), 1);
            setFlashMessages(newArr);
        }
    };

    const clearFlashMessages = () => setFlashMessages([]);

    return (
        <FlashMessageContext.Provider value={{ flashMessages, addFlashMessage, deleteFlashMessage, handleAutoDelete, clearFlashMessages }}>
            {children}
        </FlashMessageContext.Provider>
    );
};

export default FlashMessageContextProvider;
