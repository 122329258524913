import React from 'react';

export const TabItem = ({ activeItem, item, handleSelect, errorObj, shrink, smallText, right }) => {
    let containsError;

    if (item.type === 'general' && errorObj) {
        if (
            (errorObj.errors && errorObj.errors.hasOwnProperty('fullName')) ||
            errorObj.errors.hasOwnProperty('email') ||
            errorObj.errors.hasOwnProperty('timezone')
        ) {
            containsError = true;
        }
    }
    if (item.type === 'emailSchedule' && errorObj) {
        if ((errorObj.errors && errorObj.errors.hasOwnProperty('sameSchedule')) || errorObj.errors.hasOwnProperty('days')) {
            containsError = true;
        }
    }

    return (
        <div
            onClick={() => handleSelect(item?.type || item?.data)}
            className={
                'tab-item ' +
                (shrink ? 'tab-item--shrink ' : ' ') +
                (smallText ? 'tab-item--small-text ' : ' ') +
                (activeItem === item?.data || activeItem === item?.type ? 'tab-item--active ' : ' ') +
                (containsError ? 'tab-item--error ' : '') +
                (right ? 'tab-item--right ' : '')
            }>
            {item.name}
            {containsError && <i className="fa fa-exclamation-circle" />}
        </div>
    );
};
