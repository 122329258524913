import React, { useState, useEffect } from 'react';
import { Chart as ChartJS, CategoryScale, ArcElement, LinearScale, BarElement, LineElement, PointElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import moment from 'moment-timezone';
import { useDevice } from 'hooks/useMediaQuery';

ChartJS.register(CategoryScale, ArcElement, LinearScale, BarElement, PointElement, LineElement, Title, Tooltip, Legend);

const ComparativeAnalysisChart = ({ activeSetStats, chartKey }) => {
    const [dataArray, setDataArray] = useState([]);
    const { isMobile } = useDevice();

    useEffect(() => {
        if (activeSetStats && activeSetStats.length) {
            setDataArray(activeSetStats);
        }
    }, [activeSetStats]);

    const getMonth = (index) => {
        const dateObj = new Date((dataArray[0].statistics.newsPerDay[index].day + ' 00:00:00').replace(' ', 'T'));
        const momentObj = moment(dateObj);

        return momentObj.format('MMM');
    };

    const convertDateString = (dateString) => {
        if (!dateString) return null;
        const obj = new Date(dateString);

        return moment(obj).format('DD');
    };

    const ticks = dataArray?.length && [...dataArray[0]?.statistics.newsPerDay?.map((item) => convertDateString(item?.day))];

    const getValue = (index) => {
        const item = ticks[index];
        const width = window.innerWidth;

        if (index === 0) return getMonth(index);

        if (index % 4 === 0 && width < 600) {
            if (item[0] === '0' && +ticks[index - 4][0] > +item[0]) {
                return getMonth(index);
            }
            return item;
        }

        if (index % 2 === 0 && width > 600) {
            if (item[0] === '0' && +ticks[index - 2][0] > +item[0]) {
                return getMonth(index);
            }
            return item;
        }
    };

    const buildLabelDate = (dateString) => {
        if (!dateString) return null;
        const obj = new Date(dateString);

        return moment(obj).format('MMM DD,YYYY');
    };

    const buildMobileLabel = (string) => {
        if (isMobile && string.length > 30) return string.slice(0, 25) + '...';

        return string;
    };

    const getThemedColor = (i) => {
        const colorArray = [
            'rgb(26,148,199)',
            'rgb(1,26,84)',
            'rgb(3,189,234)',
            'rgb(144,204,254)',
            'rgb(0, 102, 153)',
            'rgb(30,144,255)',
            'rgb(25,25,112)',
            'rgb(0,0,128)',
            'rgb(0,0,205)',
            'rgb(0,0,255)',
            'rgb(65,105,225)',
            'rgb(147,112,219)',
            'rgb(106,90,205)',
            'rgb(72,61,139)',
            'rgb(75,0,130)',
        ];
        return colorArray[i];
    };

    const newDataset = dataArray.map((item, i) => {
        let randomColor = Math.floor(Math.random() * 16777215).toString(16);
        return {
            label: buildMobileLabel(item.name),
            tension: 0.2,
            borderWidth: isMobile ? 2 : 4,
            data: item.statistics.newsPerDay.map((item) => item.cnt),
            borderColor: i >= 15 ? `#${randomColor}` : getThemedColor(i),
            backgroundColor: i >= 15 ? `#${randomColor}` : getThemedColor(i),
            fill: false,
            type: 'line',
            datalabels: {
                display: false,
            },
        };
    });

    const data = {
        labels: dataArray?.length && dataArray[0]?.statistics.newsPerDay?.map((item) => buildLabelDate(item.day)),
        datasets: newDataset,
    };

    return (
        <div style={{ maxHeight: isMobile ? 500 : 300 }}>
            <Bar
                key={chartKey}
                data={data}
                height={isMobile ? 500 : 300}
                options={{
                    legend: {
                        display: activeSetStats?.length <= 20,
                        position: 'top',
                        paddingLeft: 10,
                        labels: {
                            boxWidth: isMobile ? 20 : 40,
                            fontSize: isMobile ? 15 : 12,
                        },
                    },
                    maintainAspectRatio: false,
                    scales: {
                        x: {
                            ticks: {
                                autoSkip: false,
                                maxRotation: 50,
                                minRotation: 50,
                                stepSize: 1,
                                callback: (_, index) => {
                                    return getValue(index);
                                },
                            },
                        },
                        y: {
                            ticks: {
                                beginAtZero: true,
                            },
                        },
                    },
                    plugins: {
                        datalabels: {
                            display: true,
                            color: 'white',
                            borderRadius: 6,
                            font: {
                                size: 12,
                            },
                            formatter: (value) => {
                                if (value > 0) {
                                    return value;
                                } else {
                                    return null;
                                }
                            },
                        },
                    },
                }}
            />
        </div>
    );
};

export default ComparativeAnalysisChart;
