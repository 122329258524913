import React from 'react';
import { Input, InputGroup, InputGroupText } from 'reactstrap';
import './style.scss';

export const SelectInputWithError = (props) => {
    const { prependIcon, onChange, autoCompleteOn, errorObj, name, marginBottomClass, children, ...rest } = props;

    const hasError = errorObj && errorObj.errors.hasOwnProperty(name);

    return (
        <div className={'d-flex flex-column ' + (hasError ? 'mb-2' : marginBottomClass ? marginBottomClass : 'mb-4')}>
            <InputGroup className={'m-0 ' + (hasError ? 'input-group-with-error' : '')}>
                {prependIcon && <InputGroupText>{prependIcon}</InputGroupText>}
                <Input
                    autoComplete={autoCompleteOn}
                    {...rest}
                    onChange={(e) => onChange(e.target.value)}
                    style={{ borderLeft: !prependIcon && hasError ? '1px solid red' : '' }}>
                    {children}
                </Input>
            </InputGroup>
            {hasError && <div className="error-text mt-1">{errorObj.errors[name][0].message}</div>}
        </div>
    );
};
