import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { convertDateString, truncateLongWords } from 'utils/helpers';
import Logo from 'images/logo.png';

export const ArticleView = ({ selectedArticle, toolbar, isPublic }) => (
    <div className="reader-article-view">
        <div className="animated fadeIn w-100">
            {isPublic && (
                <div className="reader-article-view__public-header">
                    <div className="public-reader-logo">
                        <Link to="/" target="_blank">
                            <img src={Logo} alt="logo" />
                        </Link>
                    </div>
                </div>
            )}

            <div>
                {toolbar && (
                    <div className="reader-article-view__toolbar">
                        <Link to="/account/users">
                            <i className="fa fa-arrow-left reader-article-view__back-btn" />
                        </Link>
                    </div>
                )}

                <div className="reader-article-view__body">
                    {!selectedArticle ? (
                        <Row>
                            <Col xs="12">
                                <h2 className="reader-article-view__title reader-article-view__title--empty">Select an article to get started</h2>
                            </Col>
                        </Row>
                    ) : (
                        <Row>
                            <Col xs="12">
                                {selectedArticle.title && (
                                    <h2 className="reader-article-view__title" dangerouslySetInnerHTML={{ __html: selectedArticle.title }} />
                                )}

                                {selectedArticle.resource && selectedArticle.resource.length < 34 ? (
                                    <div className="reader-article-view__info">
                                        <div className="reader-article-view__source">{selectedArticle.resource}</div>
                                        &nbsp; &#183; &nbsp;
                                        <div className="reader-article-view__date">{convertDateString(selectedArticle.created_at)}</div>
                                    </div>
                                ) : (
                                    <div>
                                        <div className="reader-article-view__source">{selectedArticle.resource}</div>
                                        <div className="reader-article-view__date">{convertDateString(selectedArticle.created_at)}</div>
                                    </div>
                                )}

                                <div
                                    className="reader-article-view__content"
                                    dangerouslySetInnerHTML={{ __html: truncateLongWords(selectedArticle.abstract, 50) }}
                                />
                            </Col>

                            <Col xs="12">
                                <Link
                                    className="btn btn-primary pull-right"
                                    target="_blank"
                                    to={selectedArticle.url}
                                    style={{
                                        color: '#fff',
                                        backgroundColor: '#20a8d8',
                                        borderColor: '#1b8eb7',
                                        marginTop: '1rem',
                                        textDecoration: 'none',
                                    }}>
                                    Read More
                                </Link>
                            </Col>
                        </Row>
                    )}
                </div>
            </div>
        </div>
    </div>
);
