import React, { useEffect, useState } from 'react';
import { LoadingSpinnerSmall } from 'common/LoadingSpinner/LoadingSpinnerSmall';
import { TagCategory } from './TagCategory';

export const AlertListItem = ({ alert, fetchingAlertContent, selectedAlert, tagData, handleTagSelect, selectedTag, setSelectedAlert }) => {
    const [tagListOpen, setTagListOpen] = useState(false);

    const isActiveAlert = selectedAlert && selectedAlert.id === alert.id ? 'reader-alert__title-container--active' : '';
    const rotateIcon = tagListOpen && isActiveAlert ? 'rotate-90' : '';

    const handleAlertSelect = (alert) => (selectedAlert && selectedAlert.id === alert.id ? toggleTagListOpen() : setSelectedAlert(alert));
    const toggleTagListOpen = () => setTagListOpen(!tagListOpen);

    const areAllTagsEmpty = (obj) => {
        for (let key in obj) {
            if (Object.keys(obj[key]).length > 0) {
                return false;
            }
        }

        return true;
    };

    useEffect(() => {
        if (selectedAlert && selectedAlert.id === alert.id) {
            !fetchingAlertContent && toggleTagListOpen();
        } else {
            setTagListOpen(false);
        }
    }, [selectedAlert]);

    return (
        <div className="reader-alert">
            <div className={'reader-alert__title-container ' + isActiveAlert} onClick={() => handleAlertSelect(alert)}>
                <div className="reader-alert__title">{alert.name}</div>

                <div className="reader-alert__arrow">
                    {fetchingAlertContent && selectedAlert.id === alert.id ? (
                        <LoadingSpinnerSmall />
                    ) : (
                        <i className={'fa fa-angle-left ' + rotateIcon} />
                    )}
                </div>
            </div>

            {tagListOpen && !fetchingAlertContent && (
                <div className="reader-alert__tag-list">
                    {areAllTagsEmpty(tagData) ? (
                        <div className="reader-alert__empty-tag">No tags found</div>
                    ) : (
                        Object.entries(tagData).map(([key, tag], i) => (
                            <TagCategory
                                key={key + i}
                                category={{ type: key, tags: tag }}
                                selectedTag={selectedTag}
                                handleTagSelect={handleTagSelect}
                            />
                        ))
                    )}
                </div>
            )}
        </div>
    );
};
