import React from 'react';
import { Navigate } from 'react-router-dom';
import { CATEGORY_TYPE, COMPANY_TYPE, SEARCH_TYPE, SOURCE_TYPE } from 'constants/alerts';
import { Dashboard } from 'components/Dashboard';
import { AlertsWrapper } from 'components/Alerts';
import { AssignAlert } from 'components/Alerts/AlertsList/AssignAlert';
import { DigDeeper } from 'components/Alerts/components/DigDeeper';
import { CreateSource } from 'components/Alerts/components/Sources/CreateSource';
import { CreateEditAlert } from 'components/Alerts/components/CreateAndEdit';
import { CompanyBasedAlerts } from 'components/Alerts/components/CreateAndEdit/CompanyBasedAlerts';
import { CompanyPagePreview } from 'components/Alerts/components/CompanyPage/CompanyPagePreview';
import { CompanyPage } from 'components/Alerts/components/CompanyPage';
import { Users } from 'components/Users';
import { SortAlerts } from 'components/Users/SortAlerts';
import { Reader } from 'components/Reader';
import { CompanyReaderSignIn } from 'components/Reader/CompanyReaderSignIn';
import { CreateEditUser } from 'components/Users/CreateAndEdit';
import { GlobalAssignment } from 'components/GlobalAssignment';
import { Newsletters } from 'components/Newsletters';
import { ManageNewsletterContent } from 'components/Newsletters/ManageContent';
import { AssignNewsletter } from 'components/Newsletters/Assignment';
import { Reports } from 'components/Reports';
import { Settings } from 'components/Settings';
import { PrivacyPolicy } from 'components/PrivacyPolicy';

export const protectedRoutes = [
    { path: '/account/dashboard', element: <Dashboard /> },
    { path: '/account/alerts', element: <AlertsWrapper type={SEARCH_TYPE} /> },
    { path: '/account/manage-sources', element: <AlertsWrapper type={SOURCE_TYPE} /> },
    { path: '/account/manage-companies', element: <AlertsWrapper type={COMPANY_TYPE} /> },
    { path: '/account/manage-category-based/:category', element: <AlertsWrapper type={CATEGORY_TYPE} /> },
    { path: '/account/users', element: <Users /> },
    { path: '/account/assignment', element: <GlobalAssignment /> },
    { path: '/account/newsletters', element: <Newsletters /> },
    { path: '/account/reports', element: <Reports /> },
    { path: '/settings', element: <Settings /> },
    { path: '/account/privacy-policy', element: <PrivacyPolicy /> },
    { path: '/account/alerts/assign/:id', element: <AssignAlert /> },
    { path: '/account/alerts/dig-deeper/:id', element: <DigDeeper /> },
    { path: '/account/sources/create', element: <CreateSource /> },
    { path: '/account/alerts/create', element: <CreateEditAlert /> },
    { path: '/account/companies/create', element: <CompanyBasedAlerts /> },
    { path: '/account/sources/edit/:id', element: <CreateEditAlert isSources isEdit /> },
    { path: '/account/searches/edit/:id', element: <CreateEditAlert isEdit /> },
    { path: '/account/alerts/:id/company-profile', element: <CompanyPage /> },
    { path: '/account/alerts/:symbol/company-preview', element: <CompanyPagePreview /> },
    { path: '/account/users/sort/:id', element: <SortAlerts /> },
    { path: '/account/users/create', element: <CreateEditUser /> },
    { path: '/account/users/edit/:id', element: <CreateEditUser isEdit /> },
    { path: '/account/reader/:id', element: <Reader /> },
    { path: '/company/reader/signin', element: <CompanyReaderSignIn /> },
    { path: '/account/newsletters/content/:id', element: <ManageNewsletterContent /> },
    { path: '/account/newsletters/assign/:id', element: <AssignNewsletter /> },
    { path: '*', element: <Navigate to="/account/dashboard" /> },
];
