import React from 'react';
import { SpinnerButton } from 'common/SpinnerButton';
import { InputWithError } from 'common/InputWithError';

export const TitleForm = ({ submitting, title, setTitle, handleSubmit, isEditForm, errors }) => (
    <div className="mt-3 alert-title-form-container">
        <div className="alert-title-form__inner">
            <div className="w-100">
                <InputWithError
                    type="text"
                    name="name"
                    onChange={setTitle}
                    value={title}
                    placeholder="Content Name"
                    errorObj={errors}
                    marginBottomClass="mb-3"
                />
            </div>
        </div>
        <SpinnerButton
            color="primary"
            type="submit"
            className="px-4 mb-2"
            onClick={handleSubmit}
            submitting={submitting}
            title={isEditForm ? 'Save' : 'Create'}
        />
    </div>
);
