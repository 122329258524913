import React from 'react';
import { Link } from 'react-router-dom';

export const API = () => (
    <>
        <h4 className="settings__page-header">API</h4>

        <div className="mt-3">
            <div>URL:</div>

            <div className="mb-3">
                <Link to="https://api.ozmosys.com/api/doc" target="_blank" rel="noopener noreferrer">
                    https://api.ozmosys.com/api/doc
                </Link>
            </div>
        </div>
    </>
);
