import React from 'react';
import { convertDateString, truncateWithoutWordBreak, truncateLongWords } from 'utils/helpers';

export const AssignedArticle = ({ handleRemoveArticle, article, withControls = false, includeAbstracts = false }) => {
    const { title, createdAt, id, abstract, resource } = article.news;
    const domain = article.news?.newsResource?.domain;

    return (
        <div
            className={
                'mobile-newsletter-article mobile-newsletter-article--assigned ' + (withControls ? '' : 'mobile-newsletter-article--without-actions')
            }
            id={id}>
            <div className="flex-grow-1">
                {title.length > 100 ? (
                    <div className="mobile-newsletter-article__title">
                        <strong>{truncateWithoutWordBreak(truncateLongWords(title, 50), 100)}</strong>
                    </div>
                ) : (
                    <div className="mobile-newsletter-article__title">
                        <strong>{title}</strong>
                    </div>
                )}

                {includeAbstracts && abstract && <div className="mobile-newsletter-article__abstract">{truncateLongWords(abstract, 150)}</div>}

                <div className="mobile-newsletter-article__info d-flex justify-content-between">
                    <div className="mobile-newsletter-article__publisher">{truncateLongWords(domain || resource, 80)}</div>

                    <div className="mobile-newsletter-article__date">{convertDateString(createdAt)}</div>
                </div>
            </div>

            {withControls && (
                <div className="mobile-newsletter-article__actions">
                    <div onClick={() => handleRemoveArticle(id)}>
                        <span>×</span>
                    </div>
                </div>
            )}
        </div>
    );
};
