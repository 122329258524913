import React, { useState, useEffect, useContext } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { AuthContext } from 'contexts/AuthContext';
import { SpinnerButton } from 'common/SpinnerButton';
import { LoadingSpinner } from 'common/LoadingSpinner';
import { getOrganizationLoginToReader, editOrganizationReaderSettings } from 'utils/api/profileAPI';
import { ReaderSettingsToggle } from './ReaderSettingsToggle';
import { handleCopyToClipboard } from 'utils/helpers';

export const ReaderSettings = ({ profile, addFlashMessage, refreshProfile }) => {
    const { handleError } = useContext(AuthContext);
    const [submitting, setSubmitting] = useState(false);
    const [allowReaderManagement, setAllowReaderManagement] = useState(false);
    const [allowPersonalReaderLogin, setAllowPersonalReaderLogin] = useState(false);
    const [readerLoginDetails, setReaderLoginDetails] = useState(null);
    const [fetchingData, setFetchingData] = useState(true);

    useEffect(() => {
        const access = profile.organization.accesses;

        if (access) {
            setAllowReaderManagement(access.myReaderAuthAccess);
            if (access.loginToReaderAccess) {
                getReaderLogin();
            } else {
                setFetchingData(false);
            }
        }
    }, [profile]);

    const getReaderLogin = async () => {
        try {
            const readerLoginDetails = await getOrganizationLoginToReader();
            setAllowPersonalReaderLogin(readerLoginDetails.allowLogin);
            setReaderLoginDetails(readerLoginDetails);
            setFetchingData(false);
        } catch (err) {
            handleError(err);
        }
    };

    const updateOrganizationReaderSettings = async () => {
        setSubmitting(true);

        try {
            const params = {
                myReaderAuth: allowReaderManagement,
                allowLogin: allowPersonalReaderLogin,
                regenerate: false,
            };
            await editOrganizationReaderSettings(params);

            if (allowPersonalReaderLogin) {
                await getReaderLogin();
            }
            addFlashMessage('success', 'Reader settings successfully updated');
            refreshProfile();
        } catch (err) {
            handleError(err);
        }

        setSubmitting(false);
    };

    if (fetchingData) return <LoadingSpinner text="Fetching reader settings" />;

    return (
        <div className="email-settings">
            <div>
                <h4 className="settings__page-header">Reader Settings</h4>

                <div className="mt-3">
                    <ReaderSettingsToggle
                        text="Allow users to manage alerts in Reader"
                        checked={allowReaderManagement}
                        handleCheck={setAllowReaderManagement}
                    />
                </div>
            </div>

            <div>
                <h4 className="settings__page-header mt-5">Reader Login</h4>

                <div className="mt-3">
                    <ReaderSettingsToggle
                        text={'Use personal reader login'}
                        checked={allowPersonalReaderLogin}
                        handleCheck={setAllowPersonalReaderLogin}
                    />
                </div>

                {readerLoginDetails && allowPersonalReaderLogin && (
                    <div className="mt-3">
                        <div>Reader URL:</div>

                        <div className="mb-3">
                            <CopyToClipboard text={'https://news.ozmosys.com/company/reader'}>
                                <p style={{ cursor: 'copy' }}>https://news.ozmosys.com/company/reader</p>
                            </CopyToClipboard>
                        </div>

                        <span>Company Password:</span>

                        <div className="reader-password-info">
                            <span onClick={() => handleCopyToClipboard(readerLoginDetails.organizationPassword)} style={{ cursor: 'copy' }}>
                                {readerLoginDetails.organizationPassword}
                            </span>
                        </div>
                    </div>
                )}
            </div>
            <div className="mt-4">
                <SpinnerButton color="primary" title="Save Changes" onClick={updateOrganizationReaderSettings} submitting={submitting} />
            </div>
        </div>
    );
};
