import React, { useState } from 'react';
import { Table } from 'reactstrap';
import { PaginationWrapper } from 'common/PaginationWrapper';

export const TopStatsGrid = ({ items, type, title }) => {
    const [activePage, setActivePage] = useState(1);

    const handlePageClick = (pageNum) => {
        setActivePage(pageNum);
    };

    const getName = (item) => {
        switch (type) {
            case 'alerts':
                return item.name;
            case 'articles':
                return (
                    <a href={item.url} target="_blank" rel="noopener noreferrer">
                        {item.title}
                    </a>
                );
            case 'publishers':
                return item.domain;
            default:
                return item.name;
        }
    };

    return (
        <div className="grid-container">
            <Table striped bordered className="data-grid">
                <thead>
                    <tr>
                        <th>Top {title} (Last 30 Days)</th>
                        <th>Clicks</th>
                    </tr>
                </thead>
                <tbody>
                    {activePage === 1 &&
                        items.slice(0, 5).map((i) => (
                            <tr key={Math.max(Math.random() * 100)}>
                                <td>
                                    <div className="td-limit-height">{getName(i)}</div>
                                </td>
                                <td>{i.cnt}</td>
                            </tr>
                        ))}
                </tbody>
            </Table>

            {items.length > 5 && <PaginationWrapper totalPages={Math.floor(items / 5)} handlePageClick={handlePageClick} />}
        </div>
    );
};
