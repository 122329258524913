import React from 'react';
import { useNavigate } from 'react-router-dom';
import { InputGroup, Col, Row } from 'reactstrap';
import { checkExistsCompanyBased } from 'utils/api/alertsAPI';
import { CompanyAutocomplete } from './CompanyAutocomplete';

export const SearchForm = ({
    searchValue,
    searchValueError,
    resetForm,
    handleCreatePreview,
    errorObj,
    handleSearchValueChange,
    setSearchValueError,
}) => {
    const navigate = useNavigate();

    const onPressEnter = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleCreatePreview();
        }
    };

    const handleAutocompleteSelect = async (term) => {
        const { alertId } = await checkExistsCompanyBased(term);

        if (alertId) return navigate(`/account/alerts/${alertId}/company-profile`);

        navigate(`/account/alerts/${term}/company-preview`);
    };

    return (
        <Row>
            <Col xs={12} md={12}>
                <InputGroup className="rss-search-form mb-0">
                    <CompanyAutocomplete
                        handleSearchValueChange={handleSearchValueChange}
                        onPressEnter={onPressEnter}
                        value={searchValue}
                        searchValueHasError={searchValueError}
                        setSearchValueHasError={setSearchValueError}
                        onSubmit={handleCreatePreview}
                        onClear={resetForm}
                        handleAutocompleteSelect={handleAutocompleteSelect}
                        error={errorObj && errorObj.errors.hasOwnProperty('keyword')}
                        preSubmitError={searchValueError}
                    />
                </InputGroup>
                {errorObj && errorObj.errors.hasOwnProperty('url') && (
                    <div className="mt-1 error-text">{errorObj.errors['url'][0].message || errorObj.errors['url']}</div>
                )}
            </Col>
            <Col xs={12}>
                {searchValueError && (
                    <div className="rss-preview-error" style={{ marginBottom: '.5rem' }}>
                        {searchValueError}
                    </div>
                )}
            </Col>
        </Row>
    );
};
