import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment-timezone';
import { Chart as ChartJS, CategoryScale, ArcElement, LinearScale, BarElement, LineElement, PointElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar, getElementAtEvent } from 'react-chartjs-2';

ChartJS.register(CategoryScale, ArcElement, LinearScale, BarElement, PointElement, LineElement, Title, Tooltip, Legend);

export const ArticlesPerDayChart = ({
    articles,
    articlesPerDay,
    handleDateSelect,
    resetAllFilters,
    activeIndex,
    colorArray,
    resetColor,
    changeColor,
    showEmptyMessage,
}) => {
    const chartRef = useRef();
    const [dataArray, setDataArray] = useState([]);

    useEffect(() => {
        const dataObj = JSON.parse(JSON.stringify(articles));
        const dataArray = dataObj.map((article) => (article.created_at = article.created_at.slice(0, 10)));
        setDataArray(dataArray);
    }, [articles]);

    const convertDateString = (dateString) => {
        if (!dateString) return null;

        return moment(dateString).format('DD');
    };

    const getOccurrence = (array, value) => array.filter((v) => v === value).length;
    const getMonth = (index) => moment(articlesPerDay[index].day).format('MMM');
    const ticks = articlesPerDay && [...articlesPerDay.map((item) => convertDateString(item?.day))];

    const getValue = (index) => {
        const item = ticks[index];
        const width = window.innerWidth;

        if (index === 0) {
            return getMonth(index);
        } else if (width >= 380 && index % 2 === 0) {
            if (item[0] === '0' && +ticks[index - 2][0] > +item[0]) {
                return getMonth(index);
            }
            return item;
        } else if (width < 380 && index % 3 === 0) {
            if (item[0] === '0' && +ticks[index - 2][0] > +item[0]) {
                return getMonth(index);
            }
            return item;
        }
    };

    const buildLabelDate = (dateString) => {
        if (dateString == null) {
            return '';
        }

        return moment(dateString).format('MMM DD,YYYY');
    };

    const filterListByDate = (index) => handleDateSelect(articlesPerDay[index]);
    const labels = articlesPerDay.map((item) => item?.day);
    const linearRegression = articlesPerDay.map((item) => item.linear_regression);
    const articlesBarArray = labels.map((item) => getOccurrence(dataArray, item));

    const handleClick = (event) => {
        const chart = getElementAtEvent(chartRef.current, event);

        if (chartRef.current) {
            const index = chart[0]?.index;

            if (activeIndex === index) {
                resetColor();
                resetAllFilters();
            } else {
                changeColor(index);
                filterListByDate(index);
            }
        }
    };

    const data = {
        labels: labels.map((item) => buildLabelDate(item)),
        datasets: [
            {
                label: 'Trends',
                data: linearRegression,
                tension: 0.2,
                borderColor: 'rgba(37,102,166,1)',
                backgroundColor: 'rgba(37,102,166,1)',
                fill: false,
                type: 'line',
                datalabels: {
                    display: false,
                },
            },
            {
                label: 'Articles',
                backgroundColor: colorArray.map((item) => item.backgroundColor),
                borderColor: colorArray.map((item) => item.borderColor),
                borderWidth: 1,
                hoverBackgroundColor: colorArray.map((item) => item.hoverBackgroundColor),
                hoverBorderColor: colorArray.map((item) => item.hoverBorderColor),
                data: articlesBarArray,
            },
        ],
    };

    return (
        <div style={{ maxHeight: 300 }}>
            {showEmptyMessage && (
                <div className="dig-deeper-empty-chart">No alert content is available yet. Please check again later for updated content.</div>
            )}
            <Bar
                ref={chartRef}
                data={data}
                onClick={handleClick}
                height={300}
                options={{
                    legend: {
                        display: true,
                    },
                    maintainAspectRatio: false,
                    scales: {
                        x: {
                            ticks: {
                                autoSkip: false,
                                maxRotation: 50,
                                minRotation: 50,
                                stepSize: 1,
                                callback: (_, index) => getValue(index),
                                maxTicksLimit: 30,
                            },
                        },
                        y: {
                            ticks: {
                                beginAtZero: true,
                            },
                        },
                    },
                    plugins: {
                        datalabels: {
                            display: true,
                            color: 'white',
                            borderRadius: 6,
                            font: {
                                size: 12,
                            },
                            formatter: (value) => (value > 0 ? value : null),
                        },
                    },
                }}
            />
        </div>
    );
};
