import React from 'react';
import { Input } from 'reactstrap';
import { CheckboxInput } from 'common/Input';
import { daysOfWeek } from 'constants/schedule';

export const ScheduleSelector = ({
    selectedHour,
    selectedPeriod,
    selectedDays,
    handleHourSelect,
    handlePeriodSelect,
    position,
    errorMessage,
    handleDaySelect,
    errorObj,
    timezone,
}) => (
    <div className="schedule-selector">
        <strong>{position === 'first' ? 'First Notification' : 'Second Notification'}</strong>

        <div className="schedule-selector__days">
            <div>Day of week:</div>

            <div className="schedule-selector__day-select">
                {daysOfWeek.map((day) => (
                    <CheckboxInput
                        key={day.value}
                        name={day.name}
                        text={day.name}
                        value={day.value}
                        checked={selectedDays.includes(day.value)}
                        onChange={(e) => handleDaySelect(position, Number(e.target.value))}
                    />
                ))}
            </div>
        </div>

        {errorObj && !selectedDays.length && <div className="mt-1 error-text">At least one day must be selected</div>}

        <div className="schedule-selector__time">
            <div>Select specific time:</div>

            <div className="schedule-selector__time-inputs">
                <div className="form-group">
                    <Input onChange={(e) => handleHourSelect(e.target.value)} value={selectedHour} type="select" name="selectedHour">
                        {[...Array(12).keys()].map((hour) => (
                            <option key={hour} value={hour + 1 + ':00'}>
                                {hour + 1}
                            </option>
                        ))}
                    </Input>
                </div>

                <div className="form-group">
                    <Input
                        onChange={(e) => handlePeriodSelect(e.target.value)}
                        value={selectedPeriod.toUpperCase()}
                        type="select"
                        name="selectedFirstPeriod">
                        <option value="AM">AM</option>
                        <option value="PM">PM</option>
                    </Input>
                </div>
            </div>

            <div className="schedule-selector__timezone-label">
                <i className="fa fa-globe" /> {timezone}
            </div>
        </div>

        <div className="error-text schedule__error">{errorMessage}</div>
    </div>
);
