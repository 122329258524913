import React from 'react';
import { convertDateString, truncateWithoutWordBreak } from 'utils/helpers';
import './style.scss';

export const AlertPreviewItem = ({ item, withHour }) => (
    <div className="alert-preview-item d-flex">
        <div className="alert-preview-item__body">
            <a target="_blank" rel="noopener noreferrer" href={item.url} className="alert-preview-item__title">
                {item.title}
            </a>
            <div className="alert-preview-item__info">
                <span className="alert-preview-item__source" dangerouslySetInnerHTML={{ __html: item.resource }} />
                {' · '}
                <span className="alert-preview-item__date">{convertDateString(item.created_at, withHour)}</span>
            </div>
            <div className="alert-preview-item__abstract" dangerouslySetInnerHTML={{ __html: truncateWithoutWordBreak(item.abstract, 240) }} />
        </div>
    </div>
);
