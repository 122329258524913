import { axios } from 'lib/axios';
import { getCsv } from './csvAPI';

export const getStatistics = ({ limit, days, isNewsletter = false }) => {
    const params = isNewsletter
        ? 'types[]=newsletter_top_alerts&types[]=newsletter_top_publishers&types[]=newsletter_top_articles&types[]=newsletter_clicks_per_day'
        : 'types[]=top_alerts&types[]=top_publishers&types[]=top_articles&types[]=clicks_per_day';

    return axios.get(`/api/v3/statistics/?limit=${limit}&days=${days}&${params}`);
};

export const getComparativeSetStatisticsById = (id) => {
    return axios.get(`/api/v3/comparative_set/${id}/statistics`);
};

export const getComparativeSets = (help, count, pageNumber, itemsPerPage, sortProperty, sortDirection, searchProperty, searchValue = '') => {
    const encodedSearchValue = encodeURIComponent(searchValue);
    let url = `/api/v3/comparative_set?help=${help}&count=${count}&page=${pageNumber}&itemsOnPage=${itemsPerPage}&sort[0][property]=${sortProperty}&sort[0][direction]=${sortDirection.toUpperCase()}`;

    if (encodedSearchValue.length > 0) {
        url = url + `&filter[0][property]=${searchProperty}&filter[0][operator]=contains&filter[0][value]=${encodedSearchValue}`;
    }

    return axios.get(url);
};

export const getAlertsForCompSetList = (searchValue = '', sortProperty, sortDirection) => {
    let url;

    searchValue.length > 0
        ? (url = `/api/v3/alert/?count=false&page=1&itemsOnPage=2000&sort[0][property]=${sortProperty}&sort[0][direction]=${sortDirection}&filter[0][property]=alert.name&filter[0][operator]=contains&filter[0][value]=${searchValue}`)
        : (url = `/api/v3/alert/?count=false&page=1&itemsOnPage=2000&sort[0][property]=${sortProperty}&sort[0][direction]=${sortDirection}`);

    return axios.get(url);
};

export const addComparativeSet = (params) => {
    return axios.post('/api/v3/comparative_set', params);
};

export const getComparativeSet = (id) => {
    return axios.get(`/api/v3/comparative_set/${id}`);
};

export const updateComparativeSet = (id, params) => {
    return axios.patch(`/api/v3/comparative_set/${id}`, params);
};

export const deleteComparativeSet = (id) => {
    return axios.delete(`/api/v3/comparative_set/${id}`);
};

export const getUserUsageEmailsPerPeriod = (fromDate, toDate, sortProperty, sortDirection, csv = false) => {
    const url = `/api/v3/statistics/user_usage_emails_per_period?fromDate=${fromDate}&toDate=${toDate.trim()}&sort_property=${sortProperty}&sort_direction=${sortDirection.toLowerCase()}&csv=${csv}`;

    if (csv) {
        return getCsv(url, `user_usages_emails_${fromDate}_${toDate}`);
    } else {
        return axios.get(url);
    }
};

export const getUserUsageNewslettersPerPeriod = (fromDate, toDate, sortProperty, sortDirection, csv = false) => {
    const url = `/api/v3/statistics/user_usage_newsletters_per_period?fromDate=${fromDate}&toDate=${toDate}&sort_property=${sortProperty}&sort_direction=${sortDirection.toLowerCase()}&csv=${csv}`;

    if (csv) {
        return getCsv(url, `user_usages_newsletters_${fromDate}_${toDate}`);
    } else {
        return axios.get(url);
    }
};

export const getMailingObjectStatsPerPeriod = (fromDate, toDate, sortProperty, sortDirection, csv = false) => {
    const url = `/api/v3/statistics/mailing_object_stats_per_period?fromDate=${fromDate}&toDate=${toDate}&sort_property=${sortProperty}&sort_direction=${sortDirection.toLowerCase()}&csv=${csv}`;

    if (csv) {
        return getCsv(url, `alerts_newsletters_used_${fromDate}_${toDate}`);
    } else {
        return axios.get(url);
    }
};

export const getBrokenSources = (fromDate, toDate, sortProperty, sortDirection, csv = false) => {
    const url = `/api/v3/statistics/broken_sources?fromDate=${fromDate}&toDate=${toDate}&sort_property=${sortProperty}&sort_direction=${sortDirection.toLowerCase()}&csv=${csv}`;

    if (csv) {
        return getCsv(url, `broken_sources_${fromDate}_${toDate}`);
    } else {
        return axios.get(url);
    }
};

export const getPendingSources = (fromDate, toDate, sortProperty, sortDirection, csv = false) => {
    const url = `/api/v3/statistics/pending_sources?fromDate=${fromDate}&toDate=${toDate}&sort_property=${sortProperty}&sort_direction=${sortDirection.toLowerCase()}&csv=${csv}`;

    if (csv) {
        return getCsv(url, `pending_sources__${fromDate}_${toDate}`);
    } else {
        return axios.get(url);
    }
};
