import React, { useContext, useEffect, useState } from 'react';
import { Table } from 'reactstrap';
import moment from 'moment-timezone';
import { AuthContext } from 'contexts/AuthContext';
import { LoadingSpinner } from 'common/LoadingSpinner';
import { DayPickerInput } from 'common/DayPickerInput';
import { getMailingObjectStatsPerPeriod } from 'utils/api/statisticsAPI';
import { handleCaret } from 'utils/helpers';
import { CsvButton } from '../CsvButton';
import { useQuery } from 'react-query';

export const MailingObjectReport = () => {
    const { handleError } = useContext(AuthContext);
    const [fetchingCsv, setFetchingCsv] = useState(false);
    const [isOpenFromDate, setIsOpenFromDate] = useState(false);
    const [isOpenToDate, setIsOpenToDate] = useState(false);
    const [fromDate, setFromDate] = useState(moment().subtract(1, 'month').format('YYYY-MM-DD'));
    const [toDate, setToDate] = useState(moment().format('YYYY-MM-DD'));
    const [activeSortProperty, setActiveSortProperty] = useState('name');
    const [activeSortDirection, setActiveSortDirection] = useState('asc');

    const { data, isLoading } = useQuery(
        ['getMailingObjectStatsPerPeriod', fromDate, toDate, activeSortProperty, activeSortDirection],
        () => getMailingObjectStatsPerPeriod(fromDate, toDate, activeSortProperty, activeSortDirection, false),
        {
            onError: (err) => handleError(err),
        }
    );

    const downloadFilteredCsv = async () => {
        setFetchingCsv(true);

        try {
            await getMailingObjectStatsPerPeriod(fromDate, toDate, activeSortProperty, activeSortDirection, true);
        } catch (err) {
            handleError(err);
        }

        setFetchingCsv(false);
    };

    const handleSortableHeaderClick = (value) => {
        if (value === activeSortProperty) {
            activeSortDirection === 'asc' ? setActiveSortDirection('desc') : setActiveSortDirection('asc');
        } else {
            setActiveSortProperty(value);
            setActiveSortDirection('asc');
        }
    };

    return (
        <div className="list">
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <div>
                    <div className="list__utility-row">
                        <div className="list__search-container">
                            <DayPickerInput
                                name="mailingObjectReport-from"
                                isOpen={isOpenFromDate}
                                setIsOpen={setIsOpenFromDate}
                                specificDate={fromDate}
                                setSpecificDate={setFromDate}
                                disabled={{ before: new Date(moment().subtract(12, 'months').format('YYYY-MM-DD')), after: new Date(toDate) }}
                            />

                            <DayPickerInput
                                name="mailingObjectReport-to"
                                isOpen={isOpenToDate}
                                setIsOpen={setIsOpenToDate}
                                specificDate={toDate}
                                setSpecificDate={setToDate}
                                disabled={{ before: new Date(fromDate), after: new Date() }}
                            />

                            <CsvButton downloadFilteredCsv={downloadFilteredCsv} downloading={fetchingCsv} />
                        </div>
                    </div>

                    {!data?.length ? (
                        <div>No results found</div>
                    ) : (
                        <Table className="responsive-table alert-list-table">
                            <tbody>
                                <tr className="responsive-table header-row">
                                    <th className="responsive-table__sortable-th" onClick={() => handleSortableHeaderClick('type')}>
                                        Type {handleCaret(activeSortProperty, activeSortDirection, 'type')}
                                    </th>

                                    <th className="responsive-table__sortable-th" onClick={() => handleSortableHeaderClick('name')}>
                                        Title {handleCaret(activeSortProperty, activeSortDirection, 'name')}
                                    </th>

                                    <th>Notes</th>
                                    <th>Paywall</th>

                                    <th className="responsive-table__sortable-th" onClick={() => handleSortableHeaderClick('countUsers')}>
                                        # Assigned Users {handleCaret(activeSortProperty, activeSortDirection, 'countUsers')}
                                    </th>

                                    <th className="responsive-table__sortable-th" onClick={() => handleSortableHeaderClick('numberOpenedEmails')}>
                                        # Clicks {handleCaret(activeSortProperty, activeSortDirection, 'numberOpenedEmails')}
                                    </th>

                                    <th className="responsive-table__sortable-th" onClick={() => handleSortableHeaderClick('rateOpenedEmails')}>
                                        % Usage (# of clicks/sent) {handleCaret(activeSortProperty, activeSortDirection, 'rateOpenedEmails')}
                                    </th>

                                    <th className="responsive-table__sortable-th" onClick={() => handleSortableHeaderClick('connectionType')}>
                                        Connection Type {handleCaret(activeSortProperty, activeSortDirection, 'connectionType')}
                                    </th>

                                    <th>Labels</th>
                                </tr>

                                {data?.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.type}</td>
                                        <td>{item.name}</td>
                                        <td dangerouslySetInnerHTML={{ __html: item.comment }} />
                                        <td>{item.paywall ? 'Yes' : 'No'}</td>
                                        <td>{item.countUsers}</td>
                                        <td>{item.numberOpenedEmails}</td>
                                        <td>{item.rateUsages}</td>
                                        <td>{item.connectionType}</td>
                                        <td style={{ whiteSpace: 'pre-line' }}>{item.labels.join('\n')}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    )}
                </div>
            )}
        </div>
    );
};
