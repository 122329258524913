import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { CreateEditGroupModal } from './Modals/CreateEditGroupModal';

const EmptyList = ({ refreshAlertGroups, handleError, addFlashMessage }) => {
    const [createModalOpen, setCreateModalOpen] = useState(false);

    const toggleCreateModal = () => setCreateModalOpen(!createModalOpen);

    return (
        <>
            <p>Create your first alert group!</p>
            <Button color="primary" onClick={toggleCreateModal}>
                Create Alert Group
            </Button>
            {createModalOpen && (
                <CreateEditGroupModal
                    isOpen={createModalOpen}
                    toggle={toggleCreateModal}
                    addFlashMessage={addFlashMessage}
                    refreshAlertGroups={refreshAlertGroups}
                    handleError={handleError}
                />
            )}
        </>
    );
};

export default EmptyList;
