import React, { useEffect, useState, useContext } from 'react';
import { Label } from 'reactstrap';
import moment from 'moment-timezone';
import { AuthContext } from 'contexts/AuthContext';
import { SpinnerButton } from 'common/SpinnerButton';
import { RadioInput } from 'common/Input';
import { LoadingSpinner } from 'common/LoadingSpinner';
import { editOrganizationSchedule } from 'utils/api/profileAPI';
import { timezones } from 'constants/timezones';
import { ScheduleSelector } from './ScheduleSelector';
import './style.scss';

export const AlertsSchedule = ({ profile, addFlashMessage, refreshProfile }) => {
    const { handleError } = useContext(AuthContext);
    const [frequency, setFrequency] = useState('once');
    const [firstAlertDays, setFirstAlertDays] = useState([]);
    const [firstAlertHour, setFirstAlertHour] = useState('9:00');
    const [firstAlertPeriod, setFirstAlertPeriod] = useState('AM');
    const [secondAlertDays, setSecondAlertDays] = useState([]);
    const [secondAlertHour, setSecondAlertHour] = useState('5:00');
    const [secondAlertPeriod, setSecondAlertPeriod] = useState('PM');
    const [fetchingScheduleData, setFetchingScheduleData] = useState(true);
    const [submitting, setSubmitting] = useState(false);
    const [errors, setErrors] = useState(null);
    const [timezone, setTimezone] = useState(null);

    useEffect(() => {
        if (profile.organization) {
            const dailySchedule = profile.organization?.schedule?.daily;
            const first = dailySchedule?.first;
            const second = dailySchedule?.second;
            second ? setFrequency('twice') : setFrequency('once');

            if (second) {
                setSecondAlertDays(second?.days_of_week);
                destructureTime(second?.run_time, 'second');
            }

            first && setFirstAlertDays(first?.days_of_week);
            first && destructureTime(first?.run_time, 'first');

            const foundTimezone = timezones.find((obj) => obj.key === profile.organization.timezone);
            setTimezone(foundTimezone.name);
            setFetchingScheduleData(false);
        }
    }, [profile.organization]);

    const destructureTime = (time, schedule) => {
        const hour = moment(time, ['hh:mm A']).format('h:mm');
        const period = moment(time, ['hh:mm A']).format('A');

        if (schedule === 'first') {
            setFirstAlertPeriod(period);
            setFirstAlertHour(hour);
        } else {
            setSecondAlertPeriod(period);
            setSecondAlertHour(hour);
        }
    };

    const handleDaySelect = (position, day) => {
        const newDayArray = position === 'first' ? [...firstAlertDays] : [...secondAlertDays];

        if (newDayArray.indexOf(day) > -1) {
            newDayArray.splice(newDayArray.indexOf(day), 1);
        } else {
            newDayArray.push(day);
        }

        position === 'first' ? setFirstAlertDays(newDayArray) : setSecondAlertDays(newDayArray);
    };

    const handleSubmit = async () => {
        if (frequency === 'twice' && firstAlertHour === secondAlertHour && firstAlertPeriod === secondAlertPeriod) {
            addFlashMessage('danger', 'Alert schedule times must be unique');
            return;
        }
        setSubmitting(true);

        try {
            setErrors(null);

            const firstParams = {
                run_time: moment(firstAlertHour + firstAlertPeriod, ['h:mmA']).format('HH:mm'),
                days_of_week: firstAlertDays,
            };
            const secondParams =
                frequency === 'twice'
                    ? {
                          run_time: moment(secondAlertHour + secondAlertPeriod, ['hh:mm A']).format('HH:mm'),
                          days_of_week: secondAlertDays,
                      }
                    : null;

            await editOrganizationSchedule({ schedule: { daily: { first: firstParams, ...(secondParams ? { second: secondParams } : null) } } });
            await refreshProfile();
            addFlashMessage('success', 'Schedule successfully updated');
        } catch (err) {
            handleError(err, setErrors, addFlashMessage);
        }

        setSubmitting(false);
    };

    return (
        <>
            {fetchingScheduleData ? (
                <div className="mt-4">
                    <LoadingSpinner />
                </div>
            ) : (
                <div className="alerts-schedule">
                    <h4 className="settings__page-header">Schedule</h4>
                    <div className="mt-3">
                        <div className="alerts-schedule__frequency">
                            <Label>Daily Frequency</Label>

                            <RadioInput
                                name="once"
                                onChange={(e) => setFrequency(e.target.name)}
                                checked={frequency === 'once'}
                                text="Once"
                                isInline
                            />

                            <RadioInput
                                name="twice"
                                onChange={(e) => setFrequency(e.target.name)}
                                checked={frequency === 'twice'}
                                text="Twice"
                                isInline
                            />
                        </div>

                        <ScheduleSelector
                            position="first"
                            selectedDays={firstAlertDays}
                            selectedHour={firstAlertHour}
                            handleHourSelect={setFirstAlertHour}
                            selectedPeriod={firstAlertPeriod}
                            handlePeriodSelect={setFirstAlertPeriod}
                            handleDaySelect={handleDaySelect}
                            errorObj={errors}
                            timezone={timezone}
                        />

                        {frequency === 'twice' && (
                            <ScheduleSelector
                                position="second"
                                selectedDays={secondAlertDays}
                                selectedHour={secondAlertHour}
                                handleHourSelect={setSecondAlertHour}
                                selectedPeriod={secondAlertPeriod}
                                handlePeriodSelect={setSecondAlertPeriod}
                                handleDaySelect={handleDaySelect}
                                errorObj={errors}
                                timezone={timezone}
                            />
                        )}
                    </div>

                    <div className="mt-6 mb-4 d-flex">
                        <SpinnerButton color="primary" submitting={submitting} onClick={handleSubmit} title={'Save Changes'} className="px-4" />
                    </div>
                </div>
            )}
        </>
    );
};
