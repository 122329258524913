import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { UncontrolledTooltip, Badge, Button, Spinner } from 'reactstrap';
import { LoadingSpinnerSmall } from 'common/LoadingSpinner/LoadingSpinnerSmall';
import { CATEGORY_TYPE, SOURCE_TYPE } from 'constants/alerts';
import { convertDateString } from 'utils/helpers';
import { suspendAlert } from 'utils/api/alertsAPI';
import { handleCopyToClipboard } from 'utils/helpers';
import { AlertCommentForm } from './AlertCommentForm';
import { LabelsBlock } from './Labels/LabelsBlock';
import RSSLogo from 'images/rss-icon.png';
import RSSLexisNexisLogo from 'images/rssnllogo3.png';
import BlueLogo from 'images/ozmosy-blue-logo.png';
import OpenEnvelope from 'images/open-envelope.svg';
import GroupLogo from 'images/group-logo-cropped3.png';

export const AlertListItem = (props) => {
    const { alert, addFlashMessage, refreshAlerts, toggleDeleteModal, allLabels, fetchingLabels, refreshLabels, isSingleUser, type } = props;
    const navigate = useNavigate();

    const [suspended, setSuspended] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [tooltipCopied, setTooltipCopied] = useState(false);

    const isDisable = alert.id === null;
    const isCategorySourceHasChildren = type === CATEGORY_TYPE && alert?.source?.countOfChildren > 0;

    useEffect(() => {
        setSuspended(alert.suspended);
        setUpdating(false);
    }, [alert.suspended]);

    const goToDigDeeper = (id) => {
        if (alert.source.type === 'company_based') {
            navigate(`/account/alerts/${id}/company-profile`);
            return;
        }
        navigate(`/account/alerts/dig-deeper/${id}`);
    };

    const goToAssignment = (id) => {
        navigate(`/account/alerts/assign/${id}`);
    };

    const goToEdit = (id) => {
        if (type === SOURCE_TYPE) {
            navigate(`/account/sources/edit/${id}`);
        } else {
            navigate(`/account/searches/edit/${id}`);
        }
    };

    const toggleSuspension = async (id) => {
        if (!updating) {
            try {
                setUpdating(true);

                await suspendAlert(id, { suspend: !alert.suspended });
                refreshAlerts(null, true);
            } catch (err) {
                setUpdating(false);
                addFlashMessage('danger', 'Unable to change alert status at this time');
            }
        }
    };

    const setTooltipText = () => {
        setTooltipCopied(true);
    };

    const resetTooltipText = () => {
        setTooltipCopied(false);
    };

    const tooltipText = tooltipCopied ? 'Copied to Clipboard' : 'Copy to Clipboard';

    const handleGoogleKeywordValue = (keyword) => {
        if (keyword.slice(0, 11) === 'allintitle:') {
            return keyword.slice(11, keyword.length);
        } else if (keyword.slice(0, 10) === 'allintext:') {
            return keyword.slice(10, keyword.length);
        } else {
            return keyword;
        }
    };

    return (
        <tr className="alert-list-item">
            <td data-label="Name / Source Preview" style={{ midWidth: '120px' }}>
                {alert.name}
                {alert.source.type === 'internal' && alert.source?.categories?.name && (
                    <>
                        <br />
                        <div className="alert-list-item__category">
                            <div className="category-type">{alert.source.categories.type}</div>
                            {alert.source.categories.name}
                        </div>
                    </>
                )}
                {isDisable ? (
                    <Spinner size="sm" style={{ marginLeft: '7px' }} />
                ) : (
                    <AlertCommentForm alert={alert} addFlashMessage={addFlashMessage} refreshAlerts={refreshAlerts} />
                )}
            </td>
            <td className="alert-list-td">
                <div
                    style={{ wordBreak: 'break-all', wordWrap: 'break-word', cursor: isDisable ? 'initial' : 'pointer' }}
                    onClick={() => handleCopyToClipboard(alert.source.url)}
                    onMouseEnter={() => !isDisable && resetTooltipText()}>
                    {alert.source.type === 'ozmosys_categorized' && (
                        <div onClick={setTooltipText} id={'google-label-tooltip-' + alert.id}>
                            <div style={{ marginLeft: 10 }}>
                                {alert.source.keyword ? (
                                    <div>
                                        <img
                                            style={{
                                                marginLeft: 10,
                                                maxHeight: '25px',
                                                position: 'relative',
                                                top: '-2px',
                                            }}
                                            alt={'internal-alert-icon'}
                                            src={BlueLogo}
                                        />
                                        <div style={{ marginLeft: 10 }}>{alert.source.keyword}</div>
                                    </div>
                                ) : (
                                    <img
                                        className="rss-logo"
                                        height="35px"
                                        style={{ marginLeft: 10 }}
                                        src={RSSLogo}
                                        alt="rss_logo"
                                        id={'rss-label-tooltip-' + alert.id}
                                        onClick={setTooltipText}
                                    />
                                )}
                            </div>
                            <Tooltip tooltipText={tooltipText} target={'google-label-tooltip-' + alert.id} />
                        </div>
                    )}
                    {alert.source.type === 'company_based' && (
                        <div onClick={setTooltipText} id={'google-label-tooltip-' + alert.id}>
                            <div style={{ marginLeft: 10 }}>{alert.source.symbol}</div>
                            <Tooltip tooltipText={tooltipText} target={'google-label-tooltip-' + alert.id} />
                        </div>
                    )}
                    {alert.source.type === 'google' && (
                        <div onClick={setTooltipText} id={'google-label-tooltip-' + alert.id}>
                            <i style={{ marginLeft: 10, fontSize: '1.2rem' }} className="google-icon fa fa-google " />
                            <Badge style={{ marginLeft: 5 }} color="info">
                                {alert.source.language}
                            </Badge>
                            <Badge>{alert.source.country}</Badge>

                            <div style={{ marginLeft: 10 }}>{!alert.source.keyword ? '' : handleGoogleKeywordValue(alert.source.keyword)}</div>
                            <Tooltip tooltipText={tooltipText} target={'google-label-tooltip-' + alert.id} />
                        </div>
                    )}
                    {alert.source.type === 'internal' && (
                        <div onClick={() => !isDisable && setTooltipText()} id={'internal-label-tooltip-' + alert.id}>
                            <img
                                style={{
                                    marginLeft: 10,
                                    maxHeight: '25px',
                                    position: 'relative',
                                    top: '-2px',
                                }}
                                alt={'internal-alert-icon'}
                                src={BlueLogo}
                            />
                            <div style={{ marginLeft: 10 }}>
                                {!alert.source.keyword
                                    ? ''
                                    : alert.source.keyword.slice(0, 8) === 'intitle:'
                                      ? alert.source.keyword.slice(8, alert.source.keyword.length)
                                      : alert.source.keyword}
                            </div>
                            {!isDisable && <Tooltip tooltipText={tooltipText} target={'internal-label-tooltip-' + alert.id} />}
                        </div>
                    )}
                    {alert.source.type === 'email_publication' && (
                        <div onClick={setTooltipText} id={'internal-label-tooltip-' + alert.id}>
                            <img style={{ marginLeft: '10px' }} src={OpenEnvelope} alt="Publication" />
                            <Tooltip tooltipText={tooltipText} target={'internal-label-tooltip-' + alert.id} />
                        </div>
                    )}
                    {(alert.source.type === 'rss' ||
                        alert.source.type === 'internal_group' ||
                        alert.source.type === 'courtlink' ||
                        alert.source.type === 'legi_scan_bill_internal') && (
                        <div style={{ cursor: 'pointer' }}>
                            {alert.source.type !== 'internal_group' ? (
                                alert.webToken && alert.webToken.length > 0 && (alert.webToken[0].type === 1 || alert.webToken[0].type === 2) ? (
                                    <img
                                        className="rss-logo"
                                        height="44px"
                                        style={{ marginLeft: 5 }}
                                        src={RSSLexisNexisLogo}
                                        alt="rss_logo"
                                        id={'rss-label-tooltip-' + alert.id}
                                        onClick={setTooltipText}
                                    />
                                ) : (
                                    <img
                                        className="rss-logo"
                                        height="35px"
                                        style={{ marginLeft: 10 }}
                                        src={RSSLogo}
                                        alt="rss_logo"
                                        id={'rss-label-tooltip-' + alert.id}
                                        onClick={setTooltipText}
                                    />
                                )
                            ) : (
                                <img
                                    className="rss-logo"
                                    height="44px"
                                    style={{ marginLeft: 6 }}
                                    src={GroupLogo}
                                    alt="rss_logo"
                                    id={'rss-label-tooltip-' + alert.id}
                                    onClick={setTooltipText}
                                />
                            )}
                            <Tooltip tooltipText={tooltipText} target={'rss-label-tooltip-' + alert.id} />
                        </div>
                    )}
                </div>
            </td>
            {!isSingleUser && (
                <td data-label="Labels" style={{ minWidth: '150px' }}>
                    <LabelsBlock
                        alert={alert}
                        labels={alert.labels}
                        allLabels={allLabels}
                        refreshLabels={refreshLabels}
                        fetchingLabels={fetchingLabels}
                        refreshAlerts={refreshAlerts}
                        disabled={isDisable}
                    />
                </td>
            )}
            <td data-label="Assigned To" style={{ minWidth: '90px' }} className="alert-list-td">
                {alert.countAssignedUsers}
            </td>
            <td data-label="Created At" className="alert-list-td">
                {convertDateString(alert.createdAt)}
            </td>
            <td data-label="Content Updated At" className="alert-list-td">
                {alert.source.contentUpdatedAt === null ? 'Pending updates' : convertDateString(alert.source.contentUpdatedAt)}
            </td>
            <td data-label="Assignment" className="alert-list-td">
                <Button
                    color="primary"
                    style={{ padding: '0.375rem 15px', cursor: isDisable ? 'not-allowed' : 'pointer' }}
                    disabled={isDisable}
                    onClick={() => !isDisable && goToAssignment(alert.id)}>
                    Assign
                </Button>
            </td>
            <td data-label="Dig Deeper" className="alert-list-td">
                <Button
                    color="light"
                    style={{ cursor: isDisable ? 'not-allowed' : 'pointer' }}
                    onClick={() => !isDisable && goToDigDeeper(alert.id)}
                    disabled={isDisable}>
                    <i className="fa fa-bar-chart fa-lg" style={{ width: '100%' }} />
                </Button>
            </td>
            <td data-label="Last 30 Days Clicks" className="alert-list-td">
                {alert.countClicks}
            </td>
            <td data-label="Actions" style={{ minWidth: '168px' }}>
                <div className="responsive-table__edit-block">
                    {alert.source.type !== 'company_based' && (
                        <Button
                            className="edit-delete-button"
                            color="light"
                            style={{ cursor: isDisable ? 'not-allowed' : 'pointer' }}
                            disabled={isDisable}
                            onClick={() => !isDisable && goToEdit(alert.id)}>
                            <i className="fa fa-edit fa-lg" />
                        </Button>
                    )}
                    <div style={{ display: suspended ? 'inline-block' : 'none' }}>
                        <Button
                            color="light"
                            style={{ cursor: isDisable ? 'not-allowed' : 'pointer' }}
                            disabled={isDisable}
                            onClick={() => !isDisable && toggleSuspension(alert.id)}
                            id={'resume-tooltip-' + alert.id}>
                            {updating ? <LoadingSpinnerSmall /> : <i className="fa fa-play fa-lg" />}
                        </Button>
                        <Tooltip tooltipText="Resume Alert" target={'resume-tooltip-' + alert.id} />
                    </div>

                    <div style={{ display: !suspended ? 'inline-block' : 'none' }}>
                        <Button
                            color="light"
                            style={{ cursor: isDisable ? 'not-allowed' : 'pointer' }}
                            disabled={isDisable}
                            onClick={() => !isDisable && toggleSuspension(alert.id, 'true')}
                            id={'pause-tooltip-' + alert.id}>
                            {updating ? (
                                <LoadingSpinnerSmall />
                            ) : alert.suspended ? (
                                <i className="fa fa-play fa-lg" />
                            ) : (
                                <i className="fa fa-pause fa-lg" />
                            )}
                        </Button>
                        <Tooltip tooltipText="Pause Alert" target={'pause-tooltip-' + alert.id} />
                    </div>
                    <Button
                        className="delete-button"
                        color="light"
                        style={{ cursor: isDisable || isCategorySourceHasChildren ? 'not-allowed' : 'pointer' }}
                        disabled={isDisable || isCategorySourceHasChildren}
                        onClick={() => !isDisable && !isCategorySourceHasChildren && toggleDeleteModal(alert)}
                        id={'delete-tooltip-' + alert.id}>
                        <i className="fa fa-trash fa-lg" />
                    </Button>
                    <Tooltip
                        tooltipText={
                            isCategorySourceHasChildren
                                ? 'There are sources associated with this category that needs to be removed first'
                                : 'Delete Alert'
                        }
                        target={'delete-tooltip-' + alert.id}
                    />
                </div>
            </td>
        </tr>
    );
};

const Tooltip = ({ tooltipText, target }) => (
    <UncontrolledTooltip placement="bottom" autohide={false} target={target} delay={{ show: 200, hide: 0 }}>
        {tooltipText}
    </UncontrolledTooltip>
);
