import React, { useState } from 'react';
import { Button, Modal, ModalFooter } from 'reactstrap';
import { SpinnerButton } from 'common/SpinnerButton';
import { ModalBodyWithClose } from 'common/ModalBodyWithClose';
import { deleteNewsletterSection } from 'utils/api/newslettersAPI';

export const DeleteSectionModal = ({ section, newsletterId, setActiveView, toggle, refreshNewsletter, isOpen, handleError }) => {
    const [deleting, setDeleting] = useState(false);

    const handleSubmit = async () => {
        setDeleting(true);

        try {
            await deleteNewsletterSection(newsletterId, section.id);
            await refreshNewsletter();
            setActiveView('sectionsList');
            toggle();
        } catch (err) {
            handleError(err);
        }

        setDeleting(false);
    };

    if (!section) return null;

    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalBodyWithClose toggle={toggle}>Are you sure you want to delete {section.name}?</ModalBodyWithClose>

            <ModalFooter>
                <Button color="secondary" onClick={toggle}>
                    Cancel
                </Button>

                <SpinnerButton type="modal" color="danger" title="Delete" submitting={deleting} onClick={handleSubmit} style={{ minWidth: '68px' }} />
            </ModalFooter>
        </Modal>
    );
};
