import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import { AuthContext } from 'contexts/AuthContext';
import { FlashMessageContext } from 'contexts/FlashMessageContext';
import { addAlertToSection, removeAlertFromSection } from 'utils/api/newslettersAPI';
import { SectionsList } from './Sections/SectionsList';
import { SectionView } from './Sections/SectionView';
import { AddArticles } from './Articles/AddArticles';
import { AddAlerts } from './Alerts/AddAlerts';
import './style.scss';

export const ManageContentMobile = ({ newsletterData, refreshNewsletter }) => {
    const navigate = useNavigate();
    const { state, handleError } = useContext(AuthContext);
    const { addFlashMessage } = useContext(FlashMessageContext);

    const [activeView, setActiveView] = useState('sectionsList');
    const [activeSection, setActiveSection] = useState(null);
    const [newsletterForDom, setNewsletterForDom] = useState(null);
    const [newsletterType, setNewsletterType] = useState('');

    useEffect(() => {
        setNewsletterForDom(newsletterData);
        let foundSection;

        if (activeSection) {
            foundSection = newsletterData.sections.find((section) => section.id === activeSection.id);
            setActiveSection(foundSection);
        }

        setNewsletterType(newsletterData.type === 'curated' ? 'curated' : 'automatic');
    }, [newsletterData]);

    useEffect(() => {
        if (activeSection) {
            const newActiveSection = newsletterForDom.sections.find((section) => section.id === activeSection.id);
            setActiveSection(newActiveSection);
        }
    }, [newsletterForDom]);

    const handleBackButtonPress = () => {
        switch (activeView) {
            case 'sectionView':
                setActiveSection(null);
                setActiveView('sectionsList');
                break;
            case 'sectionsList':
                navigate('/account/newsletters');
                break;
            case 'addAlerts':
                setActiveView('sectionView');
                break;
            case 'addArticles':
                setActiveView('sectionView');
                break;
        }
    };

    const handleSectionSelect = (section) => setActiveSection(section);

    useEffect(() => {
        activeSection && activeView === 'sectionsList' && setActiveView('sectionView');
    }, [activeSection]);

    const handleViewDisplay = () => {
        switch (activeView) {
            case 'sectionsList':
                return (
                    <SectionsList newsletterData={newsletterData} refreshNewsletter={refreshNewsletter} handleSectionSelect={handleSectionSelect} />
                );
            case 'sectionView':
                if (activeSection) {
                    return (
                        <SectionView
                            newsletter={newsletterData}
                            refreshNewsletter={refreshNewsletter}
                            setActiveView={setActiveView}
                            section={activeSection}
                            newsletterType={newsletterType}
                            handleError={handleError}
                        />
                    );
                }
                break;
            case 'addAlerts':
                return (
                    <AddAlerts
                        section={activeSection}
                        newsletterData={newsletterForDom}
                        handleAddAlert={handleAddAlert}
                        handleRemoveAlert={handleRemoveAlert}
                    />
                );
            case 'addArticles':
                return (
                    <AddArticles
                        section={activeSection}
                        newsletterData={newsletterForDom}
                        setNewsletterForDom={setNewsletterForDom}
                        handleError={handleError}
                    />
                );
            default:
                return (
                    <SectionsList newsletterData={newsletterData} refreshNewsletter={refreshNewsletter} handleSectionSelect={handleSectionSelect} />
                );
        }
    };

    const handleAddAlert = async (sectionId, alertId) => {
        const { sections, unassignedAlerts } = newsletterForDom;
        const alertLimit = state.profile.organization?.limits?.limitNewsletterAlerts;
        let assignedAlertsCnt = 0;

        sections.map((section) => (assignedAlertsCnt += section.alerts.length));

        if (assignedAlertsCnt >= alertLimit) {
            addFlashMessage('danger', `Maximum alert limit for this newsletter (${alertLimit}) has been reached.`);
            return;
        }

        const nextColumn = sections.find((obj) => obj.id === sectionId);
        const nextColumnIndex = sections.indexOf(nextColumn);
        const newStateUnassignedAlerts = cloneDeep(unassignedAlerts);
        const selectedAlert = newStateUnassignedAlerts.find((alert) => alert.id === alertId);
        const nextColumnAlerts = Array.from(nextColumn.alerts);
        nextColumnAlerts.push(selectedAlert);
        const newNextColumn = { ...nextColumn, alerts: nextColumnAlerts };
        newStateUnassignedAlerts.splice(newStateUnassignedAlerts.indexOf(selectedAlert), 1);

        const newStateSections = cloneDeep(sections);
        newStateSections[nextColumnIndex] = newNextColumn;
        const newNewsletter = { ...newsletterForDom, sections: newStateSections, unassignedAlerts: newStateUnassignedAlerts };
        setNewsletterForDom(newNewsletter);

        await addAlertToSection(newsletterData.id, sectionId, { alerts: [alertId] });
    };

    const handleRemoveAlert = async (sectionId, alertId) => {
        const { sections, unassignedAlerts } = newsletterForDom;
        const selectedSection = sections.find((obj) => obj.id === sectionId);
        const selectedSectionIndex = sections.indexOf(selectedSection);
        const newStateUnassignedAlerts = cloneDeep(unassignedAlerts);
        const newStateSections = cloneDeep(sections);

        const selectedAlert = selectedSection.alerts.find((alert) => alert.id === alertId);
        const selectedSectionAlerts = Array.from(selectedSection.alerts);
        selectedSectionAlerts.splice(selectedSectionAlerts.indexOf(selectedAlert), 1);
        const newSelectedSection = { ...selectedSection, alerts: selectedSectionAlerts };
        newStateUnassignedAlerts.push(selectedAlert);
        newStateSections[selectedSectionIndex] = newSelectedSection;
        const newNewsletter = { ...newsletterForDom, sections: newStateSections, unassignedAlerts: newStateUnassignedAlerts };
        setNewsletterForDom(newNewsletter);

        await removeAlertFromSection(newsletterData.id, sectionId, alertId);
    };

    return (
        <div className="mobile-manage-content">
            <div className="mobile-manage-content__back-btn-container">
                <div className="mobile-manage-content__back-btn" onClick={handleBackButtonPress}>
                    <i className="fa fa-angle-left" style={{ fontWeight: 'bold' }} />
                    &nbsp; Back
                </div>
            </div>

            <div className="mobile-manage-content__header">{newsletterData.name}</div>

            <div className="mobile-manage-content__body">{handleViewDisplay()}</div>
        </div>
    );
};
