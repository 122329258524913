import React, { useState } from 'react';
import { Collapse, Button, CardBody, CardHeader, UncontrolledTooltip } from 'reactstrap';
import { Droppable } from 'react-beautiful-dnd';
import { Alert } from './Alert';

export const UnassignedSection = ({ section, removeAlertFromSection, removeAllAlertsFromSection, sectionDragInfo }) => {
    const [alertListOpen, setAlertListOpen] = useState(false);

    const toggleAlertListOpen = () => setAlertListOpen(!alertListOpen);

    return (
        <div className="mt-2">
            <Droppable droppableId={String(section.id)} type="alert">
                {(provided, snapshot) => (
                    <div
                        className={
                            'newsletter-section ' +
                            (snapshot.isDraggingOver
                                ? sectionDragInfo && sectionDragInfo.destinationId === sectionDragInfo.sourceId
                                    ? 'section-is-dragging-over-source'
                                    : 'section-is-dragging-over'
                                : '')
                        }
                        {...provided.droppableProps}
                        ref={provided.innerRef}>
                        <div className="section-collapse d-flex flex-column">
                            <CardHeader
                                className={
                                    'section-collapse-toggle newsletter-section__header ' +
                                    (snapshot.isDragging ? 'newsletter-section__header--dragging' : '')
                                }
                                color="primary"
                                onClick={toggleAlertListOpen}>
                                <div className="newsletter-section__block-1">
                                    <i className={'fa fa-angle-right ' + (alertListOpen ? 'rotate-90-down' : '')} />
                                    <div className="newsletter-section__title ml-2">
                                        <span>{section.name}</span>
                                    </div>
                                    &nbsp;
                                    <div className="newsletter-section__item-cnt newsletter-section__item-cnt--unassigned">
                                        {section.alerts.length === 1 ? section.alerts.length + ' Source' : section.alerts.length + ' Sources'}
                                        <span className={section.alerts.length > 0 ? 'error-text' : ''}>
                                            {' '}
                                            (Sources in this section will not be delivered)
                                        </span>
                                    </div>
                                </div>

                                <div className="newsletter-section__block-2">
                                    <Button
                                        color="secondary"
                                        id={'clear-alerts-' + section.id}
                                        className="newsletter-section__header-btn newsletter-section__header-btn--clear"
                                        onClick={(e) => removeAllAlertsFromSection(e, section.id)}
                                        disabled={section.alerts.length < 1}>
                                        Clear
                                    </Button>

                                    <UncontrolledTooltip placement="bottom" delay={{ show: 200, hide: 0 }} target={'clear-alerts-' + section.id}>
                                        Remove all alerts from this section
                                    </UncontrolledTooltip>
                                </div>
                            </CardHeader>

                            <Collapse isOpen={alertListOpen}>
                                <CardBody className="section-collapse-body p-0">
                                    <div className="section-body">
                                        <div className="section-list">
                                            {section.alerts.length === 0 && (
                                                <div className="empty-section-item">No Alerts have been added to this section yet</div>
                                            )}

                                            {section.alerts.length > 0 &&
                                                alertListOpen &&
                                                section.alerts
                                                    .sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0))
                                                    .map((alert, i) => (
                                                        <Alert
                                                            key={section.id + '-' + alert.id}
                                                            alert={alert}
                                                            index={i}
                                                            removeAlertFromSection={removeAlertFromSection}
                                                            sectionId={section.id}
                                                        />
                                                    ))}
                                            {provided.placeholder}
                                        </div>
                                    </div>
                                </CardBody>
                            </Collapse>
                        </div>
                    </div>
                )}
            </Droppable>
        </div>
    );
};
