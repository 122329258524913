import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';
import { useDevice } from 'hooks/useMediaQuery';
import { SpinnerButton } from 'common/SpinnerButton';
import { InputWithError } from 'common/InputWithError';

export const TitleForm = ({
    submitting,
    title,
    setTitleValue,
    handleSubmit,
    isEditForm,
    errors,
    withoutInfoMessage,
    isEditCategory,
    isDisableTitle,
    additionalTitle,
    disabled,
    isAdvancedSearchShown,
    children,
}) => {
    const { isMobile } = useDevice();
    const [showInfo, setShowInfo] = useState(true);

    const onPressEnter = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleSubmit();
        }
    };

    const hideInfo = () => setShowInfo(false);

    return (
        <div className="mt-3 alert-title-form-container">
            <div className={isEditCategory ? 'alert-title-form flex' : 'alert-title-form'}>
                <div className="alert-title-form__inner">
                    {isEditCategory ? (
                        <p className="font-lg" style={{ padding: '7px 10px', backgroundColor: '#d5d5d5', borderRadius: '3px' }}>
                            {additionalTitle}
                        </p>
                    ) : (
                        <InputWithError
                            className="W-100"
                            type="text"
                            name="name"
                            autoComplete="off"
                            onChange={setTitleValue}
                            onKeyUp={onPressEnter}
                            value={title}
                            disabled={isEditCategory || isDisableTitle}
                            placeholder="Search Name"
                            errorObj={errors}
                            marginBottomClass="mb-3"
                        />
                    )}
                </div>

                {!isMobile && showInfo && !withoutInfoMessage ? (
                    <Col xs={4}>
                        <div style={{ background: '#f5f4c4', padding: '10px', marginTop: '-48px' }}>
                            <Row>
                                <Col xs={1} md={1}>
                                    <i className="fa fa-info"></i>
                                </Col>
                                <Col xs={10} md={10}>
                                    <div onClick={hideInfo} className="search-info-close-btn">
                                        <span aria-hidden="true">×</span>
                                    </div>
                                    Selecting filter(s) will refresh the results and may change the availability of other options.
                                </Col>
                            </Row>
                        </div>
                    </Col>
                ) : null}
            </div>

            {isAdvancedSearchShown ? null : children}

            <SpinnerButton
                color="primary"
                type="submit"
                className={isAdvancedSearchShown ? 'px-4 mb-2' : 'px-4 mb-2 mt-3'}
                onClick={handleSubmit}
                submitting={submitting}
                title={isEditForm ? 'Save' : 'Create'}
                disabled={disabled}
            />
        </div>
    );
};
