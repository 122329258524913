import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { Chart as ChartJS, CategoryScale, Filler, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { useDevice } from 'hooks/useMediaQuery';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(CategoryScale, ChartDataLabels, Filler, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export const NewsPerDayChart = ({ articles, articlesPerDay, showEmptyMessage }) => {
    const { isMobile } = useDevice();
    const [dataArray, setDataArray] = useState([]);

    useEffect(() => {
        const dataObj = JSON.parse(JSON.stringify(articles));
        const dataArray = dataObj.map((article) => (article.created_at = article.created_at.slice(0, 10)));
        setDataArray(dataArray);
    }, [articles]);

    const convertDateString = (dateString) => {
        if (!dateString) return;

        return moment(dateString).format('DD');
    };

    const buildLabelDate = (dateString) => {
        if (dateString == null) return '';

        const dateObj = new Date((dateString + ' 00:00:00').replace(' ', 'T'));
        const momentObj = moment(dateObj);

        return momentObj.format('MMM DD,YYYY');
    };

    const getOccurrence = (array, value) => array.filter((v) => v === value).length;
    const labels = articlesPerDay.map((item) => item.day);
    const articlesBarArray = labels.map((item) => getOccurrence(dataArray, item));
    const ticks = articlesPerDay && [...articlesPerDay.map((item) => convertDateString(item.day))];

    const getMonth = (index) => {
        const dateObj = new Date((articlesPerDay[index].day + ' 00:00:00').replace(' ', 'T'));
        const momentObj = moment(dateObj);

        return momentObj.format('MMM');
    };

    const getValue = (index) => {
        const item = ticks[index];
        const width = window.innerWidth;

        if (index === 0) {
            return getMonth(index);
        }

        if (width > 360 && width < 500) {
            if (index % 4 === 0 || index === 29) {
                if (item[0] === '0' && +ticks[index - 4][0] > +item[0]) {
                    return getMonth(index);
                }
                return item;
            } else {
                return;
            }
        }

        if (width >= 360 && index % 2 === 0) {
            if (item[0] === '0' && +ticks[index - 2][0] > +item[0]) {
                return getMonth(index);
            }
            return item;
        }

        if (width < 360) {
            if (item[0] === '0' && +ticks[index - 2][0] > +item[0]) {
                return getMonth(index);
            }
            return item;
        }
    };

    return (
        <div style={{ maxHeight: 300 }}>
            {showEmptyMessage && (
                <div className="dig-deeper-empty-chart">No alert content is available yet. Please check again later for updated content.</div>
            )}

            <Line
                data={{
                    labels: labels.map((item) => buildLabelDate(item)),
                    datasets: [
                        {
                            label: 'Volume',
                            borderColor: 'rgba(151, 187, 205, 1)',
                            backgroundColor: 'rgba(151, 187, 205, 0.2)',
                            tension: 0.2,
                            data: articlesBarArray,
                        },
                    ],
                }}
                height={isMobile ? 500 : 300}
                options={{
                    legend: {
                        display: true,
                        position: 'top',
                        paddingLeft: 10,
                        labels: {
                            boxWidth: isMobile ? 20 : 40,
                            fontSize: isMobile ? 15 : 12,
                        },
                    },
                    maintainAspectRatio: false,
                    scales: {
                        x: {
                            ticks: {
                                autoSkip: false,
                                maxRotation: 50,
                                minRotation: 50,
                                maxTicksLimit: 30,
                                callback: (_, index) => getValue(index),
                            },
                        },
                    },
                    plugins: {
                        datalabels: {
                            display: false,
                        },
                    },
                }}
            />
        </div>
    );
};
