import React, { useState } from 'react';
import { Row, Col, Card, CardHeader, CardBody } from 'reactstrap';
import { TabBar } from 'common/TabBar';
import { UserUsageEmailsReport } from './UserUsageEmailsReport';
import { UserUsageNewslettersReport } from './UserUsagesNewslettersReport';
import { MailingObjectReport } from './MailingObjectReport';
import { BrokenSourcesReport } from './BrokenSourcesReport';
import { PendingSourcesReport } from './PendingSourcesReport';
import './style.scss';

const reportsTabsData = [
    { data: 'userUsageEmailsReport', name: 'User usages report' },
    { data: 'userUsageNewslettersReport', name: 'User usages newsletters report' },
    { data: 'mailingObjectReport', name: 'Content usage report' },
    { data: 'brokenSourcesReport', name: 'Broken Sources Report' },
    { data: 'pendingSourcesReport', name: 'Pending Sources Report' },
];

export const Reports = () => {
    const [activeView, setActiveView] = useState('userUsageEmailsReport');

    const handleActiveView = () => {
        switch (activeView) {
            case 'userUsageEmailsReport':
                return <UserUsageEmailsReport />;
            case 'userUsageNewslettersReport':
                return <UserUsageNewslettersReport />;
            case 'mailingObjectReport':
                return <MailingObjectReport />;
            case 'brokenSourcesReport':
                return <BrokenSourcesReport />;
            case 'pendingSourcesReport':
                return <PendingSourcesReport />;
            default:
                return <UserUsageEmailsReport />;
        }
    };

    return (
        <div className="view view--mobile-full alerts ">
            <div className="animated fadeIn">
                <Row>
                    <Col xs="12">
                        <Card>
                            <CardHeader>
                                <div className="flex-space-between">
                                    <h1>Reports</h1>
                                </div>
                            </CardHeader>

                            <CardBody>
                                <TabBar activeItem={activeView} setActive={setActiveView} items={reportsTabsData} />
                                {handleActiveView()}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    );
};
