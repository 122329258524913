import React, { useState, useEffect } from 'react';
import { FormGroup, Button } from 'reactstrap';
import Dropzone from 'react-dropzone';
import { SpinnerButton } from 'common/SpinnerButton';
import { LoadingSpinner } from 'common/LoadingSpinner';
import { RadioInput } from 'common/Input';
import { editOrganizationLogoPosition, setCustomLogo } from 'utils/api/profileAPI';
import { CropModal } from './CropModal';
import './style.scss';

export const Appearance = ({ isSingleUser, profile, refreshProfile, addFlashMessage, handleError }) => {
    const [fetchingImage, setFetchingImage] = useState(true);
    const [brandLogoUrl, setBrandLogoUrl] = useState('');
    const [brandLogoPosition, setBrandLogoPosition] = useState('');
    const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
    const [cropModalOpen, setCropModalOpen] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    useEffect(() => {
        const logoPosition = profile.organization?.settings?.brandLogoPosition;

        if (logoPosition) {
            setBrandLogoPosition(logoPosition);

            const origin =
                window.location.origin === 'https://alerts2-tst.dataprodev.com'
                    ? `https://alerts2-api-tst.dataprodev.com/img/`
                    : `https://api.ozmosys.com/img/`;
            const brandLogo = profile.organization?.settings?.brandLogo;

            if (brandLogo) {
                setBrandLogoUrl(`${origin}${brandLogo}`);
            } else {
                setBrandLogoUrl(`${origin}/logo.png`);
            }
            setFetchingImage(false);
        }
    }, [profile]);

    const handleDropAccept = (fileArray) => {
        setImagePreviewUrl(URL.createObjectURL(fileArray[0]));
        toggleCropModal();
    };

    const handleDropReject = (item) => {
        if (!(item[0].file.type == 'image/jpeg' || item[0].file.type == 'image/png')) {
            addFlashMessage('danger', 'Invalid file type. File must be in .jpg or .png format.');
        } else if (item[0].file.size > 2000000) {
            addFlashMessage('danger', 'File exceeds the maximum upload size (2MB).');
        }
    };

    const handleLogoUploadSubmit = async (file, modalCallback) => {
        setSubmitting(true);

        try {
            await setCustomLogo({ source: file });
            refreshProfile();
            addFlashMessage('success', 'Image successfully uploaded');
            modalCallback && modalCallback();
        } catch (err) {
            if (err.errors && err.errors.hasOwnProperty('file')) {
                addFlashMessage('danger', err.errors.file[0].message);
            } else {
                addFlashMessage('danger', 'Unable to complete your request at this time');
            }
        }

        setSubmitting(false);
    };

    const handlePositionSubmit = async () => {
        setSubmitting(true);

        try {
            await editOrganizationLogoPosition({ brandLogoPosition });
            refreshProfile();
            addFlashMessage('success', 'Settings successfully updated');
        } catch (err) {
            handleError(err, null, addFlashMessage);
        }

        setSubmitting(false);
    };

    const toggleCropModal = () => setCropModalOpen(!cropModalOpen);

    if (fetchingImage) return <LoadingSpinner text="Fetching email logo" />;

    return (
        <div className="appearance">
            <h4 className="settings__page-header">{isSingleUser ? 'Custom Logo' : 'Company Image'}</h4>

            <div className="appearance__img-info">
                This image will appear at the top of your daily email alerts.
                <br />
                Image will be scaled to a maximum height and width of 300px.
                <br />
                Minimum resolution: 100 x 80. Maximum file size: 2MB.
            </div>

            <Dropzone
                className="appearance__dropzone"
                accept="image/jpeg, image/png"
                onDropAccepted={handleDropAccept}
                onDropRejected={handleDropReject}
                multiple={false}
                maxSize={2000000}>
                {({ getRootProps, getInputProps }) => (
                    <div>
                        <div className="appearance__dropzone-content" {...getRootProps()}>
                            <input {...getInputProps()} />

                            {fetchingImage ? (
                                <div className="mt-2 mb-4">
                                    <LoadingSpinner />
                                </div>
                            ) : (
                                <div className="appearance__dropzone-image">
                                    <img src={brandLogoUrl} alt="company-logo" />
                                </div>
                            )}

                            <div style={{ color: '#4c4c4c' }} />

                            <Button color="primary" className="mt-2" block style={{ maxWidth: '200px' }}>
                                Add Custom Logo
                            </Button>
                        </div>
                    </div>
                )}
            </Dropzone>

            <div className="mb-4">
                <h4 className="settings__page-header mt-6">Newsletter Logo Placement</h4>

                <FormGroup tag="fieldset" className="mt-3 mb-0">
                    <RadioInput
                        isInline
                        name="placementLeft"
                        onChange={() => setBrandLogoPosition('left')}
                        checked={brandLogoPosition === 'left'}
                        text="Left"
                    />

                    <RadioInput
                        isInline
                        name="placementCenter"
                        onChange={() => setBrandLogoPosition('center')}
                        checked={brandLogoPosition === 'center'}
                        text="Center"
                    />

                    <RadioInput
                        isInline
                        name="placementRight"
                        onChange={() => setBrandLogoPosition('right')}
                        checked={brandLogoPosition === 'right'}
                        text="Right"
                    />
                </FormGroup>

                <div className="mt-4">
                    <SpinnerButton submitting={submitting} color="primary" onClick={handlePositionSubmit} type="button" title={'Save Changes'} />
                </div>
            </div>

            {cropModalOpen && (
                <CropModal
                    isOpen={cropModalOpen}
                    toggle={toggleCropModal}
                    imagePreviewUrl={imagePreviewUrl}
                    submitting={submitting}
                    addFlashMessage={addFlashMessage}
                    onSubmit={handleLogoUploadSubmit}
                />
            )}
        </div>
    );
};
