import { axios } from 'lib/axios';
import { getCsv } from './csvAPI';

export const getNewsletters = (
    count,
    pageNumber,
    itemsPerPage,
    sortProperty,
    sortDirection,
    searchProperty,
    searchValue = '',
    csv = false,
    title
) => {
    let url = `/api/v3/newsletter?count=${count}&page=${pageNumber}&itemsOnPage=${itemsPerPage}&sort[0][property]=${sortProperty}&sort[0][direction]=${sortDirection.toUpperCase()}&csv=${csv}`;

    if (searchValue.length > 0) {
        const encodedSearchValue = encodeURIComponent(searchValue);
        url = url + `&filter[0][property]=${searchProperty}&filter[0][operator]=contains&filter[0][value]=${encodedSearchValue}`;
    }

    if (csv) {
        return getCsv(url, title);
    } else {
        return axios.get(url);
    }
};

export const addNewsletter = (params) => {
    return axios.post('/api/v3/newsletter', params);
};

export const getNewsletterById = (id) => {
    return axios.get(`/api/v3/newsletter/${id}`);
};

export const editNewsletterById = (id, params) => {
    return axios.patch(`/api/v3/newsletter/${id}`, params);
};

export const deleteNewsletter = (id) => {
    return axios.delete(`/api/v3/newsletter/${id}`);
};

export const editNewsletterComment = (id, params) => {
    return axios.patch(`/api/v3/newsletter/${id}/comment`, params);
};

export const addNewsletterSection = (id, params) => {
    return axios.post(`/api/v3/newsletter/${id}/section`, params);
};

export const editNewsletterSection = (id, sectionId, params) => {
    return axios.patch(`/api/v3/newsletter/${id}/section/${sectionId}`, params);
};

export const deleteNewsletterSection = (id, sectionId) => {
    return axios.delete(`/api/v3/newsletter/${id}/section/${sectionId}`);
};

export const sortNewsletterSections = (id, params) => {
    return axios.patch(`/api/v3/newsletter/${id}/section`, params);
};

export const clearNewsletterSection = (id, sectionId) => {
    return axios.delete(`/api/v3/newsletter/${id}/section/${sectionId}/alert`);
};

export const addAlertToSection = (id, sectionId, params) => {
    return axios.post(`/api/v3/newsletter/${id}/section/${sectionId}/alert`, params);
};

export const addArticleToSection = (id, sectionId, params) => {
    return axios.post(`/api/v3/newsletter/${id}/section/${sectionId}/news`, params);
};

export const removeAlertFromSection = (id, sectionId, alertId) => {
    return axios.delete(`/api/v3/newsletter/${id}/section/${sectionId}/alert/${alertId}`);
};

export const removeArticleFromSection = (id, sectionId, newsId) => {
    return axios.delete(`/api/v3/newsletter/${id}/section/${sectionId}/news/${newsId}`);
};

export const moveAlertBetweenNewsletterSections = (id, alertId, previousSectionId, params) => {
    return axios.patch(`/api/v3/newsletter/${id}/section/${previousSectionId}/alert/${alertId}`, params);
};

export const moveArticleBetweenNewsletterSections = (id, newsId, previousSectionId, params) => {
    return axios.patch(`/api/v3/newsletter/${id}/section/${previousSectionId}/news/${newsId}`, params);
};

export const addCommentToNewsletterArticle = (id, sectionId, newsId, params) => {
    return axios.patch(`/api/v3/newsletter/${id}/section/${sectionId}/news/${newsId}`, params);
};

export const getFreeCuratedArticlesByDate = async (id, dates, sortByDate = 'desc') => {
    const result = [];

    for await (let date of dates) {
        const res = await axios.get(
            `/api/v3/newsletter/${id}/free_news?from=${date.from}%20days%20ago&to=${Number.isInteger(date.to) ? date.to + '%20days%20ago' : date.to}&sortByDate=${sortByDate}`
        );
        result.push(res);
    }

    return result.flat();
};

export const clearCuratedNewsletterSection = (id, sectionId) => {
    return axios.delete(`/api/v3/newsletter/${id}/section/${sectionId}/news`);
};

export const sendInstantNewsletter = (id) => {
    return axios.post(`/api/v3/newsletter/${id}/email`);
};

export const getNewsletterPreview = (id, sentId, pdf = false, title) => {
    const url = `/api/v3/newsletter/${id}/email?pdf=${pdf}`;

    if (pdf) {
        return getCsv(url, title, true);
    } else {
        return axios.get(url);
    }
};

export const assignUsersToNewsletter = (id, params) => {
    return axios.patch(`/api/v3/newsletter/${id}/user`, params);
};

export const hideArticleFromList = (id, params) => {
    return axios.post(`/api/v3/newsletter/${id}/excluded_news`, params);
};

export const addArticleToCuratedNewsletter = (id, sectionId, params) => {
    return axios.post(`/api/v3/newsletter/${id}/section/${sectionId}/custom_news`, params);
};
