import React from 'react';
import { Button } from 'reactstrap';

export const SpinnerButtonSquare = (props) => {
    const { submitting, title, disabled, block, color, onClick, className, id } = props;

    return (
        <Button
            color={color}
            style={{
                minHeight: '35px',
                minWidth: '39px',
                padding: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
            type="button"
            className={className}
            disabled={disabled}
            block={block}
            onClick={onClick}
            id={id}>
            {submitting ? <i className="fa fa-spinner fa-spin" /> : title}
        </Button>
    );
};
