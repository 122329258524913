import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { addAlertLabel, addRssAlert, editRssAlert, previewRssAlert } from 'utils/api/alertsAPI';
import { FlashMessageContext } from 'contexts/FlashMessageContext';
import { AuthContext } from 'contexts/AuthContext';
import { LoadingSpinner } from 'common/LoadingSpinner';
import { SearchForm } from './SearchForm';
import { TitleForm } from './TitleForm';
import { RssPreview } from './RssPreview';
import './style.scss';

export const Rss = ({ foundAlert, isEdit, labels, internalGroup, refreshLabels }) => {
    const navigate = useNavigate();
    const { addFlashMessage } = useContext(FlashMessageContext);
    const { handleError, state } = useContext(AuthContext);

    const [searchValue, setSearchValue] = useState('');
    const [searchValueEditWarning, setSearchValueEditWarning] = useState(false);
    const [titleValue, setTitleValue] = useState('');
    const [preview, setPreview] = useState(null);
    const [titleFormVisible, setTitleFormVisible] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [selectedLabels, setSelectedLabels] = useState([]);
    const [creatingLabel, setCreatingLabel] = useState(false);
    const [newLabelToAdd, setNewLabelToAdd] = useState(null);
    const [errors, setErrors] = useState(null);
    const tempSearchValue = foundAlert?.source?.url || searchValue;

    const {
        isLoading: isLoadingPreview,
        isFetching: isFetchingPreview,
        refetch: refetchPreview,
    } = useQuery(['previewRssAlert'], () => (tempSearchValue ? previewRssAlert(tempSearchValue) : null), {
        onSuccess: (res) => {
            setErrors(null);

            if (res?.errors) return handleRSSErrors(res);

            !isEdit && setTitleValue(res?.globalTitle?.length ? res.globalTitle : 'Enter Title');
            setPreview(res);
            setTitleFormVisible(true);
        },
        onError: (err) => handleRSSErrors(err),
    });

    useEffect(() => {
        if (isEdit && foundAlert && !preview) {
            setSearchValue(foundAlert.source.url);
            setTitleValue(foundAlert.name);
            setTitleFormVisible(true);
            setSelectedLabels(foundAlert.labels);
        }
    }, [isEdit, foundAlert, preview]);

    const handleRSSErrors = (err) => {
        const tempErrObj = {
            code: 'VALIDATION_FAILED',
            errors: {
                url: [{ message: err?.message || '404 Not Found' }],
            },
        };

        handleError(tempErrObj, setErrors);
        setTitleFormVisible(false);
    };

    const handleSearchValueChange = (text) => setSearchValue(text);

    useEffect(() => {
        if (isEdit) {
            if (searchValue !== foundAlert.source.url) {
                setSearchValueEditWarning(true);
            } else {
                setSearchValueEditWarning(false);
            }
        }
    }, [searchValue]);

    const resetForm = () => {
        setPreview(null);
        setTitleFormVisible(false);
        setTitleValue('');
        setSearchValue('');
        setErrors(null);
    };

    const handleSubmit = async () => {
        const rssSearchValues = {
            name: titleValue,
            url: searchValue,
            alertLabels: selectedLabels.map((n) => n.id),
        };

        try {
            setSubmitting(true);

            if (isEdit) {
                await editRssAlert(foundAlert.id, rssSearchValues);
                addFlashMessage('success', 'Alert successfully updated');
                navigate('/account/manage-sources');
            } else {
                await addRssAlert(rssSearchValues);
                setSubmitting(false);
                addFlashMessage('success', 'Alert successfully created');
                navigate('/account/manage-sources');
            }
        } catch (err) {
            setSubmitting(false);
            if (err?.message?.includes('You can add up to')) {
                return addFlashMessage(
                    'danger',
                    "You've reached the limit of allowed number of alerts for your account.\n" + 'Contact Customer Care team for more information.'
                );
            }

            if (err.code === 'NOT_FOUND') {
                const errObj = {
                    code: 'VALIDATION_FAILED',
                    errors: {
                        url: [{ message: '404 Not Found' }],
                    },
                };
                handleError(errObj, setErrors);
                return;
            }

            if (err.code === 'VALIDATION_FAILED') {
                if (err.errors.name) {
                    if (err.errors.name[0].code === 'TOO_SHORT_ERROR') {
                        addFlashMessage('danger', 'Alert name must be at least 2 characters long.');
                    } else if (err.errors.name[0].code === 'IS_BLANK_ERROR') {
                        addFlashMessage('danger', 'Alert name must be at least 2 characters long.');
                    }
                } else {
                    if (err.message && err.message.substr(0, 13) === 'Client error:') {
                        const errObj = {
                            code: 'VALIDATION_FAILED',
                            errors: {
                                url: [{ message: '404 Not Found' }],
                            },
                        };
                        handleError(errObj, setErrors);
                    } else if (
                        err.message.slice(0, 17) === 'Alert with source' ||
                        (err.errors.hasOwnProperty('url') && err.errors.url[0].code === 'NOT_UNIQUE_ERROR')
                    ) {
                        const errObj = {
                            code: 'VALIDATION_FAILED',
                            errors: {
                                url: [{ message: 'An alert with this url already exists.' }],
                            },
                        };
                        handleError(errObj, setErrors);
                    }
                }
                err?.message && handleError(err.message, setErrors);
            } else {
                handleError(err, setErrors);
            }
        }
    };

    useEffect(() => {
        if (newLabelToAdd) {
            const foundLabel = labels.find((label) => label.id === newLabelToAdd.id);
            foundLabel && addToSelected(foundLabel);
            setNewLabelToAdd(null);
        }
    }, [labels]);

    const addLabel = async (name) => {
        setCreatingLabel(true);
        try {
            const newLabel = await addAlertLabel({ name });
            setNewLabelToAdd(newLabel);
            await refreshLabels();
            setCreatingLabel(false);
        } catch (res) {
            if (res.errors.name[0].message) {
                addFlashMessage('danger', res.errors.name[0].message);
            }
            setCreatingLabel(false);
        }
    };

    const addToSelected = (label) => {
        let newSelectedLabels = [...selectedLabels];

        if (newSelectedLabels.indexOf(label) === -1) {
            newSelectedLabels.push(label);
            setSelectedLabels(newSelectedLabels);
        }
    };

    const removeFromSelected = (label) => {
        let newSelectedLabels = [...selectedLabels];

        if (newSelectedLabels.indexOf(label) > -1) {
            newSelectedLabels.splice(newSelectedLabels.indexOf(label), 1);
            setSelectedLabels(newSelectedLabels);
        }
    };

    return (
        <div className="mb-4">
            <SearchForm
                searchValue={searchValue}
                handleSearchValueChange={handleSearchValueChange}
                handleCreatePreview={refetchPreview}
                resetForm={resetForm}
                searchSubmitting={isLoadingPreview}
                searchValueEditWarning={searchValueEditWarning}
                errorObj={errors}
                internalGroup={internalGroup}
            />

            {titleFormVisible && (
                <TitleForm
                    title={titleValue}
                    setTitle={setTitleValue}
                    submitting={submitting}
                    isEditForm={isEdit}
                    handleSubmit={handleSubmit}
                    errors={errors}
                />
            )}

            {isLoadingPreview || isFetchingPreview ? (
                <div className="mt-4">
                    <LoadingSpinner text="Fetching preview" />
                </div>
            ) : (
                preview && (
                    <RssPreview
                        preview={preview}
                        updatingPreview={isFetchingPreview}
                        selectedLabels={selectedLabels}
                        labels={labels}
                        addLabel={addLabel}
                        addToSelected={addToSelected}
                        removeFromSelected={removeFromSelected}
                        creatingLabel={creatingLabel}
                        isSingleUser={state.isSingleUser}
                    />
                )
            )}
        </div>
    );
};
