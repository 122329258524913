import React, { useState, useEffect, useContext, useRef } from 'react';
import { Card, CardBody, Col, Input, InputGroup, InputGroupText, Row } from 'reactstrap';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { isEqual } from 'lodash';
import { FlashMessageContext } from 'contexts/FlashMessageContext';
import { AuthContext } from 'contexts/AuthContext';
import { truncateWithoutWordBreak } from 'utils/helpers';
import { initBriefingReport } from 'utils/api/alertsAPI';
import { TabBar } from 'common/TabBar';
import { PaginationWrapper } from 'common/PaginationWrapper';
import { ListItem } from './ListItem';

export const ArticleList = ({ articlesWithoutDuplicates, alertId, alertName, articles: allArticles }) => {
    const { state } = useContext(AuthContext);
    const ref = useRef(null);
    const [articles, setArticles] = useState([]);
    const [sec, setSec] = useState([]);
    const [activeView, setActiveView] = useState('latest_news');
    const [totalPages, setTotalPages] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalSecPages, setTotalSecPages] = useState([]);
    const [currentSecPage, setCurrentSecPage] = useState(1);
    const [accessSec, setAccessSec] = useState(false);
    const { addFlashMessage } = useContext(FlashMessageContext);
    const [articlesToBriefingReport, setArticlesToBriefingReport] = useState([]);
    const [accessBriefingReport, setAccessBriefingReport] = useState(false);
    const [emails, setEmails] = useState('');
    const [articlesToEmail, setArticlesToEmail] = useState([]);
    const [activeTag, setActiveTag] = useState('');
    const access = state.profile.organization?.accesses;

    useEffect(() => {
        if (access?.accessCompanyBasedSec) {
            setAccessSec(true);
        }
        if (access?.accessBriefingReport) {
            setAccessBriefingReport(true);
        }
    }, [state.profile]);

    useEffect(() => {
        const allA = allArticles.filter((el) => el.resource !== 'sec.gov');
        const a = articlesWithoutDuplicates.filter((el) => el.resource !== 'sec.gov');
        const s = articlesWithoutDuplicates.filter((el) => el.resource === 'sec.gov');

        if (activeTag && activeView !== 'sec_filings') {
            let filteredArray = [...allA];
            let filteredArrayWithoutDuplicates = [...a];

            filteredArray = filteredArray.filter((article) => article[activeTag.ind].includes(activeTag.name));
            filteredArrayWithoutDuplicates = filteredArrayWithoutDuplicates.filter((article) => article[activeTag.ind].includes(activeTag.name));
            const resultArray = filteredArrayWithoutDuplicates?.length ? filteredArrayWithoutDuplicates : filteredArray;

            setArticles(resultArray);
            setTotalPages(Math.ceil(resultArray.length / 10));
        } else {
            setArticles(a);
            setTotalPages(Math.ceil(a.length / 10));
            setSec(
                s.map((el) => {
                    el.title = el.title.replace(/\([a-z0-9]{32}\)/, '').trim();
                    return el;
                })
            );
            setTotalSecPages(Math.ceil(s.length / 10));
        }

        setCurrentPage(1);
    }, [articlesWithoutDuplicates, activeTag]);

    const handlePageClick = (e) => {
        setCurrentPage(e.selected + 1);
        ref?.current && ref.current.scrollIntoView({ behavior: 'smooth' });
    };
    const handleSecPageClick = (e) => {
        setCurrentSecPage(e.selected + 1);
        ref?.current && ref.current.scrollIntoView({ behavior: 'smooth' });
    };

    const handleTagSelect = (tag) => {
        if (isEqual(activeTag, tag)) {
            setActiveTag('');
        } else {
            setActiveTag(tag);
        }
    };

    const getItems = () => {
        const items = [{ type: 'latest_news', name: 'Latest news (' + articles.length + ')' }];

        if (accessSec) {
            items.push({ type: 'sec_filings', name: 'SEC Filings (' + sec.length + ')' });
        }

        return items;
    };

    const handleShareEmailClick = (e) => {
        e.preventDefault();

        if (articlesToEmail.length < 1) {
            addFlashMessage('danger', 'First you need select articles...');
            return false;
        }

        window.open(buildMailtoUrl(), '_blank', 'noopener,noreferrer');
    };

    const buildMailtoUrl = () => {
        let toSend = articlesWithoutDuplicates.filter((x) => articlesToEmail.indexOf(x.id) !== -1);
        toSend = toSend.map((i) => i.title + '\n' + i.bit_url);
        toSend = toSend.join('\n\n');

        return 'mailto:?subject=' + truncateWithoutWordBreak(alertName) + '&body=' + encodeURIComponent(toSend);
    };

    const validateEmails = () => {
        if (!emails) return false;

        let valid = true;
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        const arr = emails.split(' ');

        arr.forEach((el) => {
            el = el.trim();

            if (el && !emailRegex.test(el) && valid) {
                addFlashMessage('danger', 'Please enter a valid email address');
                valid = false;
            }
        });

        if (valid) setEmails(emails);

        return valid;
    };

    const handleBriefingReportClick = async (e) => {
        e.preventDefault();

        if (articlesToBriefingReport.length < 2 || articlesToBriefingReport.length > 5) {
            addFlashMessage('danger', 'You need select between 2 and 5 articles...');
            return false;
        }

        if (!emails || !validateEmails()) {
            addFlashMessage('danger', 'Please enter a valid email address');
            return false;
        }

        let urls = articles.filter((x) => articlesToBriefingReport.indexOf(x.id) !== -1);
        urls = urls.map((x) => x.url);

        try {
            await initBriefingReport({
                urls: urls,
                emails: emails.split(' '),
            });
            addFlashMessage('success', 'Briefing Report successfully sent');
        } catch (err) {
            addFlashMessage('danger', 'Something wrong...');
        }
    };

    return (
        <div className="view view--mobile-full alerts ">
            {alertId && (
                <div className="row">
                    <div className="col-10">
                        {accessBriefingReport && articlesToBriefingReport.length > 0 && (
                            <>
                                <InputGroup className="m-0">
                                    <InputGroupText data-tip="Build Briefing Report">
                                        <i className="fa fa-user" />
                                    </InputGroupText>

                                    <ReactTooltip place="bottom" effect="solid" delayShow={200} className="tooltip-thin" />

                                    <Input
                                        placeholder="Email address(es) separated by space"
                                        value={emails}
                                        type="text"
                                        onChange={(e) => setEmails(e.target.value)}
                                        onBlur={validateEmails}
                                    />
                                    <ReactTooltip place="bottom" effect="solid" delayShow={200} className="tooltip-thin" />

                                    <button className="btn btn-secondary" data-tip="Build Briefing Report" onClick={handleBriefingReportClick}>
                                        <i className="fa fa-cubes" />
                                    </button>
                                </InputGroup>
                            </>
                        )}
                    </div>
                    <div className="col-2">
                        {articlesToEmail.length > 0 && (
                            <>
                                <ReactTooltip place="bottom" effect="solid" delayShow={200} className="tooltip-thin" />

                                <button
                                    className="btn btn-secondary"
                                    id="email-label-tooltip"
                                    data-tip="Share via email"
                                    onClick={handleShareEmailClick}>
                                    <i className="fa fa-envelope-o" />
                                </button>
                            </>
                        )}
                    </div>
                </div>
            )}

            <div className="animated fadeIn" ref={ref}>
                <Row>
                    <Col xs="12">
                        <Card style={{ border: 'none' }}>
                            <CardBody>
                                <TabBar activeItem={activeView} setActive={setActiveView} items={getItems()} />
                                <div className="company-news-list">
                                    <ul className="list-unstyled list-group">
                                        {(activeView === 'sec_filings'
                                            ? sec?.slice(currentSecPage * 10 - 10, currentSecPage * 10)
                                            : articles?.slice(currentPage * 10 - 10, currentPage * 10)
                                        )?.map((article, index) => (
                                            <ListItem
                                                key={article.id + index + article?.url}
                                                item={article}
                                                withSummary={activeView === 'latest_news'}
                                                alertId={alertId}
                                                accessBriefingReport={accessBriefingReport}
                                                articlesToEmail={articlesToEmail}
                                                setArticlesToEmail={setArticlesToEmail}
                                                articlesToBriefingReport={articlesToBriefingReport}
                                                setArticlesToBriefingReport={setArticlesToBriefingReport}
                                                isSecFilings={activeView === 'sec_filings'}
                                                activeTag={activeTag}
                                                handleTagSelect={handleTagSelect}
                                            />
                                        ))}

                                        {activeView === 'sec_filings'
                                            ? totalSecPages > 1 && (
                                                  <PaginationWrapper
                                                      totalPages={totalSecPages}
                                                      forcePage={currentSecPage - 1}
                                                      handlePageClick={handleSecPageClick}
                                                  />
                                              )
                                            : totalPages > 1 && (
                                                  <PaginationWrapper
                                                      totalPages={totalPages}
                                                      forcePage={currentPage - 1}
                                                      handlePageClick={handlePageClick}
                                                  />
                                              )}
                                    </ul>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    );
};
