import React from 'react';

export const Tag = ({ handleTagSelect, isActive, tag }) => {
    const { name, cnt } = tag;

    const fontSize = cnt >= 10 ? 22 : cnt >= 4 ? 16 : 13;
    const activeState = isActive ? 'reader-tag-category__tag--active' : '';

    return (
        <div onClick={() => handleTagSelect(tag)} className={'reader-tag-category__tag ' + activeState} style={{ fontSize }}>
            {name}
        </div>
    );
};
