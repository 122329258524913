import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal, ModalFooter, Button } from 'reactstrap';
import { SpinnerButton } from 'common/SpinnerButton';
import { ModalBodyWithClose } from 'common/ModalBodyWithClose';
import { universalAssignAlerts } from 'utils/api/alertsAPI';

export const ConfirmAllAssignment = ({ isOpen, toggle, handleError, addFlashMessage, tempAssignedUsers, tempAssignedAlerts }) => {
    const navigate = useNavigate();
    const [submitting, setSubmitting] = useState(false);

    const handleConfirmAssign = async () => {
        setSubmitting(true);

        try {
            const params = {
                users: tempAssignedUsers.map((user) => user.id),
                alerts: tempAssignedAlerts.map((alert) => alert.id),
            };

            await universalAssignAlerts(params);
            addFlashMessage('success', 'Alerts successfully assigned');
            navigate(`/account/alerts`);
        } catch (err) {
            handleError(err);
        }

        setSubmitting(false);
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalBodyWithClose toggle={toggle}>
                <span>You have selected all existing alerts for assignment. Are you sure you want to assign that many alerts?</span>
            </ModalBodyWithClose>

            <ModalFooter>
                <Button color="secondary" onClick={toggle}>
                    Cancel
                </Button>

                <SpinnerButton type="modal" color="primary" onClick={handleConfirmAssign} submitting={submitting} title="Confirm" />
            </ModalFooter>
        </Modal>
    );
};
