import React, { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import { InputWithError } from 'common/InputWithError';
import { SpinnerButton } from 'common/SpinnerButton';
import { deleteJwtToken, editJwtToken } from 'utils/api/profileAPI';
import { ConfirmDeleteModal } from './ConfirmDeleteModal';
import { handleCopyToClipboard } from 'utils/helpers';

export const Token = ({ jwtTokenObj, addFlashMessage, handleError, refreshProfile }) => {
    const [editMode, setEditMode] = useState(false);
    const [name, setName] = useState('');
    const [tempName, setTempName] = useState('');
    const [token, setToken] = useState('');
    const [tempToken, setTempToken] = useState('');
    const [errors, setErrors] = useState(null);
    const [submitting, setSubmitting] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);

    useEffect(() => {
        setName(jwtTokenObj.name);
        setTempName(jwtTokenObj.name);
        setToken(jwtTokenObj.token.token);
        setTempToken(jwtTokenObj.token.token);
    }, [jwtTokenObj]);

    const resetFields = () => {
        setTempToken(token);
        setEditMode(false);
    };

    const updateJwt = async () => {
        setSubmitting(true);

        try {
            await editJwtToken(jwtTokenObj.id, { name: tempName, token: tempToken });
            addFlashMessage('success', 'Token successfully updated');
            setName(tempName);
            setToken(tempToken);
            setEditMode(false);
            refreshProfile();
        } catch (err) {
            if (err.message && err.message === 'This token already used') {
                addFlashMessage('danger', 'This token is already in use');
            } else {
                addFlashMessage('danger', 'Invalid token details');
            }
        }
        setSubmitting(false);
    };

    const deleteJwt = async () => {
        setDeleting(true);

        try {
            await deleteJwtToken(jwtTokenObj.id);
            addFlashMessage('success', 'Token successfully deleted');
            toggleDeleteModal();
            refreshProfile();
        } catch (err) {
            handleError(err, setErrors);
        }

        setDeleting(false);
    };

    const toggleDeleteModal = () => setDeleteModalOpen((prevState) => !prevState);

    if (jwtTokenObj.type !== 1) return null;

    return (
        <div className="courtlink-token">
            <ConfirmDeleteModal onDelete={deleteJwt} isOpen={deleteModalOpen} toggle={toggleDeleteModal} deleting={deleting} errors={errors} />

            {editMode ? (
                <div className="courtlink-token__info">
                    <div style={{ maxWidth: '490px', width: '100%' }}>
                        <InputWithError
                            prependIcon={<i className="fa fa-tag" />}
                            name="name"
                            value={tempName}
                            type="text"
                            onChange={setTempName}
                            errorObj={errors}
                            marginBottomClass="mb-3"
                            placeholder="Token Name"
                        />
                    </div>

                    <div style={{ maxWidth: '490px', width: '100%' }}>
                        <InputWithError
                            prependIcon={<i className="fa fa-key" />}
                            name="token"
                            value={tempToken}
                            type="text"
                            onChange={setTempToken}
                            errorObj={errors}
                            marginBottomClass="mb-0"
                            placeholder="JWT Token"
                        />
                    </div>
                </div>
            ) : (
                <div className="courtlink-token__info">
                    <div className="courtlink-token-info-item" onClick={() => handleCopyToClipboard(name)}>
                        <strong>Token Name:</strong> {name}
                    </div>

                    <div className="courtlink-token-info-item" onClick={() => handleCopyToClipboard(token)}>
                        <strong>Token Value:</strong> {token}
                    </div>
                </div>
            )}

            {editMode ? (
                <div className="courtlink-token__actions">
                    <Button className="px-4 mr-2 courtlink-field-btn" onClick={resetFields}>
                        Cancel
                    </Button>

                    <SpinnerButton className="courtlink-field-btn" color="primary" submitting={submitting} onClick={updateJwt} title="Update" />
                </div>
            ) : (
                <div className="courtlink-token__actions">
                    <Button className="btn-light mr-2" onClick={() => setEditMode(true)}>
                        <i className="fa fa-edit" />
                    </Button>

                    <Button onClick={toggleDeleteModal} color="danger">
                        <i className="fa fa-trash" />
                    </Button>
                </div>
            )}
        </div>
    );
};
