import React, { useState, useEffect, useRef } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { AutoSizer, List, CellMeasurer, CellMeasurerCache } from 'react-virtualized';
import { SearchInput } from 'common/SearchInput';
import { filterByValue, sortObjects } from 'utils/helpers';
import { Source } from './Source';

const cache = new CellMeasurerCache({ fixedWidth: true });

export const Sources = ({ newsletterData, assignAlertToSection }) => {
    const [quickAddDropdownOpen, setQuickAddDropdownOpen] = useState(false);
    const [activeQuickAddSection, setActiveQuickAddSection] = useState(null);
    const [searchValue, setSearchValue] = useState('');
    const [visibleAlerts, setVisibleAlerts] = useState([]);
    const sourceListRef = useRef(null);

    useEffect(() => {
        setVisibleAlerts(newsletterData.unassignedAlerts);
        const foundUnassignedSection = newsletterData.sections.find((section) => section.name === 'Unassigned');

        foundUnassignedSection && setActiveQuickAddSection(foundUnassignedSection);
    }, []);

    const toggleQuickAddDropdown = () => setQuickAddDropdownOpen(!quickAddDropdownOpen);

    useEffect(() => {
        const filteredArr = filterByValue(newsletterData.unassignedAlerts, 'name', searchValue);

        setVisibleAlerts(sortObjects(filteredArr, 'name', 'asc'));
        cache.clearAll();
        sourceListRef && sourceListRef.current && sourceListRef.current.recomputeRowHeights();
    }, [searchValue, newsletterData.unassignedAlerts]);

    const rowRenderer = ({ index, key, style, parent }) => {
        if (visibleAlerts[index]) {
            return (
                <CellMeasurer key={key} cache={cache} parent={parent} columnIndex={0} rowIndex={index}>
                    <div className="newsletter-content-source-row" style={{ ...style }}>
                        <Source
                            source={visibleAlerts[index]}
                            assignAlertToSection={assignAlertToSection}
                            activeQuickAddSection={activeQuickAddSection}
                        />
                    </div>
                </CellMeasurer>
            );
        }
    };

    return (
        <div className="newsletter-content-panel newsletter-content-panel--right">
            <div className="newsletter-content-panel__header">
                <h4>Content</h4>

                {newsletterData.sections.length > 0 && (
                    <Dropdown className="newsletter-content-panel__quick-add-btn" isOpen={quickAddDropdownOpen} toggle={toggleQuickAddDropdown}>
                        <DropdownToggle caret={true} color="primary">
                            {activeQuickAddSection
                                ? 'Adding to: ' +
                                  (activeQuickAddSection.name.length > 20
                                      ? activeQuickAddSection.name.substring(0, 20) + '...'
                                      : activeQuickAddSection.name)
                                : 'Select Section'}
                        </DropdownToggle>

                        <DropdownMenu>
                            {newsletterData.sections.map((section) => (
                                <DropdownItem key={'dropdown' + section.id} onClick={() => setActiveQuickAddSection(section)}>
                                    {section.name.length > 20 ? section.name.substring(0, 20) + '...' : section.name}
                                </DropdownItem>
                            ))}
                        </DropdownMenu>
                    </Dropdown>
                )}
            </div>

            <div className="newsletter-content-panel__body">
                <div className="d-flex">
                    <SearchInput onClear={() => setSearchValue('')} onChange={setSearchValue} placeholder="Filter by title" value={searchValue} />
                </div>

                <div className="newsletter-content-panel__source-list">
                    {visibleAlerts.length < 1 ? (
                        <div>No alerts found</div>
                    ) : (
                        <div className="virtualized-flex-outer">
                            <div className="virtualized-flex-inner">
                                <AutoSizer>
                                    {({ height, width }) => (
                                        <List
                                            ref={sourceListRef}
                                            width={width}
                                            height={height}
                                            deferredMeasurementCache={cache}
                                            rowHeight={cache.rowHeight}
                                            rowCount={visibleAlerts.length}
                                            rowRenderer={rowRenderer}
                                        />
                                    )}
                                </AutoSizer>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
