import React from 'react';
import { convertDateString, truncateLongWords, truncateWithoutWordBreak } from 'utils/helpers';

export const ArticleListItem = ({ article, selectedArticle, handleArticleSelect }) => {
    const { title, abstract, resource, created_at, id } = article;

    return (
        <div
            id={id}
            className={'reader-article ' + (selectedArticle && selectedArticle.id === id ? 'reader-article--active' : '')}
            onClick={() => handleArticleSelect(article)}>
            <div className="reader-article__info-block">
                <div className="reader-article__publisher">{resource}</div>
                <div className="reader-article__date">{convertDateString(created_at)}</div>
            </div>

            {title.length > 100 ? (
                <div
                    className="reader-article__title"
                    dangerouslySetInnerHTML={{ __html: truncateWithoutWordBreak(truncateLongWords(title, 50), 100) }}
                />
            ) : (
                <div className="reader-article__title" dangerouslySetInnerHTML={{ __html: title }} />
            )}

            {abstract && abstract.length > 36 ? (
                <div
                    className="reader-article__content"
                    dangerouslySetInnerHTML={{ __html: truncateWithoutWordBreak(truncateLongWords(abstract, 50), 120) }}
                />
            ) : (
                <div className="reader-article-content" dangerouslySetInnerHTML={{ __html: abstract }} />
            )}
        </div>
    );
};
