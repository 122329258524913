import React from 'react';
import { Modal, ModalFooter, Button } from 'reactstrap';
import { ModalBodyWithClose } from 'common/ModalBodyWithClose';

export const ConfirmModal = ({ isOpen, toggle, onConfirm }) => (
    <Modal isOpen={isOpen} toggle={toggle} className="organization-switch-confirm">
        <ModalBodyWithClose toggle={toggle}>Switch your account to organization mode?</ModalBodyWithClose>

        <ModalFooter>
            <Button color="secondary" onClick={toggle}>
                Cancel
            </Button>

            <Button color="primary" onClick={onConfirm}>
                Confirm
            </Button>
        </ModalFooter>
    </Modal>
);
