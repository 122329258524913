import React from 'react';
import Toggle from 'react-toggle';

export const EmailSettingsToggle = ({ checked, handleCheck, text, subtext = '' }) => (
    <div className="email-settings-toggle">
        <label>
            <Toggle checked={checked} icons={false} onChange={() => handleCheck(!checked)} />
        </label>

        <span id="email-settings-toggle-label">
            {text} <span className="email-settings-toggle__subtext">{subtext}</span>
        </span>
    </div>
);
