import React from 'react';
import { Modal, ModalFooter, Button } from 'reactstrap';
import { SpinnerButton } from 'common/SpinnerButton';
import { ModalBodyWithClose } from 'common/ModalBodyWithClose';

export const DeleteSetModal = ({ isOpen, toggle, onSubmit, submitting, item }) => (
    <Modal isOpen={isOpen} toggle={toggle}>
        {!item ? (
            <div />
        ) : (
            <div>
                <ModalBodyWithClose toggle={toggle}>
                    <span>Are you sure you want to delete {item.name}</span>
                </ModalBodyWithClose>

                <ModalFooter>
                    <Button color="secondary" onClick={toggle}>
                        Cancel
                    </Button>

                    <SpinnerButton type="modal" color="danger" onClick={() => onSubmit(item.id, toggle)} submitting={submitting} title="Delete" />
                </ModalFooter>
            </div>
        )}
    </Modal>
);
