import React from 'react';
import { Card, CardHeader, CardBody } from 'reactstrap';
import { LoadingSpinner } from 'common/LoadingSpinner';
import { EmptyChart } from 'common/EmptyChart';
import { NewsPerDayChart } from './index';
import 'components/Alerts/components/CompanyPage/style.scss';

export const NewsPerDayWrapper = ({ alertData, fetchingData }) => (
    <Card>
        <CardHeader>News per Day (Last 30 Days)</CardHeader>
        <CardBody>
            {fetchingData ? (
                <LoadingSpinner />
            ) : alertData?.news?.length > 0 ? (
                <NewsPerDayChart articles={alertData.news} articlesPerDay={alertData.newsPerDay} />
            ) : (
                <EmptyChart />
            )}
        </CardBody>
    </Card>
);
