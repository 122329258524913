import React, { useState, useEffect } from 'react';
import { Button, Card, CardBody, Table } from 'reactstrap';
import { isEqual } from 'lodash';
import { useQuery } from 'react-query';
import { handleCaret } from 'utils/helpers';
import { getComparativeSets, deleteComparativeSet } from 'utils/api/statisticsAPI';
import { SearchInputWithSubmit } from 'common/SearchInput/SearchInputWithSubmit';
import { PaginationWrapper } from 'common/PaginationWrapper';
import { LoadingSpinner } from 'common/LoadingSpinner';
import { CreateEditCompSetModal } from '../Modals/CreateEditSetModal';
import { DeleteSetModal } from '../Modals/DeleteSetModal';
import { ListItem } from './ListItem';

export const List = ({ handleCompSetSelect, fetchCompSetStatistics, activeSet, handleError, addFlashMessage, compSetFilters, setFiltersObject }) => {
    const { paginationCurrentPageNumber, activeSortProperty, activeSortDirection, searchValue, searchProperty } = compSetFilters;

    const [compSets, setCompSets] = useState([]);
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [tempSearchValue, setTempSearchValue] = useState('');
    const [deleting, setDeleting] = useState(false);
    const [compSetToEdit, setCompSetToEdit] = useState(null);
    const [compSetToDelete, setCompSetToDelete] = useState(null);
    const [totalPages, setTotalPages] = useState(1);
    const refreshCount = paginationCurrentPageNumber === 1;

    const { data, isLoading, error, refetch } = useQuery(
        ['comparativeSets', refreshCount, paginationCurrentPageNumber, activeSortProperty, activeSortDirection, searchProperty, searchValue],
        () =>
            getComparativeSets(
                false,
                refreshCount,
                paginationCurrentPageNumber,
                10,
                activeSortProperty,
                activeSortDirection,
                searchProperty,
                searchValue
            )
    );

    useEffect(() => {
        if (data && data?.result && data?.navigation) {
            setCompSets(data.result);

            if (refreshCount) setTotalPages(data.navigation.total_pages);
            if (tempSearchValue !== searchValue) setTempSearchValue(searchValue);
        }
    }, [data]);

    useEffect(() => {
        error && handleError(error);
    }, [error]);

    const handleSearchValueSubmit = () => {
        if (tempSearchValue.length > 2048) {
            addFlashMessage('danger', 'Your search exceeds the maximum number of allowed characters (2048)');
            return;
        }

        const filters = { ...compSetFilters };
        filters.searchValue = tempSearchValue;
        filters.paginationCurrentPageNumber = 1;
        setFiltersObject('compSets', filters);
    };

    const handleClearSearchValue = () => {
        const filters = { ...compSetFilters };
        filters.searchValue = '';
        filters.paginationCurrentPageNumber = 1;

        setFiltersObject('compSets', filters);
        setTempSearchValue('');
    };

    const toggleCreateModal = () => setCreateModalOpen(!createModalOpen);
    const toggleDeleteModal = () => setDeleteModalOpen(!deleteModalOpen);

    const toggleEditModal = () => {
        if (editModalOpen) {
            setEditModalOpen(false);
            setCompSetToEdit(null);
        } else {
            setEditModalOpen(true);
        }
    };

    useEffect(() => {
        if (compSetToEdit) setEditModalOpen(true);
    }, [compSetToEdit]);

    useEffect(() => {
        if (compSetToDelete) setDeleteModalOpen(true);
    }, [compSetToDelete]);

    useEffect(() => {
        if (activeSet) {
            const foundSet = compSets.find((item) => item.id === activeSet.id);

            if (!isEqual(activeSet, foundSet)) {
                handleCompSetSelect(foundSet);
            }
        }
    }, [activeSet, compSets]);

    const deleteCompSet = async (id, modalCallback) => {
        try {
            setDeleting(true);
            await deleteComparativeSet(id);
            modalCallback && modalCallback();
            setDeleting(false);
            addFlashMessage('success', 'Comparative set successfully deleted');
            setCompSetToDelete(null);
            await refetch();
        } catch (err) {
            setDeleting(false);
            handleError(err);
        }
    };

    const handlePageClick = (data) => {
        let filters = { ...compSetFilters };
        filters.paginationCurrentPageNumber = data.selected + 1;
        filters.useCount = false;
        setFiltersObject('compSet', filters);
    };

    const handleSortableHeaderClick = (value) => {
        const filters = { ...compSetFilters };

        if (value === activeSortProperty) {
            activeSortDirection === 'asc' ? (filters.activeSortDirection = 'desc') : (filters.activeSortDirection = 'asc');
        } else {
            filters.activeSortProperty = value;
            filters.activeSortDirection = 'asc';
        }
        setFiltersObject('compSets', filters);
    };

    return (
        <Card className="comparative-analysis-list">
            <CardBody style={{ minHeight: 0 }}>
                <div className="d-flex mb-4 comparative-analysis-list__toolbar">
                    <SearchInputWithSubmit
                        value={tempSearchValue}
                        onChange={setTempSearchValue}
                        placeholder="Search by comparative set name"
                        onClear={handleClearSearchValue}
                        onSubmit={handleSearchValueSubmit}
                        submitting={isLoading}
                        list
                    />

                    <Button className="comparative-analysis-list__create-btn" onClick={toggleCreateModal} color="primary">
                        Create Set
                    </Button>
                </div>

                <div>
                    {isLoading ? (
                        <LoadingSpinner />
                    ) : compSets.length < 1 ? (
                        <div>No comparative sets found</div>
                    ) : (
                        <div>
                            <Table className="comp-set-table responsive-table">
                                <tbody>
                                    <tr className="responsive-table header-row">
                                        <th className="responsive-table__sortable-th" onClick={() => handleSortableHeaderClick('name')}>
                                            Name
                                            {handleCaret('name', activeSortDirection, 'name')}
                                        </th>

                                        <th>Alerts</th>
                                        <th>Compare</th>
                                        <th>Download</th>
                                        <th>Edit/Delete</th>
                                    </tr>

                                    {compSets.map((compSet) => (
                                        <ListItem
                                            key={compSet.id}
                                            compSet={compSet}
                                            handleCompSetSelect={handleCompSetSelect}
                                            fetchCompSetStatistics={fetchCompSetStatistics}
                                            setCompSetToEdit={setCompSetToEdit}
                                            setCompSetToDelete={setCompSetToDelete}
                                            activeSet={activeSet}
                                            handleError={handleError}
                                        />
                                    ))}
                                </tbody>
                            </Table>

                            <PaginationWrapper
                                totalPages={totalPages}
                                handlePageClick={handlePageClick}
                                forcePage={paginationCurrentPageNumber - 1}
                            />
                        </div>
                    )}
                </div>
            </CardBody>

            {createModalOpen && (
                <CreateEditCompSetModal
                    isOpen={createModalOpen}
                    toggle={toggleCreateModal}
                    addFlashMessage={addFlashMessage}
                    refreshCompSets={refetch}
                    handleError={handleError}
                />
            )}

            {editModalOpen && (
                <CreateEditCompSetModal
                    isEditForm
                    isOpen={editModalOpen}
                    toggle={toggleEditModal}
                    item={compSetToDelete}
                    compSetToEdit={compSetToEdit}
                    setCompSetToEdit={setCompSetToEdit}
                    refreshCompSets={refetch}
                    addFlashMessage={addFlashMessage}
                    activeSet={activeSet}
                    compSets={compSets}
                    handleCompSetSelect={handleCompSetSelect}
                    handleError={handleError}
                />
            )}

            {deleteModalOpen && (
                <DeleteSetModal
                    isOpen={deleteModalOpen}
                    toggle={toggleDeleteModal}
                    item={compSetToDelete}
                    onSubmit={deleteCompSet}
                    submitting={deleting}
                />
            )}
        </Card>
    );
};
