import React from 'react';
import { Chart as ChartJS, CategoryScale, Filler, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { Line } from 'react-chartjs-2';
import moment from 'moment';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(CategoryScale, ChartDataLabels, Filler, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export const ClicksPerDayChart = ({ clicks }) => {
    const buildLabelDate = (dateString) => {
        if (dateString == null) return '';

        const dateObj = new Date((dateString + ' 00:00:00').replace(' ', 'T'));
        const momentObj = moment(dateObj);

        return momentObj.format('MMM DD,YYYY');
    };

    const getMonth = (index) => {
        const dateObj = new Date((clicks[index].day + ' 00:00:00').replace(' ', 'T'));
        const momentObj = moment(dateObj);

        return momentObj.format('MMM');
    };

    const convertDateString = (dateString) => {
        if (dateString == null) {
            return '';
        }

        const dateObj = new Date((dateString + ' 00:00:00').replace(' ', 'T'));
        const momentObj = moment(dateObj);

        return momentObj.format('DD');
    };

    const ticks = clicks && [...clicks.map((item) => convertDateString(item.day))];

    const getValue = (index) => {
        const item = ticks[index];
        const width = window.innerWidth;

        if (index === 0) {
            return getMonth(index);
        }

        if (width > 360 && width < 500) {
            if (index % 4 === 0 || index === 29) {
                if (item[0] === '0' && +ticks[index - 4][0] > +item[0]) {
                    return getMonth(index);
                }
                return item;
            } else {
                return;
            }
        }

        if (width >= 360 && index % 2 === 0) {
            if (item[0] === '0' && +ticks[index - 2][0] > +item[0]) {
                return getMonth(index);
            }
            return item;
        }

        if (width < 360) {
            if (item[0] === '0' && +ticks[index - 2][0] > +item[0]) {
                return getMonth(index);
            }
            return item;
        }
    };

    const clickData = {
        labels: clicks.map((item) => buildLabelDate(item.day)),
        datasets: [
            {
                label: 'Clicks',
                fill: true,
                backgroundColor: 'rgba(26,148,199,0.4)',
                tension: 0.2,
                borderColor: '#1a94c7',
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: 'rgba(75,192,192,1)',
                pointBackgroundColor: '#fff',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: 'rgba(26,148,199,1)',
                pointHoverBorderColor: 'rgba(220,220,220,1)',
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                maintainAspectRatio: false,
                data: clicks.map((item) => item.cnt),
            },
        ],
    };

    const max = clicks.map((item) => item.cnt).reduce((a, b) => Math.max(a, b));

    return (
        <div style={{ maxHeight: 300 }}>
            <Line
                data={clickData}
                height={300}
                options={{
                    legend: {
                        display: false,
                    },
                    maintainAspectRatio: false,
                    scales: {
                        x: {
                            ticks: {
                                autoSkip: false,
                                maxRotation: 50,
                                minRotation: 50,
                                stepSize: 1,
                                callback: (_, index) => getValue(index),
                            },
                        },
                        y: {
                            max: max + 1,
                        },
                    },
                    plugins: {
                        datalabels: {
                            display: true,
                            color: 'white',
                            backgroundColor: '#1a94c7',
                            borderRadius: 6,
                            font: {
                                size: 10,
                            },
                            formatter: (value) => {
                                if (value > 0) {
                                    return value;
                                } else {
                                    return null;
                                }
                            },
                        },
                    },
                }}
            />
        </div>
    );
};
