import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Card, CardHeader, CardBody } from 'reactstrap';
import { FlashMessageContext } from 'contexts/FlashMessageContext';
import { AuthContext } from 'contexts/AuthContext';
import { SpinnerButton } from 'common/SpinnerButton';
import { LoadingSpinner } from 'common/LoadingSpinner';
import { DualSelect } from 'common/DualSelect';
import { getAlertGroups, getAlertsWithoutParams, universalAssignAlerts } from 'utils/api/alertsAPI';
import { getUserGroups, getUsersWithoutParams } from 'utils/api/usersAPI';
import { ConfirmAllAssignment } from './ConfirmAllModal';

export const GlobalAssignment = () => {
    const navigate = useNavigate();
    const { addFlashMessage } = useContext(FlashMessageContext);
    const { handleError } = useContext(AuthContext);
    const [tempAssignedUsers, setTempAssignedUsers] = useState([]);
    const [tempAssignedAlerts, setTempAssignedAlerts] = useState([]);
    const [fetchingAlerts, setFetchingAlerts] = useState(true);
    const [fetchingUsers, setFetchingUsers] = useState(true);
    const [submitting, setSubmitting] = useState(false);
    const [users, setUsers] = useState([]);
    const [userGroups, setUserGroups] = useState([]);
    const [alerts, setAlerts] = useState([]);
    const [alertGroups, setAlertGroups] = useState([]);
    const [confirmAssignModalOpen, setConfirmAssignModalOpen] = useState(false);

    const fetchAlerts = async () => {
        !fetchingAlerts && setFetchingAlerts(true);

        try {
            const alertData = await getAlertsWithoutParams(false, false, 1, 2000);
            const alertGroupData = await getAlertGroups();
            setAlerts(alertData.result);
            setAlertGroups(alertGroupData);
        } catch (err) {
            addFlashMessage('danger', 'Unable to fetch alerts');
        }

        setFetchingAlerts(false);
    };

    const fetchUsers = async () => {
        !fetchingUsers && setFetchingUsers(true);

        try {
            const userData = await getUsersWithoutParams(false, false, 1, 2000);
            const userGroupData = await getUserGroups();
            setUsers(userData.result);
            setUserGroups(userGroupData);
        } catch (err) {
            handleError(err);
        }

        setFetchingUsers(false);
    };

    const onSubmit = async () => {
        try {
            if (!tempAssignedUsers.length || !tempAssignedAlerts) {
                addFlashMessage('danger', 'At least one user and one alert must be selected');
                return;
            }
            setSubmitting(true);

            if (tempAssignedAlerts.length === alerts.length) {
                toggleConfirmModal();
            } else {
                const params = {
                    users: tempAssignedUsers.map((user) => user.id),
                    alerts: tempAssignedAlerts.map((alert) => alert.id),
                };

                await universalAssignAlerts(params);
                addFlashMessage('success', 'Alerts successfully assigned');
                navigate(`/account/alerts`);
            }
        } catch (err) {
            handleError(err);
        }

        setSubmitting(false);
    };

    const toggleConfirmModal = () => setConfirmAssignModalOpen(!confirmAssignModalOpen);

    useEffect(() => {
        fetchAlerts();
        fetchUsers();
    }, []);

    return (
        <div className="view view--mobile-full global-assign">
            <div className="animated fadeIn">
                <Row>
                    <Col xs="12">
                        <Card>
                            <CardHeader>
                                <h1>Routing</h1>
                            </CardHeader>

                            <CardBody>
                                {fetchingAlerts || fetchingUsers ? (
                                    <div>
                                        <LoadingSpinner />
                                    </div>
                                ) : (
                                    <div className="mt-3">
                                        <div>
                                            <h3>Select Users</h3>

                                            <DualSelect
                                                selectedList={tempAssignedUsers}
                                                fullList={users}
                                                groups={userGroups}
                                                onSelect={setTempAssignedUsers}
                                                contentType="users"
                                                hideDoubleArrows
                                            />
                                        </div>

                                        <div className="mt-5">
                                            <h3>Select Content</h3>
                                            <DualSelect
                                                selectedList={tempAssignedAlerts}
                                                fullList={alerts}
                                                groups={alertGroups}
                                                onSelect={setTempAssignedAlerts}
                                                contentType="alerts"
                                                hideDoubleArrows
                                            />
                                        </div>

                                        <div className="global-assign__submit-container">
                                            <SpinnerButton color="primary" submitting={submitting} onClick={onSubmit} title="Save" />
                                        </div>
                                    </div>
                                )}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>

            {confirmAssignModalOpen && (
                <ConfirmAllAssignment
                    toggle={toggleConfirmModal}
                    isOpen={confirmAssignModalOpen}
                    tempAssignedUsers={tempAssignedUsers}
                    tempAssignedAlerts={tempAssignedAlerts}
                    addFlashMessage={addFlashMessage}
                    handleError={handleError}
                />
            )}
        </div>
    );
};
