import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { convertFormatString } from 'utils/helpers';
import { useDevice } from 'hooks/useMediaQuery';
import './style.scss';

export const CompanyPageHeader = ({ alertData, publicMode }) => {
    const { isMobile, isTablet, isDesktop } = useDevice();
    const [image, setImage] = useState(alertData.image || null);

    const convertWebsite = () => alertData.website?.split('/').slice(0, 3).join('/');
    const getAddress = () => alertData.address;

    const labelWebsite = () => {
        if (!convertWebsite()) return convertWebsite();

        const splitedWebsite = convertWebsite().split('://');
        return splitedWebsite.length === 2 ? splitedWebsite[1] : splitedWebsite[0];
    };

    const getGeo = () => {
        let geo = alertData.city ? `${alertData.city}` : '';

        geo += alertData.state ? `${geo ? ', ' : ''}${alertData.state}` : '';
        geo += alertData.country ? `${geo ? ', ' : ''}${alertData.country}` : '';
        return geo;
    };

    const getCompetitors = () => {
        return alertData.competitors?.slice(0, 10).map((item, index) => (
            <Fragment key={item.symbol + index}>
                {publicMode !== true ? (
                    <Link
                        to={
                            item?.alertId?.id
                                ? `/account/alerts/${item.alertId?.id}/company-profile`
                                : `/account/alerts/${item.symbol}/company-preview`
                        }>
                        {item.symbol}&nbsp;
                    </Link>
                ) : (
                    item.symbol + ' '
                )}
                {(index + 1) % 5 === 0 && <br />}
            </Fragment>
        ));
    };

    return (
        <Row className="col-12">
            {image !== null ? (
                <Col xs={isDesktop ? '1' : isTablet ? '2' : '3'}>
                    <div className="company-logo">
                        <img src={image} onError={() => setImage(null)} alt="Logo" />
                    </div>
                </Col>
            ) : null}

            <Row className={isDesktop ? 'col-11' : isTablet ? 'col-10' : 'col-9'}>
                <Col xs="12">
                    <div className="company-name">
                        <strong>
                            {alertData.companyName} ({alertData.symbol})
                        </strong>
                    </div>
                </Col>

                {isDesktop && (
                    <>
                        <Col xs="2">
                            <h4 className="company-header">CEO</h4>
                            <p className="company-header-info">{alertData.ceo}</p>
                        </Col>

                        <Col xs="2">
                            <h4 className="company-header">Sector</h4>
                            <p className="company-header-info">{alertData.sector}</p>
                        </Col>

                        <Col xs="2">
                            <h4 className="company-header">Industry</h4>
                            <p className="company-header-info">{alertData.industry}</p>
                        </Col>

                        <Col xs="3">
                            <h4 className="company-header">Company Peers</h4>
                            <div className="competitors">{getCompetitors()}</div>
                        </Col>

                        <Col xs="3" className="company-header-info-block">
                            <p className="company-header-info">{getAddress()}</p>
                            <p className="company-header-info">{getGeo()}</p>
                            <p className="company-header-info">{convertFormatString(alertData.phone, alertData.country)}</p>
                            <Link to={convertWebsite()} target="blank" className="company-header-info">
                                {labelWebsite()}
                            </Link>
                        </Col>
                    </>
                )}

                {isTablet && (
                    <>
                        <Col xs="6">
                            <div className="company-content-space-between">
                                <h4 className="company-header">CEO</h4>
                                <p className="company-header-info">{alertData.ceo}</p>
                            </div>

                            <div className="company-content-space-between">
                                <h4 className="company-header">Sector</h4>
                                <p className="company-header-info">{alertData.sector}</p>
                            </div>

                            <div className="company-content-space-between">
                                <h4 className="company-header">Industry</h4>
                                <p className="company-header-info">{alertData.industry}</p>
                            </div>
                        </Col>

                        <Col xs="6" className="company-header-info-block">
                            <p className="company-header-info">{getAddress()}</p>
                            <p className="company-header-info">{getGeo()}</p>
                            <p className="company-header-info">{convertFormatString(alertData.phone, alertData.country)}</p>

                            <Link to={convertWebsite()} target="blank" className="company-header-info">
                                {labelWebsite()}
                            </Link>
                        </Col>

                        <Col xs="6">
                            <div className="company-content-space-between">
                                <h4 className="company-header">Company Peers</h4>
                                <div className="competitors">{getCompetitors()}</div>
                            </div>
                        </Col>
                    </>
                )}

                {isMobile && (
                    <>
                        <Col xs="12">
                            <Row>
                                <Col xs="6">
                                    <h4 className="company-header">CEO</h4>
                                </Col>
                                <Col xs="6">
                                    <p className="company-header-info">{alertData.ceo}</p>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs="6">
                                    <h4 className="company-header">Sector</h4>
                                </Col>
                                <Col xs="6">
                                    <p className="company-header-info">{alertData.sector}</p>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs="6">
                                    <h4 className="company-header">Industry</h4>
                                </Col>
                                <Col xs="6">
                                    <p className="company-header-info">{alertData.industry}</p>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs="6">
                                    <h4 className="company-header">Company Peers</h4>
                                </Col>
                                <Col xs="6">
                                    <div className="competitors">{getCompetitors()}</div>
                                </Col>
                            </Row>

                            <Row className="mt-3">
                                <Col xs="12" className="company-header-info-block">
                                    <p className="company-header-info">{getAddress()}</p>
                                    <p className="company-header-info">{getGeo()}</p>
                                    <p className="company-header-info">{convertFormatString(alertData.phone, alertData.country)}</p>
                                    <Link to={convertWebsite()} target="blank" className="company-header-info">
                                        {labelWebsite()}
                                    </Link>
                                </Col>
                            </Row>
                        </Col>
                    </>
                )}
            </Row>
        </Row>
    );
};
