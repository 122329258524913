import React, { Suspense, useState, useContext, lazy, useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CFooter, CHeader } from '@coreui/react';
import { AuthContext } from 'contexts/AuthContext';
import { useDevice } from 'hooks/useMediaQuery';
import { Sidebar } from './Sidebar';

const Footer = lazy(() => import('components/Layout/Footer/index'));
const Header = lazy(() => import('components/Layout/Header/index'));

export const Layout = ({ children }) => {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const { state } = useContext(AuthContext);
    const { isMobile } = useDevice();

    const [isCloseSidebar, setIsCloseSidebar] = useState(false);
    const [isCloseBurger, setIsCloseBurger] = useState(isMobile);

    const isReader = pathname?.includes('/account/reader');
    const isNewsletterContent = pathname?.includes('/account/newsletters/content');
    const isNarrow = isReader || isNewsletterContent;

    const loading = useCallback(
        () => (
            <div className="animated fadeIn pt-1 text-center">
                <div className="sk-spinner sk-spinner-pulse" />
            </div>
        ),
        []
    );

    useEffect(() => {
        setIsCloseBurger(isMobile);
    }, [isMobile]);

    useEffect(() => {
        setIsCloseSidebar(isNarrow);
    }, [isNarrow]);

    if (!state.isAuthenticated) navigate('/signin');

    return (
        <div className="app">
            <CHeader style={{ zIndex: '1050' }}>
                <Suspense fallback={loading()}>
                    <Header isCloseBurger={isCloseBurger} setIsCloseBurger={setIsCloseBurger} />
                </Suspense>
            </CHeader>
            <div className="app-body">
                <Sidebar isCloseSidebar={isCloseSidebar} setIsCloseSidebar={setIsCloseSidebar} isCloseBurger={isCloseBurger} />
                <main className={'main ' + (isNarrow ? 'reader ' : '') + (isCloseBurger ? '' : isCloseSidebar ? 'm-50' : 'm-200')}>
                    <Suspense fallback={loading()}>{children}</Suspense>
                </main>
            </div>
            <CFooter className={isCloseBurger ? '' : isCloseSidebar ? 'm-50' : 'm-200'}>
                <Suspense fallback={loading()}>
                    <Footer />
                </Suspense>
            </CFooter>
        </div>
    );
};
