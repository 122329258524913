import React from 'react';
import moment from 'moment-timezone';
import { Line } from 'react-chartjs-2';
import { useDevice } from 'hooks/useMediaQuery';

export const SentimentChart = (props) => {
    const { isMobile } = useDevice();

    const data = {
        labels: props.data[0].days.map((item) => moment(item.day).format('MMM DD')),
        datasets: [
            {
                data: props.data[0].days.map((item) => item.value),
                label: 'Positive',
                tension: 0.2,
                borderColor: 'green',
                backgroundColor: 'green',
                fill: false,
            },
            {
                data: props.data[1].days.map((item) => item.value),
                label: 'Neutral',
                tension: 0.2,
                borderColor: '#a7a7a7',
                backgroundColor: '#a7a7a7',
                fill: false,
            },
            {
                data: props.data[2].days.map((item) => item.value),
                label: 'Negative',
                tension: 0.2,
                borderColor: '#e85354',
                backgroundColor: '#e85354',
                fill: false,
            },
        ],
    };

    return (
        <div style={{ maxHeight: isMobile ? 500 : 300, width: '100%', flex: 1 }}>
            <Line
                data={data}
                height={isMobile ? 500 : 300}
                options={{
                    legend: {
                        display: true,
                        position: 'top',
                        paddingLeft: 10,
                        labels: {
                            boxWidth: isMobile ? 20 : 40,
                            fontSize: isMobile ? 15 : 12,
                        },
                    },
                    maintainAspectRatio: false,
                    scales: {
                        x: {
                            ticks: {
                                maxTicksLimit: 15,
                            },
                        },
                    },
                    plugins: {
                        datalabels: {
                            display: false,
                        },
                    },
                }}
            />
        </div>
    );
};
