import React from 'react';
import { Row, Col, Card, CardHeader, CardBody } from 'reactstrap';
import { NewslettersList } from './NewslettersList';

export const Newsletters = () => (
    <div className="view view--mobile-full newsletters">
        <div className="animated fadeIn">
            <Row>
                <Col xs="12">
                    <Card>
                        <CardHeader>
                            <div className="flex-space-between">
                                <h1>Newsletters</h1>
                            </div>
                        </CardHeader>

                        <CardBody>
                            <NewslettersList />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    </div>
);
