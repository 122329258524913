import { axios } from 'lib/axios';

export const login = (username, password) => {
    const params = { _username: username, _password: password };

    return axios.post('/api/v3/security/get_token', params);
};

export const signUp = (email, targetUrl) => {
    const params = { email, targetUrl };

    return axios.post('/api/v3/security/registration', params);
};

export const checkRegistrationToken = (token) => {
    return axios.patch('/api/v3/security/registration/confirm', { token });
};

export const finishRegistration = (params) => {
    return axios.patch('/api/v3/security/registration/finish', params);
};

export const resetPasswordRequest = (params) => {
    return axios.post('/api/v3/security/reset_password/request', params);
};

export const checkEmailToken = (token) => {
    return axios.patch('/api/v3/security/confirm_new_email', { token });
};

export const resetPassword = (params) => {
    return axios.patch('/api/v3/security/reset_password', params);
};

export const getProfile = () => {
    return axios.get('/api/v3/profile');
};

export const updateProfile = (params) => {
    return axios.patch('/api/v3/profile', params);
};

export const switchToOrganization = (params) => {
    return axios.patch('/api/v3/profile/switch_to_organization', params);
};

export const loginToReader = (username, password) => {
    const params = { email: username, password: password };

    return axios.post('/api/v3/security/reader', params);
};
