import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Col, Input, InputGroup, InputGroupText, Row } from 'reactstrap';
import { AuthContext } from 'contexts/AuthContext';
import { SpinnerButton } from 'common/SpinnerButton';
import { login } from 'utils/api/authAPI';
import Logo from 'images/logo.png';

export const MobileSignIn = ({ publicEmail }) => {
    const { handleError, signIn, authorizeManagePublicReader } = useContext(AuthContext);
    const [submitting, setSubmitting] = useState(false);
    const [password, setPassword] = useState('');

    const onSubmit = async () => {
        setSubmitting(true);

        try {
            const res = await login(publicEmail, password);
            if (res.content?.area && res.content?.area === 'reader') {
                authorizeManagePublicReader(res.content.token);
            } else {
                signIn(res.content.token);
            }
        } catch (err) {
            handleError(err);
        }
        setSubmitting(false);
    };

    return (
        <div className="mobile-reader-list" style={{ backgroundColor: 'white' }}>
            <div className="auth-block">
                <div className="animated fadeIn">
                    <Row className="justify-content-center mt-5" style={{ marginBottom: '.5rem' }}>
                        <Col md={9}>
                            <div className="auth-block__image-container">
                                <a href="/">
                                    <img src={Logo} alt="Ozmosys" className="auth-block__image img-fluid" />
                                </a>
                            </div>
                            <div className="text-center">
                                <h2>Sign In</h2>
                                <p className="mb-4 mt-3 text-muted">Enter your password to manage alerts</p>
                            </div>

                            <InputGroup>
                                <InputGroupText>@</InputGroupText>
                                <Input placeholder="Email" name="email" value={publicEmail} type="email" disabled autoComplete="email" />
                            </InputGroup>

                            <InputGroup>
                                <InputGroupText>
                                    <i className="fa fa-lock" />
                                </InputGroupText>
                                <Input
                                    placeholder="Password"
                                    name="password"
                                    value={password}
                                    autoComplete="password"
                                    onChange={(e) => setPassword(e.target.value)}
                                    type="password"
                                />
                            </InputGroup>

                            <Row className="mb-4">
                                <Col xs="12" className="text-left">
                                    <SpinnerButton
                                        color="primary"
                                        block
                                        disabled={password.length < 1}
                                        onClick={onSubmit}
                                        submitting={submitting}
                                        title={'Login'}
                                    />
                                    <div className="mt-1">
                                        <Link to={'/forgot-password'}>Set or reset password</Link>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
};
