import React, { useState, useEffect, useRef } from 'react';
import { AlertPreviewItem } from 'components/Alerts/components/Preview/AlertPreviewItem';
import { LoadingSpinner } from 'common/LoadingSpinner';
import { PaginationWrapper } from 'common/PaginationWrapper';
import { AddLabelsBlock } from '../AddLabelsBlock';

export const RssPreview = ({
    preview,
    updatingPreview,
    selectedLabels,
    labels,
    addLabel,
    addToSelected,
    removeFromSelected,
    creatingLabel,
    isSingleUser,
}) => {
    const ref = useRef();
    const [pageNumbers, setPageNumbers] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        const pageNumbers = [];

        for (let i = preview.content.length; i > 0; i -= 10) {
            pageNumbers.push(i);
        }

        setPageNumbers(pageNumbers);
        setCurrentPage(1);
    }, [preview]);

    const handlePaginationClick = (e) => {
        setCurrentPage(e.selected + 1);
        ref?.current && ref.current?.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <div className="mt-2 internal-preview-list bordered-preview-list">
            <div className="bordered-preview-list__summary" ref={ref}>
                <strong>
                    {preview?.count} {preview?.count === 1 ? 'article' : 'articles'} found
                </strong>
            </div>
            <div className="mb-3 d-flex">
                <div className="bordered-preview-list__left">
                    <div style={{ marginTop: '20px' }}>
                        {preview?.content?.length ? (
                            preview.content
                                .slice(currentPage * 10 - 10, currentPage * 10)
                                .map((item) => <AlertPreviewItem item={item} key={item.id} withHour />)
                        ) : (
                            <div>
                                <h5>No results found</h5>
                            </div>
                        )}
                    </div>
                </div>

                <div className="bordered-preview-list__right mobile-hide">
                    {updatingPreview ? (
                        <div className="relevance-selector-container">
                            <LoadingSpinner text="Applying search relevance" />
                        </div>
                    ) : (
                        <AddLabelsBlock
                            selectedLabels={selectedLabels}
                            labels={labels}
                            addLabel={addLabel}
                            addToSelected={addToSelected}
                            removeFromSelected={removeFromSelected}
                            creatingLabel={creatingLabel}
                            isSingleUser={isSingleUser}
                        />
                    )}
                </div>
            </div>
            {pageNumbers?.length > 1 && (
                <PaginationWrapper totalPages={pageNumbers.length} handlePageClick={handlePaginationClick} forcePage={currentPage - 1} />
            )}
        </div>
    );
};
