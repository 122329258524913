import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'reactstrap';
import { PaginationWrapper } from 'common/PaginationWrapper';
import { RadioInput } from 'common/Input';
import { AlertPreviewItem } from 'components/Alerts/components/Preview/AlertPreviewItem';
import { GeoDropdownFilter } from '../Dropdowns/GeoDropdownFilter';
import { FilterDropdown } from '../Dropdowns/FilterDropdown';

export const Preview = ({
    preview,
    setActivePreviewSortValue,
    activeCountryFilter,
    setActiveCountryFilter,
    activeRegionFilter,
    setActiveRegionFilter,
    additionalCategories,
    activeAdditionalCategoryFilter,
    setActiveAdditionalCategoryFilter,
    typeOfSource,
    setTypeOfSource,
    countries,
    regions,
}) => {
    const ref = useRef(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    useEffect(() => {
        setTotalPages(Math.ceil(preview?.content?.length / 10));
        setCurrentPage(1);
    }, [preview]);

    const clearAllFilters = () => {
        setActiveCountryFilter('');
        setActiveRegionFilter('');
        setActiveAdditionalCategoryFilter(null);
        setActivePreviewSortValue('date-new');
        setTypeOfSource('all');
    };

    const handlePageClick = (e) => {
        setCurrentPage(e.selected + 1);
        ref?.current && ref.current?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
    };

    return (
        <div className="mt-2 internal-preview-list bordered-preview-list">
            <div className="bordered-preview-list__summary" style={{ display: 'flex', flexWrap: 'wrap' }}>
                <div>
                    <RadioInput onChange={() => setTypeOfSource('all')} checked={typeOfSource === 'all'} name="all" text="All Sources" />
                    <RadioInput onChange={() => setTypeOfSource('legal')} checked={typeOfSource === 'legal'} name="all" text="Legal Sourcess" />
                </div>

                <div style={{ width: 'calc(100% - 150px)', display: 'flex', flexWrap: 'wrap' }}>
                    <GeoDropdownFilter
                        activeCountryFilter={activeCountryFilter}
                        setActiveCountryFilter={setActiveCountryFilter}
                        setActiveRegionFilter={setActiveRegionFilter}
                        countries={countries || []}
                        isCountyFilter
                        style={{ marginLeft: '0' }}
                    />

                    {!!regions?.length && (
                        <GeoDropdownFilter
                            activeRegionFilter={activeRegionFilter}
                            setActiveRegionFilter={setActiveRegionFilter}
                            regions={regions || []}
                            style={{ marginLeft: '0' }}
                        />
                    )}

                    <FilterDropdown
                        title="AI Category"
                        activeFilter={activeAdditionalCategoryFilter}
                        setActiveFilter={setActiveAdditionalCategoryFilter}
                        list={additionalCategories}
                    />

                    <Button color="link" className="btn-clear-all" onClick={clearAllFilters}>
                        Clear all
                    </Button>
                </div>
            </div>

            <div className="mb-3 d-flex" ref={ref}>
                <div className="bordered-preview-list__left">
                    <strong>
                        {preview.count} {preview.count === 1 ? 'article' : 'articles'} matched your filters for the last 30 days&nbsp;&nbsp;
                    </strong>

                    <div style={{ marginTop: '20px' }}>
                        {preview?.content?.length ? (
                            preview.content
                                .slice(currentPage * 10 - 10, currentPage * 10)
                                .map((item) => <AlertPreviewItem item={item} key={item.id} withHour />)
                        ) : (
                            <div>
                                <h5>No results found</h5>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            {totalPages > 1 && <PaginationWrapper totalPages={totalPages} forcePage={currentPage - 1} handlePageClick={handlePageClick} />}
        </div>
    );
};
