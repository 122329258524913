import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from 'reactstrap';
import { useDevice } from 'hooks/useMediaQuery';
import { CATEGORY_TYPE, COMPANY_TYPE, SEARCH_TYPE, SOURCE_TYPE } from 'constants/alerts';
import { ListLabelDropdown } from 'common/Labels/ListLabelDropdown';
import { SearchInputWithSubmit } from 'common/SearchInput/SearchInputWithSubmit';
import { SourceLookup } from 'components/Alerts/AlertsList/SourceLookup';
import { AlertListMobileFilterDropdown } from './AlertListMobileFilterDropdown';
import { AlertListCsvButton } from './AlertListCsvButton';
import { ManageCategoryModal } from './Modals/ManageCategory/ManageCategoryModal';

export const AlertsListHeader = ({
    type,
    searchValue,
    setSearchValue,
    handleClearSearchValue,
    handleSearchValueSubmit,
    submittingSearch,
    allLabels,
    activeFilterLabels,
    handleActiveFilterLabelSelect,
    clearActiveLabels,
    downloadFilteredCsv,
    fetchingCsv,
    handleMobileFilterSelect,
    activeMobileFilterValue,
    searchTypeId,
    refetch,
}) => {
    const { isDesktop, isTablet, isMobile } = useDevice();

    const getSearchPlaceholder = () => {
        if (type === SEARCH_TYPE) return 'Filter by name or keyword';
        if (type === SOURCE_TYPE || type === CATEGORY_TYPE) return 'Filter by title';
        if (type === COMPANY_TYPE) return 'Filter by company or ticker';
    };

    if (isDesktop) {
        return (
            <div className="list__utility-row list__utility-row--desktop">
                <div className="d-flex">
                    <HeaderButtons type={type} searchTypeId={searchTypeId} refetch={refetch} isDesktop />
                </div>
                <div className="list__search-container">
                    <SearchInputWithSubmit
                        value={searchValue}
                        onChange={setSearchValue}
                        placeholder={getSearchPlaceholder()}
                        onClear={handleClearSearchValue}
                        onSubmit={handleSearchValueSubmit}
                        extraParams={null}
                        submitting={submittingSearch}
                        list
                    />
                    <div className="mr-2">
                        <ListLabelDropdown
                            labels={allLabels}
                            activeFilterLabels={activeFilterLabels}
                            handleActiveFilterLabelSelect={handleActiveFilterLabelSelect}
                        />
                    </div>
                    {activeFilterLabels.length > 0 && (
                        <div className="ml-3">
                            {activeFilterLabels.length + ' ' + ((activeFilterLabels.length === 1 ? 'label' : 'labels') + ' selected')}
                            <span className="list__utility-row__clear-labels-btn" onClick={clearActiveLabels}>
                                ×
                            </span>
                        </div>
                    )}
                </div>
                {(type === SEARCH_TYPE || type === SOURCE_TYPE) && (
                    <div className="mr-2">
                        <SourceLookup />
                    </div>
                )}
                <div className="d-flex">
                    <AlertListCsvButton downloadFilteredCsv={downloadFilteredCsv} downloading={fetchingCsv} desktopSize />
                </div>
            </div>
        );
    }

    if (isTablet) {
        return (
            <div className="list__utility-row list__utility-row--tablet">
                <div className="d-flex justify-content-between w-100">
                    <div className="list__search-container">
                        {type === CATEGORY_TYPE && (
                            <div className="mr-2 d-flex">
                                <HeaderButtons searchTypeId={searchTypeId} refetch={refetch} type={type} />
                            </div>
                        )}
                        <SearchInputWithSubmit
                            value={searchValue}
                            onChange={setSearchValue}
                            placeholder={getSearchPlaceholder()}
                            onClear={handleClearSearchValue}
                            onSubmit={handleSearchValueSubmit}
                            extraParams={null}
                            submitting={submittingSearch}
                            list
                        />
                    </div>
                </div>
                <div className="d-flex mt-2 w-100 justify-content-end">
                    {type !== CATEGORY_TYPE && (
                        <div className="mr-2 d-flex">
                            <HeaderButtons type={type} />
                        </div>
                    )}
                    <div className="mr-2">
                        <ListLabelDropdown
                            labels={allLabels}
                            activeFilterLabels={activeFilterLabels}
                            handleActiveFilterLabelSelect={handleActiveFilterLabelSelect}
                            useIconOnly
                        />
                    </div>
                    <div>
                        <AlertListCsvButton downloadFilteredCsv={downloadFilteredCsv} downloading={fetchingCsv} />
                    </div>
                    <AlertListMobileFilterDropdown
                        handleMobileFilterSelect={handleMobileFilterSelect}
                        activeMobileFilterValue={activeMobileFilterValue}
                    />
                </div>
            </div>
        );
    }

    if (isMobile) {
        return (
            <div className="list-utility-row list__utility-row--mobile">
                {(type === SOURCE_TYPE || type === COMPANY_TYPE) && (
                    <>
                        <div className="mr-2 mb-2 d-flex">
                            <HeaderButtons searchTypeId={searchTypeId} type={type} />
                        </div>
                        <div className="d-flex mb-2">
                            <AlertListCsvButton downloadFilteredCsv={downloadFilteredCsv} downloading={fetchingCsv} showText />
                        </div>
                        <div className="d-flex mb-2">
                            <AlertListMobileFilterDropdown
                                handleMobileFilterSelect={handleMobileFilterSelect}
                                activeMobileFilterValue={activeMobileFilterValue}
                            />
                            <div className="ml-2">
                                <ListLabelDropdown
                                    labels={allLabels}
                                    activeFilterLabels={activeFilterLabels}
                                    handleActiveFilterLabelSelect={handleActiveFilterLabelSelect}
                                    useIconOnly
                                />
                            </div>
                        </div>
                    </>
                )}
                {type === SEARCH_TYPE && (
                    <>
                        <div className="d-flex justify-content-between mb-2 w-100">
                            <SourceLookup />
                        </div>
                        <div className="d-flex mb-2">
                            <AlertListCsvButton downloadFilteredCsv={downloadFilteredCsv} downloading={fetchingCsv} showText mobileSize />
                            <HeaderButtons searchTypeId={searchTypeId} type={type} isMobile />
                        </div>
                        <div className="d-flex mb-2">
                            <AlertListMobileFilterDropdown
                                handleMobileFilterSelect={handleMobileFilterSelect}
                                activeMobileFilterValue={activeMobileFilterValue}
                            />
                            <div className="ml-2">
                                <ListLabelDropdown
                                    labels={allLabels}
                                    activeFilterLabels={activeFilterLabels}
                                    handleActiveFilterLabelSelect={handleActiveFilterLabelSelect}
                                    useIconOnly
                                />
                            </div>
                        </div>
                    </>
                )}
                {type === CATEGORY_TYPE && (
                    <>
                        <div className="d-flex mb-2">
                            <AlertListCsvButton downloadFilteredCsv={downloadFilteredCsv} downloading={fetchingCsv} showText />
                        </div>
                        <div className="d-flex mb-2">
                            <AlertListMobileFilterDropdown
                                handleMobileFilterSelect={handleMobileFilterSelect}
                                activeMobileFilterValue={activeMobileFilterValue}
                            />
                            <div className="ml-2">
                                <ListLabelDropdown
                                    labels={allLabels}
                                    activeFilterLabels={activeFilterLabels}
                                    handleActiveFilterLabelSelect={handleActiveFilterLabelSelect}
                                    useIconOnly
                                />
                            </div>
                        </div>
                        <div className="mb-3">
                            <HeaderButtons searchTypeId={searchTypeId} type={type} refetch={refetch} isMobile />
                            <SearchInputWithSubmit
                                value={searchValue}
                                onChange={setSearchValue}
                                placeholder={getSearchPlaceholder()}
                                onClear={handleClearSearchValue}
                                onSubmit={handleSearchValueSubmit}
                                extraParams={null}
                                submitting={submittingSearch}
                                list
                            />
                        </div>
                    </>
                )}
                {type !== CATEGORY_TYPE && (
                    <div className="mb-3">
                        <SearchInputWithSubmit
                            value={searchValue}
                            onChange={setSearchValue}
                            placeholder={getSearchPlaceholder()}
                            onClear={handleClearSearchValue}
                            onSubmit={handleSearchValueSubmit}
                            extraParams={null}
                            submitting={submittingSearch}
                            list
                        />
                    </div>
                )}
                {activeFilterLabels.length > 0 && (
                    <div className="list__utility-row__clear-labels-mobile">
                        <span>
                            {activeFilterLabels.length + ' ' + ((activeFilterLabels.length === 1 ? 'label' : 'labels') + ' selected')}
                            <span onClick={clearActiveLabels} className="list__utility-row__clear-labels-btn">
                                ×
                            </span>
                        </span>
                    </div>
                )}
            </div>
        );
    }
};

const HeaderButtons = ({ type, searchTypeId, isMobile, isDesktop, refetch }) => {
    const navigate = useNavigate();
    const { category } = useParams();
    const [manageCategoryModalOpen, setManageCategoryModalOpen] = useState(false);

    const togglePreviewModal = () => {
        setManageCategoryModalOpen(!manageCategoryModalOpen);
    };

    return (
        <>
            {type === SOURCE_TYPE && (
                <>
                    <Button onClick={() => navigate('/account/sources/create?type=rss')} color="primary" className="mr-2">
                        Add RSS
                    </Button>
                    <Button onClick={() => navigate('/account/sources/create?type=email_to_rss')} color="primary" className="mr-2">
                        Add Inbox/Email
                    </Button>
                </>
            )}
            {type === SEARCH_TYPE && (
                <div className={'d-flex' + (isMobile ? ' w-50' : '')}>
                    <Button
                        style={{ width: '100%' }}
                        onClick={() => navigate('/account/alerts/create')}
                        color="primary"
                        className={isMobile ? '' : 'mr-2'}>
                        {!isDesktop && (
                            <>
                                <i className="fa fa-plus" style={{ margin: 0 }} />
                                &nbsp;
                            </>
                        )}
                        Create Search
                    </Button>
                </div>
            )}
            {type === COMPANY_TYPE && (
                <div className="d-flex">
                    <Button onClick={() => navigate('/account/companies/create')} color="primary" className="mr-2">
                        Add a company
                    </Button>
                </div>
            )}
            {type === CATEGORY_TYPE && (
                <Button
                    color="primary"
                    className={isDesktop ? 'mr-2' : ''}
                    style={{ minWidth: '88.34px' }}
                    onClick={() => setManageCategoryModalOpen(true)}>
                    Manage {category}
                </Button>
            )}

            {manageCategoryModalOpen && (
                <ManageCategoryModal
                    isOpen={manageCategoryModalOpen}
                    setIsOpen={togglePreviewModal}
                    searchType={category}
                    searchTypeId={searchTypeId}
                    refetch={refetch}
                />
            )}
        </>
    );
};
